import React from 'react'
import RestrictedFileAssociationDialog from '../../RestrictedFileAssociationDialog/RestrictedFileAssociationDialog'
import UserAccessGrid from '../../common/UserAccessGrid/UserAccessGrid';
import { IRestrictedUser } from '../../../interfaces/IRestrictedUser';
import { Box, Button, Grid, Tooltip, Typography } from '@material-ui/core';
import IRestrictedUserListDto from '../../../interfaces/IRestrictedUserListDto';
import DataReadService from '../../../services/DataReadService';
import { useSelector } from 'react-redux';
import ICurrentUser from '../../../interfaces/ICurrentUser';
import IDocument from '../../../interfaces/IDocument';
import { Link } from 'react-router-dom';
import { Group } from '@material-ui/icons';
import DetailSection from '../../common/DetailSectionHeader/DetailSection/DetailSection';
import { DetailSectionHeader } from '../../common/DetailSectionHeader/DetailSectionHeader';

interface Props {
    document: IDocument | null;
    onUpdateAccess: (isPrivate: boolean, usersWithAccess: string[]) => void;
    hasRestrictedParent: boolean;
    isAssociatedWithRestrictedRequest: boolean;
}

export default function DocumentFileAccessDetails({ document, onUpdateAccess, hasRestrictedParent, isAssociatedWithRestrictedRequest }: Props) {
    const [showRestrictedFileAssociationDialog, setShowRestrictedFileAssociationDialog] = React.useState(false);
    const [data, setData] = React.useState<IRestrictedUser[] | null>(null);
    const [loading, setLoading] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const currentUser = useSelector((state: any) => state.currentUser as ICurrentUser);

    const showAccessGrid = !(isAssociatedWithRestrictedRequest || hasRestrictedParent);

    const selectedDocumentGuid: string | null = React.useMemo(() => {
        if (document) {
            return document.documentGuid;
        }
        return null;
    }, [document?.documentGuid])

    React.useEffect(() => {
        if (selectedDocumentGuid) {
            setLoaded(false);
        }
        else {
            setLoaded(true);
        }
    }, [selectedDocumentGuid])

    React.useEffect(() => {
        getRestrictedUserList();
    }, [selectedDocumentGuid, document?.isPrivate, loaded])

    const getRestrictedUserList = async () => {
        if (!document || loading || loaded) return;

        if (document.isPrivate) {
            setLoading(true);
            const dataReadService = new DataReadService();

            const dto: IRestrictedUserListDto = {
                documentId: document.documentGuid,
                folderId: document.parentGuid
            }

            const list = await dataReadService.GetRestrictedUserList(dto);
            if (list.status) {
                const maGroupName = "Moss Adams User";
                const clientGroupName = "Client User"
                const groupedData = list.data.map(restrictedUser => {
                    return {
                        ...restrictedUser,
                        firstLetter: restrictedUser.displayName?.charAt(0),
                        groupName: restrictedUser.isMossAdamsStaff ? maGroupName : clientGroupName
                    }
                })

                const dataArray = groupedData.filter(user => user.hasAccess);
                setData(dataArray);
            }
        }
        else {
            setData([]);
        }

        setLoading(false);
        setLoaded(true);
    }

    const showManageButton = document && data && currentUser.isMossAdamsStaff 
        && (data.some(restrictedUser => restrictedUser.userId === currentUser?.userId) 
            || data.every(restrictedUser => !restrictedUser.isMossAdamsStaff));

    return <>
        <Grid container>
            <DetailSectionHeader title="Users With Access">
                {showManageButton && showAccessGrid &&
                    <Tooltip title="Manage Access">
                        <span>
                            <Button size='small' color='primary' variant='contained'
                                onClick={() => setShowRestrictedFileAssociationDialog(true)}
                            >
                                <Group />
                            </Button>
                        </span>
                    </Tooltip>
                }
            </DetailSectionHeader>
            <DetailSection>
                {showAccessGrid ?
                    <UserAccessGrid
                        loading={loading}
                        data={data}
                    />
                    : <Box height={210}>
                        <Typography >
                            {isAssociatedWithRestrictedRequest && <>
                                Access to this file is restricted to the assignees of the associated request.
                            </>}
                            {hasRestrictedParent && <>
                                Access to this file is managed via its <Link to={`/document-management/folder/${document?.parentGuid}`}>folder</Link>.
                            </>}
                        </Typography>
                    </Box>
                }
            </DetailSection>
        </Grid>

        <RestrictedFileAssociationDialog
            open={showRestrictedFileAssociationDialog}
            onClose={() => setShowRestrictedFileAssociationDialog(false)}
            document={document}
            onUpdateAccess={onUpdateAccess}
        />

    </>
}


