import React from 'react'
import DoubleConfirmDialog from '../common/DoubleConfirmDialog/DoubleConfirmDialog'
import { Box, Typography } from '@material-ui/core'
import DataWriteService from '../../services/DataWriteService';
import ITask from '../../interfaces/ITask';
import { useSnackbar } from 'notistack';
import { SnackbarVariantTypes } from '../../helpers/enums/enums';
import useAsyncFunctionIsLoading from '../../hooks/useIsLoadingAsync';
import SpinnerBackdrop from '../common/SpinnerBackdrop';

interface Props {
    isOpen: boolean;
    tasks: ITask[];
    onDelete: () => void;
    onClose: () => void;
}

export default function DeleteTasksConfirmDialog(props: Props) {
    const { enqueueSnackbar } = useSnackbar();

    const restrictedTasksSelected = props.tasks.some(t => t.isRestricted);

    const deleteTasks = async () => {
        const dataWriteService = new DataWriteService();
        const tasks: string[] = props.tasks?.map(t => t.taskId) as string[] || [];

        const response = await dataWriteService.PermanentlyDeleteTasks(tasks);

        if (response.status) {
            props.onDelete();
            enqueueSnackbar("Request(s) deleted successfully.", { variant: SnackbarVariantTypes.Success });
            props.onClose();
        }
        else {
            enqueueSnackbar([...response.errorMessages], { variant: SnackbarVariantTypes.Error });
        }
    }

    const { onTrigger: onConfirm, loading } = useAsyncFunctionIsLoading(deleteTasks);

    return (
        <DoubleConfirmDialog
            key={`${props.isOpen}`}
            isOpen={props.isOpen}
            onClose={props.onClose}
            onConfirm={onConfirm}
            title="Delete Selected Requests?"
            useCountdown
        >
            <SpinnerBackdrop isActive={loading} />
            <Typography>Are you sure you would like to delete the selected request(s)?</Typography>
            {
                restrictedTasksSelected && <Box my={5}>
                    <Typography>The attachments for all selected Restricted Requests will be permanently deleted if you proceed.</Typography>
                </Box>
            }
        </DoubleConfirmDialog>
    )
}
