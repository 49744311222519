import { ValueGetterParams } from "@ag-grid-community/core";
import IUser from "../../interfaces/IUser";
import IUserEngagement from "../../interfaces/IUserEngagement";
import ITaskAssignee from "../../interfaces/ITaskAssignee";


export const assignedToUserNameValueGetter = (params: ValueGetterParams, clientUserList: IUser[] | null) => {
    if (!(params && params.data)) return null;

    let assignees: string[] = [];

    params.data.taskAssignees.forEach((assignee: ITaskAssignee) => {
        const user = clientUserList?.find(
            user => user.userId === assignee.userId
        );
        if (user?.isActive) {
            assignees.push(assignee.displayName ? assignee.displayName : '');
        } else {
            assignees.push(assignee.displayName + ' (Inactive)');;
        }
    });
    return assignees.join(', ');
}
