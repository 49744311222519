import { AxiosStatic, AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { History } from 'history';
import { isEmpty } from '../helpers/StringHelpers';
import { handleSerializedDatesToJSDate, handleDatesToSerialized } from "../helpers/DateHelpers";
import { Store } from "redux";
import AuthenticationService from './AuthenticationService';

/**
 * Use axios interceptors to add additional behavior to API calls.
 * 
 * For requests add Bearer tokens for MATRIX Core api to authenticate api calls.
 * If the token has expired then get a new token.
 * 
 * For response, handle HTTP 401 Unuthorized results by displaying an alert error message.
 * 
 * @param axios The axios reference that we want to configure.
 * @param store The redux store reference.
 */
const setupAxios = (axios: AxiosStatic, store: Store, history: History): void => {

    axios.interceptors.request.use(async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
        const { accessToken, accessTokenExpiresOn } = store.getState().currentUser;
        const currentUrl = new URL(config.url as string);

        if (!isEmpty(accessToken)) {

            if ((accessTokenExpiresOn instanceof Date) && (accessTokenExpiresOn > new Date())) {

                config.headers.Authorization = `Bearer ${accessToken}`;
            } else {

                const authenticationService = new AuthenticationService();
                const updatedAccessToken = await authenticationService.getClientPlatformApiTokenSilent(store);
                config.headers.Authorization = `Bearer ${updatedAccessToken}`;
            }
        }

        handleDatesToSerialized(config.data);
       
        return config;

    }, (error: AxiosError) => {
        // Do something with request error
        return Promise.reject(error);
    });

      axios.interceptors.response.use((response: AxiosResponse) => {

        handleSerializedDatesToJSDate(response.data);
            
            return response;
    
        }, (error: AxiosError) => {
        
            return error;
        });
};

export default setupAxios;

