import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { useSnackbar } from 'notistack';
import { SnackbarVariantTypes } from '../../../helpers/enums/enums';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
    options: {
        display: string;
        value: string | number;
    }[];
    onConfirm: Function;
    defaultText: string;
    defaultOptionIndex?: number; 
    children?: React.ReactNode;
    isLoading?: boolean;
}

/**
 * Combination button and dropdown box.
 * 
 * This component is adapted from (and closely follows) the example within the MUI v4 docs. 
 * @param props
 */
export default function SplitButton({ options, onConfirm, defaultText, defaultOptionIndex, isLoading }: Props) {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState<number | null>(defaultOptionIndex === undefined ? null : defaultOptionIndex);

    const { enqueueSnackbar } = useSnackbar();

    const handleClick = () => {
        if (!isLoading) {
            if (selectedIndex !== null) {
                onConfirm(options[selectedIndex].value);
            }
            else {
                enqueueSnackbar("Please select a menu option.", { variant: SnackbarVariantTypes.Error });
            }
        }
    };

    const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        if (!isLoading) {
            setOpen((prevOpen) => !prevOpen);
        }
    };

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    const buttonText = selectedIndex === null ? defaultText : `${defaultText} ${options[selectedIndex].display}`;

    return (
        <>
            <ButtonGroup size="small" variant="contained" color="primary" ref={anchorRef} aria-label="split button">
                <Button onClick={handleClick}>
                    {isLoading ? <CircularProgress size={14} color='inherit' />: buttonText}
                </Button>
                <Button
                    color="primary"
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select option"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper open={open} style={{ zIndex: 100 }} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={`${option.display}-${option.value}`}
                                            selected={index === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            {option.display}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}