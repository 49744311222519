import React from 'react'
import FileNameCollisionDialog from '../../components/common/FileNameCollisionDialog/FileNameCollisionDialog';
import { FileUploadContextProviderProps, FileUploadContextProvider } from './FileUploadContextProvider';
import { useFileUploadContext } from './FileUploadContext';

export const FileUploadProviderWrapper = (props: FileUploadContextProviderProps) => {
    return (
        <>
            <FileUploadContextProvider {...props}>
                <FileUploadProviderContainer {...props} />
            </FileUploadContextProvider>
        </>
    )
}

function FileUploadProviderContainer(props: FileUploadContextProviderProps) {
    const context = useFileUploadContext();
    const { files, uploadNewVersion, uploadWithRenameOnCollision, uploadingBackdrop, fileNameCollisions } = context;

    return <>
        {uploadingBackdrop}
        {props.isRestrictedFileUpload ? null
            : <FileNameCollisionDialog
                onConfirm={uploadNewVersion}
                onConfirmSaveAsNew={uploadWithRenameOnCollision}
                files={files}
                fileNameCollisions={fileNameCollisions}
            >
                {uploadingBackdrop}
            </FileNameCollisionDialog>
        }
        {props.children}
    </>
}