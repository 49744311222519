import { DateTime } from "luxon";

export const LocalDateTimeString = (date: Date | null | undefined, includeTime: boolean = true): string => {
  if (date) {
    let dateTime = DateTime.fromJSDate(date);

    if (includeTime) {
      const localDateString = dateTime.toLocaleString();
      const localTimeString = dateTime.toLocaleString({
        ...DateTime.TIME_SIMPLE,
        timeZoneName: "short",
      });
      return `${localDateString} ${localTimeString}`;
    } else {
      let formattedDate = dateTime.toFormat('MM/dd/yyyy');

      if (formattedDate == "01/01/0001") {
        return "";
      }

      return formattedDate;
    }
  } else {
    return "";
  }
}


const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

function isIsoDateString(value: any): boolean {
  return value && typeof value === "string" && isoDateFormat.test(value);
}

export function handleDatesToSerialized(body: any) {

  if (body === null || body === undefined || typeof body !== "object")
    return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (value instanceof Date) {
      if (key === 'validFrom') {
        body[key] = DateTime.fromJSDate(new Date()).toISO();
      } else {
        body[key] = DateTime.fromJSDate(value).toISO();
      }
    }
    else if (typeof value === "object") handleDatesToSerialized(value);
  }
}


export function handleSerializedDatesToJSDate(body: any) {

  if (body === null || body === undefined || typeof body !== "object")
    return body;

  // Necessary when referencing blob storage dates because information is lost due to higher fractional second precision than JS dates support.
  const keysToIgnore = ['fileVersion']; // Add keys to this array to avoid conversion.

  for (const key of Object.keys(body)) {
    const value = body[key];
    const alterKey = !keysToIgnore.includes(key);
    if (alterKey && isIsoDateString(value)) {
      body[key] = DateTime.fromISO(value);
      if (key !== 'requestedDate') {
        if (!value.endsWith('z') && !value.endsWith('Z')) {
          body[key] = body[key].setZone('utc', { keepLocalTime: true });
        }
        body[key] = body[key].toLocal();
      }
      body[key] = body[key].toJSDate();
    }
    else if (alterKey && typeof value === "object") handleSerializedDatesToJSDate(value);
  }
}