import { ValueFormatterParams } from "@ag-grid-community/core";
import IUser from "../../interfaces/IUser";
import IUserEngagement from "../../interfaces/IUserEngagement";
import ITaskAssignee from "../../interfaces/ITaskAssignee";


export const assignedToUserNameValueFormatter = (params: ValueFormatterParams, clientUserList: IUser[] | null, userEngagementList: IUserEngagement[]) => {
    if (!(params && params.data)) return null;

    let assignees: string[] = [];

    params.data.taskAssignees.forEach((assignee: ITaskAssignee) => {
        const user = clientUserList?.find(
            user => user.userId === assignee.userId
        );
        const userEngagement = userEngagementList.find(
            ue =>
                ue.userId === assignee.userId &&
                ue.engagementId === params.data.engagementId
        );
        if (userEngagement && user?.isActive) {
            assignees.push(assignee.displayName ? assignee.displayName : '');
        } else {
            assignees.push(assignee.displayName + ' (Inactive)');;
        }
    });
    return assignees.join(', ');
}

export const mossAdamsContactUserNameValueFormatter = (params: ValueFormatterParams, mossAdamsUserList: IUser[], userEngagementList: IUserEngagement[]) => {
    if (!(params && params.data)) return null;
    const user = mossAdamsUserList?.find(
        user => user.userId === params.data.mossAdamsContactUserId
    );
    const userEngagement = userEngagementList.find(
        ue =>
            ue.userId === params.data.mossAdamsContactUserId &&
            ue.engagementId === params.data.engagementId
    );
    if (userEngagement && user?.isActive) {
        return params.value;
    } else {
        return params.value && `${params.value} (Inactive)`;
    }
}