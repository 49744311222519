// Fragile due to this relationship being defined in ClientPlatform\ClientPlatform.Db\Scripts\Seed\0_Priority_Seed.sql

export const TaskPriority = {
    Normal: {
        id: 1,
        string: "Normal"
    },
    High: {
        id: 2,
        string: "High"
    }
}

export const TaskPriorityStrings: string[] = [
    TaskPriority.Normal.string,
    TaskPriority.High.string
];

export const TaskPriorityStringsLower = TaskPriorityStrings.map(s => s.toLowerCase());

export const TaskPriorityIds: number[] = [
    TaskPriority.Normal.id,
    TaskPriority.High.id,
];