//import moment from 'moment';
import { matchPath } from 'react-router';
//import TDateFormat from '../types/TDateFormat';
//import { MatrixApiEndpoints } from './Constants';

/**
 * If a string exceeds the specified length, truncated and append an ellipsis to the end.
 * 
 * @param input The string to truncate.
 * @param length The max number of characters in the string before it will be truncated.
 * 
 * @returns Either the truncated string with ellipsis appended or the original unmodified string if less than or equal to the max length.
 */
export const truncate = (input: string, length: number): string => {

    return input.length > length ? `${input.substring(0, length)}...` : input;
};

/**
 * Check if a string is undefined, null, or only contains whitespace characters.
 * 
 * @remarks
 * https://stackoverflow.com/questions/154059/how-can-i-check-for-an-empty-undefined-null-string-in-javascript
 * 
 * @param text The string to evaluate.
 * 
 * @returns true if undefined, null, or only whitespace; false otherwise.
 */
export const isEmpty = (text: string): boolean => {

    return text === undefined || text === null || text.match(/^\s*$/) !== null;
};


/**
 * Evaluate if a given string is a GUID.
 * 
 * @remarks
 * A GUID is a string of letters, numbers, and dashes that look something like this: b25df478-e497-4a26-b9fe-4770c475b789
 * They are used widely in the MATRIX application as ID values.  GUID stands for globally unique identifier (GUID).
 * https://en.wikipedia.org/wiki/Universally_unique_identifier
 * https://stackoverflow.com/questions/11040707/c-sharp-regex-for-guid/35648213#35648213
 * 
 * @param value The string to be checked for matching the GUID pattern.
 */
export const isGuid = (value: string): boolean => {

    const guidRegEx = new RegExp('^([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})$');

    return guidRegEx.test(value);
};


/**
 * A Regex expression that validates whether characters are numbers, have a minus sign, or have commas
 */
export const numberValidationMinusSignCommas = new RegExp(/^-?(?:\d+|\d{1,3}(?:,\d{3})*)(?:\.\d{1,2})?$/);

/**
 * A Regex expression that validates that that the characters are not spaces or whitespace only
 */
export const isNotWhiteSpaceOnly = new RegExp(/(?!^$)([^\s])/);

/**
 * A Regex expression that validates whether characters contain decimals
 */
export const validationHasDecimal = new RegExp(/^-?\d+(\.)/);

/**
 * Evaluate if a given string is a number
 *
 * 
 * @param value The string to be checked for matching the number pattern.
 */
export const isTextANumber = (value: string): boolean => {

    return !isEmpty(value) && !isNaN(+value);

};

/**
 * Evaluate if a given string contains a decimal
 *
 * 
 * @param value The string to be checked for matching the number pattern.
 */
export const isDecimalInText = (value: string): boolean => {

    const decimalRegex = validationHasDecimal;

    return decimalRegex.test(value);
};


/**
 * Evaluate if a given string is an empty GUID.
 * 
 * @remarks
 * An empty GUID is a GUID as described above, but only contains zeros: 00000000-0000-0000-0000-000000000000.
 * Intentional uses of empty GUIDs in the MATRIX application are using them to denote an unitialize state or testing.
 * Accidential uses would be on the API side if a GUID variable was not initialized.
 * 
 * @param value The string to be checked for matching the GUID pattern.
 */
export const isEmptyGuid = (value: string): boolean => {

    const guidRegEx = new RegExp('^([0]{8}[-][0]{4}[-][0]{4}[-][0]{4}[-][0]{12})$');

    return guidRegEx.test(value);
};

/**
 * Determine if a string has a value, is a GUID, and is not an empty GUID.
 * 
 * @param value The string to check if it is a GUID.
 */
export const isGuidValue = (value: string): boolean => {

    return !isEmpty(value) && isGuid(value) && !isEmptyGuid(value);
};

/**
 * Evaluate if the expected path name is the same as the actual path name of the current page.
 * 
 * @remarks
 * If the expected path is '/trialbalance' it will match: '/trialbalance', '/trialbalance/', '/TRIALBALANCE', '/TrIaLbAlAnCe/'.
 * 
 * @param expectedPath The path we are attempting to match.
 * @param actualPath The actual path. Most likely sourced from history.location.pathname
 * 
 * @returns A boolean indicating a match.  If true then the actual path is a match; otherwise false.
 */
export const isExpectedPath = (expectedPath: string, actualPath: string): boolean => {

    // This regular expression assserts the string starts at the beginning of the line, the match is case-insensitive, and contains zero or one trailing forward slash.
    return RegExp(`^\\${expectedPath}$`, 'i').test(actualPath);
};

/**
 * This function will remove commas from the input parameter string
 * It could be used when trying to sum numerical values that are stored as strings with commas
 * @param valueToUnformat the value that you want to format with commas
 */
export const removeCommasFromNumber = (valueToUnformat: string): string => {
    if (valueToUnformat === undefined || valueToUnformat === null || typeof valueToUnformat !== 'string') {
        return '';
    }
    return valueToUnformat.replace(/,/g, '');
};

/**
 * This function will remove commas from the input parameter string, parse the string, and return the string with commas
 * It can be used to update the UI in real time based on what the user is typing so commas are always added in the correct place in the number string
 * @param valueToFormat the value that you want to format with commas
 */
export const formatNumberWithCommas = (valueToFormat: string): string => {
    const valueWithoutCommas = removeCommasFromNumber(valueToFormat);
    const valueAsInt = parseInt(valueWithoutCommas);
    return isNaN(valueAsInt) ? valueToFormat : valueAsInt.toLocaleString('en');
};

/**
 * Given a first and last name, get the initials for that name.
 * 
 * @param firstName The first name of a person's name.
 * @param lastName The last name of a person's name
 * 
 * @returns Either the two letters of the person's initials or an empty string if there is an issue parsing the first or last name.
 */
export const getInitials = (firstName: string, lastName: string): string => {

    let initials = '';

    if (!isEmpty(firstName) && !isEmpty(lastName)) {

        initials = `${firstName.charAt(0)}${lastName.charAt(0)}`;
    }

    return initials;
};


