import React from 'react'
import { ConfirmDialog } from '../../ConfirmDialog/ConfirmDialog'
import { Typography } from '@material-ui/core';

interface Props {
    onConfirm: () => void;
    onConfirmSaveAsNew: () => void;
    files: File[];
    fileNameCollisions: File[];
    children?: React.ReactNode
}

export default function FileNameCollisionDialog({ onConfirm, onConfirmSaveAsNew, files, fileNameCollisions, children }: Props) {
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (fileNameCollisions.length && !open) {
            setOpen(true);
        }
        else if (!fileNameCollisions.length && open) {
            setOpen(false);
        }
    }, [fileNameCollisions])


    const onClickClose = () => {
        setOpen(false);
    }

    return (
        <ConfirmDialog
            isOpen={open}
            onClose={onClickClose}
            onConfirm={onConfirm}
            onConfirmWithOptions={onConfirmSaveAsNew}
            title="Duplicate files detected."
            confirmButtonText={`Upload as New Version${(files.length > 1) ? 's' : ''}`}
            confirmWithOptionsButtonText="Save as New"
            closeButtonText="Cancel"
            children={<>
                <Typography>
                    The following files already exist. How do you want to proceed?
                    <Typography>
                        {fileNameCollisions.map((file) => (<Typography>{file.name}</Typography>))}
                    </Typography>
                </Typography>
                {children}
            </>}
        />
    )
}
