import { Button, CircularProgress, Link } from "@material-ui/core";
import React from "react";

interface ISpinnerButtonProps {
    onClickAsync: () => Promise<void>,
    variant: 'button' | 'link'
    buttonText: string
    classes?: string
}

export const SpinnerButton = (props: ISpinnerButtonProps) => {
    const { variant, onClickAsync, buttonText } = props;
    const [loading, setLoading] = React.useState(false);
    const style = props.classes ?? '';

    const onClick = async () => {
        if (!loading) {
            setLoading(true);
            await onClickAsync();
            setLoading(false);
        }
    }

    const spinner = <CircularProgress size={14} color='inherit' />

    let component = <></>;
    if (variant === 'button') {
        component = <Button className={style} onClick={onClick} name={buttonText} disabled={loading} title={buttonText}>
            {loading && spinner}
            {buttonText}
        </Button>
    }
    else if (variant === 'link') {
        component = <Link className={style} onClick={onClick} underline="always" title={buttonText}>
            {loading && spinner}
            {buttonText}
        </Link>
    }

    return (component)
}