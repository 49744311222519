import React from 'react'
import { ISavedViewControls, useSavedView } from '../../hooks/useSavedView';
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core';
import MADialogTitle from '../MADialogTitle/MADialogTitle';
import { ColumnApi, GridApi } from '@ag-grid-enterprise/all-modules';
import { SavedViewTable } from './SavedViewTable';
import ConditionalVisiblity from '../common/ConditionalVisibility/ConditionalVisiblity';

export interface SavedViewModalProps {
    isOpen: boolean;
    onClose: () => void;
    onClickResetColumns: () => void;
    onLoadView?: () => void;
    gridApi: GridApi | undefined;
    columnApi: ColumnApi | undefined;
    viewStorageKey: string;
}

export default function SavedViewModal({ isOpen, onClose, gridApi, columnApi, viewStorageKey, onClickResetColumns, onLoadView }: SavedViewModalProps) {
    const controls: ISavedViewControls = useSavedView(gridApi, columnApi, viewStorageKey, onLoadView);

    const [addNewView, setAddNewView] = React.useState(false);

    const onAddNewView = () => {
        setAddNewView(false);
        onCloseModal();
    }

    const onClickReset = () => {
        onClickResetColumns();
        onCloseModal();
    }

    const onCloseModal = () => {
        setAddNewView(false);
        onClose();
    }

    const hasViews = controls.savedViews.length !== 0;

    const instructions = hasViews ? null 
    : <>
        <Typography>
            To add a custom layout based on the current grid click "+ Save Current Layout," choose a name, then click Save.
        </Typography>
        <Typography>
            Your last used saved layout will be used automatically when you navigate to this page.
        </Typography>
    </>;

    return <>
        <Dialog
            open={isOpen}
            onClose={onCloseModal}
            aria-labelledby="manage-view-dialog"
            maxWidth={"md"}
            fullWidth={true}
        >
            <MADialogTitle id="manage-view-dialog-title" onClose={onCloseModal}>
                Layout Manager
            </MADialogTitle>
            <DialogContent>
                {instructions}
                <ConditionalVisiblity isVisible={hasViews || addNewView}>
                    <SavedViewTable
                        rowProps={{
                            viewControls: controls,
                            onCancelAddView: () => setAddNewView(false),
                            onLoadView: onCloseModal,
                            onAddNewView: onAddNewView,
                        }}
                        addNewView={addNewView}
                    />
                </ConditionalVisiblity>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="space-between" >
                    <Grid item>
                        {!controls.maxViewsReached() &&
                            <Button onClick={() => setAddNewView(true)} color="primary" variant="outlined" disabled={addNewView}>
                                + Save Current Layout
                            </Button>
                        }
                    </Grid>
                    <Grid item>
                        <Button onClick={onClickReset} color="primary" variant="outlined" disabled={addNewView}>
                            Reset Columns
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={onCloseModal} color="primary" variant="outlined">
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    </>
}