import { ColumnApi, GridApi } from "@ag-grid-enterprise/all-modules";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";
import { SnackbarVariantTypes } from "../helpers/enums/enums";

export class Utils {
    /**
     * If the object is null or has a length of zero then return true, else return false.
     * @param value The value to test against.
     * @returns true or false if empty or not empty.
     */
    public static isNullOrEmpty(value: any) {
        return value === undefined || value === null || value.length === 0;
    }

    public static endsWith(val: string, test: string): boolean {
        return val.substring(val.length - test.length) === test;
    }

    public static isGUID(val: string): boolean {
        var isGUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        return isGUID.test(val);
    }

    public static getLastGUID(string: string) {
        const parts = string.split("/");
        for (let i = parts.length - 1; i > -1; i--) {
            if (Utils.isGUID(parts[i])) {
                return parts[i];
            }
        }
        return null;
    }

    public static getUrlGUID = () => {
        return Utils.getLastGUID(window.location.pathname);
    }

    public static getUrlDocumentId = () => {
        const id = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
        return id !== 'document-management' ? id : null;
    }

    public static saveItemToLocalStorage(key: string, value: string, onErrorMessage: string = "Unable to set item to local storage") {
        try {
            localStorage.setItem(key, value);
        }
        catch (error) {
            console.error(onErrorMessage, error);
        }
    }

    /**
     * Save an item to the localStorage object.
     * @param key The unique identifier for the data to save.
     * @param value The value to save.
     */
    public static setLocalStorage(key: string, value: string) {
        if (this.isNullOrEmpty(value)) {
            window.localStorage.removeItem("MossAdams::" + key);
        } else {
            this.saveItemToLocalStorage("MossAdams::" + key, value);
        }
    }

    /**
     * Save an item to the sessionStorage object.
     * @param key The unique identifier for the data to save.
     * @param value The value to save.
     */
    public static setSessionStorage(key: string, value: string) {
        if (this.isNullOrEmpty(value)) {
            window.sessionStorage.removeItem("MossAdams::" + key);
        } else {
            window.sessionStorage.setItem("MossAdams::" + key, value);
        }
    }

    /**
     * Get an item from localStorage.
     * @param key The unique identifier of the data to retrieve.
     * @returns A string value.
     */
    public static getLocalStorage(key: string): string | number {
        const val = window.localStorage.getItem("MossAdams::" + key);
        if (val != null && this.isNumber(val)) {
            return parseInt(val);
        }
        return val as string | number;
    }

    /**
     * Get an item from sessionStorage.
     * @param key The unique identifier of the data to retrieve.
     * @returns A string value.
     */
    public static getSessionStorage(key: string): string | number {
        const val = window.sessionStorage.getItem("MossAdams::" + key);
        if (val != null && this.isNumber(val)) {
            return parseInt(val);
        }
        return val as string | number;
    }

    public static isNumber(value: string | number): boolean {
        if (value == null) {
            return false;
        }
        const toString = Object.prototype.toString;
        return (
            typeof value === "number" ||
            (!!value &&
                typeof value === "object" &&
                toString.call(value) === "[object Number]") ||
            parseInt(value, 10).toString() === value.toString()
        );
    }

    //in bytes
    public static oneHundredMB_binary = 104857600;
    public static maxUploadSize = 2147483648;

    /**
     * Takes in a number value and outputs a representation of that number as a file size.
     * Ex. 2140 -> 2.1 KB
     * Adapted from https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
     */
    public static getFileSize(value: number): string {
        let chunk = 1024; //difference between kilo, mega, etc. IEC - 1024,  SI - 1000
        let magnitude = Math.log(value) / Math.log(chunk) | 0;
        let outputValue = (value / Math.pow(chunk, magnitude)).toFixed(2);
        let outputString = ' ' + (magnitude ? 'KMGTPEZY'[--magnitude] + 'B' : 'B');

        return outputValue + outputString;
    }

    /**
     * Remove all entries from localStorage that begin with the specified key value.
     * @param key The key to search for to clear (clears all data that starts with the specified key value).
     */
    public static clearLocalStorage(key: string) {
        const toRemove: string[] = [];
        for (let i = 0; i < window.localStorage.length; i++) {
            const lskey = window.localStorage.key(i);
            if (lskey != null && lskey.startsWith("MossAdams::" + key)) {
                toRemove.push(lskey);
            }
        }
        for (let i = 0; i < toRemove.length; i++) {
            window.localStorage.removeItem(toRemove[i]);
        }
    }

    /**
     * Remove all entries from sessionStorage that begin with the specified key value.
     * @param key The key to search for to clear (clears all data that starts with the specified key value).
     */
    public static clearSessionStorage(key: string) {
        const toRemove: string[] = [];
        for (let i = 0; i < window.sessionStorage.length; i++) {
            const lskey = window.sessionStorage.key(i);
            if (lskey != null && lskey.startsWith("MossAdams::" + key)) {
                toRemove.push(lskey);
            }
        }
        for (let i = 0; i < toRemove.length; i++) {
            window.sessionStorage.removeItem(toRemove[i]);
        }
    }

    private static _canvas: HTMLCanvasElement | undefined = undefined;
    public static getTextWidth(text?: string, font?: string): number {
        // re-use canvas object for better performance
        if (text == null) {
            return 0;
        }
        const canvas =
            this._canvas || (this._canvas = document.createElement("canvas"));
        const context = canvas.getContext("2d");
        if (context == null) {
            return 0;
        }
        if (font != null) {
            context.font = font;
        }
        const metrics = context.measureText(text);
        return metrics.width;
    }

    public static getParameterByName(win: Window, name: string) {
        const isInIFrame = window.parent !== win;
        let parentUrl = win.location.href;
        name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
        const regexS = "[\\?&]" + name + "=([^&#]*)";
        const regex = new RegExp(regexS);
        let results = regex.exec(parentUrl);
        if (
            results == null ||
            results.length === 0 ||
            results[1].replace(/ /g, " ") === ""
        ) {
            if (isInIFrame) {
                parentUrl = document.referrer;
            } else {
                return "";
            }
            results = regex.exec(parentUrl);
            if (results == null || results.length === 0) {
                return "";
            } else {
                return decodeURIComponent(results[1].replace(/ /g, " "));
            }
        } else {
            return decodeURIComponent(results[1].replace(/ /g, " "));
        }
    }

    /* reference for cookie methods: https://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript */
    public static setCookie(name: string, value: string, days: number) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    public static getCookie(name: string) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    public static eraseCookie(name: string) {
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
    /* end cookie methods */

    public static enqueueMultiLineSnackbar(
        messages: string[],
        enqueueSnackbar: ((message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey),
        enqueueSnackbarProps?: OptionsObject | undefined
    ) {

        let uniqueMessages: string[] = [];

        for (const message of messages) {
            message && !uniqueMessages.includes(message) && uniqueMessages.push(message);
        }

        if (uniqueMessages.length) {
            enqueueSnackbar(uniqueMessages.join("\n"), { ...enqueueSnackbarProps, style: { whiteSpace: 'pre-line' } });
        }
    }
}

// For use with type guards, source: https://dev.to/mapleleaf/indexing-objects-in-typescript-1cgi
type PropertyKeys = string | number | symbol;
export function hasKey<O>(obj: O, key: PropertyKeys): key is keyof O {
    return key in obj
}
