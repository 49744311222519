import React, { useState, useMemo, } from "react";
import { IDocumentTreeProps } from "./IDocumentTreeProps";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper, FormControl, TextField } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import DescriptionIcon from '@material-ui/icons/Description';
import IContentHierarchy from "../../interfaces/IContentHierarchy";
import DataReadService from "../../services/DataReadService"
import IContentHierarchyGetDto from "../../interfaces/IContentHierarchyGetDto";
import FolderIcon from "@material-ui/icons/Folder";
import { HierarchyType } from "../../helpers/enums/HierarchyType";
import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { ICellRendererParams, ValueGetterParams, RowClassParams } from "@ag-grid-community/core";
import { GridApi, AllModules, SelectionChangedEvent, GridReadyEvent } from "@ag-grid-enterprise/all-modules";
import { agGridThemeOverride } from "../../helpers/portalTheme";
import { associatedTasksComparator } from "../../helpers/Comparators";
import { LocalDateTimeString } from "../../helpers/DateHelpers";
import { Utils } from "../../utilities/utils";
import { PrivateHierarchyCellRenderer } from "../AgGridRenderers/PrivateHierarchyCellRenderer";
import RestrictedIcon from "../common/RestrictedIcon/RestrictedIcon";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        margin: 0,
        ...agGridThemeOverride
    },
    cellIcon: {
        verticalAlign: "middle",
        marginRight: "10px",
        color: theme.palette.primary.main,
        height: "26px",
        width: "auto"
    },
    pageTitle: {
        fontSize: "2rem"
    },
    documentsGridWrapper: {
        display: "flex",
        minHeight: "400px",
    },
    documentGrid: {
        height: "100%",
        '& .ag-cell-value': {
            flexGrow: '1',
        },
        '& .ag-group-value': {
            flexGrow: '1',
        },
        '&& .ag-checkbox-input': {
            width: '18px',
            height: '18px',
            verticalAlign: 'middle',
            accentColor: theme.palette.primary.main,
        },
    },
    clientRow: {
        "&&.ag-row": {
            backgroundColor: theme.palette.mossadams.darkGreen600,
            color: theme.palette.common.white,

            "&& .ag-icon": {
                color: theme.palette.common.white
            }
        }
    },
    engagementRow: {
        "&&.ag-row": {
            backgroundColor: theme.palette.mossadams.darkGreen400,
            color: theme.palette.common.white,

            "&& .ag-icon": {
                color: theme.palette.common.white
            },
            "&& .MuiSvgIcon-root": {
                color: theme.palette.common.white
            }
        }
    },
    folderRow: {
        "&&.ag-row": {
            backgroundColor: theme.palette.mossadams.darkGreen200,
        }
    },
    rightAlignedGridCell: {
        textAlign: "right"
    },
    searchBox: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "end",
        alignItems: "center",
        padding: theme.spacing(4)
    },
    searchIcon: {
        marginTop: theme.spacing(8),
    }
}));

const DocumentTree = (props: IDocumentTreeProps): JSX.Element => {
    const classes = useStyles();
    const [hierarchy, setHierarchy] = useState<IContentHierarchy[] | null>();
    const [gridApi, setGridApi] = React.useState<GridApi>();
    const [searchField, setSearchField] = React.useState("");

    const rowClassRules = {
        [classes.clientRow]: (rowClassParams: RowClassParams) => {
            if (rowClassParams.data.hierarchyTypeDescription === HierarchyType.Client.string) {
                return true;
            } else {
                return false;
            }
        },
        [classes.engagementRow]: (rowClassParams: RowClassParams) => {
            if (rowClassParams.data.hierarchyTypeDescription === HierarchyType.Engagement.string) {
                return true;
            } else {
                return false;
            }
        },
        [classes.folderRow]: (rowClassParams: RowClassParams) => {
            if (rowClassParams.data.hierarchyTypeDescription === HierarchyType.CustomFolder.string
                || rowClassParams.data.hierarchyTypeDescription === HierarchyType.SystemFolder.string
                || rowClassParams.data.hierarchyTypeDescription === HierarchyType.SecureFolder.string
                || rowClassParams.data.hierarchyTypeDescription === HierarchyType.RestrictedFolder.string) {
                return true;
            } else {
                return false;
            }
        }
    }

    const onDocumentsGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    }

    React.useEffect(() => {
        if (gridApi) {
            gridApi.setQuickFilter(searchField);
        }
    }, [searchField]);

    const handleSelectionChanged = (event: SelectionChangedEvent) => {
        const selectedRows = event.api.getSelectedRows();

        if (selectedRows && selectedRows.length > 0) {
            props.handleFileSelect(selectedRows);
        }
    }

    function getIndentClass(params: any) {
        var indent = 0;
        var node = params.node;
        while (node && node.parent) {
            indent++;
            node = node.parent;
        }
        return 'indent-' + indent;
    }

    const hierarchyNameSort = (a: string, b: string, nodeA: any, nodeB: any, inverted: boolean = false) => {
        if (!nodeA || !nodeB) { return 0; }
        return ((nodeB.data.hierarchyTypeDescription === HierarchyType.File.string ? 0 : 1) -
            (nodeA.data.hierarchyTypeDescription === HierarchyType.File.string ? 0 : 1))
            || + (a.toLowerCase() > b.toLowerCase())
            || - (a.toLowerCase() < b.toLowerCase())
    }

    const TreeCellRenderer = (props: ICellRendererParams) => {
        const rowData: IContentHierarchy = props.data;

        switch (rowData.hierarchyTypeDescription) {
            case HierarchyType.Client.string:
                return (<>{rowData.displayName}</>);
            case HierarchyType.Engagement.string:
            case HierarchyType.SystemFolder.string:
            case HierarchyType.CustomFolder.string:
            case HierarchyType.SecureFolder.string:
            case HierarchyType.RestrictedFolder.string:
                return <div>
                    <FolderIcon className={classes.cellIcon} />{rowData.displayName}
                </div>;
            case HierarchyType.File.string:
                return (
                    <>
                        <DescriptionIcon className={classes.cellIcon} />{rowData.displayName}
                    </>);
        }
    }

    const components = {
        treeCellRenderer: TreeCellRenderer,
        privateFileCellRenderer: PrivateHierarchyCellRenderer,
    }

    const getParentLoop = (id: string, path: string[], data: IContentHierarchy[]): any => {
        let p = data.filter(i => i.id == id)[0];
        if (p.parentId) {
            path.unshift(p.displayName);
            return getParentLoop(p.parentId, path, data)
        }
        else {
            path.unshift(p.displayName);
        }
        return path;
    }

    const buildPath = (data: IContentHierarchy[]) => {
        return data.map(i => {
            i.path = []
            if (i.parentId) {
                i.path.push(i.displayName);
                getParentLoop(i.parentId, i.path, data);
            }
            else {
                i.path.push(i.displayName)
            }
            return i;
        });
    }

    const isRowSelectable = useMemo(() => {
        return (params: any) => {
            return params.data.hierarchyTypeDescription === HierarchyType.File.string;
        };
    }, []);

    function lastUpdatedFormatter(params: ValueGetterParams) {
        return LocalDateTimeString(params.data.updatedDateTime, false);
    }

    React.useEffect(() => {
        // Build the tree.
        if (props.clientId) {
            const data: IContentHierarchyGetDto = {
                clientIds: [props.clientId],
                engagementIds: null,
                excludeEngagementId: props.engagementId,
                includeAllClientEngagements: true
            };

            if (props.chooseFromCurrentEngagement) {
                data.engagementIds = [props.engagementId];
                data.excludeEngagementId = null;
            }

            const dataReadService = new DataReadService();
            dataReadService.GetContentHierarchy(data).then((response) => {
                let data = buildPath(response.data);
                setHierarchy(data);
            });

        } else {
            setHierarchy(null);
        }
    }, [props.clientId, props.engagementId]);

    let body: any = <Typography>Working...</Typography>;
    if (!props.clientId) {
        body = <Typography>A client ID must be specified.</Typography>;
    }
    if (hierarchy) {
        body =
            <Paper className={classes.root}>
                <div className={classes.searchBox}>
                    <SearchIcon className={classes.searchIcon} />
                    <div>
                        <FormControl>
                            <Typography variant="subtitle1">
                                <TextField
                                    label="Search"
                                    name="Search"
                                    type="search"
                                    value={searchField}
                                    onChange={(e: any) => {
                                        setSearchField(e.target.value);
                                    }}>
                                </TextField>
                            </Typography>
                        </FormControl>
                    </div>
                </div>
                <div className={"ag-theme-alpine " + classes.documentGrid}>
                    <AgGridReact
                        rowData={hierarchy}
                        onGridReady={onDocumentsGridReady}
                        onSelectionChanged={handleSelectionChanged}
                        editType=""
                        treeData={true}
                        rowClassRules={rowClassRules}
                        rowSelection={props.allowMultiSelect ? "multiple" : "single"}
                        rowMultiSelectWithClick={props.allowMultiSelect}
                        suppressCellFocus={true}
                        suppressMenuHide={true}
                        suppressContextMenu={true}
                        components={components}
                        getRowId={(row: any) => row.data.id}
                        getDataPath={(data: any) => { return data.path; }}
                        modules={AllModules}
                        groupDefaultExpanded={-1}
                        isRowSelectable={isRowSelectable}
                        headerHeight={25}

                        autoGroupColumnDef={{
                            headerName: 'Name',
                            minWidth: 300,
                            width: 405,
                            flex: 1,
                            cellClass: getIndentClass,
                            colId: "name",
                            initialSort: 'asc',
                            suppressCellFlash: true,
                            filter: "agTextColumnFilter",
                            cellRendererParams: {
                                suppressCount: true,
                                checkbox: true,
                                innerRenderer: "treeCellRenderer"
                            },
                            comparator: hierarchyNameSort,
                        }}
                        defaultColDef={{
                            editable: false,
                            sortable: true,
                            filter: true,
                            resizable: true,
                            floatingFilter: true,
                        }}
                    >
                        <AgGridColumn
                            field="associatedTasks"
                            headerName="Req #"
                            minWidth={87}
                            width={87}
                            maxWidth={300}
                            cellRenderer="requestNumberCellRenderer"
                            comparator={associatedTasksComparator}
                            filter="agTextColumnFilter"
                            valueGetter={(params: ValueGetterParams) => {
                                const data: IContentHierarchy = params.data;
                                const val = data.associatedTasks?.length
                                    ? data.associatedTasks.map(at => at.taskNumber).join(',')
                                    : data.hierarchyTypeDescription === HierarchyType.File.string ? 'Unfiled' : ''
                                return val;
                            }
                            }
                        />
                        <AgGridColumn
                            field="isPrivate"
                            headerName="Private"
                            minWidth={60}
                            maxWidth={60}
                            headerComponent={() => <RestrictedIcon />}
                            cellRenderer="privateFileCellRenderer"
                        />
                        <AgGridColumn
                            cellRenderer="uploadedByRenderer"
                            field="uploadedByUserName"
                            headerName="Uploaded By"
                            minWidth={110}
                            filter="agTextColumnFilter"
                        />
                        <AgGridColumn
                            field="updatedDateTime"
                            headerName="Last Modified"
                            filter="agDateColumnFilter"
                            valueFormatter={lastUpdatedFormatter}
                            getQuickFilterText={(params: any) => {
                                return LocalDateTimeString(params.value as Date, false);
                            }}
                            minWidth={180}
                            filterParams={{
                                comparator: (filterLocalDateAtMidnight: any, cellValue: Date) => {
                                    var cellDate = new Date(cellValue.getFullYear(), cellValue.getMonth(), cellValue.getDate())
                                    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                                        return 0;
                                    }
                                    if (cellDate < filterLocalDateAtMidnight) {
                                        return -1;
                                    }
                                    if (cellDate > filterLocalDateAtMidnight) {
                                        return 1;
                                    }
                                    return 0;
                                },
                                inRangeInclusive: true,
                                defaultOption: 'inRange'
                            }}
                        />
                        <AgGridColumn
                            field="fileSize"
                            headerName="Size"
                            cellClass={classes.rightAlignedGridCell}
                            minWidth={94}
                            maxWidth={100}
                            filter={false}
                            suppressFilterButton={true}
                            valueFormatter={(params: any) => {
                                return params.data.hierarchyTypeDescription == HierarchyType.File.string ? Utils.getFileSize(params.data.fileSize) : ''
                            }}
                        />
                    </AgGridReact>
                </div>
            </Paper>
    }

    return (<>{body}</>);

};

export default DocumentTree