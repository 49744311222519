import { Box, Checkbox, InputLabel } from '@material-ui/core'
import _ from 'lodash';
import React from 'react'

export type ClientAccountFilter = "Active" | "Former Clients" | "Prospects";

interface Props {
    activeFilters: Set<number>;
    setActiveFilters: (af: Set<number>) => void;
}

function ClientEngagementFilters({activeFilters, setActiveFilters}: Props) {

    const onChangeFilter = (filter: number) => { 
        const newSet = _.clone(activeFilters);
        newSet.has(filter) ? newSet.delete(filter) : newSet.add(filter) 
        setActiveFilters(newSet);
    }

    return (
        <>
            <Box>
            <Filter
                filterText="Active"
                onChange={onChangeFilter}
                active={activeFilters}
                value={1}
            />
            <Filter
                filterText="Former Clients"
                onChange={onChangeFilter}
                active={activeFilters}
                value={2}
            />
            <Filter
                filterText="Prospects"
                onChange={onChangeFilter}
                active={activeFilters}
                value={0}
            />
            </Box>
        </>
    )
}

const Filter = ({ filterText, onChange, active, value }: any) => {
    return <InputLabel>
        <Checkbox color={'primary'}
            checked={active.has(value)}
            onClick={() => onChange(value)}
        />
        {filterText}
    </InputLabel>
}

export default ClientEngagementFilters