
export const SocStatus = {
    PendingInitialRequest: {
        id: 1,
        string: "Pending Initial Request"
    },
    FollowUpRequired: {
        id: 2,
        string: "Follow-Up Required"
    },
    Provided: {
        id: 3,
        string: "Provided"
    },
    ControlRemoved: {
        id: 4,
        string: "Control Removed"
    },
    TestedNoExceptionsInterim: {
        id: 5,
        string: "Tested-No Exceptions (Interim)"
    },
    TestedExceptionsInterim: {
        id: 6,
        string: "Tested-Exceptions (Interim)"
    },
    TestedNoExceptionsFinal: {
        id: 7,
        string: "Tested-No Exceptions (Final)"
    },
    TestedExceptionsFinal: {
        id: 8,
        string: "Tested-Exceptions (Final)"
    },
    NA: {
        id: 9,
        string: "N/A"
    },
}

export const SocStatusStrings: string[] = [
    SocStatus.PendingInitialRequest.string,
    SocStatus.FollowUpRequired.string,
    SocStatus.Provided.string,
    SocStatus.ControlRemoved.string,
    SocStatus.TestedNoExceptionsInterim.string,
    SocStatus.TestedExceptionsInterim.string,
    SocStatus.TestedNoExceptionsFinal.string,
    SocStatus.TestedExceptionsFinal.string,
    SocStatus.NA.string,
];

export const SocStatusStringsLower = SocStatusStrings.map(s => s.toLowerCase());

export const SocStatusIds: number[] = [
    SocStatus.PendingInitialRequest.id,
    SocStatus.FollowUpRequired.id,
    SocStatus.Provided.id,
    SocStatus.ControlRemoved.id,
    SocStatus.TestedNoExceptionsInterim.id,
    SocStatus.TestedExceptionsInterim.id,
    SocStatus.TestedNoExceptionsFinal.id,
    SocStatus.TestedExceptionsFinal.id,
    SocStatus.NA.id,
];