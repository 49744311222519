import React, { FunctionComponent } from "react"
import ITitleEditFieldProps from "./ITitleEditFieldProps"
import { Grid, IconButton, makeStyles, TextField, Typography } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import _ from "lodash";
import RestrictedIcon from "../RestrictedIcon/RestrictedIcon";

const useStyles = makeStyles((theme) => ({
    titleEditFieldContainer: {
        fontSize: "1.5rem",
        whiteSpace: "nowrap",
        color: theme.palette.primary.main,
        paddingRight: theme.spacing(6),
        paddingLeft: theme.spacing(6),
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(2.5),
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        flexDirection: "row",
        flexWrap: "nowrap",
    },
    typography: {
        alignSelf: 'center',
        fontSize: "1.25rem",
        color: theme.palette.primary.main,
        fontFamily: "Founders Grotesk Med",
        padding: theme.spacing(2),
        flexGrow: 1,
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "100%",
    },
    editConfirmIconButton: {
        "& .MuiSvgIcon-root": {
            color: theme.palette.primary.main,
        },
        padding: theme.spacing(2),
    },
    titleEditField: {
        "& input": {
            fontSize: "1.25rem",
            color: theme.palette.primary.main,
            fontFamily: "Founders Grotesk Med",
            border: `solid 1px ${theme.palette.mossadams.gray200}`,
            borderRadius: "5px",
            padding: theme.spacing(2),
        },
        "& .MuiInput-underline:before,.MuiInput-underline:after": {
            display: "none",
        },
    },
    privateIcon: {
        alignSelf: 'center',
        paddingTop: theme.spacing(2),
    },
}));

const TitleEditField: FunctionComponent<ITitleEditFieldProps> = ({ data, onEdit = null, editField = 'name', displayField = 'displayName' }) => {
    const classes = useStyles();
    const [isTitleReadOnly, setIsTitleReadOnly] = React.useState(true);
    const [newData, setNewData] = React.useState(_.cloneDeep(data));

    const resetData = () => setNewData(_.cloneDeep(data));

    React.useEffect(() => {
        resetData();
    }, [data]);

    React.useEffect(() => {
        if (!isTitleReadOnly) {
            document.getElementById("titleField")?.focus();
        }
    }, [isTitleReadOnly])

    const handleEditClick = () => {
        setIsTitleReadOnly(false);
    }

    const handleCheckClick = () => {
        setIsTitleReadOnly(true);
        if (onEdit && !_.isEqual(newData, data)) {
            onEdit(newData).then((result) => {
                if (!result) {
                    resetData()
                }
            });
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Esc" || event.key === "Escape") {
            resetData()
            setIsTitleReadOnly(true);
        }
        if (event.key === "Enter") {
            handleCheckClick();
        }
    }

    const editButton = (
        <IconButton className={classes.editConfirmIconButton} aria-label="edit" onClick={handleEditClick}>
            <EditIcon />
        </IconButton>
    )

    const checkButton = (
        <IconButton className={classes.editConfirmIconButton} aria-label="confirm" onClick={handleCheckClick}>
            <CheckIcon />
        </IconButton>
    )

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        newData[editField] = e.target.value;
        setNewData({ ...newData });
    }

    const display = newData ? newData[displayField] : '';
    return (
        <>
            <Grid container className={classes.titleEditFieldContainer}>
                {data?.isPrivate &&
                    <div className={classes.privateIcon}>
                        <RestrictedIcon />
                    </div>
                }
                {isTitleReadOnly ?
                    <Typography className={classes.typography} title={display}>
                        {display}
                    </Typography> :
                    <TextField id="titleField"
                        className={`${classes.typography} ${classes.titleEditField}`}
                        onChange={onChange}
                        onKeyDown={handleKeyDown}
                        onFocus={(event) => { event.target.select(); }}
                        value={newData[editField]}
                    />
                }
                <div>
                    {onEdit !== null && (isTitleReadOnly ? editButton : checkButton)}
                </div>
            </Grid>
        </>
    );
}

export default TitleEditField;