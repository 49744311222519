import React from 'react'
import { Button, Tooltip, makeStyles } from '@material-ui/core'

export interface IconButtonProps {
    onClick: ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
    tooltip: string;
    children: React.ReactNode;
    disabled?: boolean;
    svgIcon?: boolean;
    describedBy?: string;
    classes?: string;
}


const useStyles = makeStyles((theme) => ({
    icon: {
        marginTop: theme.spacing(8),
        marginRight: theme.spacing(5),
        minWidth: theme.spacing(20),
    },
    svgIconButton: {
        // The span for the SVG image is nested in the span tag for the label so that 
        // is why it is necessary to use two child combinator to apply the style
        '& span > span': {

            backgroundColor: "#fff",
            display: 'flex',
            height: '24px',
            '&:hover': {
                backgroundColor: "#fff",
                cursor: 'pointer'
            },
            maskSize: 'contain',
            width: '24px'
        }
    },
}));

export default function TooltipIconButton({ onClick, tooltip, children, svgIcon, classes, disabled }: IconButtonProps) {
    const styles = useStyles();

    return (
        <Tooltip title={tooltip}>
            <Button size="small" color="primary" variant="contained"
                disabled={disabled}
                onClick={onClick}
                className={`${styles.icon} ${svgIcon ? styles.svgIconButton : ''} ${classes || ''}`} 
            >
                {children}
            </Button>
        </Tooltip>
    )
}
