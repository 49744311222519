import React from 'react'
import { RestrictedUserOption } from '../components/RestrictedFileAssociationDialog/RestrictedUserAutocomplete';
import { useSelector } from 'react-redux';
import ICurrentUser from '../interfaces/ICurrentUser';

export interface PermittedUserListProps {
    userOptions: RestrictedUserOption[];
    setUserOptions: (options: RestrictedUserOption[]) => void;
    selectedUserOptions: RestrictedUserOption[];
    setSelectedUserOptions: (options: RestrictedUserOption[]) => void;
    getSelectedPermittedUserIdsForRequest: () => string[];
    getPermittedUserIds: () => string[];
    hasSelections: boolean;
}

/**
 * A hook for managing the selection of permitted users for documents and folders. 
 */
export default function usePermittedUsers(): PermittedUserListProps {
    const currentUser = useSelector((state: any) => state.currentUser as ICurrentUser);
    const [userOptions, setUserOptions] = React.useState<RestrictedUserOption[]>([]);
    const [selectedUserOptions, setSelectedUserOptions] = React.useState<RestrictedUserOption[]>([]);

    function getUserIds(options: RestrictedUserOption[]) {
        return options.map(option => option.userId);
    }

    function filterSelectedUserOptions(filterMossAdamsUsers = true): RestrictedUserOption[] {
        const optionsToSelect = [...selectedUserOptions];
    
        const selectedMaStaffIds = optionsToSelect
            .filter(option => option.isMossAdamsStaff)
            .map(option => option.userId);
    
        const maUserOptionIds = getUserIds(
            userOptions.filter(option => option.isMossAdamsStaff)
        );
    
        const assignedToAllMa =
            maUserOptionIds.every(id => selectedMaStaffIds.includes(id)) ||
            selectedMaStaffIds.length === 0;
    
        if (assignedToAllMa) {
            const externalUsers = optionsToSelect.filter(option => !option.isMossAdamsStaff);
            return filterMossAdamsUsers
                ? externalUsers
                : [...externalUsers, ...userOptions.filter(option => option.isMossAdamsStaff)];
        }
    
        return optionsToSelect;
    }

    function getSelectedPermittedUserIdsForRequest(): string[] {
        if (!currentUser.isMossAdamsStaff) {
            return [currentUser.userId];
        }

        return getUserIds(filterSelectedUserOptions());
    }

    function getPermittedUserIds(): string[] {
        return getUserIds(filterSelectedUserOptions(false));
    }
    
    return {
        userOptions,
        setUserOptions,
        selectedUserOptions,
        setSelectedUserOptions,
        getSelectedPermittedUserIdsForRequest,
        getPermittedUserIds,
        hasSelections: selectedUserOptions.length > 0
    }
}