import React, { useState, ReactNode } from "react";
import IClientEngagementSelectorProps from "./IClientEngagementSelectorProps";
import { makeStyles } from "@material-ui/core/styles";
import { ClientFavorite } from "../ClientFavorite/ClientFavorite"
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from "react-redux";
import MaterialTable, { } from "@material-table/core";
import Popover from '@material-ui/core/Popover';
import IconButton from "@material-ui/core/IconButton/IconButton";
import TableIcons from "../TableIcons";
import { Tooltip, Divider, InputLabel, Box } from "@material-ui/core";
import IClientEngagement, { IMTableRowData } from "../../interfaces/IClientEngagement";
import { useSnackbar } from "notistack";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Utils } from "../../utilities/utils";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import ClientEngagementFilters from "./ClientEngagementFilters";
import ICurrentUser from "../../interfaces/ICurrentUser";
import Search from "@material-ui/icons/Search";
import ClientAutocomplete from "./ClientAutocomplete";
import { useSelectedClientsAndEngagements } from "../../hooks/useSelectedClientsAndEngagements";
import { useUpdateSelectedClientsAndEngagements } from "./useUpdateSelectedClientsAndEngagements";
import useFetchClientsAndEngagements from "./useFetchClientsAndEngagements";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        padding: "0 15px",
    },
    selectors: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    formControl: {
        margin: theme.spacing(1),
        width: "260px",
    },
    selectLabel: {
        color: "#859d9d",
        display: "inline-flex",
        transform: "none",
        position: "relative",
        textTransform: "uppercase",
        fontSize: "20px",
        paddingRight: "6px",
        paddingLeft: "20px",
        letterSpacing: "2px",
        fontFamily: "Founders Grotesk Cond Med",
        cursor: "pointer"
    },
    selectLabelValue: {
        color: theme.palette.common.white,
        paddingRight: "40px",
        paddingLeft: 0,
        cursor: "pointer"
    },
    select: {
        "& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:before": {
            borderBottom: "none",
        },
        "& .MuiAutocomplete-endAdornment button": {
            color: theme.palette.common.white,
        },

        "& input": {
            color: theme.palette.common.white,
            textTransform: "uppercase",
            fontSize: "20px",
            fontFamily: "Founders Grotesk Cond Med",
            letterSpacing: "1px",
        },
    },
    popOverSwitch: {
        color: theme.palette.primary.contrastText
    },
    popover: {
        color: theme.palette.common.white,
        width: "100%",
        "& .MuiPopover-paper": {
            overflow: 'hidden',
            display: 'flex'
        },
    },
    popoverContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    tableContainer: {
        overflowY: 'auto'
    },
    mTableContainerRoot: {
        width: '340px',
        maxWidth: '340px',
        maxHeight: '90vh',
        margin: 0,
        "&& td > *, .MuiTableCell-root": {
            padding: "4px",
            borderBottom: "none",
        },
        "&& tr > td:nth-child(2)": {
            verticalAlign: "middle",
            whiteSpace: "nowrap",
        },
        "&& tr[level='0'] > td:nth-child(1)": {
            width: "32px !important", //important because material-table hard-codes a style attribute, which takes precedence
            display: "inline-flex",
        },
        "&& tr[level='1'] > td:nth-child(2)": {
            paddingLeft: "90px",
            textIndent: "-2.6em",
        },
        "&& tr[level='2'] > td:nth-child(2)": {
            paddingLeft: "180px",
            textIndent: "-3em",
        },
        "&& tr[level='3'] > td:nth-child(2)": {
            paddingLeft: "240px",
            textIndent: "-3em",
        },
        spacer: {
            display: "none",
        },
        "&& .MuiFormControl-root > .MuiTextField-root": {
            width: "100%",
        }
    },
    mTableToolbarRoot: {
        width: "100%",
    },
    mTableToolbarSpacer: {
        display: "none",
    },
    client: {
        display: "inline-grid",
        width: "calc(100% - 60px)",
    },
    clientName: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    clientNumber: {
        color: theme.palette.mossadams.gray400
    },
    engagement: {
        display: "inline-grid",
        textIndent: "0px",
    },
    engagementName: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    engagementNumber: {
        color: theme.palette.mossadams.gray400
    },
    filters: {
        "& .MuiFormLabel-root": {
            color: theme.palette.common.black,
            fontSize: '0.77rem',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
        },
        "& .MuiIconButton-root": {
            padding: 0,
            "& .MuiIconButton-label": {
                transform: "scale(0.76)"
            }
        },
        "& .MuiBox-root": {
            display: 'flex',
            justifyContent: 'space-evenly',
            margin: theme.spacing(3),
        }
    },
    divider: {
        "&.MuiDivider-root": {  // match search bar
            backgroundColor: "rgba(0, 0, 0, 0.42)",
            margin: theme.spacing(3),
            height: '0.5px'
        }
    },
    searchBar: {
        width: '98%',
        margin: theme.spacing(2),
    },
    maxClientsMessage: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

const ClientEngagementSelector = (props: IClientEngagementSelectorProps): JSX.Element => {

    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const [selectedClientText, setSelectedClientText] = useState<string>();
    const [selectedEngagementText, setSelectedEngagementText] = useState<string>();
    const [oldestSelectedClientEngagement, setOldestSelectedClientEngagement] = useState<IClientEngagement | null>(null);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [anchorElement, setAnchorElement] = React.useState(null);
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

    const [activeFilters, setActiveFilters] = React.useState<Set<number>>(props.currentUser.isMossAdamsStaff ? new Set([1]) : new Set([]));
    const [showClientWarningMessage, setShowClientWarningMessage] = React.useState(false);

    const maxNumberClients = 30;
    const maxClientsMessage = `Top ${maxNumberClients} Clients Displayed`;
    const searchbarTooltip = "Enter 3 or more characters to search for clients and engagements and with current filters applied";

    const { loadingClientsAndEngagements,
        updateClientAndEngagementList,
        clientEngagements
    } = useFetchClientsAndEngagements();

    const { selectedClients,
        selectedEngagements,
        selectedClientAndEngagementIds,
    } = useSelectedClientsAndEngagements();


    const {
        updateSelectedClientAndEngagementIds,
        clients,
        updateClientLastAccessed,
    } = useUpdateSelectedClientsAndEngagements();

    React.useEffect(() => {
        setIsDisabled(loadingClientsAndEngagements || props.engagementManagementIsDirty || props.taskListEditorIsDirty || props.clientEngagementSelectorDisabled);
    }, [loadingClientsAndEngagements, props.engagementManagementIsDirty, props.taskListEditorIsDirty, props.clientEngagementSelectorDisabled]);

    const triggerClientEngagementListUpdate = async (clientIdToSelect?: string) => {
        const clientAndEngagementList = await updateClientAndEngagementList(clientIdToSelect);

        if (clientAndEngagementList && clientIdToSelect) {
            selectClient(clientIdToSelect, clientAndEngagementList);
            scrollToClient(clientIdToSelect);
        }
        const clientCount = clientAndEngagementList?.filter(ce => ce.isClient).length;
        if (clientCount === maxNumberClients) {
            setShowClientWarningMessage(true);
        }
    }

    React.useEffect(() => {
        setSelectedClientEngagementText(selectedClients, selectedEngagements);

        if (!oldestSelectedClientEngagement) {
            const selectedClientEngagement = selectedClients.length && clientEngagements.find(ce => selectedClients.includes(ce.id))
            const oldestParent = selectedClientEngagement && findOldestParent(clientEngagements, selectedClientEngagement);
            oldestParent && setOldestSelectedClientEngagement(oldestParent);
        }

    }, [selectedClients, selectedEngagements, clientEngagements])

    const setSelectedClientEngagementText = (selectedClientIds: string[], selectedEngagementIds: string[]) => {
        const clientText = getClientText(selectedClientIds, clientEngagements);
        const engagementText = getEngagementText(selectedEngagementIds, clientEngagements);

        setSelectedClientText(clientText);
        setSelectedEngagementText(engagementText);
    };

    const getClientText = (selectedClientIds: string[], clientEngagementList: IClientEngagement[]) => {
        const selectedClientCount = selectedClientIds.length;
        if (selectedClientCount === 0) return "";

        const oldestParent = findOldestParent(
            clientEngagementList,
            clientEngagementList.find(ce => ce.id === selectedClientIds[0])!
        );
        const selectedClientNames = selectedClientIds.map(
            clientId => clientEngagementList.find(ce => ce.id === clientId)?.name
        );
        const plusXClients = selectedClientCount - 1 > 0 ? `(+${selectedClientCount - 1})` : "";

        return oldestParent?.name && selectedClientNames.includes(oldestParent.name)
            ? `${oldestParent.name} ${plusXClients}`
            : `${selectedClientNames.sort()[0]} ${plusXClients}`;
    };

    const getEngagementText = (selectedEngagementIds: string[], clientEngagementList: IClientEngagement[]) => {
        const selectedEngagementCount = selectedEngagementIds.length;
        if (selectedEngagementCount === 0) return "";
        if (selectedEngagementCount === 1) {
            return clientEngagementList.find(ce => ce.id === selectedEngagementIds[0])?.name!;
        }
        return `Multiple (+${selectedEngagementCount})`;
    };

    const findOldestParent = (list: IClientEngagement[], node: IClientEngagement): IClientEngagement | null => {
        let currentClientOldestParentId: string | null | undefined = node?.parentId || node?.id;

        while (currentClientOldestParentId) {
            const parent: IClientEngagement | undefined = list?.find(ce => ce.id === currentClientOldestParentId);

            if (parent !== undefined && !parent.parentId) {
                return parent;
            }

            currentClientOldestParentId = parent?.parentId;
        }

        return null;
    }

    const removeGuidFromUrl = () => {
        // Clear the URL GUID to avoid the click being reverted due to deep linking behavior within the pages
        const urlHasGuid = Utils.getUrlGUID();
        const isOnEditRequestList = location.pathname.includes("request-list");

        if (isOnEditRequestList) {
            setTimeout(() => history.replace(`/${location.pathname.split("/")[1]}`), 500);
        } else if (urlHasGuid) {
            history.replace(`/${location.pathname.split("/")[1]}`);
        }
    }

    const selectClient = (clientId: string, clientEngagements: IClientEngagement[]) => {
        const clientNeedsToBeSelected = !selectedClientAndEngagementIds.includes(clientId);
        if (!clientNeedsToBeSelected) return;

        const childEngagements = getChildEngagements(clientEngagements, clientId);
        const childClients = getChildClients(clientEngagements, clientId);

        const existingSelectedClients = selectedClients?.length;
        if (!existingSelectedClients) {
            updateSelectedClientAndEngagementIds([...childEngagements, clientId]);
            return;
        }

        const client = clientEngagements.find(c => c.id == clientId);
        if (!client) return;

        const oldestParent = findOldestParent(clientEngagements, client);
        if (oldestParent?.id === oldestSelectedClientEngagement?.id) {
            updateSelectedClientAndEngagementIds([...selectedClientAndEngagementIds, clientId, ...childEngagements]);
            return;
        }

        const selectedClientsToRetain = selectedClients.filter(c => childClients.includes(c));
        const engagementsOfClientsToRetain = selectedClientsToRetain.map(c => getChildEngagements(clientEngagements, c)).flat();
        const selectedEngagmentsToRetain = selectedEngagements.filter(e => engagementsOfClientsToRetain.includes(e));

        if (existingSelectedClients) {
            const previouslySelectedClients = clientEngagements.filter(ce => selectedClients && selectedClients.includes(ce.id));
            previouslySelectedClients.forEach((psc: IMTableRowData) => {
                if (!selectedClientsToRetain.includes(psc.id)) {
                    psc.tableData && (psc.tableData.isTreeExpanded = false);
                }
            });
        }

        const newSelections = [clientId, ...selectedClientsToRetain, ...childEngagements, ...selectedEngagmentsToRetain];
        updateSelectedClientAndEngagementIds(newSelections);
        setOldestSelectedClientEngagement(oldestParent);
    }

    const getChildEngagements = (clientEngagements: IClientEngagement[], clientId: string) =>
        clientEngagements.filter(ce => !ce.isClient && ce.parentId == clientId).map(ce => ce.id);

    const getChildClients = (clientEngagements: IClientEngagement[], clientId: string) =>
        clientEngagements.filter(ce => ce.isClient && ce.parentId == clientId).map(ce => ce.id);

    const handleCheckboxClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, rowData: IMTableRowData) => {
        removeGuidFromUrl();

        const rowId = rowData.id;
        const clickedRowIsClient = rowData.isClient;
        const clientOrEngagementWasDeselected = selectedClientAndEngagementIds?.includes(rowData.id);
        const childRows = rowData?.tableData?.childRows || [];
        const childrenIds = childRows.map(child => child.id);

        if (clientOrEngagementWasDeselected) {
            clickedRowIsClient ? deselectClientAndChildren(rowId, childrenIds, rowData) : deselectEngagement(rowData);
        }
        else {
            clickedRowIsClient ? selectClientAndChildren(rowId, childrenIds, rowData) : selectEngagement(rowData);
            updateClientLastAccessed(rowId);
        }
    };

    const deselectClientAndChildren = (clientId: string, childrenIds: string[], rowData: IMTableRowData) => {
        const remainingSelectedClientEngagementIds = selectedClientAndEngagementIds.filter(
            ceId => ![...childrenIds, clientId].includes(ceId)
        );
        updateSelectedClientAndEngagementIds([...remainingSelectedClientEngagementIds]);

        rowData?.tableData && (rowData.tableData.isTreeExpanded = false);
    };

    const deselectEngagement = (rowData: IMTableRowData) => {
        const engagementId = rowData?.id;
        const parentId = rowData.parentId;
        if (!parentId) {
            console.log(`Expected parent id for engagement ${engagementId} but got ${engagementId}.`);
            return;
        }

        const updatedSelection = selectedClientAndEngagementIds.filter(id => engagementId !== id);
        updateSelectedClientAndEngagementIds(updatedSelection);
    };

    const selectClientAndChildren = (clientId: string, childrenIds: string[], rowData: IMTableRowData) => {
        const hasExistingSelection = oldestSelectedClientEngagement !== null;

        if (hasExistingSelection) {
            const oldestParent = findOldestParent(clientEngagements, rowData);
            if (oldestParent?.id === oldestSelectedClientEngagement?.id) {
                updateSelectedClientAndEngagementIds([...selectedClientAndEngagementIds, clientId, ...childrenIds]);
            }
            else {
                if (selectedClients?.length) {
                    const previouslySelectedClients = clientEngagements.filter(ce => selectedClients && selectedClients.includes(ce.id));
                    previouslySelectedClients.forEach((psc: IMTableRowData) => {
                        psc.tableData && (psc.tableData.isTreeExpanded = false);
                    });
                }

                updateSelectedClientAndEngagementIds([clientId, ...childrenIds]);
                setOldestSelectedClientEngagement(oldestParent);
            }
        }
        else {
            updateSelectedClientAndEngagementIds([clientId, ...childrenIds]);
            const oldestParent = findOldestParent(clientEngagements, rowData);
            setOldestSelectedClientEngagement(oldestParent);
        }

        rowData?.tableData && (rowData.tableData.isTreeExpanded = true);
    };

    const selectEngagement = (rowData: IMTableRowData) => {
        if (!rowData.parentId) return;

        const oldestParent = findOldestParent(clientEngagements, rowData);
        const parentIsSelected = oldestParent !== null && oldestParent?.id === oldestSelectedClientEngagement?.id;
        if (parentIsSelected) {
            if (selectedClientAndEngagementIds.includes(rowData?.parentId)) {
                updateSelectedClientAndEngagementIds([...selectedClientAndEngagementIds, rowData.id]);
            }
            else {
                updateSelectedClientAndEngagementIds(
                    [
                        ...selectedClientAndEngagementIds,
                        rowData?.id,
                        rowData?.parentId
                    ]);
            }
        }
        else {
            updateSelectedClientAndEngagementIds([rowData?.id, rowData?.parentId]);
            setOldestSelectedClientEngagement(oldestParent);
        }
    };


    const renderRow = (rowData: IMTableRowData): ReactNode => {
        const clientEngagementsCheckbox = (
            <Checkbox
                disabled={isDisabled}
                checked={selectedClientAndEngagementIds?.includes(rowData.id)}
                color={'primary'}
                onClick={event => handleCheckboxClick(event, rowData)}
            />
        );

        if (rowData?.name) {
            if (rowData?.isClient) {
                return (
                    <>
                        <ClientFavorite
                            clientId={rowData.id}
                            isFavorite={rowData?.isFavorite || false}
                            onRequestSuccessful={() => updateClientAndEngagementList()}
                        />
                        {clientEngagementsCheckbox}
                        <span className={classes.client} id={`client-${rowData.id}`}>
                            <span className={classes.clientName} title={rowData.name}>
                                {`${rowData.name}`}
                            </span>
                            <span className={classes.clientNumber}>
                                {` (${rowData.clientNumber})`}
                            </span>
                        </span>

                    </>
                );
            } else {
                return (
                    <>
                        {clientEngagementsCheckbox}
                        <span className={classes.engagement}>
                            <span className={classes.engagementName} title={rowData.name}>
                                {`${rowData.name}`}
                            </span>
                            <span className={classes.engagementNumber}>
                                {` (${rowData.engagementNumber})`}
                            </span>
                        </span>
                    </>
                );
            }
        }
    }

    const scrollToClient = (clientId: string) => {
        const container = document.getElementById('table-container-id');
        const client = document.getElementById(`client-${clientId}`);

        let clientPosition
        if (client && container) {
            clientPosition = client.getBoundingClientRect()?.top - container.getBoundingClientRect().top + container.scrollTop;
        }

        if (clientPosition) {
            container?.scrollTo({
                top: clientPosition,
                behavior: 'smooth'
            })
        }

    }

    const customSort = (a: IClientEngagement, b: IClientEngagement, type: "row" | "group") => {
        if (a.isClient && b.isClient) {
            return (
                (b.isFavorite ? 1 : 0) -
                (a.isFavorite ? 1 : 0)
            )
                || +(a.name > b.name)
                || -(a.name < b.name);
        }
        else if (!a.isClient && !b.isClient) {
            return +(a.engagementNumber > b.engagementNumber)
                || -(a.engagementNumber < b.engagementNumber);
        }
        return 0;
    };

    const defaultExpandedRules = (rowData: IMTableRowData): boolean => {
        const isSelected = selectedClientAndEngagementIds.includes(rowData.id);
        if (clients.length === 1) {
            // Automatically select client if there is only one
            // Expand row if there is only one client
            if (rowData.isClient && !isSelected) {
                // Select this client and all children
                const clientId = rowData.id;
                const childRows = rowData?.tableData?.childRows || []
                const childrenIds = childRows.map(child => child.id);

                updateSelectedClientAndEngagementIds([clientId, ...childrenIds]);
                const oldestParent = findOldestParent(clientEngagements, rowData);
                setOldestSelectedClientEngagement(oldestParent);
            }
            return true;
        } else if (rowData.isClient && isSelected) {
            // Expand if currently selected
            return true;
        } else {
            // Row is not expanded
            return false;
        }
    }

    const popOverSwitch = (isLoading: boolean, isPopoverOpen: boolean) => {
        if (isLoading) {
            return (<CircularProgress color="inherit" size={20} />);
        } else {
            return (<Search />)
        }
    }

    const toggleMenu = () => {
        if (!isDisabled) {
            setAnchorElement(dropdownRef.current);
            setIsPopoverOpen(!isPopoverOpen);
        }
    }

    let dropdownRef = React.useRef<any>();
    const isMAStaff = props.currentUser.isMossAdamsStaff;

    const getSelectorTooltip = () => {
        let tooltip = "Select clients and engagements";
        if (props.clientEngagementSelectorDisabled) {
            tooltip = "Clients cannot be changed while data is loading";
        }
        if (props.taskListEditorIsDirty || props.taskListEditorIsDirty) {
            tooltip = "Clients cannot be changed while data is invalid";
        }
        return tooltip;
    }

    return (
        <div>
            {clientEngagements &&
                <div className={classes.root}>
                    <Tooltip title={getSelectorTooltip()}>
                        <div onClick={toggleMenu} className={classes.selectors}>
                            <InputLabel className={classes.selectLabel}>
                                Client:
                            </InputLabel>
                            <InputLabel className={`${classes.selectLabel + ' ' + classes.selectLabelValue}`}>
                                {selectedClientText}
                            </InputLabel>
                            <InputLabel className={classes.selectLabel}>
                                Engagement:
                            </InputLabel>
                            <InputLabel className={`${classes.selectLabel + ' ' + classes.selectLabelValue}`}>
                                {selectedEngagementText}
                            </InputLabel>
                            <IconButton
                                className={classes.popOverSwitch}
                                disabled={isDisabled}
                                ref={dropdownRef}
                            >
                                {popOverSwitch(loadingClientsAndEngagements, isPopoverOpen)}
                            </IconButton>
                        </div>
                    </Tooltip>

                    <Popover
                        open={isPopoverOpen}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        className={classes.popover}
                        anchorEl={anchorElement}
                        onClose={() => {
                            setAnchorElement(null);
                            setIsPopoverOpen(false);
                        }}
                    >
                        <div className={classes.popoverContainer}>
                            <div className={classes.searchBar}>
                                <ClientAutocomplete key={`${loadingClientsAndEngagements}`}
                                    disabled={isDisabled}
                                    filters={activeFilters}
                                    triggerClientEngagementListUpdate={triggerClientEngagementListUpdate}
                                />
                                {isMAStaff &&
                                    <>
                                        <Box className={classes.filters}>
                                            <ClientEngagementFilters activeFilters={activeFilters} setActiveFilters={setActiveFilters} />
                                        </Box>
                                        <Divider className={classes.divider} />
                                    </>
                                }
                            </div>
                            <div className={classes.tableContainer} id="table-container-id">
                                <MaterialTable
                                    data={clientEngagements}
                                    icons={TableIcons}
                                    columns={[
                                        {
                                            title: 'Name',
                                            field: 'name',
                                            defaultSort: "asc",
                                            render: renderRow,
                                            customSort: customSort,
                                        }
                                    ]}
                                    parentChildData={(row, rows) => rows.find(a => a.id === row.parentId && !row.isClient)}
                                    components={{
                                        Container: props => (
                                            <Box className={classes.mTableContainerRoot}>
                                                {props.children}
                                            </Box>
                                        ),
                                        Toolbar: props => (
                                            <div>
                                                {showClientWarningMessage ?
                                                    <>
                                                        <div className={classes.maxClientsMessage}>
                                                            <strong>{maxClientsMessage}</strong>
                                                        </div>
                                                    </>
                                                    : null
                                                }
                                            </div>
                                        ),
                                    }}
                                    options={{
                                        search: true,
                                        paging: false,
                                        header: false,
                                        showTitle: false,
                                        defaultExpanded: defaultExpandedRules,
                                    }}
                                />
                            </div>
                        </div>
                    </Popover>

                </div>
            }
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return ({
        areSelectedClientEngagementsLoaded: state.areSelectedClientEngagementsLoaded,
        engagementManagementIsDirty: state.engagementManagementIsDirty as boolean,
        taskListEditorIsDirty: state.taskListEditorIsDirty as boolean,
        currentUser: state.currentUser as ICurrentUser,
        clientEngagementSelectorDisabled: state.clientEngagementSelectorDisabled as boolean,
    })
}

export default connect(mapStateToProps)(ClientEngagementSelector);