import React from 'react'
import DetailHeader from '../common/DetailHeader/DetailHeader'
import { Box, Button, Divider, Grid, Paper, makeStyles } from '@material-ui/core';
import IContentHierarchy from '../../interfaces/IContentHierarchy';
import TitleEditField from '../common/TitleEditField/TitleEditField';
import FolderAccessDetails from './FolderAccess/FolderAccessDetails';
import { actionButtonVariant } from '../../helpers/portalTheme';
import { Link } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { SnackbarVariantTypes } from '../../helpers/enums/enums';

interface Props {
    onClose: () => void;
    folder: IContentHierarchy | null;
    onUpdateAccess: (usersWithAccess: string[]) => void;
    downloadFolder: Function;
    deleteFolder: Function;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        height: '100%',
        overflowY: 'auto',
    },
    grid: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%'
    },
    actionButton: {
        ...actionButtonVariant,
    },
}));

export default function FolderDetails(props: Props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const copyFolderLink = async () => {
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(window.location.href);
            enqueueSnackbar("Folder link copied to clipboard.", { variant: SnackbarVariantTypes.Success });
            return;
        }
    }

    return <Box className={classes.grid} >
        <Divider />
        <DetailHeader onClose={props.onClose}>
            <Box display={'flex'}>
                <Button className={classes.actionButton} title="Copy Folder Link" onClick={copyFolderLink} color="primary" startIcon={<Link />}></Button>
            </Box>
        </DetailHeader>
        <Paper className={classes.paper}>
            <TitleEditField data={props.folder} />
            <Box p={6} display='flex'>
                <Grid container>
                    <Grid container item>
                        <Box display='flex' mt='2.5' mb='2.5' minWidth='100%' minHeight='0' flex='1 1 auto' flexDirection='column' >
                            <FolderAccessDetails
                                folder={props.folder}
                                onUpdateAccess={props.onUpdateAccess}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    </Box>
}
