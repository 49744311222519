import { Location } from "history";
import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import { ConfirmDialog } from "./ConfirmDialog";
import { Typography } from '@material-ui/core';
import IRouteLeavingGuardProps from './IRouteLeavingGuardProps';

const RouteLeavingGuard = ({when, navigate, dialogTitle, dialogConfirmButtonText, dialogCloseButtonText, dialogBodyText, children}: IRouteLeavingGuardProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const closeModal = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    // Route leaving guard for browser buttons(back, forward, refresh)
    if(when){
      window.onbeforeunload = (e: BeforeUnloadEvent) => {
        // Show the prompt
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = '';
      }
    } else {
      // Otherwise show no prompt
      window.onbeforeunload = null;
    }

    // Returned function will be called on component un-mount
    return () => {
      window.onbeforeunload = null;
    }
  }, [when])

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  }; 

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  const _dialogBodyText = dialogBodyText || "You have unsaved changes. Do you want to leave page and discard changes?"
  const dialogChildren = (<><Typography>{_dialogBodyText}</Typography>{children && children}</>)

  return (
    <>
        <Prompt when={when} message={handleBlockedNavigation} />
        <ConfirmDialog
            isOpen={modalVisible}
            onClose={closeModal}
            onConfirm={handleConfirmNavigationClick}
            title={dialogTitle || "Navigate without saving?"}
            confirmButtonText={dialogConfirmButtonText || "Discard Changes"}
            closeButtonText={dialogCloseButtonText || "Cancel"}
            children={dialogChildren}
        />
    </>
  );
};
export default RouteLeavingGuard;