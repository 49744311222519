import axios from 'axios';
import React, { useRef } from 'react'
import { Box, Button, Checkbox, Grid, InputLabel } from '@material-ui/core';
import PageHeader from '../PageHeader/PageHeader';
import SpinnerBackdrop from '../common/SpinnerBackdrop';
import useAsyncFunctionIsLoading from '../../hooks/useIsLoadingAsync';
import DataWriteService from '../../services/DataWriteService';
import { useSnackbar } from 'notistack';
import { useCurrentUserTermsAndConditionsStatus } from '../../hooks/useCurrentUserTermsAndConditionsStatus';
import { useAuthentication } from '../../hooks/useAuthentication';

export default function TermsAndConditionsPage() {
    const [acceptanceChecked, setAcceptanceChecked] = React.useState(false);
    const [termsAndConditionsDocument, setTermsAndConditionsDocument] = React.useState<Blob | null>(null);
    const [termsURL, setTermsURL] = React.useState<string>('');
    const termsLinkRef = useRef<HTMLAnchorElement>(null);

    const { enqueueSnackbar } = useSnackbar();
    const { logout } = useAuthentication();
    const { refreshUserStatus } = useCurrentUserTermsAndConditionsStatus();

    const { onTrigger: getTermsAndConditions, loading: loadingTermsAndConditions } = useAsyncFunctionIsLoading(async () => {
        let termsAndConditionsFile: any = termsAndConditionsDocument;
        if (!termsAndConditionsFile) {
            const response = await axios.get(`${process.env.REACT_APP_CLIENTPLATFORM_API_URL}${process.env.REACT_APP_CLIENTPLATFORM_API_GET_TERMS_AND_CONDITIONS}`,
                { responseType: 'blob' }
            )
            termsAndConditionsFile = response.data;
        }
        if (termsAndConditionsFile) {
            const blob = termsAndConditionsDocument || new Blob([termsAndConditionsFile], { type: "application/pdf" });
            setTermsAndConditionsDocument(blob);
            setTermsURL(URL.createObjectURL(blob));
        }
    });

    const { onTrigger: acceptTermsAndConditions, loading: submittingTerms } = useAsyncFunctionIsLoading(async () => {
        const dataWriteService = new DataWriteService();
        const response = await dataWriteService.AcceptTermsAndConditions();
        response.status ? onSuccessfulAcceptance() : onFailedAcceptance();
    });

    const onSuccessfulAcceptance = async () => {
        enqueueSnackbar('Terms and Conditions accepted successfully', { variant: 'success' });
        refreshUserStatus();
    }

    const onFailedAcceptance = () => {
        enqueueSnackbar('Failed to accept Terms and Conditions. Please try again.', { variant: 'error' });
        logout();
    }

    const onClickTermsLink = (e: React.MouseEvent) => {
        e.preventDefault();
        getTermsAndConditions().then(() => {
            if (termsLinkRef.current) {
                termsLinkRef.current.click();
            }
        });
    };

    const onClickLeavePortal = () => {
        window.location.href = 'https://mossadams.com';
    }

    const termsLink = <a onClick={(e) => onClickTermsLink(e)} href='#'>here</a>;
    return <Grid container justifyContent='center' alignItems='center'>
        <Grid item >
            <Box m={50} >
                <SpinnerBackdrop isActive={loadingTermsAndConditions || submittingTerms} />
                <PageHeader>Terms and Conditions</PageHeader>
                <a hidden
                    ref={termsLinkRef}
                    href={termsURL}
                    target="_blank"
                    rel='noopener noreferrer'
                />

                <p>
                    The Moss Adams LLP portal access agreement terms have been updated. Click <strong>{termsLink}</strong> to view the new terms.
                </p>

                <InputLabel aria-label='terms-and-conditions-acceptance-checkbox' focused={true}>
                            <Checkbox color={'primary'}
                                checked={acceptanceChecked}
                                onClick={() => setAcceptanceChecked(!acceptanceChecked)}
                            />
                            I agree to the terms referenced above.
                        </InputLabel>
                <Grid container justifyContent='space-between' alignContent='space-between' spacing={4}>
                    <Grid item>
                        <Button disabled={!acceptanceChecked}
                            color='primary'
                            onClick={acceptTermsAndConditions}
                            variant='outlined'
                        >
                            Accept Terms and Conditions
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant='outlined'
                            onClick={onClickLeavePortal}
                            color='primary'
                        >
                            Leave Moss Adams LLP Portal
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    </Grid>
}
