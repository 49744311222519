import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IConfirmDialogProps from './IConfirmDialogProps'
import MADialogTitle from '../MADialogTitle/MADialogTitle'

export const ConfirmDialog = (props: IConfirmDialogProps): JSX.Element => {
    const { isOpen, title, children, confirmButtonText = "Yes", confirmWithOptionsButtonText = "Yes (with Options)", closeButtonText = "No" } = props;

    return (
        <Dialog open={isOpen}>
            <MADialogTitle id="new-folder-dialog-title" onClose={props.onClose}>
                {title}
            </MADialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onConfirm} color="primary" size="small" fullWidth={false} variant="outlined" disabled={props.confirmDisabled}>
                    {confirmButtonText}
                </Button>
                {props.onConfirmWithOptions &&
                    <Button onClick={props.onConfirmWithOptions} color="primary" size="small" fullWidth={false} variant="outlined">
                        {confirmWithOptionsButtonText}
                    </Button>
                }
                <Button onClick={props.onClose} color="primary" size="small" fullWidth={false} variant="outlined">
                    {closeButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}