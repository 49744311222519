import { Box } from '@material-ui/core';
import * as React from 'react';


interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    className?: string;
}

export const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, className, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`grid-tab-${index}`}
            className={className}
            {...other}
        >
            <Box className={className} style={{ visibility: value === index ? 'visible' : 'hidden' }}>
                {children}
            </Box>
        </div>
    );
}