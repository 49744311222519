import React from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    actionButton: {
        color: theme.palette.primary.main,
        height: "40px",
        cursor: "pointer",
        position: "relative",
        right: "10px"
    }
}));

export const EllipsisButtonActionRenderer = (props: any) => {
    const classes = useStyles();

    let showEllipsis = true;

    if (typeof props?.data?.userHasAccess === "boolean" && props?.data?.userHasAccess == false) {
        showEllipsis = false;
    }

    return (
        <div>
            {showEllipsis &&
                <MoreHorizIcon className={classes.actionButton} />
            }
        </div>
    )
}