import { Link } from "react-router-dom"
import { GetApp, Search, Star, Edit, Save, Publish, ViewColumn } from '@material-ui/icons';
import HelpfulHintIcon from "./HelpfulHintIcon";
import RestrictedIcon from "../common/RestrictedIcon/RestrictedIcon";

type HelpfulHint = string | JSX.Element;

export const publicEngagementManagementHints = [
    "Click on a request to display the request details pane on the right.",
    "To upload a file, you can drag and drop files onto the Attachments section of a request.",
    "Note that you can select more than one file at a time and drag and drop them in a single action. This is helpful when minimizing notifications that are sent.",
    "To view requests assigned to you, use the Assignee filter. The Assignee(s) can be changed and saved for unrestricted requests.",
    <>Restricted Requests (designated with <HelpfulHintIcon><RestrictedIcon/></HelpfulHintIcon>) are only visible to the Assignee and the Moss Adams team. Any files or comments added to those requests follow the same restrictions.</>,
    "Files uploaded to Unrestricted Requests are visible to all users that have access to that client portal.",
    "If you need to upload a file that does not apply to a particular request, you may upload on the Documents screen itself.",
    "By uploading a file or leaving a comment, the request status will change to \"Submitted by Client\" and the Moss Adams team will be notified."
]

export const internalEngagementManagementHints = [
    <>
        To import a request list, click on the <HelpfulHintIcon><Publish /></HelpfulHintIcon> icon.
        Note: importing another file will append those requests to the bottom of the existing list.
    </>,
    <>
        To edit multiple requests at a single time, click on the <HelpfulHintIcon><Edit /></HelpfulHintIcon> icon, select rows (shift+click or CTRL+click), modify values on the right, and click <HelpfulHintIcon><Save /></HelpfulHintIcon>.
    </>,
    <>
        To download files associated with requests, click on the <HelpfulHintIcon><GetApp /></HelpfulHintIcon> icon, select requests, and click OK.
    </>,
    <>
        If records appear to be missing, you may need to clear filters <HelpfulHintIcon><ViewColumn /></HelpfulHintIcon>.
    </>,
    <>
        To upload <strong>unrestricted</strong> files, you can click and drag them directly onto Attachments.
    </>,
    <>
        To upload <strong>restricted</strong> files with sensitive information, click the + icon in the Attachments section and select the Restricted option.
    </>,
]

export const dashboardHints = [
    <>Use the client selector <HelpfulHintIcon><Search /></HelpfulHintIcon>  in the upper right to search for and view your engagement portal(s).</>,
    <>Use the <HelpfulHintIcon><Star /></HelpfulHintIcon> to favorite and pin portals to the top of the list.</>,
    "The Engagement Requests screen is where your Moss Adams team can publish requests and track open items.",
    "The Documents screen is the folder and file-level view that supports simplified drag and drop functionality to share files.",
    <>To change notification settings, click on the avatar in the upper right and go to Account Settings or click <Link to='/account-settings' target="_blank" rel="noopener noreferrer">here</Link>.</>,
]

export const getDocumentManagementHints = (isMossAdamsStaff: boolean): HelpfulHint[] => {
    const hints = [
        "Drag and drop files to upload to folders that you have access to, e.g. the Client Documents folder, Initial File Transfer.",
        "By default, files uploaded to a public folder (e.g Client Documents) by client users are only accessible to the uploading user and the Moss Adams team.",
        "Files uploaded to a restricted folder are accessible to users who have access to that folder.",
        isMossAdamsStaff ? "Files and folders can be moved via drag and drop using the \"handle\" on the left side of the grid." : null,
        "Final deliverables are uploaded by Moss Adams into the Final Documents folder(s).",
        <>To download a file or files, you can either:
            <ul>
            <li>Click on a file and then on the <HelpfulHintIcon><GetApp /></HelpfulHintIcon> in the File Details pane to the right, or</li>
            <li>Click the <HelpfulHintIcon><GetApp /></HelpfulHintIcon> above the grid and select the file(s)</li>
            </ul>
        </>,
    ];

    return hints.filter((hint): hint is HelpfulHint => Boolean(hint));
}