import { useDispatch, useSelector } from "react-redux"
import DataReadService from "../services/DataReadService";
import { ILookupLists } from "../interfaces/ILookupLists";
import { setLoadingLookupLists, setLookupLists } from "../store/actions";
import React from "react";
import { Utils } from "../utilities/utils";

export const useLookupLists = () => {
    const [lookupListError, setLookupListError] = React.useState<string | null>(null);
    const lookupLists: ILookupLists = useSelector((state: any) => state.lookupLists as ILookupLists);
    const loadingLookups: boolean = useSelector((state: any) => state.loadingLookups as boolean);
    const dispatch = useDispatch();

    const getLookupLists = async () => {
        dispatch(setLoadingLookupLists(true));

        const lists: ILookupLists | undefined = getLookupListsFromLocalStorage() ?? await fetchLookupLists();
        if (lists) {
            dispatch(setLookupLists(lists));
        }
        else {
            setLookupListError("Failed to load Lookup lists.");
        }

        dispatch(setLoadingLookupLists(false));
    }

    React.useEffect(() => {
        if (!lookupLists && !loadingLookups) {
            getLookupLists();
        }
    }, [])

    return { lookupLists, loadingLookups, lookupListError };
}

const fetchLookupLists = async () => {
    const dataReadService = new DataReadService();

    const promises = {
        areas: dataReadService.GetAreaList(),
        phases: dataReadService.GetPhaseList(),
        priorities: dataReadService.GetTaskPriorityList(),
        statuses: dataReadService.GetTaskStatusList(),
        socStatuses: dataReadService.GetSocStatusList(),
        taskTypes: dataReadService.GetTaskTypeList(),
    }

    let data: ILookupLists | undefined;

    await Promise
        .all(Object.entries(promises).map(
            async ([key, promise]) => {
                const result = await promise;
                if (!result.status) {
                    throw `Error: ${key} - ${result.status}`;
                }
                return [key, result?.data];
            }
        )
        )
        .then(results => {
            data = Object.fromEntries(results);
        })
        .catch(err => {
            console.log(err);
        });

    saveLookupListsToLocalStorage(data);

    return data;
}

const lookupListsKey = "CLIENT_PORTAL_LOOKUP_LISTS";

const getLookupListsFromLocalStorage = (): ILookupLists | undefined => {
    let lookupLists, data;

    const dataString = localStorage.getItem(lookupListsKey);
    if (dataString) {
        data = JSON.parse(dataString);
    }

    if (data && data.buildStamp === process.env.REACT_APP_BUILD_STAMP && data.expirationTime > Date.now()) {
        lookupLists = data.lookupLists;
    }

    return lookupLists;
}

const saveLookupListsToLocalStorage = (lookupLists: ILookupLists | undefined) => {
    if (lookupLists) {
        const minutesToRetain = 60;
        const expirationTime = Date.now() + (60 * 1000) * minutesToRetain;

        const item = {
            lookupLists,
            expirationTime,
            buildStamp: process.env.REACT_APP_BUILD_STAMP,
        }

        Utils.saveItemToLocalStorage(lookupListsKey, JSON.stringify(item));
    }
}
