// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_CLIENTPLATFORM_AZURE_APP_CLIENT_ID}`,
    redirectUri: `${process.env.REACT_APP_CLIENTPLATFORM_CLIENT_URL}`,
    authority: `${process.env.REACT_APP_CLIENTPLATFORM_AUTHORITY}`,
    knownAuthorities: [
      `${process.env.REACT_APP_CLIENTPLATFORM_AUTHORITY_ROOT}`,
    ],
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      /*  loggerCallback: (level: any, message: any, containsPii: any) => {
                 if (containsPii) {
                     return;
                 }
                 console.error(message);
             } */
    },
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ["openid"],
  forceRefresh: false,
};
