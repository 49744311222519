import { Typography, makeStyles } from '@material-ui/core'
import { ConfirmDialog } from '../ConfirmDialog/ConfirmDialog';

const useStyles = makeStyles((theme) => ({
    list: {
        "& li": {
            fontFamily: 'Founders Grotesk Reg',
        },
    },
}));

interface Props {
    restricting: boolean;
    onClose: () => void;
    isOpen: boolean;
    onConfirm: () => void;
}

export default function RequestRestrictionConfirmDialog({ restricting, onClose, isOpen, onConfirm }: Props) {
    const classes = useStyles();

    return (
        <ConfirmDialog title={restricting ? "Restrict Request?" : "Unlock Request?"}
            isOpen={isOpen}
            onConfirm={onConfirm}
            onClose={onClose}
        >
            <Typography>
                {restricting
                    ? "Restricting a request will:"
                    : "Unlocking a request will:"
                }
            </Typography>
            <ul className={classes.list}>
                <li>
                    {restricting
                        ? "Grant all selected Assignees access to all attachments (overriding any file-level access), and"
                        : "Make the request visible to all users with access to the portal, and"
                    }
                </li>
                <li>
                    {restricting
                        ? "Restrict visibility of the request to only the selected Assignees and all MA users"
                        : "Revert the current request-level access of associated attachments to their previous file-level access"
                    }
                </li>
            </ul>
            <Typography>Do you want to proceed?</Typography>
        </ConfirmDialog>
    )
}
