import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Route, Switch } from 'react-router-dom'
import { drawerWidth } from '../layout/Layout';
import _ from 'lodash';
import portalTheme from '../../helpers/portalTheme';
import * as PageHints from './Hints';
import ICurrentUser from '../../interfaces/ICurrentUser';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    helpfulHintsContainer: {
        overflowY: 'auto',
        paddingRight: theme.spacing(8),
        minWidth: drawerWidth,
    },
    title: {
        paddingLeft: theme.spacing(3),
        fontSize: '1rem',
        fontWeight: 'bold'
    },
    hintList: {
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(9),
    },
    hint: {
        whiteSpace: 'normal',
        fontSize: '.75rem',
        padding: `${theme.spacing(1)}px 0`,
        "& ul": {
            paddingLeft: theme.spacing(10),
        }
        
    }
}));

interface HelpfulHintProps { sidebarOpen: boolean };

function HelpfulHints({ sidebarOpen }: HelpfulHintProps) {
    const classes = useStyles();
    const currentUser = useSelector((state: any) => state.currentUser as ICurrentUser);

    const [showHints, setShowHints] = React.useState(true);
    const hideHintsOnUpdate = _.debounce(() => setShowHints(false), portalTheme.transitions.duration.leavingScreen);

    React.useEffect(() => {
        if (sidebarOpen) {
            setShowHints(true);
        }
        else {
            hideHintsOnUpdate(); // mirror slide behavior of sidebar on close
        }
    }, [sidebarOpen]);

    return showHints ? <div className={classes.helpfulHintsContainer}>
            <Switch>
                <Route path="/document-management" >
                    {Hints(PageHints.getDocumentManagementHints(currentUser.isMossAdamsStaff), classes)}
                </Route>
                <Route path="/engagement-management/request-list">
                    {Hints(PageHints.internalEngagementManagementHints, classes)}
                </Route>
                <Route path="/engagement-management">
                    {Hints(PageHints.publicEngagementManagementHints, classes)}
                </Route>
                <Route exact path="/" >
                    {Hints(PageHints.dashboardHints, classes)}
                </Route>
            </Switch>
        </div>
        : null
}

const Hints = (hints: (string | JSX.Element)[], classes: ClassNameMap) => {
    // Note: Rather than calling the makeStyles hook here, classes are passed as prop to avoid a
    //   React hooks rendering error when the sidebar is closing.
    return <>
        <Typography className={classes.title}>
            Helpful Hints:
        </Typography>
        <ul className={classes.hintList}>
            {hints.map( (hint, i) => <li key={i} className={classes.hint}>{hint}</li>)}
        </ul>
    </>

}

export default HelpfulHints