import { GridApi, RowNode } from "@ag-grid-enterprise/all-modules";
import IContentHierarchy from "../interfaces/IContentHierarchy";
import { HierarchyType } from "./enums/HierarchyType";

export const hierarchyIsFolder = (hierarchy: IContentHierarchy) => {
    return !hierarchyIsFile(hierarchy);
}

export const hierarchyIsFile = (hierarchy: IContentHierarchy) => {
    return hierarchy.hierarchyTypeDescription === HierarchyType.File.string;
}

export const hierarchyIsRestrictedFolder = (hierarchy: IContentHierarchy | undefined) => {
    return Boolean(hierarchy) && (hierarchy?.hierarchyTypeDescription === HierarchyType.RestrictedFolder.string);
}

export const getSelectedHierarchyTypeCount = (hierarchyTypeId: number, selectedHierarchies: IContentHierarchy[]) => {
    return selectedHierarchies.filter(h => h.hierarchyTypeId === hierarchyTypeId).length;
}

export const getAllDescendentItems = (folder: IContentHierarchy, fullHierarchy: IContentHierarchy[], includeRoot: boolean = false) => {
    let descendents: IContentHierarchy[] = [];

    const getFolderDescendents = (folderId: string) => {
        fullHierarchy.forEach(item => {
            if (item.parentId && item.parentId === folderId) {
                descendents.push(item);
                hierarchyIsFolder(item) && getFolderDescendents(item.id);
            }
        });
    }

    if (hierarchyIsFolder(folder)) {
        getFolderDescendents(folder.id);
        includeRoot && descendents.push(folder);
    }

    return descendents;
}

export const getContentHierarchyUrl = (hierarchy: IContentHierarchy) => {
    return `/document-management/${hierarchyIsFile(hierarchy) ? '' : 'folder/'}${hierarchy.id}`;
}

export const buildPath = (data: IContentHierarchy[]) => {
    return data.map(ch => {
        ch.path = []
        if (ch.parentId) {
            ch.path.push(ch.displayName);
            getParentDataLoop(ch, ch.path, data);
        }
        else {
            ch.path.push(ch.displayName)
        }
        return ch;
    });
}
const getParentDataLoop = (hierarchy: IContentHierarchy, path: string[], data: IContentHierarchy[]): any => {
    let p = data.filter(i => i.id == hierarchy.parentId)[0];
    hierarchy.parentIsRestricted = hierarchyIsRestrictedFolder(p);
    if (p.parentId) {
        path.unshift(p.displayName);
        return getParentDataLoop(p, path, data)
    }
    else {
        path.unshift(p.displayName);
    }
    return path;
}

export const getParentGridDataForNewChild = (child: IContentHierarchy, gridApi: GridApi | undefined | null): void => {
    if (gridApi && child.parentId) {
        const rowNode = gridApi.getRowNode(child.parentId);
        if (rowNode?.data) {
            const parent = rowNode.data;
            child.path = [...parent.path, child.displayName];
            child.parentIsRestricted = hierarchyIsRestrictedFolder(parent);
        }
    }
}

export function getUpdatedSubtreeForUpdatedFolder(updatedNode: RowNode, updatedNodeParent: RowNode, allUpdatedHierarchies: IContentHierarchy[] = []): IContentHierarchy[] {
    updateRowNodeParent(updatedNode, updatedNodeParent);
    allUpdatedHierarchies.push(updatedNode.data);

    if (updatedNode.allChildrenCount && updatedNode.allChildrenCount > 0) {
        updatedNode.childrenAfterGroup?.forEach(child => {
            return getUpdatedSubtreeForUpdatedFolder(child, updatedNode, allUpdatedHierarchies);
        })
    }
    return allUpdatedHierarchies;
}
function updateRowNodeParent(movedNode: RowNode, updatedParent: RowNode) {
    const parentData: IContentHierarchy = updatedParent.data;
    const moveNodeData: IContentHierarchy = movedNode.data;

    moveNodeData.path = parentData.path ? [...parentData.path, moveNodeData.displayName] : [moveNodeData.displayName];
    moveNodeData.parentId = parentData.id;
    const parentWasRestricted = moveNodeData.parentIsRestricted;
    moveNodeData.parentIsRestricted = hierarchyIsRestrictedFolder(parentData);
    moveNodeData.isPrivate = moveNodeData.isRestrictedDocument || (!parentWasRestricted && moveNodeData.isPrivate) || moveNodeData.parentIsRestricted;
}