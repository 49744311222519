import IContentHierarchy from "../interfaces/IContentHierarchy";
import IDocument from "../interfaces/IDocument";
import ITaskDocument from "../interfaces/ITaskDocument";
import DataWriteService from "./DataWriteService";

export const updateExpireDates = async (documents: IDocument[] | IContentHierarchy[] | ITaskDocument[], dayIncrement: number = 30) => {
    const dataWriteService = new DataWriteService();

    const model = {
        dtos: buildExpireDateDtosFromArray(documents),
        dayIncrement
    }

    return await dataWriteService.UpdateDocumentExpireDate(model);
}


const buildExpireDateDtosFromArray = (documents: IDocument[] | IContentHierarchy[] | ITaskDocument[]) => {
    return documents.map(doc => {
        return {
            documentGuid: "documentGuid" in doc ? doc.documentGuid
                : "id" in doc ? doc.id
                    : doc.documentId,
            currentExpirationDate: doc.expirationDate
        }
    });
}