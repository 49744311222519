import { Utils } from "../utilities/utils";
import * as mime from 'mime-types';
import { getFileExtensionFromFileName } from "../helpers/FileHelpers";

const invalidFileExtensions = ["", "bat", "lnk", "pif", "vb", "cmd", "msc", "ps1", "vbe", "com", "msh", "ps1xml", "vbs", "cpl", "msh1",
    "ps2", "ws", "dll", "msh2", "ps2xml", "wsc", "exe", "mshxml", "psc1", "wsf", "inf", "msh1xml", "psc2", "wsh", "jar", "msh2xml",
    "reg", "js", "msi", "scf", "jse", "msp", "scr", "rar", "gif",];

export interface IValidation {
    isValid: boolean,
    isNotValid: boolean,
    error: string,
    helperText: string,
}

export interface ValidatedFile {
    file: File,
    validation: IValidation,
};

export interface ValidatedFileSet {
    validFiles: File[],
    invalidFiles: ValidatedFile[],
    fileSetSizeValidation: IValidation
    hasValidFiles: boolean
    hasInvalidFiles: boolean
}

const rejectedString = (name: string, error: string) => {
    return `${name} was rejected. ${error}`
}

export const validateFile = (file: File, validateSize: boolean = true): IValidation => {
    let isValid = true;
    let error = "";

    const name = validateFileName(file.name);
    if (name.isNotValid) {
        name.error = rejectedString(file.name, name.error);
        return name;
    }

    const type = validateFileType(file.name);
    if (type.isNotValid) {
        type.error = rejectedString(file.name, type.error);
        return type;
    }

    if (validateSize) {
        const size = validateUploadSize(file.size);
        if (size.isNotValid) {
            size.error = rejectedString(file.name, type.error);
            if (size.isNotValid) return size;
        }
    }

    return {
        isValid,
        isNotValid: !isValid,
        error,
        helperText: error
    }
}

export const validateFiles = (files: File[]): ValidatedFileSet => {
    const validFiles: File[] = [],
        invalidFiles: ValidatedFile[] = [];

    files.forEach(file => {
        const validation = validateFile(file, false);
        validation.isValid ? validFiles.push(file) : invalidFiles.push({ file, validation });
    })

    const uploadSize = files.map(file => file.size)?.reduce((sum, currentValue) => sum + currentValue);
    const fileSetSizeValidation = validateUploadSize(uploadSize);

    return {
        validFiles,
        invalidFiles,
        hasValidFiles: validFiles.length > 0,
        hasInvalidFiles: invalidFiles.length > 0,
        fileSetSizeValidation,
    }
}

export const validateFileName = (value: string): IValidation => {
    let isValid = true;
    let error = "";

    if (!value) {
        isValid = false;
        error = "File name is required.";
    }
    else if (value.length < 3 || value.length > 250) {
        isValid = false;
        error = "File name length must be between 3 and 250.";
    }
    else if (RegExp("[\\\\\\/:*?\"<>|]+").test(value) === true) {
        isValid = false;
        error = "A file name can't contain any of the following characters: \\ / : * ? \" < > |";
    }
    else if (RegExp("[^\x00-\x7F]+").test(value) === true) {
        isValid = false;
        error = "A file name cannot contain invalid characters.";
    }

    return {
        isValid,
        isNotValid: !isValid,
        error,
        helperText: error
    }
}

export const validateFileType = (fileName: string,): IValidation => {
    // file.type is unreliable for verifiying filetype so name is used with mime-types lookup
    const extension = getFileExtensionFromFileName(fileName)
    const isValid = !!extension && !invalidFileExtensions.includes(extension);
    let error = isValid ? "" : "Invalid file type.";

    return {
        isValid,
        isNotValid: !isValid,
        error,
        helperText: error
    }
}

// Individual files cannot exceed 2GB, nor can any set of files
export const validateUploadSize = (uploadSize: number | null | undefined): IValidation => {
    let isValid = true;
    let error = "";

    if (typeof uploadSize === 'number') {
        if (uploadSize > Utils.maxUploadSize) {
            error = `The upload size is too large. ${Utils.getFileSize(uploadSize)} / 2.0 GB`;
            isValid = false;
        }
    } else {
        error = `Unable to determine file size(s)`
        isValid = false;
    }

    return {
        isValid,
        isNotValid: !isValid,
        error,
        helperText: error
    }
}
