

// Previously the file extension was being derived from the File.type property. 
// This frequently works, but is dependent on the browser and operating system setting these values properly. 
export const getFileExtensionFromFileName = (fileName: string) => {
    const extensionIndex = fileName.lastIndexOf('.') + 1;
    if (extensionIndex === 0) 
        return "";
    const extension = fileName.substring(extensionIndex,fileName.length);
    return extension;
}