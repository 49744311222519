

import { AgGridColumn, AgGridReact } from '@ag-grid-community/react'
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { CircularProgress, Divider, makeStyles } from '@material-ui/core';
import React from 'react'
import { IRestrictedUser } from '../../../interfaces/IRestrictedUser';

const useStyles = makeStyles((theme) => ({
    grid: {
        height: "210px",
        "& .ag-center-cols-viewport": {
            overflowX: "hidden"
        }
    },
}))

interface Props {
    data: IRestrictedUser[] | null;
    loading: boolean;
}

const UserAccessGrid = (props: Props) => {
    const classes = useStyles();

    const noRowsOverlay = () => {
        return <div>Unrestricted Access</div>
    }

    const loadingOverlay = () => {
        return <div><CircularProgress size={14} color='inherit' /></div> 
    }

    return (<div key={props.data?.length} className={`ag-theme-alpine ${classes.grid}`} >
        <Divider />
        <AgGridReact
            rowData={props.data}
            modules={AllModules}
            noRowsOverlayComponent={noRowsOverlay}
            loadingOverlayComponent={loadingOverlay}
            suppressCellFocus={true}
            suppressContextMenu={true}
            onGridReady={(event) => event.api.sizeColumnsToFit()}
            defaultColDef={{
                editable: false,
                sortable: true,
                filter: true,
                floatingFilter: false,
            }}
            headerHeight={25}
        >
            <AgGridColumn
                field="displayName"
                headerName="Name"
                resizable={true}
                editable={false}
                maxWidth={400}
            />
            <AgGridColumn
                field="groupName"
                headerName="Organization"
                resizable={true}
                editable={false}
                sort={"asc"}
                maxWidth={170}
            />
        </AgGridReact>
    </div>
    )
}

export default UserAccessGrid