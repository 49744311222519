import React from 'react'
import { Button, DialogActions, DialogContent, Dialog } from '@material-ui/core'
import MADialogTitle from '../MADialogTitle/MADialogTitle';
import { ManageFolderAccessModalContent } from './ManageFolderAccessModalContent';
import IContentHierarchy from '../../interfaces/IContentHierarchy';
import useAsyncFunctionIsLoading from '../../hooks/useIsLoadingAsync';
import SpinnerBackdrop from '../common/SpinnerBackdrop';
import IUpdateFolderPermissionsDto from '../../interfaces/IUpdateFolderPermissionsDto';
import DataWriteService from '../../services/DataWriteService';
import { useSnackbar } from 'notistack';
import usePermittedUsers from '../../hooks/usePermittedUsers';

interface Props {
    onUpdateAccess?: Function;
    onClose: () => void;
    isOpen: boolean;
    folder: IContentHierarchy;
}

const updateSuccess = "Successfully updated folder permissions.";

export default function ManageFolderAccessModal(props: Props) {
    const permittedUsers = usePermittedUsers();
    const { enqueueSnackbar } = useSnackbar();

    const {onTrigger: onSubmit, loading: isSubmitting} = useAsyncFunctionIsLoading(async () => {
        const dto: IUpdateFolderPermissionsDto = {
            userIds: permittedUsers.getSelectedPermittedUserIdsForRequest(),
            folderId: props.folder.id
        }
        const dataWriteService = new DataWriteService();
        const response = await dataWriteService.UpdateFolderAccessPermissions(dto);

        if (response.status) {
            props.onUpdateAccess?.(permittedUsers.getPermittedUserIds());
            enqueueSnackbar(updateSuccess, { variant: 'success' });
        }
        else {
            enqueueSnackbar(response.errorMessages, { variant: 'error'});
        }
    });

    return <>
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            fullWidth
        >
            <MADialogTitle id="restricted-folder-dialog-title" onClose={props.onClose}>
                Manage Restricted Folder Access
            </MADialogTitle>
            <DialogContent>
                <ManageFolderAccessModalContent
                    permittedUsers={permittedUsers}
                    folder={props.folder}
                />
                <SpinnerBackdrop isActive={isSubmitting} />
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' color='primary' onClick={onSubmit} disabled={isSubmitting || !permittedUsers.hasSelections}>
                    Save
                </Button>
                <Button variant='outlined' color='primary' onClick={props.onClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    </>
}