import React, { useEffect, useState } from "react";
import DataReadService from "../services/DataReadService";
import { useDispatch, useSelector } from "react-redux";
import ICurrentUser from "../interfaces/ICurrentUser";
import { setCurrentUser } from "../store/actions";

/**
 * Hook to set and update the current user's terms and conditions status. 
 */
export const useCurrentUserTermsAndConditionsStatus = () => {
    const currentUser = useSelector((state: any) => state.currentUser as ICurrentUser);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if(currentUser && currentUser.userTermsAcknowledgmentUpToDate === null) {
            refreshUserStatus();
        }
    }, [])

    const refreshUserStatus = async () => {
        const status = await fetchCurrentUserStatus();
        dispatch(setCurrentUser({ currentUser: { ...currentUser, userTermsAcknowledgmentUpToDate: status.data } }));
        return status;
    }

    const fetchCurrentUserStatus = () => {
        const dataReadService = new DataReadService();
        return dataReadService.GetUserTermsAndConditionsAcceptanceStatus();
    }

    return {
        userTermsAcknowledgmentUpToDate: currentUser.userTermsAcknowledgmentUpToDate,
        refreshUserStatus
    }
}