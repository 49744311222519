import React, { useState } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { setSelectedClientEngagementIds, setSelectedClients, setSelectedEngagements } from '../../store/actions';
import IClientEngagement from '../../interfaces/IClientEngagement';
import { useSelectedClientsAndEngagements } from '../../hooks/useSelectedClientsAndEngagements';
import { Utils } from '../../utilities/utils';
import DataWriteService from '../../services/DataWriteService';

export const useUpdateSelectedClientsAndEngagements = () => {
    const dispatch = useDispatch();

    const [clientsAndEngagementsToSelect, setClientsAndEngagementsToSelect] = useState<string[]>([]);

    const {
        selectedClients,
        selectedEngagements,
    } = useSelectedClientsAndEngagements();

    const clients: IClientEngagement[] = useSelector((state: any) => state.clients);
    const engagements: IClientEngagement[] = useSelector((state: any) => state.engagements);

    React.useEffect(() => {
        const clientsAndEngagementsAreInState = clients.length !== 0;
        if (clientsAndEngagementsAreInState && clientsAndEngagementsToSelect.length > 0) {
            updateSelectedClientAndEngagementIds(clientsAndEngagementsToSelect);
        }
    }, [clients, engagements, clientsAndEngagementsToSelect])

    React.useEffect(() => {
        setSelectedClientEngagementIdsSessionStorage(selectedClients, selectedEngagements);
    }, [selectedClients, selectedEngagements])

    const selectedClientsAndEngagementsAreLoaded = (selectedClientEngagementIds: string[]) => {
        const loadedClientsAndEngagements = [...clients, ...engagements].map(c => c.id);
        return selectedClientEngagementIds
            .every(id => loadedClientsAndEngagements.includes(id));
    }

    const updateSelectedClientAndEngagementIds = async (selectedClientEngagementIds: string[]) => {
        if (selectedClientEngagementIds === undefined) return;

        if (!selectedClientsAndEngagementsAreLoaded(selectedClientEngagementIds)) {
            await updateClientLastAccessed(selectedClientEngagementIds[0]);
            return setClientsAndEngagementsToSelect(selectedClientEngagementIds);
        }
        else if (clientsAndEngagementsToSelect.length > 0) {
            setClientsAndEngagementsToSelect([]);
        }

        const newSelectedClients = filterIds(selectedClientEngagementIds, clients);
        const newSelectedEngagements = filterIds(selectedClientEngagementIds, engagements);

        const clientsUpdated = hasUpdated(newSelectedClients, selectedClients);
        const engagementsUpdated = hasUpdated(newSelectedEngagements, selectedEngagements);

        batch(() => {
            if (clientsUpdated) dispatch(setSelectedClients(newSelectedClients));
            if (engagementsUpdated) dispatch(setSelectedEngagements(newSelectedEngagements));
            if (clientsUpdated || engagementsUpdated) dispatch(setSelectedClientEngagementIds([...selectedClientEngagementIds]));
        });
    }

    const filterIds = (ids: string[], data: { id: string }[]) => {
        return ids.filter(id => data.map(d => d.id).includes(id));
    }

    const hasUpdated = (newData: string[], oldData: string[]) => {
        return newData.every(id => !oldData.includes(id)) || newData.length !== oldData.length;
    }

    const setSelectedClientEngagementIdsSessionStorage = (clientIds: string[], engagementIds: string[]) => {
        if (clientIds?.length > 0 || engagementIds?.length > 0) {
            let clientEngagementIdsString = '';

            if (clientIds?.length > 0) {
                clientEngagementIdsString = clientIds.join('|');
            }
            if (engagementIds?.length > 0) {
                clientEngagementIdsString += '|' + engagementIds.join('|');
            }
            Utils.setSessionStorage('selectedClientEngagementIds', `${clientEngagementIdsString}`);
        }
    }

    const updateClientLastAccessed = (clientId: string) => {
        const params = new URLSearchParams();
        params.append("clientId", clientId);
        const dataWriteService = new DataWriteService();
        return dataWriteService.UpdateLastAccess(params);
    }

    return {
        clients,
        engagements,
        updateSelectedClientAndEngagementIds,
        updateClientLastAccessed,
    };
};