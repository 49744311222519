import { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import PlatformApiService from './PlatformApiService';
import IDocumentCopyModel from '../interfaces/IDocumentCopyModel';
import IContentHierarchy from '../interfaces/IContentHierarchy';
import IPlatformApiPostResult from '../interfaces/IPlatformApiPostResult';
import ICreateFolderModel from '../interfaces/ICreateFolderModel'
import IUpdateFavoriteModel from '../interfaces/IUpdateFavoriteModel';
import IUpdateTaskListCommandModel from '../interfaces/IUpdateTaskListCommandModel';
import IDeleteTaskDocumentDto from "../interfaces/IDeleteTaskDocumentDto";
import ITask from '../interfaces/ITask';
import ITaskComment from '../interfaces/ITaskComment';
import ITaskDocument from '../interfaces/ITaskDocument';
import IDocumentsUploadModel from '../interfaces/IDocumentsUploadModel';
import IDeleteFolderOrDocumentModel from '../interfaces/IDeleteFolderOrDocumentModel';
import { IUserClientEngagementNotificationSetting, IUserNotificationSetting } from '../interfaces/IUserNotificationSettings';
import { IUpdateDocumentAccessDto } from '../interfaces/IUpdateDocumentPermissionsDto';
import { CurrentDocumentExpireDate, IDocumentExpireDateDto } from '../interfaces/IDocumentExpireDateDto';
import IUpdateFolderPermissionsDto from '../interfaces/IUpdateFolderPermissionsDto';
import { MoveDocumentsDto } from '../hooks/useMoveDocuments';
import { MoveFoldersDto } from '../hooks/useMoveFolders';
import { RequestRestrictionDto, RequestRestrictionResponse } from '../hooks/useUpdateRequestRestriction';

/**
 * A set of methods for making POST calls to the Client Platform API to create or update data.
 */
export default class DataWriteService extends PlatformApiService {

    /**
    * Upload multiple documents to a folder
    * 
    * @param IDocumentCopyModel 
    */
    public async PostDocuments(model: IDocumentsUploadModel, progressCallback?: (progressEvent: AxiosProgressEvent) => void): Promise<IPlatformApiPostResult> {
        const postDocumentEndpoint = process.env.REACT_APP_CLIENTPLATFORM_API_DOCUMENTS_UPLOAD || '';

        // FormData is used when sending files to a server
        // Append the files and entityId to the formData 
        // The keys in this key value pair must match the parameter names in the API
        let formDataBody = new FormData();
        model.documentFiles.forEach((document) => {
            formDataBody.append("DocumentFiles", document);
        });
        model.permittedUsers.forEach((userId) => {
            formDataBody.append("PermittedUsers", userId);
        });

        formDataBody.append('FolderId', model.folderId);
        formDataBody.append('OverwriteExisting', model.overwriteExisting.toString());
        formDataBody.append('IsPrivate', model.isPrivate.toString());
        formDataBody.append('SuppressNotifications', Boolean(model.suppressNotifications).toString());

        if (model.newVersionDocumentId) {
            formDataBody.append('NewVersionDocumentId', model.newVersionDocumentId);
        }

        const axiosConfigurations: AxiosRequestConfig = {

            headers: {
                'Content-Type': 'multipart/form-data',
                'accept': 'application/json'
            },
            onUploadProgress: progressCallback,
            timeout: Number(process.env.REACT_APP_CLIENTPLATFORM_UPLOAD_TIMEOUT)
        };

        const response = await this.PlatformApiPost<null, FormData>(postDocumentEndpoint, formDataBody, axiosConfigurations);

        return response;
    }

    /**
    * Make an old document version a new head version
    * 
    * @param docId
    * @param fileVersion
    */
    public async RestoreDocumentVersion(docId: string, fileVersion: string): Promise<IPlatformApiPostResult> {
        const postDocumentEndpoint = process.env.REACT_APP_CLIENTPLATFORM_API_DOCUMENT_RESTOREVERSION || '';

        // FormData is used when sending files to a server
        const formDataBody = new FormData();
        // Append the file and entityId to the formData 
        // The keys in this key value pair must match the parameter names in the API
        formDataBody.append('fileId', docId);
        formDataBody.append('version', fileVersion);

        const axiosConfigurations: AxiosRequestConfig = {

            headers: {
                'Content-Type': 'multipart/form-data',
                'accept': 'application/json'
            },
            // For file upload we will use a timeout of 2 minutes 
            timeout: 120000
        };

        const response = await this.PlatformApiPost<null, FormData>(postDocumentEndpoint, formDataBody, axiosConfigurations);

        return response;
    }

    /**
    * Copy an existing document to a different folder
    * 
    * @param IDocumentCopyModel 
    */
    public async CopyDocument(model: IDocumentCopyModel): Promise<IPlatformApiPostResult> {
        const postDocumentEndpoint = process.env.REACT_APP_CLIENTPLATFORM_API_DOCUMENT_COPY || '';

        // FormData is used when sending files to a server
        const formDataBody = new FormData();
        // Append the file and entityId to the formData 
        // The keys in this key value pair must match the parameter names in the API
        formDataBody.append('SourceFileId', model.sourceFileId);
        formDataBody.append('FolderId', model.folderId);
        formDataBody.append('FileName', model.fileName);

        const axiosConfigurations: AxiosRequestConfig = {

            headers: {
                'Content-Type': 'multipart/form-data',
                'accept': 'application/json'
            },
            // For file upload we will use a timeout of 5 minutes 
            timeout: 300000
        };

        const response = await this.PlatformApiPost<null, FormData>(postDocumentEndpoint, formDataBody, axiosConfigurations);

        return response;
    }

    /**
    * Update Document metadata
    * 
    * @param model
    */
    public async UpdateDocument(model: IContentHierarchy): Promise<IPlatformApiPostResult> {
        const updateDocumentEndpoint = process.env.REACT_APP_CLIENTPLATFORM_API_DOCUMENT_UPDATE || '';

        return await this.PlatformApiPost<IContentHierarchy, IContentHierarchy>(updateDocumentEndpoint, model);
    }

    /**
    * Restore Document 
    * 
    * @param model
    */
    public async RestoreDocument(fileId: string): Promise<IPlatformApiPostResult> {
        const restoreDocumentEndpoint = `${process.env.REACT_APP_CLIENTPLATFORM_API_DOCUMENT_RESTORE}/${fileId}`;
        return await this.PlatformApiPost<string, string>(restoreDocumentEndpoint, fileId);
    }

    /**
    * Update Folder 
    * 
    * @param model
    */
    public async UpdateFolder(model: IContentHierarchy): Promise<IPlatformApiPostResult> {
        const updateFolderEndpoint = process.env.REACT_APP_CLIENTPLATFORM_API_FOLDER_UPDATE || '';

        return await this.PlatformApiPost<IContentHierarchy, IContentHierarchy>(updateFolderEndpoint, model);
    }

    /**
    * Delete an array of folders or documents represented by IDeleteFolderOrDocumentModel objects. 
    * 
    * @param documentsAndFolders 
    */
    public async DeleteFoldersOrDocuments(documentsAndFolders: IDeleteFolderOrDocumentModel[]): Promise<IPlatformApiPostResult> {
        const clientPlatformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_FOLDERS_OR_DOCUMENTS_DELETE || "";

        return await this.PlatformApiPost<null, IDeleteFolderOrDocumentModel[]>(clientPlatformApiEndpointPath, documentsAndFolders);
    }

    /**
    * Sends a delete request to the database API to delete an document
    * 
    * @param fileId 
    */
    public async DeleteDocument(fileId: string): Promise<IPlatformApiPostResult> {
        const clientPlatformApiEndpointPath: string = `${process.env.REACT_APP_CLIENTPLATFORM_API_DOCUMENT_DELETE}/${fileId}`;

        return await this.PlatformApiDelete<string>(clientPlatformApiEndpointPath, fileId);
    }

    /**
    * Add a sub folder to the specified parent folder
    * 
    * @param model: ICreateFolderModel
    */
    public async CreateFolder(model: ICreateFolderModel): Promise<IPlatformApiPostResult> {
        const clientPlatformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_FOLDER_CREATE || "";

        return await this.PlatformApiPost<IContentHierarchy, ICreateFolderModel>(clientPlatformApiEndpointPath, model);
    }

    /**
     * Toggle a whether or not a client is a "favorite"
     * 
     * @param model: IUpdateFavoriteModel
     */

    public async UpdateFavorite(model: IUpdateFavoriteModel): Promise<IPlatformApiPostResult> {
        const clientPlatformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_UPDATE_FAVORITE || "/000122/updatefavorite";

        return await this.PlatformApiPost<IContentHierarchy, IUpdateFavoriteModel>(clientPlatformApiEndpointPath, model);
    }


    /**
     * Update the last accessed data for a client.
     * 
     * @returns A [[DataResult]] with the Clients as the data payload.
     */
    public async UpdateLastAccess(clientIdParam: URLSearchParams): Promise<IPlatformApiPostResult> {

        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_CLIENT_LAST_ACCESS || '';

        let axiosConfig: AxiosRequestConfig = { params: clientIdParam };

        return await this.PlatformApiPost(platformApiEndpointPath, undefined, axiosConfig);
    }

    /**
     * Update Engagement Management record
     * 
     * @param model: IUpdateFavoriteModel
     */

    public async UpdateEngagementManagementTaskAdmin(model: ITask): Promise<IPlatformApiPostResult> {
        const clientPlatformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_UPDATE_ENGAGEMENTMANAGEMENT_TASK_ADMIN || "";

        return await this.PlatformApiPost<null, ITask>(clientPlatformApiEndpointPath, model);
    }

    /**
     * Update Engagement Management record
     * 
     * @param model: IUpdateFavoriteModel
     */

    public async UpdateEngagementManagementTaskClient(model: ITask): Promise<IPlatformApiPostResult> {
        const clientPlatformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_UPDATE_ENGAGEMENTMANAGEMENT_TASK_CLIENT || "";

        return await this.PlatformApiPost<null, ITask>(clientPlatformApiEndpointPath, model);
    }

    /**
     * Update Task List tasks
     * 
     * @param model: ITaskListVM
     */

    public async UpdateTaskList(model: IUpdateTaskListCommandModel): Promise<IPlatformApiPostResult> {
        const clientPlatformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_UPDATE_TASKLIST_UPDATE || "";

        return await this.PlatformApiPost<null, IUpdateTaskListCommandModel>(clientPlatformApiEndpointPath, model);
    }

    /**
     * Update Task 
     * 
     * @param model: ITaskM
     */

    public async UpdateTask(model: ITask): Promise<IPlatformApiPostResult> {
        const clientPlatformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_UPDATE_TASK || "";

        return await this.PlatformApiPost<null, ITask>(clientPlatformApiEndpointPath, model);
    }

    /**
     * Create Task Comment
     * 
     * @param model: ITaskListVM
     */

    public async CreateTaskComment(model: ITaskComment): Promise<IPlatformApiPostResult> {
        const clientPlatformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_CREATE_TASK_COMMENT || "";

        return await this.PlatformApiPost<null, ITaskComment>(clientPlatformApiEndpointPath, model);
    }

    /**
     * Update Task Comment
     * 
     * @param model: ITaskListVM
     */

    public async UpdateTaskComment(model: ITaskComment): Promise<IPlatformApiPostResult> {
        const clientPlatformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_TASKCOMMENT_UPDATE || "";

        return await this.PlatformApiPost<null, ITaskComment>(clientPlatformApiEndpointPath, model);
    }

    /**
    * Sends a delete request to the database API to delete a set of tasks
    * 
    * @param taskId 
    */
    public async PermanentlyDeleteTasks(taskIds: string[]): Promise<IPlatformApiPostResult> {
        const clientPlatformApiEndpointPath: string = `${process.env.REACT_APP_CLIENTPLATFORM_API_TASKS_PERMANENTLY_DELETE}`;
        return await this.PlatformApiDelete<string[]>(clientPlatformApiEndpointPath, taskIds);
    }

    /**
    * Sends a create task document request to the database API to delete a task document
    * 
    * @param model
    * 
    */
    public async CreateTaskDocument(model: ITaskDocument[]): Promise<IPlatformApiPostResult> {
        const clientPlatformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_TASKDOCUMENT_CREATE || "";

        return await this.PlatformApiPost<ITaskDocument[], ITaskDocument[]>(clientPlatformApiEndpointPath, model);
    }

    /**
    * Sends a delete task document request to the database API to delete a task document
    * 
    * @param taskId
    * @param documentId 
    * 
    */
    public async DeleteTaskDocument(model: IDeleteTaskDocumentDto): Promise<IPlatformApiPostResult> {
        const clientPlatformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_TASKDOCUMENT_DELETE || "";

        return await this.PlatformApiDelete<IDeleteTaskDocumentDto>(clientPlatformApiEndpointPath, model);
    }

    /**
    * Sends a delete task comment request to the database API to delete a task comment
    * 
    * @param taskId
    * @param documentId 
    * 
    */
    public async DeleteTaskComment(taskCommentId: string): Promise<IPlatformApiPostResult> {
        const clientPlatformApiEndpointPath: string = `${process.env.REACT_APP_CLIENTPLATFORM_API_TASKCOMMENT_DELETE}/${taskCommentId}`;

        return await this.PlatformApiDelete<string>(clientPlatformApiEndpointPath, taskCommentId);
    }

    /**
     * Toggle whether a notification setting is on or off
     * 
     * @param model: INotificationSetting
     */

    public async UpdateNotificationSettings(model: IUserNotificationSetting[]): Promise<IPlatformApiPostResult> {
        const clientPlatformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_USERNOTIFICATIONSETTINGS_UPDATE || "";

        return await this.PlatformApiPost<null, IUserNotificationSetting[]>(clientPlatformApiEndpointPath, model);
    }

    /**
     * Toggle whether a client/engagement notification setting is on or off
     * 
     * @param model: INotificationSetting
     */

    public async UpdateClientEngagementNotificationSettings(model: IUserClientEngagementNotificationSetting[]): Promise<IPlatformApiPostResult> {
        const clientPlatformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_USERCLIENTENGAGEMENTNOTIFICATIONSETTINGS_UPDATE || "";

        return await this.PlatformApiPost<null, IUserClientEngagementNotificationSetting[]>(clientPlatformApiEndpointPath, model);
    }

    /**
     * Update access permissions on restricted document
     * 
     * @param model: IUpdateDocumentAccessDto
     */
    public async UpdateDocumentAccessPermissions(model: IUpdateDocumentAccessDto): Promise<IPlatformApiPostResult> {
        const clientPlatformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_DOCUMENT_RESTRICTED_ACCESS_UPDATE || "";

        return await this.PlatformApiPost<null, IUpdateDocumentAccessDto>(clientPlatformApiEndpointPath, model);
    }

    /**
     * Update access permissions on restricted document
     * 
     * @param model: IUpdateFolderPermissionsDto
     */
    public async UpdateFolderAccessPermissions(model: IUpdateFolderPermissionsDto): Promise<IPlatformApiPostResult> {
        const clientPlatformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_FOLDER_UPDATE_ACCESS || "";

        return await this.PlatformApiPost<null, IUpdateFolderPermissionsDto>(clientPlatformApiEndpointPath, model);
    }

    /**
     * Update the expire date for one or many documents.
     * 
     * @param model: IDocumentExpireDateDto
     */
    public async UpdateDocumentExpireDate(model: IDocumentExpireDateDto): Promise<IPlatformApiPostResult> {
        const clientPlatformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_DOCUMENT_EXPIRE_DATE_UPDATE || "";

        return await this.PlatformApiPost<CurrentDocumentExpireDate[], IDocumentExpireDateDto>(clientPlatformApiEndpointPath, model);
    }

    /**
     * Move documents to a new folder.
     * @param model: MoveDocumentsDto
     */
    public async MoveDocuments(model: MoveDocumentsDto): Promise<IPlatformApiPostResult> {
        const clientPlatformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_DOCUMENTS_MOVE || "";

        return await this.PlatformApiPost<null, MoveDocumentsDto>(clientPlatformApiEndpointPath, model);
    }

    /**
     * Move folders to a new folder.
     * @param model: MoveFoldersDto
     */
    public async MoveFolders(model: MoveFoldersDto): Promise<IPlatformApiPostResult> {
        const clientPlatformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_FOLDERS_MOVE || "";

        return await this.PlatformApiPost<null, MoveFoldersDto>(clientPlatformApiEndpointPath, model);
    }


    /**
     * Update IsRestricted for a set of requests. 
     * @param model: RequestRestrictionDto 
     * @returns RequestRestrictionResponse
     */
    public async UpdateRequestRestriction(model: RequestRestrictionDto): Promise<IPlatformApiPostResult> {
        const clientPlatformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_UPDATE_TASK_RESTRICTIONS || "";

        return await this.PlatformApiPost<RequestRestrictionResponse, RequestRestrictionDto>(clientPlatformApiEndpointPath, model);
    }

    /**
     * Update terms and condtions acceptance status for the current user.
     */
    public async AcceptTermsAndConditions(): Promise<IPlatformApiPostResult> {
        const clientPlatformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_ACCEPT_TERMS_AND_CONDITIONS || "";

        return await this.PlatformApiPost(clientPlatformApiEndpointPath, undefined, undefined);
    }
}