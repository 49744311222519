import React from 'react'
import UserAccessGrid from '../../common/UserAccessGrid/UserAccessGrid';
import { IRestrictedUser } from '../../../interfaces/IRestrictedUser';
import { Button, Tooltip } from '@material-ui/core';
import IRestrictedUserListDto from '../../../interfaces/IRestrictedUserListDto';
import DataReadService from '../../../services/DataReadService';
import IContentHierarchy from '../../../interfaces/IContentHierarchy';
import ManageFolderAccessModal from '../../ManageFolderAccessModal/ManageFolderAccessModal';
import { hierarchyIsRestrictedFolder } from '../../../helpers/ContentHierarchyHelpers';
import DetailSection from '../../common/DetailSectionHeader/DetailSection/DetailSection';
import { DetailSectionHeader } from '../../common/DetailSectionHeader/DetailSectionHeader';
import { Group } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import ICurrentUser from '../../../interfaces/ICurrentUser';


interface Props {
    folder: IContentHierarchy | null;
    onUpdateAccess?: (usersWithAccess: string[]) => void;
}

export default function FolderAccessDetails({ folder, onUpdateAccess }: Props) {
    const [showManageFolderAccessModal, setShowManageFolderAccessModal] = React.useState(false);
    const [data, setData] = React.useState<IRestrictedUser[] | null>(null);
    const [loading, setLoading] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const currentUser = useSelector((state: any) => state.currentUser as ICurrentUser);

    const selectedFolderId: string | null = React.useMemo(() => {
        if (folder) {
            return folder.id;
        }
        return null;
    }, [folder?.id])

    React.useEffect(() => {
        if (selectedFolderId) {
            setLoaded(false);
        }
        else {
            setLoaded(true);
        }
    }, [selectedFolderId])

    React.useEffect(() => {
        getRestrictedUserList();
    }, [selectedFolderId, loaded])

    if (folder === null) {
        return null;
    }

    const getRestrictedUserList = async () => {
        if (!folder || loading || loaded) return;

        if (hierarchyIsRestrictedFolder(folder)) {
            setLoading(true);
            const dataReadService = new DataReadService();

            const dto: IRestrictedUserListDto = {
                folderId: folder.id
            }

            const list = await dataReadService.GetRestrictedUserList(dto);
            if (list.status) {
                const maGroupName = "Moss Adams User";
                const clientGroupName = "Client User"
                const groupedData = list.data.map(restrictedUser => {
                    return {
                        ...restrictedUser,
                        firstLetter: restrictedUser.displayName?.charAt(0),
                        groupName: restrictedUser.isMossAdamsStaff ? maGroupName : clientGroupName
                    }
                })

                const dataArray = groupedData.filter(user => user.hasAccess);
                setData(dataArray);
            }
        }
        else {
            setData([]);
        }

        setLoading(false);
        setLoaded(true);
    }

    const handleUpdate = (usersWithAccess: string[]) => {
        onUpdateAccess && onUpdateAccess(usersWithAccess);
        setLoaded(false);
        setShowManageFolderAccessModal(false);
    }

    const showManageButton = currentUser.isMossAdamsStaff && hierarchyIsRestrictedFolder(folder);
    return <>

        <DetailSectionHeader title="Users With Access">
            {showManageButton &&
                <Tooltip title="Manage Access">
                    <span>
                        <Button size='small' color='primary' variant='contained'
                            onClick={() => setShowManageFolderAccessModal(true)}
                        >
                            <Group />
                        </Button>
                    </span>
                </Tooltip>
            }
        </DetailSectionHeader>
        <DetailSection>
            <UserAccessGrid
                loading={loading}
                data={data}
            />
        </DetailSection>

        <ManageFolderAccessModal
            isOpen={showManageFolderAccessModal}
            onClose={() => setShowManageFolderAccessModal(false)}
            folder={folder}
            onUpdateAccess={handleUpdate}
        />
    </>
}


