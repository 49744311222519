import ILookup from "../interfaces/ILookup"
import IUser from "../interfaces/IUser"
import { LicenseManager } from '@ag-grid-enterprise/all-modules';

export const setAgGridLicense = (): void => {

    // AG Grid Config
    const agGridLicenseKey = 'CompanyName=Moss Adams LLP,LicensedApplication=MAA,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=5,LicensedProductionInstancesCount=1,AssetReference=AG-022388,ExpiryDate=15_December_2022_[v2]_MTY3MTA2MjQwMDAwMA==df5a46987b166b1b436c50366d7b657a';
    LicenseManager.setLicenseKey(agGridLicenseKey);
};
export const convertLookupToArray = (list: ILookup[]) => {
    const lookup = list.map((item) => {
        return item.description;
    });
    return lookup;
}

export function convertLookupToKeyValuePair(list: ILookup[]) {
    let lookup = {};
    for (let i = 0; i < list.length; i++) {
        //@ts-ignore
        lookup[list[i].id.toString()] = list[i].description;
    }
    return lookup;
}

export function convertUserListToKeyValuePair(list: IUser[]) {
    let lookup = {};
    for (let i = 0; i < list.length; i++) {
        //@ts-ignore
        lookup[list[i].userId.toString()] = list[i].displayName;
    }
    return lookup;
}

export const extractValues = (mappings: any, field: string) => {
    if (mappings) {
        let values = [];
        for (let i = 0; i < mappings.length; i++) {
            values.push(mappings[i][field]);
        }
        return values;
    } else {
        return null;
    }
}

export const lookupValue = (list: ILookup[], id: number) => {
    if (list) {
        const item = list.find(i => i.id == id);
        if (item) {
            const kv = {};
            //@ts-ignore
            kv[item?.id.toString()] = item.description;
            return item?.description.toString();
        } else {
            return "";
        }
    } else {
        return "";
    }
}

export const userListValue = (list: IUser[], id: string) => {
    if (list) {
        const item = list.find(i => i.userId == id);
        if (item) {
            const kv = {};
            //@ts-ignore
            kv[item?.userId.toString()] = item.displayName;
            return item?.displayName.toString();
        } else {
            return "";
        }
    } else {
        return "";
    }
}

export const lookupKey = (list: ILookup[], name: string) => {
    if (list) {
        return list.find(i => i.description == name)!.description;
    } else {
        return null;
    }
}

export const userListKey = (list: IUser[], name: string) => {
    if (list) {
        return list.find(i => i.displayName == name)!.userId;
    } else {
        return null;
    }
}
