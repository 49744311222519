import React from "react"
import { Button, FormControl, FormControlLabel, Grid, Box, makeStyles, Paper, TextField, Typography, Divider, Accordion, AccordionDetails, AccordionSummary, Tooltip, FormLabel, } from "@material-ui/core";
import TaskValidator from "../../Validators/TaskValidator";
import DetailHeader from "../common/DetailHeader/DetailHeader";
import IEngagementManagementDetailsProps from "./IEngagementManagementDetailsProps";
import ITask from "../../interfaces/ITask";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { getLookupKey, getLookupValue, getUserNameAllowNull, } from "../../utilities/lookupUtils";
import DateFnsUtils from "@date-io/date-fns";
import { TaskStatus } from "../../helpers/enums/TaskStatus";
import AttachmentsGrid from "../AttachmentsGrid/AttachmentsGrid";
import TaskComments from "../TaskComments/TaskComments";
import { ConfirmDialog } from "../ConfirmDialog/ConfirmDialog";
import DataWriteService from "../../services/DataWriteService";
import DataReadService from "../../services/DataReadService";
import { useSnackbar } from 'notistack';
import { SnackbarVariantTypes } from "../../helpers/enums/enums";
import ITaskDocument from "../../interfaces/ITaskDocument";
import { Utils } from "../../utilities/utils";
import { LocalDateTimeString } from "../../helpers/DateHelpers";
import { actionButtonVariant, detailPaneAccordionHeaderStyle, detailPaneAccordionStyle, detailPaneTextStyle, detailsPaneLastUpdatedStyle, marginAroundDescription } from "../../helpers/portalTheme";
import SaveIcon from '@material-ui/icons/Save';
import DiscardIcon from '@material-ui/icons/Undo';
import SendIcon from '@material-ui/icons/Send';
import CompleteIcon from '@material-ui/icons/Done';
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import AutocompleteField from "../common/AutocompleteField/AutocompleteField";
import DetailDataField from "../common/DetailDataField/DetailDataField";
import { LineOfBusinessId } from "../../helpers/enums/LineOfBusinessId";
import ILookup from "../../interfaces/ILookup";
import { getTrimmedTaskEntityForTask, isTaskOverdue } from "../../helpers/TaskHelpers";
import useAsyncFunctionIsLoading from "../../hooks/useIsLoadingAsync";
import SpinnerBackdrop from "../common/SpinnerBackdrop";
import ICurrentUser from "../../interfaces/ICurrentUser";
import MultiUserSelector from "../MultiUserSelector/MultiUserSelector";
import { useSelector } from "react-redux";
import { Delete, ExpandMore } from "@material-ui/icons";
import DeleteTasksConfirmDialog from "../DeleteTasksConfirmDialog/DeleteTasksConfirmDialog";
import { IsDirtyDisabledMessage } from "../TaskListEditor/TaskDetailsEditor";
import RestrictRequestToggle from "../RequestDetails/RestrictRequestToggle";
import RestrictedIcon from "../common/RestrictedIcon/RestrictedIcon";
import ConditionalVisiblity from "../common/ConditionalVisibility/ConditionalVisiblity";
import LastModified from "../RequestDetails/LastModified";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        minWidth: "300px",
        ...marginAroundDescription
    },
    header: {
        backgroundColor: theme.palette.mossadams.darkGreen400,
        color: theme.palette.primary.contrastText,
        "&& .MuiIconButton-label": {
            color: theme.palette.primary.contrastText,
        }
    },
    formControl: {
        width: "100%",
        marginBottom: theme.spacing(5),
    },
    formControlLabelStart: {
        margin: "0px 0px 0px 2px",
        width: "100%",
        justifyContent: "flex-end",
        "&.overdue": {
            "& .MuiInputBase-root input": {
                color: theme.palette.error.main
            },
            "& time ": {
                color: theme.palette.error.main
            },
        }
    },
    overdueDate: {
        color: theme.palette.error.main
    },
    formControlInput: {
        flex: "1 1 100%",
        maxWidth: "300px",
    },
    label: {
        fontSize: "1.1rem",
        paddingRight: theme.spacing(2.5),
        minWidth: theme.spacing(65),
    },
    lastUpdated: {
        ...detailsPaneLastUpdatedStyle
    },
    input: {
        "& input": {
            ...detailPaneTextStyle,
        },
        "& .MuiSelect-select.MuiSelect-select": {},
        "& .MuiAutocomplete-inputRoot[class*=\"MuiInput-root\"] .MuiAutocomplete-input:first-child": {},
        "& .MuiAutocomplete-popupIndicator": {
            marginRight: 0,
            padding: 0,
        },
        "& .MuiIconButton-root": {
            padding: 0,
        }
    },
    inactiveUserText: {
        color: theme.palette.mossadams.gray200
    },
    keyboardDatePickerInput: {
        "& input": {
            paddingTop: theme.spacing(5.5),
        },
        "& .MuiInputBase-input": {
            padding: '4px 0 6px 0'
        }
    },
    taskNumberDisplay: {
        "& input": {}
    },
    descriptionBox: {
        marginTop: theme.spacing(5),
    },
    descriptionTextField: {
        "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
            transform: "translate(20px, 13px) scale(1)",
        },
        "& .MuiOutlinedInput-multiline": {
            padding: '10px 10px',
        },
        "&&& textarea": {
            overflowY: "auto !important",
            maxHeight: "360px",
            fontSize: "0.9rem",
        },
        "& legend": {
            width: "84px"
        }
    },
    notesTextField: {
        "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
            transform: "translate(20px, 13px) scale(1)",
        },
        "& .MuiOutlinedInput-multiline": {
            padding: '10px 10px',
        },
        "&&& textarea": {
            overflowY: "auto !important",
            maxHeight: "360px",
            fontSize: "0.9rem",
        },
        "& legend": {
            width: "50px"
        }
    },
    attachments: {
        marginTop: "0px"
    },
    buttonRow: {
        display: "flex",
        marginRight: theme.spacing(-5),
        marginLeft: theme.spacing(-5),
    },
    body: {
        maxHeight: `calc(100vh - ${theme.spacing(80)}px)`,
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    actionButton: {
        ...actionButtonVariant,
    },
    accordion: {
        ...detailPaneAccordionStyle
    },
    accordionHeader: {
        ...detailPaneAccordionHeaderStyle
    }
}));

const saveTaskErrorText = "Failed to save request.";
const saveTaskSuccessText = "Request saved successfully.";

const EngagementManagementDetails = (props: IEngagementManagementDetailsProps): JSX.Element => {
    const {
        task,
        onTaskUpdated: handleTaskUpdated,
        onTaskDocumentUpdated,
        onClose,
        clientUserList,
        mossAdamsUserList,
        areas,
        phases,
        priorities,
        statuses,
        socStatuses,
        uploadFolderId,
        overdueIndicatorClass,
        userEngagementList,
    } = props;

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const isDirty = useSelector((state: any) => state.engagementManagementIsDirty as boolean);

    const [updatedTask, setUpdatedTask] = React.useState<ITask>(props.task);
    const [originalTask, setOriginalTask] = React.useState<ITask>(props.task);
    const [taskDocuments, setTaskDocuments] = React.useState<ITaskDocument[]>([]);
    const [cancelConfirmDialogIsOpen, setCancelConfirmDialogIsOpen] = React.useState<boolean>(false);
    const [updateDueDateConfirmDialogIsOpen, setUpdateDueDateConfirmDialogIsOpen] = React.useState<boolean>(false);
    const [checkModelChangeConfirmDialogIsOpen, setCheckModelChangeConfirmDialogIsOpen] = React.useState<boolean>(false);
    const [hasNewComments, setHasNewComments] = React.useState<boolean>(false);
    const [taskTypeList, setTaskTypeList] = React.useState<ILookup[]>(props.taskTypes);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [commentsLoading, setCommentsLoading] = React.useState(false);
    const [attachmentsLoading, setAttachmentsLoading] = React.useState(false);
    const currentUser = useSelector((state: any) => state.currentUser as ICurrentUser);

    let taskValidator = new TaskValidator(taskTypeList);

    React.useEffect(() => {
        const task = { ...props.task, isOverdue: isTaskOverdue(props.task) };
        setOriginalTask({ ...task });
        setUpdatedTask({ ...task });
        getTaskTypes();
    }, [props.task]);

    React.useEffect(() => {
        props.onIsDirtyChanged(!_.isEqual(originalTask, updatedTask));
    }, [updatedTask])

    const setInitializedState = (updatedData: ITask) => {
        updatedData.isOverdue = isTaskOverdue(updatedData)
        setOriginalTask(updatedData);
        setUpdatedTask(updatedData);
        handleTaskUpdated(updatedData);
        props.onIsDirtyChanged(false);
        setHasNewComments(false);
    }

    const getTaskTypes = () => {
        if (props.task.engagementId) {
            const dataReadService = new DataReadService();
            dataReadService.GetTaskTypes(props.task.engagementId).then((response) => {
                if (response.status) {
                    setTaskTypeList(response.data);
                    taskValidator = new TaskValidator(taskTypeList);
                } else {
                    enqueueSnackbar("Error getting the request type lookup list.", { variant: SnackbarVariantTypes.Error });
                }
            });
        }
    }

    const handleDueDateChange = (date: MaterialUiPickersDate) => {
        if (updatedTask) {
            let updatedData: ITask = { ...updatedTask, requestedDate: date };
            updatedData.isOverdue = isTaskOverdue(updatedData);
            setUpdatedTask(updatedData);
        }
    };

    const handleAssigneesChange = (e: any, newValue: any) => {
        setUpdatedTask({
            ...updatedTask,
            taskAssignees: newValue,
        });
    }

    const handleChange = (e: any) => {

        if (e === null) {
            return;
        }

        switch (e.target.name) {
            case "Status":
                const statusDescription = getLookupValue(statuses, e.target.value);
                setUpdatedTask({
                    ...updatedTask,
                    taskStatusId: e.target.value,
                    taskStatusDescription: statusDescription,
                });
                break;
            case "SocStatus":
                const socStatusId = getLookupKey(socStatuses, e.target.value);
                setUpdatedTask({
                    ...updatedTask,
                    socStatusId: socStatusId,
                    socStatusDescription: e.target.value,
                });
                break;
            case "Area":
                const areaId = getLookupKey(areas, e.target.value);
                setUpdatedTask({
                    ...updatedTask,
                    areaId: areaId,
                    areaDescription: e.target.value,
                });
                break;
            case "Phase":
                const phaseId = getLookupKey(phases, e.target.value);
                setUpdatedTask({
                    ...updatedTask,
                    phaseId: phaseId,
                    phaseDescription: e.target.value,
                });
                break;
            case "RequestType":
                const typeId = getLookupKey(taskTypeList, e.target.value);
                setUpdatedTask({
                    ...updatedTask,
                    taskTypeId: typeId,
                    taskTypeDescription: e.target.value,
                });
                break;
            case "Priority":
                const priorityId = getLookupKey(priorities, e.target.value);
                setUpdatedTask({
                    ...updatedTask,
                    taskPriorityId: priorityId,
                    taskPriorityDescription: e.target.value,
                });
                break;
            case "Contact":
                const mossAdamsContactUserName = getUserNameAllowNull(props.mossAdamsUserList!, e.target.value);
                setUpdatedTask({
                    ...updatedTask,
                    mossAdamsContactUserId: e.target.value,
                    mossAdamsContactUserName: mossAdamsContactUserName
                });
                break;
            case "title":
                setUpdatedTask({
                    ...updatedTask,
                    title: e.target.value,
                });
                break;
            case "description":
                setUpdatedTask({
                    ...updatedTask,
                    description: e.target.value,
                });
                break;
            case "notes":
                setUpdatedTask({
                    ...updatedTask,
                    notes: e.target.value === '' ? null : e.target.value.trimStart(),
                });
                break;
            case "taskEntity":
                setUpdatedTask({
                    ...updatedTask,
                    taskEntity: e.target.value === '' ? null : e.target.value.trimStart(),
                });
                break;
            default:
                setUpdatedTask({
                    ...updatedTask,
                    [e.target.name]: e.target.value,
                });
                break;
        }
    }

    const handleSaveClick = () => {
        if (currentUser?.isMossAdamsStaff) {
            adminSave(updatedTask);
        } else {
            clientSave(updatedTask);
        }
    }


    const handleCancelClick = () => {
        setCancelConfirmDialogIsOpen(true);
    }

    const handleCancelConfirmDialogClose = () => {
        setCancelConfirmDialogIsOpen(false);
    };

    const handleUpdateDueDateConfirmDialogClose = () => {
        setUpdateDueDateConfirmDialogIsOpen(false);
        sendToClient();
    }

    const handleUpdateDueDateConfirmDialogConfirm = () => {
        setUpdateDueDateConfirmDialogIsOpen(false);
    }

    const handleCancelConfirmDialogConfirm = () => {
        setCancelConfirmDialogIsOpen(false);
        setUpdatedTask(originalTask);
    };

    const handleCheckModelChangeConfirmDialogClose = () => {
        setCheckModelChangeConfirmDialogIsOpen(false);

    }

    const handleCheckModelChangeConfirmDialogConfirm = () => {
        setCheckModelChangeConfirmDialogIsOpen(false);
        sendToClient();
    }

    const getUpdatedTaskStatus = (task: ITask) => {
        if ((task.taskStatusId == TaskStatus.New.id || task.taskStatusId == TaskStatus.MossAdamsResponded.id) && currentUser?.isMossAdamsStaff == false) {
            task.taskStatusId = TaskStatus.SubmittedByClient.id;
            task.taskStatusDescription = TaskStatus.SubmittedByClient.string;
        }
        if ((task.taskStatusId == TaskStatus.New.id || task.taskStatusId == TaskStatus.SubmittedByClient.id) && currentUser?.isMossAdamsStaff == true) {
            task.taskStatusId = TaskStatus.MossAdamsResponded.id;
            task.taskStatusDescription = TaskStatus.MossAdamsResponded.string;
        }
        return task;
    }

    const handleCommentCreated = () => {
        var updatedData: ITask = {
            ...originalTask,
        }

        updatedData = getUpdatedTaskStatus(updatedData);

        if (originalTask.taskStatusId !== updatedData.taskStatusId) {
            updatedData.updatedDateTime = new Date();

            setOriginalTask(updatedData);
            setUpdatedTask(updatedData);
            handleTaskUpdated(updatedData);
        }

        setHasNewComments(true);
    }

    const handleTaskDocumentsUpdated = (task: ITask, documentAdded: boolean) => {
        var updatedData: ITask = {
            ...task
        }

        if (documentAdded && !currentUser?.isMossAdamsStaff) {
            updatedData = getUpdatedTaskStatus(updatedData);
            updatedData.updatedDateTime = originalTask.taskStatusId !== updatedData.taskStatusId ? new Date() : task.updatedDateTime;
        }

        setOriginalTask(updatedData);
        setUpdatedTask(updatedData);
        handleTaskUpdated(updatedData);
        onTaskDocumentUpdated(updatedData, documentAdded);
    }

    const { onTrigger: adminSave, loading: adminSaving } = useAsyncFunctionIsLoading(async (updatedData: ITask) => {
        if (taskValidator.TaskIsValid(updatedTask) === false) {
            enqueueSnackbar("There are invalid values, please correct before saving.", { variant: SnackbarVariantTypes.Error });
            return;
        }
        const dataWriteService = new DataWriteService();
        const requestDto: ITask = { ...updatedData, taskEntity: getTrimmedTaskEntityForTask(updatedData) };
        const result = await dataWriteService.UpdateEngagementManagementTaskAdmin(requestDto);
        if (result.status) {
            updatedData.updatedDateTime = new Date();
            updatedData.updatedByUserId = currentUser.userId;
            updatedData.updatedByUserName = currentUser.name;
            enqueueSnackbar(saveTaskSuccessText, { variant: SnackbarVariantTypes.Success });
            setInitializedState(updatedData);
        } else {
            const errors = [saveTaskErrorText, ...result.errorMessages];
            Utils.enqueueMultiLineSnackbar(errors, enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
        }
    })

    const { onTrigger: clientSave, loading: clientSaving } = useAsyncFunctionIsLoading(async (updatedData: ITask) => {
        if (taskValidator.TaskIsValid(updatedTask) === false) {
            enqueueSnackbar("There are invalid values, please correct before saving.", { variant: SnackbarVariantTypes.Error });
            return;
        }
        const dataWriteService = new DataWriteService();
        const requestDto: ITask = { ...updatedData };
        const result = await dataWriteService.UpdateEngagementManagementTaskClient(requestDto);
        if (result.status) {
            updatedData.updatedDateTime = new Date();
            updatedData.updatedByUserId = currentUser.userId;
            updatedData.updatedByUserName = currentUser.name;
            enqueueSnackbar(saveTaskSuccessText, { variant: SnackbarVariantTypes.Success });
            setInitializedState(updatedData);
        } else {
            const errors = [saveTaskErrorText, ...result.errorMessages];
            Utils.enqueueMultiLineSnackbar(errors, enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
        }
    })

    const handleSendToMAButton = async () => {
        if (updatedTask) {
            let updatedData: ITask = { ...updatedTask };
            updatedData.taskStatusId = TaskStatus.SubmittedByClient.id;
            updatedData.taskStatusDescription = TaskStatus.SubmittedByClient.string;
            setUpdatedTask(updatedData);
            clientSave(updatedData);
        }
    }

    const handleSendToClientButton = () => {
        if (!(isDirty || hasNewComments)) {
            setCheckModelChangeConfirmDialogIsOpen(true);
            return;
        }

        if (updatedTask) {
            if (updatedTask.taskStatusId == TaskStatus.SubmittedByClient.id && new Date(updatedTask.requestedDate!) < new Date()) {
                setUpdateDueDateConfirmDialogIsOpen(true);
            } else {
                sendToClient();
            }
        }
    }

    const sendToClient = () => {
        let updatedData: ITask = { ...updatedTask };
        updatedData.taskStatusId = TaskStatus.MossAdamsResponded.id;
        updatedData.taskStatusDescription = TaskStatus.MossAdamsResponded.string;
        adminSave(updatedData);
    }

    const { onTrigger: handleCompletedButton, loading: completing } = useAsyncFunctionIsLoading(
        async () => {
            if (updatedTask) {
                let updatedData: ITask = { ...updatedTask };
                updatedData.taskStatusId = TaskStatus.Completed.id;
                updatedData.taskStatusDescription = TaskStatus.Completed.string;
                await adminSave(updatedData);
            }
        }
    )

    const userIsActive = (userId: string | null) => {
        const selectedUser = clientUserList.find((user) => user.userId === userId);
        const userEngagement = userEngagementList.find(
            ue =>
                ue.userId === selectedUser?.userId &&
                ue.engagementId === updatedTask.engagementId
        );
        return userEngagement && selectedUser?.isActive;
    }

    const onUpdateTaskRestriction = (task: ITask): void => {
        handleTaskUpdated({ ...task });
        setOriginalTask(task);

        const updated = isDirty ? { ...updatedTask, updatedDateTime: task.updatedDateTime, isRestricted: task.isRestricted } : { ...task };
        setUpdatedTask(updated);
    }

    return (
        <Grid item className={classes.root}>
            <ConfirmDialog
                isOpen={cancelConfirmDialogIsOpen}
                onClose={handleCancelConfirmDialogClose}
                onConfirm={handleCancelConfirmDialogConfirm}
                title="Discard Changes"
                children={<Typography>Are you sure you would like to discard changes?</Typography>}
            />
            <ConfirmDialog
                isOpen={updateDueDateConfirmDialogIsOpen}
                onClose={handleUpdateDueDateConfirmDialogClose}
                onConfirm={handleUpdateDueDateConfirmDialogConfirm}
                title="Update Due Date?"
                children={<Typography>Do  you want to update the due date before sending back to the client?</Typography>}
            />

            <ConfirmDialog
                isOpen={checkModelChangeConfirmDialogIsOpen}
                onClose={handleCheckModelChangeConfirmDialogClose}
                onConfirm={handleCheckModelChangeConfirmDialogConfirm}
                title="Update Request?"
                children={<Typography>This request has not been modified. Are you sure you want to send back to the client?</Typography>}
            />
            <DeleteTasksConfirmDialog
                isOpen={deleteDialogOpen}
                onDelete={props.onDelete}
                onClose={() => setDeleteDialogOpen(false)}
                tasks={[props.task]}
            />
            <SpinnerBackdrop isActive={clientSaving || adminSaving || commentsLoading || attachmentsLoading || completing} />
            <DetailHeader
                onClose={onClose}
            >
                <Box className={classes.buttonRow}>
                    <Button className={classes.actionButton} color="primary" disabled={!isDirty} onClick={handleSaveClick} title="Save" startIcon={<SaveIcon />}></Button>
                    <Button className={classes.actionButton} color="primary" disabled={!isDirty} onClick={handleCancelClick} title="Discard Changes" startIcon={<DiscardIcon />}></Button>

                    {(updatedTask.taskStatusDescription === TaskStatus.New.string ||
                        updatedTask.taskStatusDescription === TaskStatus.MossAdamsResponded.string) &&
                        !currentUser?.isMossAdamsStaff &&
                        <Button className={classes.actionButton} color="primary" onClick={handleSendToMAButton} title="Send to Moss Adams" startIcon={<SendIcon />}></Button>
                    }
                    {(updatedTask.taskStatusDescription === TaskStatus.SubmittedByClient.string) &&
                        currentUser?.isMossAdamsStaff &&
                        <>
                            <Button className={classes.actionButton} color="primary" onClick={handleCompletedButton} title="Mark Completed" startIcon={<CompleteIcon />}></Button>
                            <Button className={classes.actionButton} color="primary" onClick={handleSendToClientButton} title="Send to Client" startIcon={<SendIcon />}></Button>
                        </>
                    }
                    {currentUser.isMossAdamsStaff &&
                        <Button className={classes.actionButton} color="primary"
                            onClick={() => setDeleteDialogOpen(true)} title="Delete Request"
                            startIcon={<Delete />}
                        />
                    }
                </Box>
            </DetailHeader>
            <Divider />
            <Paper className={`${props.rootClass} ${classes.body}`} elevation={0}>
                <RestrictRequestToggle task={originalTask} onUpdateTaskRestriction={onUpdateTaskRestriction} />
                <LastModified task={updatedTask} />

                <Accordion defaultExpanded disabled={updatedTask.taskStatusId === TaskStatus.Completed.id} className={classes.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="RequestDetails-content"
                        id="RequestDetails-header"
                        className={classes.header}
                    >
                        <Typography className={classes.accordionHeader}>
                            Request Details
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container direction="row">
                            <Grid item xs={12}>
                                <DetailDataField
                                    label={"Request #"}
                                >
                                    <Typography color="textPrimary" className={classes.taskNumberDisplay}>{`${updatedTask.taskNumber}`}</Typography>
                                </DetailDataField>
                            </Grid>
                            <Grid item xs={12}>
                                <DetailDataField
                                    label={"Request Title"}
                                >
                                    <TextField
                                        value={updatedTask.title}
                                        className={classes.input}
                                        name="title"
                                        disabled={!currentUser?.isMossAdamsStaff || updatedTask.taskStatusId === TaskStatus.Completed.id}
                                        onChange={handleChange}
                                        helperText={taskValidator.TitleIsValid(updatedTask.title) ? '' : taskValidator.TitleValidationMessage}
                                        error={!taskValidator.TitleIsValid(updatedTask.title)}
                                    />
                                </DetailDataField>
                            </Grid>
                            <Grid item xs={12}>
                                <DetailDataField
                                    label={"Entity"}
                                    key={updatedTask.taskId}
                                >
                                    <TextField
                                        value={updatedTask.taskEntity}
                                        className={classes.input}
                                        name="taskEntity"
                                        disabled={!currentUser?.isMossAdamsStaff || updatedTask.taskStatusId === TaskStatus.Completed.id}
                                        onChange={handleChange}
                                        placeholder={"(Optional)"}
                                        error={!taskValidator.EntityIsValid(updatedTask.taskEntity)}
                                    />
                                </DetailDataField>
                            </Grid>
                            <Grid item xs={12} className={classes.descriptionBox}>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        className={classes.descriptionTextField}
                                        label="Description"
                                        name="description"
                                        multiline
                                        variant="outlined"
                                        value={updatedTask.description}
                                        onChange={handleChange}
                                        disabled={!currentUser?.isMossAdamsStaff || updatedTask.taskStatusId === TaskStatus.Completed.id}
                                        error={!taskValidator.DescriptionIsValid(updatedTask.description)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        className={classes.notesTextField}
                                        label="Notes"
                                        name="notes"
                                        multiline
                                        variant="outlined"
                                        value={updatedTask.notes || ''}
                                        onChange={handleChange}
                                        disabled={!currentUser?.isMossAdamsStaff || updatedTask.taskStatusId === TaskStatus.Completed.id}
                                        error={!taskValidator.NotesIsValid(updatedTask.notes)}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <DetailDataField
                                    label={'Due Date'}
                                    controlClasses={(updatedTask.isOverdue ? "overdue" : "")}
                                >
                                    {currentUser?.isMossAdamsStaff == true && updatedTask.taskStatusId !== TaskStatus.Completed.id ?
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                autoOk={true}
                                                className={classes.input + " " + classes.keyboardDatePickerInput + " " + classes.formControlInput}
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                value={updatedTask.requestedDate}
                                                name="DueDate"
                                                onChange={handleDueDateChange}
                                                disabled={!currentUser?.isMossAdamsStaff}
                                                error={!taskValidator.RequestedDateIsValid(updatedTask.requestedDate, updatedTask.taskStatusId)}
                                            />
                                        </MuiPickersUtilsProvider>
                                        :
                                        <div className={updatedTask.isOverdue ? classes.overdueDate : ""} >
                                            {LocalDateTimeString(updatedTask.requestedDate, false)}
                                        </div>
                                    }
                                </DetailDataField>
                            </Grid>
                            <Grid item xs={12}>
                                <>
                                    <MultiUserSelector
                                        selectAll
                                        label={<Tooltip title={originalTask.isRestricted
                                            ? currentUser?.isMossAdamsStaff
                                                ? 'Client visibility of this restricted request and associated data is limited to these users.'
                                                : 'Please contact the Moss Adams team to modify assignees.'
                                            : ''}
                                        >
                                            <label style={{ display: 'flex', alignItems: 'center' }}>
                                                Assignees: <ConditionalVisiblity isVisible={originalTask.isRestricted}>
                                                    <RestrictedIcon />
                                                </ConditionalVisiblity>
                                            </label>
                                        </Tooltip>
                                        }
                                        name="Assignees"
                                        disabled={currentUser?.isMossAdamsStaff == false && originalTask.isRestricted}
                                        selectedUsers={updatedTask.taskAssignees}
                                        onSelectedUsersChange={handleAssigneesChange}
                                        userList={clientUserList.filter(user =>
                                            user.isActive && userEngagementList.find(ue =>
                                                ue.userId === user.userId
                                                && ue.engagementId === updatedTask.engagementId)
                                        )}
                                    />
                                </>
                            </Grid>
                            <Grid item xs={12}>
                                <AutocompleteField
                                    key={updatedTask.taskNumber}
                                    name="RequestType"
                                    value={updatedTask.taskTypeDescription}
                                    onChange={handleChange}
                                    optionData={taskTypeList}
                                    controlProps={{
                                        readOnly: currentUser?.isMossAdamsStaff == false || updatedTask.taskStatusId === TaskStatus.Completed.id,
                                        label: "Request Type",
                                        error: !taskValidator.TypeIsValid(updatedTask.taskTypeId),
                                        disabled: !currentUser?.isMossAdamsStaff
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AutocompleteField
                                    key={updatedTask.taskNumber}
                                    name="Status"
                                    value={updatedTask.taskStatusId}
                                    onChange={handleChange}
                                    optionData={statuses}
                                    optionAccessorsProp={{ valueKey: 'id' }}
                                    controlProps={{
                                        readOnly: currentUser?.isMossAdamsStaff != true,
                                        label: "Status",
                                        error: !taskValidator.StatusIsValid(updatedTask.taskStatusId),
                                    }}
                                    disabledOptionValues={[TaskStatus.Deleted.id]}
                                />
                            </Grid>
                            {updatedTask.isSocEngagementType == true &&
                                <Grid item xs={12}>
                                    <AutocompleteField
                                        key={updatedTask.socStatusId}
                                        name="SocStatus"
                                        value={updatedTask.socStatusDescription}
                                        onChange={handleChange}
                                        optionData={socStatuses}
                                        controlProps={{
                                            readOnly: currentUser?.isMossAdamsStaff == false || updatedTask.taskStatusId === TaskStatus.Completed.id,
                                            label: "SOC Status",
                                            error: !taskValidator.SocStatusIsValid(updatedTask.socStatusId, updatedTask.taskStatusId, updatedTask.isPublished, updatedTask.isSocEngagementType),
                                        }}
                                    />
                                </Grid>
                            }
                            {updatedTask.engagementLineOfBusinessId == LineOfBusinessId.BA &&
                                <Grid item xs={12}>
                                    <AutocompleteField
                                        key={updatedTask.taskNumber}
                                        name="Area"
                                        value={updatedTask.areaDescription}
                                        onChange={handleChange}
                                        optionData={areas}
                                        controlProps={{
                                            readOnly: currentUser?.isMossAdamsStaff == false || updatedTask.taskStatusId === TaskStatus.Completed.id,
                                            label: "Area",
                                            error: !taskValidator.AreaIsValid(updatedTask.areaId),
                                        }}
                                    />
                                </Grid>
                            }
                            {updatedTask.engagementLineOfBusinessId == LineOfBusinessId.BA &&
                                <Grid item xs={12}>
                                    <AutocompleteField
                                        key={updatedTask.taskNumber}
                                        name="Phase"
                                        value={updatedTask.phaseDescription}
                                        onChange={handleChange}
                                        optionData={phases}
                                        controlProps={{
                                            readOnly: currentUser?.isMossAdamsStaff == false || updatedTask.taskStatusId === TaskStatus.Completed.id,
                                            label: "Phase",
                                            error: !taskValidator.PhaseIsValid(updatedTask.phaseId),
                                        }}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <AutocompleteField
                                    key={updatedTask.taskNumber}
                                    name="Priority"
                                    value={updatedTask.taskPriorityDescription}
                                    onChange={handleChange}
                                    optionData={priorities}
                                    controlProps={{
                                        readOnly: currentUser?.isMossAdamsStaff == false || updatedTask.taskStatusId === TaskStatus.Completed.id,
                                        label: "Priority",
                                        error: !taskValidator.PriorityIsValid(updatedTask.taskPriorityId),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AutocompleteField
                                    key={updatedTask.taskNumber}
                                    name="Contact"
                                    value={updatedTask.mossAdamsContactUserId}
                                    onChange={handleChange}
                                    isInactiveValue={
                                        !(props.mossAdamsUserList!.find(user => user.userId === updatedTask.mossAdamsContactUserId &&
                                            userEngagementList.find(ue => ue.userId === user.userId && ue.engagementId === updatedTask.engagementId))?.isActive)}
                                    inactiveInputValue={updatedTask.mossAdamsContactUserName}
                                    optionData={mossAdamsUserList.filter(user =>
                                        user.isActive && userEngagementList.find(ue =>
                                            ue.userId === user.userId
                                            && ue.engagementId === updatedTask.engagementId)
                                    )}
                                    optionAccessorsProp={{ idKey: 'userId', displayKey: 'displayName', valueKey: 'userId' }}
                                    controlProps={{
                                        readOnly: currentUser?.isMossAdamsStaff == false || updatedTask.taskStatusId === TaskStatus.Completed.id,
                                        label: "MA Contact",
                                        error: !taskValidator.MossAdamsContactUserIsValid(updatedTask.mossAdamsContactUserId, updatedTask.taskStatusId),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <Grid item xs={12} className={classes.attachments}>
                    <Tooltip title={isDirty ? IsDirtyDisabledMessage : ''} placement='top'>
                        <Box>
                            <AttachmentsGrid
                                onLoadingStatusChanged={(loading: boolean) => setAttachmentsLoading(loading)}
                                allowMultipleAttachments={updatedTask.allowMultipleAttachments}
                                task={updatedTask}
                                setTaskDocuments={setTaskDocuments}
                                taskDocuments={taskDocuments}
                                onTaskDocumentUpdated={handleTaskDocumentsUpdated}
                                clientName={updatedTask.clientName}
                                engagementName={updatedTask.engagementName}
                                disabled={isDirty || updatedTask.taskStatusId === TaskStatus.Completed.id || updatedTask.isNew}
                                uploadFolderId={uploadFolderId}
                                taskIsNew={false}
                            />
                        </Box>
                    </Tooltip>
                </Grid>

                <Tooltip title={isDirty ? IsDirtyDisabledMessage : ''}>
                    <Box>
                        <TaskComments
                            onLoadingStatusChanged={(loading: boolean) => setCommentsLoading(loading)}
                            onCommentCreated={handleCommentCreated}
                            taskId={updatedTask.taskId}
                            taskNumber={updatedTask.taskNumber}
                            taskTitle={updatedTask.title}
                            disabled={isDirty || updatedTask.taskStatusId === TaskStatus.Completed.id || updatedTask.isNew}
                        />
                    </Box>
                </Tooltip>

            </Paper>
        </Grid >
    );
}

export default EngagementManagementDetails;