import React, { DragEventHandler } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import IContentHierarchy from '../../interfaces/IContentHierarchy';

const useStyles = makeStyles((theme) => ({
    dragOver: {
        backgroundColor: theme.palette.mossadams.selectedRow
    },
    dragReject: {
        backgroundColor: theme.palette.mossadams.red100
    },
}));

export interface IDropZoneRendererProps {
    handleDrop: (jsonData: any, taskId: string) => void,
    validateData: (data: any) => boolean,
    data?: any
}

export const DropZoneRenderer = (props: IDropZoneRendererProps) => {

    const classes = useStyles();

    const [className, setClassName] = React.useState<any>();

    const onDropHandler: DragEventHandler = (event: any) => {
        setClassName(null);
        var jsonData = JSON.parse(event.dataTransfer.getData('application/json'));
        const taskId = event.currentTarget.dataset.taskId;
        var valid = props.validateData(props.data);
        if (valid) {
            props.handleDrop(jsonData, taskId);
        }
        event.preventDefault();
    }

    function onDragOver(event: any) {
        var valid = props.validateData(props.data);
        if (valid) {
            setClassName(classes.dragOver);
        }
        else {
            setClassName(classes.dragReject);
        }

        if (valid) {
            event.dataTransfer.dropEffect = 'move';
        }
        else {
            event.dataTransfer.dropEffect = 'none';
        }

        event.preventDefault();
    }

    function onDragLeave(event: any) {
        setClassName(null);
        event.preventDefault();
    }

    return (
        <div className={className} onDrop={onDropHandler} onDragOver={onDragOver} onDragLeave={onDragLeave} data-task-id={props.data.taskId}>
            {props.data.title}
        </div>
    )
}