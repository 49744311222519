import { ICellRendererParams } from '@ag-grid-community/core';
import ITask from "../../interfaces/ITask";

const RequestDescriptionCellRenderer = (params: ICellRendererParams) => {
    const task: ITask = params.data;

    if (task !== null) {
        return <span title={task.description}>{task.description}</span>
    }
    return "";
}

export default RequestDescriptionCellRenderer;