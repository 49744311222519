import React from 'react'
import { FormLabel, makeStyles } from '@material-ui/core';
import { detailsPaneLastUpdatedStyle } from '../../helpers/portalTheme';
import ITask from '../../interfaces/ITask';
import { LocalDateTimeString } from '../../helpers/DateHelpers';

const useStyles = makeStyles((theme) => ({
    lastUpdated: {
        ...detailsPaneLastUpdatedStyle
    },
    lastModifiedText: {
        marginRight: theme.spacing(3)
    }
}))

export default function LastModified({ task }: { task: ITask }) {
    const classes = useStyles();
    return (
        <FormLabel
            className={classes.lastUpdated}
        >
            <span className={classes.lastModifiedText}>
                Last Modified:
            </span>
            <span>
                {task.updatedByUserName ? task.updatedByUserName : ""} {LocalDateTimeString(task.updatedDateTime)}
            </span>
        </FormLabel>
    )
}