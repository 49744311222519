import { Grid } from '@material-ui/core'
import React from 'react'

interface Props {
    children: React.ReactNode
}

export default function DetailSection(props: Props) {
    return <Grid item style={{ width: '100%' }}>
        {props.children}
    </Grid>
}
