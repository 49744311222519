import InitialState from "../InitialState"

export const reducers = (state = InitialState, action) => {
    switch (action.type) {
        case 'SET_CLIENTS_AND_ENGAGEMENTS':
            let { clients, engagements } = action.payload
            return {
                ...state,
                clients: clients,
                engagements: engagements
            }
        case 'SET_SELECTED_CLIENTS':
            return {
                ...state,
                selectedClients: action.payload
            }
        case 'SET_SELECTED_ENGAGEMENTS':
            return {
                ...state,
                selectedEngagements: action.payload
            }
        case 'SET_SELECTED_CLIENT_ENGAGEMENT_IDS':
            return {
                ...state,
                selectedClientEngagementIds: action.payload
            }
        case 'SET_ARE_SELECTED_CLIENT_ENGAGEMENTS_LOADED':
            return {
                ...state,
                areSelectedClientEngagementsLoaded: action.payload
            }
        case 'SET_CURRENT_USER':
            let { currentUser } = action.payload
            return {
                ...state,
                currentUser: currentUser
            }
        case 'SET_ENGAGEMENT_MANAGEMENT_IS_DIRTY':
            let { engagementManagementIsDirty } = action.payload
            return {
                ...state,
                engagementManagementIsDirty: engagementManagementIsDirty
            }
        case 'SET_TASK_LIST_EDITOR_IS_DIRTY':
            let { taskListEditorIsDirty } = action.payload
            return {
                ...state,
                taskListEditorIsDirty: taskListEditorIsDirty
            }
        case 'SET_LOOKUP_LISTS':
            return {
                ...state,
                lookupLists: { ...action.payload }
            }
        case 'SET_LOADING_LOOKUP_LISTS':
            return {
                ...state,
                loadingLookupLists: action.payload
            }
        case 'SET_CLIENT_ENGAGEMENT_SELECTOR_DISABLED':
            return {
                ...state,
                clientEngagementSelectorDisabled: action.payload
            }
        default:
            return state;
    }
}