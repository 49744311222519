import React from 'react'
import ExpirationWarningIcon from '../ExpirationWarningIcon/ExpirationWarningIcon';
import { LocalDateTimeString } from '../../helpers/DateHelpers';
import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';

export default function DocumentExpirationDateCellRenderer(params: ICellRendererParams) {
    const date: Date = params.data?.expirationDate;

    return <>
        {LocalDateTimeString(date, false)} <ExpirationWarningIcon date={date} />
    </>
}

