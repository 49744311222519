import { CircularProgress, FormHelperText, InputLabel, MenuItem, Select, makeStyles } from '@material-ui/core'
import { DropDownMenu } from 'material-ui'
import React from 'react'
import useAsyncFunctionIsLoading from '../../../hooks/useIsLoadingAsync';
import IPlatformApiGetResult from '../../../interfaces/IPlatformApiGetResult';
import TRowData from '../../../types/TRowData';


const useStyles = makeStyles((theme) => {
    return {
        container: {width: '100%'},
    }
});

interface AsyncDropdownProps {
    getOptions: () => Promise<IPlatformApiGetResult<TRowData>>;
    onSelectOption: (data: TRowData) => void;
    selectedOption: TRowData | undefined;
    placeholder: string;
    noOptionsPlaceholder: string;
    label: string;
    optionDisplay: (data: TRowData) => string;
    valueKey: string;
}

export default function AsyncDropdown(props: AsyncDropdownProps) {
    const classes =  useStyles();
    const [open, setOpen] = React.useState(false);
    const {onTrigger, loading} = useAsyncFunctionIsLoading(props.getOptions);
    const [options, setOptions] = React.useState<TRowData[]>([]);

    const onOpen = async () => {
        setOpen(true);
        if (!options.length) {
            let result = await onTrigger();
            if (result?.data) {
                setOptions(result.data);
            }
        }
    }

    const getOptionValue = (option: TRowData) => {
        return option[props.valueKey as keyof TRowData];
    }

    const onChange = (e: any) => {
        let selected = options.filter(option => option.hasOwnProperty(props.valueKey) && getOptionValue(option) === e.target.value)[0];
        props.onSelectOption(selected);
    };

    const getPlaceholder =() => {
        if (loading) {
            return "Loading..."; 
        }
        return !options.length ? props.noOptionsPlaceholder : props.placeholder;
    }

    const menuItems = loading ? <MenuItem><CircularProgress size={14} /></MenuItem>
        : options.map(option => <MenuItem value={getOptionValue(option)}>
            {props.optionDisplay(option)}
        </MenuItem>)

    return (
        <div className={classes.container}>
            <InputLabel shrink id={props.placeholder}>
                {props.label}
            </InputLabel>
            <Select 
                variant='outlined'
                fullWidth
                defaultValue="none"
                open={open} 
                onOpen={onOpen} 
                onClose={() => setOpen(false)}
                placeholder={props.placeholder}
                onChange={onChange}
                value={props.selectedOption ? getOptionValue(props.selectedOption) : undefined}
            >
                <MenuItem value={undefined} disabled>
                    {getPlaceholder()}
                </MenuItem>
                {menuItems}
            </Select>
            {props.selectedOption === undefined ? <FormHelperText>{props.placeholder}</FormHelperText> : null}
        </div>
    )
}
