import { Button, Tooltip } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import * as React from 'react';
import DownloadTaskDocumentsPopper, { DownloadTaskDocumentPopperProps } from '../DownloadTaskDocumentsPopper/DownloadTaskDocumentsPopper';


interface DownloadTaskDocumentsButtonProps {
    disabled: boolean;
    className: string;
    onClick: Function;
    popperProps: Omit<DownloadTaskDocumentPopperProps, 'popperText' | 'anchorEl'>;
}

const DownloadTaskDocumentsButton = (props: DownloadTaskDocumentsButtonProps) => {
    const { disabled, className, onClick, popperProps } = props;
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const onClickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        anchorEl === null && onClick && onClick(event);
        setAnchorEl(event.currentTarget);
    }

    const onClickCancelPopper = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(null);
        popperProps.onCancel(event);
    }

    const onCompleteBulkDownload = () => {
        setAnchorEl(null);
        popperProps.onCompleteRequest();
    }

    return <>
        <DownloadTaskDocumentsPopper
            {...props.popperProps}
            onCancel={onClickCancelPopper}
            onCompleteRequest={onCompleteBulkDownload}
            anchorEl={anchorEl}
            popperText={`Please select at least one request and click OK to download the associated files.`}
        />
        <Tooltip title="Download Files">
            <Button
                aria-describedby={Boolean(anchorEl) ? 'confirm-popper' : undefined}
                size="small" color="primary" variant="contained" disabled={disabled}
                onClick={onClickButton} className={className}
            >
                <GetApp />
            </Button>
        </Tooltip>
    </>
}


export default DownloadTaskDocumentsButton;