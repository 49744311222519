import { FormControl, FormHelperText, makeStyles, FormControlLabel, } from "@material-ui/core";
import React from "react"

const useStyles = makeStyles((theme) => {
    const focusedColor = theme.palette.primary.main;

    return {
        formControlLabelStart: {
            margin: "0px 12px 0px 12px",
            width: "100%",
            justifyContent: "flex-end",
        },
        label: {
            fontSize: "1.1rem",
            paddingRight: theme.spacing(2.5),
            minWidth: theme.spacing(65),
            cursor: 'default',
        },
        formControl: {
            cursor: 'default',
            maxWidth: 300,
            marginTop: theme.spacing(2),
            flex: "1 1 100%",
            '& .MuiOutlinedInput-root': {
                borderRadius: 0,
                '&:focus': {
                    borderColor: focusedColor,
                },
            },
            '& .MuiSelect-select:focus': {
                backgroundColor: 'inherit'
            },
            "&.overdue": {
                "& .MuiInputBase-root input": {
                    color: theme.palette.error.main
                },
                "& time ": {
                    color: theme.palette.error.main
                },
            }
        },
    }
});

export interface IDataFieldProps {
    label: string;
    children?: React.ReactNode;

    error?: boolean;
    errorMessage?: string;
    disabled?: boolean;
    readOnly?: boolean;
    labelClasses?: string;
    controlClasses?: string;
}

/**
 * A styled FormControlLabel component designed for use in Detail panes.
 * Use to either wrap generic components or inputs which would use FormControlLabel.
 * @param {IDataFieldProps} props
 * @returns {JSX.Element}
 */
const DetailDataField = (props: IDataFieldProps) => {
    const { children, error, errorMessage, label, labelClasses, controlClasses, disabled } = props;
    const classes = useStyles();

    const control = <>
        <FormControl error={error} className={classes.formControl + (controlClasses ? ` ${controlClasses}` : '')}>
            {children}
            <FormHelperText>
                {errorMessage}
            </FormHelperText>
        </FormControl>
    </>

    return <FormControlLabel
        className={classes.formControlLabelStart}
        disabled={disabled} 
        classes={{
            label: classes.label + (labelClasses ? ` ${labelClasses}` : '')
        }}
        control={control}
        label={`${label}:`}
        labelPlacement={"start"}
    />
}

export default DetailDataField;