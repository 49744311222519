import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import DataWriteService from '../../services/DataWriteService';
import IPlatformApiPostResult from '../../interfaces/IPlatformApiPostResult';
import IUpdateFavoriteModel from '../../interfaces/IUpdateFavoriteModel';
import { useSnackbar } from 'notistack';
import { successSeverity, errorSeverity } from '../FileUploadDialog/FileUploadDialog';
import { IconButton, Tooltip } from '@material-ui/core';


interface ClientFavoriteProps {
    clientId: string,
    isFavorite: boolean,
    onRequestSuccessful?: () => void,
    onRequestFailed?: () => void,
}

const useStyles = makeStyles((theme) => ({
    star: { color: theme.palette.primary.main },
    tooltip: { padding: "3px" }
}));

export const ClientFavorite = (props: ClientFavoriteProps): JSX.Element => {
    const { clientId, isFavorite, onRequestSuccessful, onRequestFailed } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const onClickFavorite = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, clientId: string, isFavorite: boolean) => {
        event.stopPropagation();
        const service = new DataWriteService();
        let result: IPlatformApiPostResult;
        const model: IUpdateFavoriteModel = { clientId, isFavorite: !isFavorite };
        result = await service.UpdateFavorite(model);
        if (result && result.response && result.response.status === 200) {
            const successText = !isFavorite ? "Favorited!" : "Unfavorited!";
            enqueueSnackbar(successText, { variant: successSeverity });
            onRequestSuccessful && onRequestSuccessful();
        } else {
            enqueueSnackbar(result.errorMessages, { variant: errorSeverity });
            onRequestFailed && onRequestFailed();
        }
    }

    const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onClickFavorite(event, clientId, isFavorite);
    }

    return (
        <>
            {isFavorite ?
                <Tooltip title="Unfavorite client" className={classes.tooltip}>
                    <IconButton
                        aria-label="Unfavorite client"
                        onClick={onClick}
                    >
                        <StarIcon className={classes.star} />
                    </IconButton>
                </Tooltip>
                :
                <Tooltip title="Favorite client">
                    <IconButton aria-label="Favorite client" onClick={onClick}>
                        <StarBorderIcon />
                    </IconButton>
                </Tooltip>
            }
        </>
    )
}


