// Ids are fragile due to this relationship being defined in ClientPlatform\ClientPlatform.Db\Scripts\Seed\0_Phase_Seed.sql

export const TaskStatus = {
    Draft: {
        id: 1,
        string: "Draft"
    },
    New: {
        id: 2,
        string: "New"
    },
    SubmittedByClient: {
        id: 3,
        string: "Submitted By Client"
    },
    MossAdamsResponded: {
        id: 4,
        string: "Moss Adams Responded"
    },
    Completed: {
        id: 5,
        string: "Completed"
    },
    Deleted: {
        id: 6,
        string: "Deleted"
    }
}

export const TaskStatusStrings: string[] = [
    TaskStatus.Draft.string,
    TaskStatus.New.string,
    TaskStatus.SubmittedByClient.string,
    TaskStatus.MossAdamsResponded.string,
    TaskStatus.Completed.string,
    TaskStatus.Deleted.string
];

export const TaskStatusStringsLower = TaskStatusStrings.map(s => s.toLowerCase());

export const TaskStatusIds: number[] = [
    TaskStatus.Draft.id,
    TaskStatus.New.id,
    TaskStatus.SubmittedByClient.id,
    TaskStatus.MossAdamsResponded.id,
    TaskStatus.Completed.id,
    TaskStatus.Deleted.id
];

export const GetTaskStatusById = (statusId: number) => {
    for (const [key, value] of Object.entries(TaskStatus)) {
        if (value?.id === +statusId) {
            return value?.string;
        }
    }
    return null;
}