import { makeStyles, MenuItem, Select, TextField, } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react"
import { hasKey } from "../../../utilities/utils";
import DetailDataField, { IDataFieldProps } from "../DetailDataField/DetailDataField";

const useStyles = makeStyles((theme) => {
    const focusedColor = theme.palette.primary.main;
    const font = "Founders Grotesk Reg";
    return {
        autocomplete: {
            '& .MuiAutocomplete-endAdornment': {
                top: 'auto',
            },
            "& .MuiAutocomplete-popupIndicator": {
                marginRight: 0,
                padding: 0,
            },
            "& .MuiAutocomplete-clearIndicator": {
                marginRight: 0,
                padding: 0,
            },
            '&.Mui-focused ': {
                borderColor: focusedColor,
            },
            "&.Mui-focused .MuiAutocomplete-inputRoot": {
                borderColor: focusedColor,
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
                padding: '0px',
                fontSize: '0.95rem !important',
                fontFamily: `${font} !important`,
            },
        },
        listbox: {
            padding: 0,
            margin: 0,
        },
        option: {
            fontSize: '1rem',
            fontFamily: font,
            "&.MuiAutocomplete-option[aria-selected='true']": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                "&:hover": {
                    backgroundColor: theme.palette.mossadams.darkGreen500
                },
            },
        },
        noOptions: {
            fontSize: '1rem',
            fontFamily: font,
        },
        paper: {
            borderRadius: '0px',
            margin: 0,
        },
        inputLabel: {
            paddingLeft: '0.41rem',
            color: `${theme.palette.mossadams.gray300} !important`,
            '&.MuiInputLabel-formControl': {
                fontFamily: font,
                transform: 'translate(0, 6px) scale(1)',
            }
        },
        inactive: {
            "& .MuiAutocomplete-inputRoot": {
                color: theme.palette.mossadams.gray200
            },
        },
    }
});

interface IAutocompleteProps<T> {
    onChange: Function;
    name: string;
    controlProps: IDataFieldProps;

    optionData: T[] | null;
    className?: string;
    value?: string | number | null;
    optionAccessorsProp?: AutocompleteOptionAccessors;
    isInactiveValue?: boolean;
    inactiveInputValue?: string | null;
    inputHook?: Function; // function to get current value in input
    optionsHook?: () => T[]; // function to override optionsData array
    isOptionDisabled?: (option: any) => boolean;
    disabledOptionValues?: (string | number)[];
}

/**
 * Use to provide non-default accessors.
 */
type AutocompleteOptionAccessors = {
    displayKey?: string;
    displayFunction?: Function;
    valueKey?: string;
    idKey?: string;
};

/**
 * A styled MUI Autocomplete component designed for use in Detail panes or other forms.
 * @param {IAutocompleteProps<T>} props 
 * @returns {JSX.Element}
 */
const AutocompleteField = <T extends unknown>(props: IAutocompleteProps<T>): JSX.Element => {
    const { onChange, value, name, optionData, controlProps, optionAccessorsProp, className, isInactiveValue, inactiveInputValue, inputHook, optionsHook } = props;
    const isDisabledOrReadOnly = Boolean(controlProps.disabled || controlProps.readOnly);

    const optionAccessors: AutocompleteOptionAccessors = {
        displayKey: optionAccessorsProp?.displayKey || 'description',
        valueKey: optionAccessorsProp?.valueKey || 'description',
        idKey: optionAccessorsProp?.idKey || 'id',
        displayFunction: optionAccessorsProp?.displayFunction,
    };

    const getOptionValues = (options: T[] | null) => {
        return options?.map(option => {
            const key = hasKey(option, optionAccessors.idKey!) && option[optionAccessors.idKey!];
            const value = hasKey(option, optionAccessors.valueKey!) && option[optionAccessors.valueKey];
            const display = optionAccessors.displayFunction ? optionAccessors.displayFunction(option)
                : hasKey(option, optionAccessors.displayKey!) && option[optionAccessors.displayKey];
            return { key, value, display };
        }) || [];
    }
    const optionHookValues = optionsHook && getOptionValues(optionsHook());

    const options = React.useMemo(() => getOptionValues(optionData), [optionData, optionAccessorsProp]);
    const [inputValue, setInputValue] = React.useState<string | undefined>(value ? value + '' : '');
    const [isOpen, setIsOpen] = React.useState(false);

    React.useEffect(() => {
        setInputValue(getDisplayValue());
        setIsOpen(false);
    }, [value, options, isDisabledOrReadOnly])

    React.useEffect(() => {
        if (isOpen) {
            setInputValue('');
        }
        else {
            setInputValue(getDisplayValue());
        }
    }, [isOpen])

    const handleChange = (event: any, value: any, reason: string) => {
        if (reason === "clear") {
            setIsOpen(false);
        }

        const syntheticEvent = {
            target: {
                value: value?.value || null,
                name: name,
            }
        }
        onChange(syntheticEvent);
    };

    const getDisplayValue = () => {
        const allOptions = optionHookValues || options;
        let display = allOptions?.find(option => option.value === value)?.display || '';
        if (value && isInactiveValue && inactiveInputValue) {
            display = `${inactiveInputValue} (Inactive)`;
        }
        return display;
    }

    const onBlur = () => {
        const display = getDisplayValue();
        if (inputValue !== display) {
            setInputValue(display);
        }
    }

    const onInputChange = (event: any, val: any, reason: string) => {
        setInputValue(val);
        inputHook && inputHook(val);
    }

    const classes = useStyles();
    const applyInactiveClass = isInactiveValue && inputValue === getDisplayValue();
    const autocompleteClasses = `${classes.autocomplete} ${applyInactiveClass ? classes.inactive : ''} ${className ? className : ''}`
    return (
        <DetailDataField {...controlProps}>
            {
                isDisabledOrReadOnly ?
                    <Select value={'read-only'} readOnly={true} variant='outlined' disabled={controlProps?.disabled} IconComponent={"span"}>
                        <MenuItem value={'read-only'} selected>{inputValue}</MenuItem>
                    </Select>
                    : <Autocomplete
                        classes={{
                            option: classes.option,
                            listbox: classes.listbox,
                            paper: classes.paper,
                            noOptions: classes.noOptions,
                        }}
                        ListboxProps={{
                            getContentAnchorEl: null,
                        }}
                        className={autocompleteClasses}
                        size='small'
                        options={optionHookValues ? optionHookValues as [] : options as []}
                        getOptionLabel={(option: any) => option?.display || ''}
                        getOptionSelected={(option: any) => option.value == value}
                        value={value}
                        inputValue={inputValue}
                        clearOnBlur={false}
                        onBlur={onBlur}
                        renderInput={(params) =>
                            <TextField {...params}
                                InputLabelProps={{
                                    className: classes.inputLabel,
                                    id: `${name}-select-label`,
                                    shrink: false,
                                }}
                                error={controlProps?.error}
                                label={!inputValue ? controlProps?.label : undefined}
                                variant='outlined'
                            />}
                        getOptionDisabled={(option: any) => props.disabledOptionValues?.includes(option?.value) === true}
                        onInputChange={onInputChange}
                        onOpen={() => setIsOpen(true)}
                        onClose={() => setIsOpen(false)}
                        onChange={handleChange}
                    />
            }
        </DetailDataField>
    );
}

export default AutocompleteField;