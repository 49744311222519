import React from "react";
import axios from "axios";
import { IUserInfoProps } from "./IUserInfoProps";
import ICurrentUser from "../../interfaces/ICurrentUser";
import PersonIcon from "@material-ui/icons/Person";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AuthenticationService from "../../services/AuthenticationService";
import { connect } from "react-redux";
import { setCurrentUser } from "../../store/actions";
import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    "&& svg circle": {
      fill: theme.palette.mossadams.darkGreen300,
    },
    "&& svg:first-child": {
      transform: "scale(0.8)",
    },
    width: "64px",
  },
  userIcon: {
    color: "white",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    "a:visited": {
      color: theme.palette.text.primary,
    },
  },
}));

const UserInfo: React.FC<IUserInfoProps> = (props: IUserInfoProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const authService = new AuthenticationService();
  const history = useHistory();
  let idleTimer: NodeJS.Timeout;
  const mauiUrl = `${process.env.REACT_APP_CLIENTPLATFORM_MAUI_URL}`;

  /*
        The following uses an axios interceptor to reset the user's idle timeout whenever an axios request is made.
    */
  axios.interceptors.request.use(
    (request) => {
      clearTimeout(idleTimer);
      idleTimer = setTimeout(
        logOut,
        Number(process.env.REACT_APP_CLIENTPLATFORM_IDLE_TIMEOUT)
      );
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    authService.logout(props.currentUser.account.localAccountId);
  };

  const userProfile = () => {
    var page = `/CurrentUserProfile`;
    window.location.href = `${mauiUrl}${page}`;
  };

  return (
    <div className={props.className}>
      {!(props.currentUser && props.currentUser.accessToken) ? null : (
        <div className={classes.root}>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <circle cx="50" cy="50" r="50" />
              <PersonIcon
                className={classes.userIcon}
                fontSize={"small"}
                x="20"
                y="20"
                width="60%"
                height="60%"
              />
            </svg>
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem onClick={handleClose}>
              Logged in as {props.currentUser.name}
            </MenuItem>
            {!props.currentUser.isMossAdamsStaff && (
              <MenuItem onClick={userProfile}>User Profile</MenuItem>
            )}
            <MenuItem
              onClick={() => {
                history.push("/account-settings");
                handleClose();
              }}
            >
              Account Settings
            </MenuItem>
            <Link
              className={classes.link}
              onClick={handleClose}
              to="/help-center"
              target={"_blank"}
            >
              <MenuItem>Help</MenuItem>
            </Link>
            <MenuItem onClick={logOut}>Logout</MenuItem>
          </Menu>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    currentUser: state.currentUser as ICurrentUser,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setCurrentUser: (data: any) => dispatch(setCurrentUser(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
