import { makeStyles, CircularProgress } from "@material-ui/core";
import React from "react";
import ICustomDropzoneProps from "./ICustomDropzoneProps";
import "./CustomDropzone.css";
import { DropzoneArea } from "material-ui-dropzone";
import { validateFiles } from "../../Validators/FileValidators";
import { useSnackbar } from "notistack";
import { SnackbarVariantTypes } from "../../helpers/enums/enums";

const useStyles = makeStyles((theme) => ({
    dropzoneContainer: {
        position: "relative",
        height: "100%"
    },
    dropzone: {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        pointerEvents: "none",
        borderRadius: "3px",
    },
    hidden: {
        display: "none !important",
    },
    loadingIndicator: {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        borderRadius: "3px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 999,
        backgroundColor: "#fff",
        opacity: 0.75,
    },
}));

export const CustomDropzone = (props: ICustomDropzoneProps): JSX.Element => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const initialDropzoneClass = classes.dropzone;
    const [dropzoneClass, setDropzoneClass] = React.useState(initialDropzoneClass);
    const [isLoading, setIsLoading] = React.useState(!!props?.loading);

    const isLoadingControlled = props?.loading !== undefined;

    React.useEffect(() => {
        isLoadingControlled && setIsLoading(props.loading!);
    }, [props.loading]);


    React.useEffect(() => {
        if (props.fileUploadDialogIsOpen === false) {
            setDropzoneClass(initialDropzoneClass);
            setIsLoading(false);
        }
    }, [props.fileUploadDialogIsOpen]);

    const handleDragEnter = (event: any): void => {
        // verify that items are files (prevents UI from displaying other things as droppable)
        // if we want to use this component more generically in the future add a prop for supported item types
        const dragItems = Object.values(event.dataTransfer?.items);
        let itemsAreDroppable = dragItems && true;
        dragItems?.forEach((item: any) => {
            if (item.kind !== 'file') {
                itemsAreDroppable = false;
            }
        });
        if (itemsAreDroppable) {
            setDropzoneClass(`${classes.dropzone} MuiDropzoneArea-active`);
        }
    }

    const handleDragLeave = (event: any): void => {
        event.preventDefault();
        setDropzoneClass(initialDropzoneClass);
    }

    const finishedLoadingCallback = (): void => {
        !isLoadingControlled && setIsLoading(false);
    }

    const handleDrop = (event: any): void => {
        event.preventDefault();
        !isLoadingControlled && setIsLoading(true);
        if (event.dataTransfer.files) {
            var fileList: FileList = event.dataTransfer.files;
            const { fileSetSizeValidation, validFiles, invalidFiles } = validateFiles(Array.from(fileList));
            const doUpload = fileSetSizeValidation.isValid && validFiles.length;
            if (doUpload) {
                props.handleDrop(validFiles, finishedLoadingCallback);
            }
            else if (fileSetSizeValidation.isNotValid) {
                enqueueSnackbar(fileSetSizeValidation.error, { variant: SnackbarVariantTypes.Error });
                finishedLoadingCallback();
            }
            if (invalidFiles.length) {
                invalidFiles.forEach(invalidFile => {
                    enqueueSnackbar(invalidFile.validation.error, { variant: SnackbarVariantTypes.Error });
                })
                !doUpload && finishedLoadingCallback();
            }
        }
        setDropzoneClass(initialDropzoneClass);
    }

    return <div className={classes.dropzoneContainer} onDragEnter={handleDragEnter}>
        {isLoading &&
            <div className={classes.loadingIndicator}>
                <CircularProgress color="primary" size={20}></CircularProgress>
            </div>
        }
        <div className={dropzoneClass} onDragLeave={handleDragLeave} onDragEnd={handleDragLeave} onDrop={handleDrop}></div>
        {props.children}
        {/* this seems bad, but needed to use MuiDropzoneArea-active style, also seems
            to enable some other things that are required for dropping to work correctly */}
        <DropzoneArea key={'' + dropzoneClass} dropzoneClass={classes.hidden}></DropzoneArea>
    </div>
}