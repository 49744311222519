import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MALogo from "../malogo/MALogo";
import Container from "@material-ui/core/Container";
import Sidebar from "../sidebar/Sidebar";
import UserInfo from "../userInfo/UserInfo";
import ClientEngagementSelector from "../ClientEngagementSelector/ClientEngagementSelector";
import ApplicationsPopover from "./ApplicationsPopover";
import TermsAndConditionsPage from "../TermsAndConditionsPage/TermsAndConditionsPage";
import { useCurrentUserTermsAndConditionsStatus } from "../../hooks/useCurrentUserTermsAndConditionsStatus";

export const drawerWidth: number = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        paddingBottom: theme.spacing(0.5),
    },
    contentRoot: {
        display: "flex",
        marginTop: "75px",
        height: "100%"
    },
    sidebarOpen: {
        height: "100%",
        minHeight: `calc(100vh - ${theme.spacing(38)}px)`,
        display: "flex",
        flexDirection: "column",
        width: drawerWidth,
        minWidth: drawerWidth,
        zIndex: 100
    },
    sidebarClosed: {
        height: "100%",
        minHeight: `calc(100vh - ${theme.spacing(38)}px)`,
        display: "flex",
        flexDirection: "column",
        width: theme.spacing(29),
        minWidth: theme.spacing(29),
        zIndex: 100
    },
    content: {
        flexGrow: 1,
        minHeight: `calc(100vh - ${theme.spacing(38)}px)`,
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(10),
    },
    toolbar: {
        padding: theme.spacing(0, 10),
        display: "flex",
        justifyContent: "right",
    },
    appBar: {
        zIndex: theme.zIndex.drawer - 1,
        width: "100%",
        backgroundColor: theme.palette.primary.main,
        border: "none",
        padding: "5px 0",
    },
    headerControls: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    userControlSpacer: {
        width: "80px",
        minWidth: "80px",
    },
    userInfo: {
        position: "fixed",
        right: "20px",
        top: "8px",
    },
    menuButton: {
        marginRight: theme.spacing(7),
        color: theme.palette.common.white,
    },
    menuButtonHidden: {
        display: "none",
    },
    title: {
        flexGrow: 1,
    },
    container: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1
    },

    scaleXMenuOpenIcon: {
        transform: "scaleX(-1)",
    },
    avatar: {
        width: "30px",
        height: "30px",
    },
    applicationsPopoverIcon: {
        marginRight: theme.spacing(3),
        color: theme.palette.mossadams.darkGreen400,
    }
}));

export default function Layout(props: {
    children?: React.ReactNode;
}): JSX.Element {
    const classes = useStyles();
    const [sidebarOpen, setSidebarOpen] = React.useState(true);

    const handleSidebarOpenChanged = (isOpen: boolean) => {
        setSidebarOpen(isOpen);
    }

    const { userTermsAcknowledgmentUpToDate } = useCurrentUserTermsAndConditionsStatus();
    if (userTermsAcknowledgmentUpToDate === null) {
        return <></>;
    }

    return (
        <React.Fragment>
            <div className={classes.root}>
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar} elevation={6} >
                    <Toolbar className={classes.toolbar}>
                        <ApplicationsPopover iconStyles={classes.applicationsPopoverIcon} />
                        <MALogo />
                        <div className={classes.headerControls}>
                            {!userTermsAcknowledgmentUpToDate
                                ? null
                                : <>
                                    <ClientEngagementSelector />
                                    <div className={classes.userControlSpacer} />
                                    <UserInfo className={classes.userInfo} />
                                </>
                            }
                        </div>
                    </Toolbar>
                </AppBar>
            </div>
            {!userTermsAcknowledgmentUpToDate
                ? <TermsAndConditionsPage />
                : <div className={classes.contentRoot}>
                    <div className={sidebarOpen ? classes.sidebarOpen : classes.sidebarClosed}>
                        <Sidebar onSidebarOpenChanged={handleSidebarOpenChanged} />
                    </div>
                    <main className={classes.content}>
                        <Container
                            maxWidth={false}
                            className={classes.container}
                        >
                            <div />
                            {props.children}
                        </Container>
                    </main>
                </div>
            }
        </React.Fragment>
    );
}
