import React from 'react';
import { useSnackbar } from 'notistack';
import DataWriteService from '../services/DataWriteService';
import useAsyncFunctionIsLoading from './useIsLoadingAsync';
import { SnackbarVariantTypes } from '../helpers/enums/enums';
import ITask from '../interfaces/ITask';
import ICurrentUser from '../interfaces/ICurrentUser';
import { useSelector } from 'react-redux';
import { Utils } from '../utilities/utils';


export interface RequestRestrictionDto {
    taskIds: string[];
    restrictTasks: boolean;
}

export interface RequestRestrictionResponse {
    success: boolean;
    tasksWithInaccessibleDocuments: string[];
    tasksWithDocumentsAttachedToOtherRequests: string[];
    tasksWithAttachmentsInRestrictedFolders: string[];
    tasks: null | ITask[];
}

export const useUpdateRequestRestriction = () => {
    const { enqueueSnackbar } = useSnackbar();
    const currentUser = useSelector((state: any) => state.currentUser as ICurrentUser);

    const dataWriteService = new DataWriteService();

    const [response, setResponse] = React.useState<RequestRestrictionResponse | null>(null);

    const updateTask = (task: ITask, restricted: boolean) => {
        task.isRestricted = restricted;
        task.updatedDateTime = new Date();
        task.updatedByUserId = currentUser.userId;
        task.updatedByUserName = currentUser.name;
        return task;
    }

    // to be updated with bulk update implementation
    const handleBulkUpdate = (response: RequestRestrictionResponse, tasks: ITask[], restrictTasks: boolean) => {
        if (response && response.success) {
            tasks.forEach(task => {
                updateTask(task, restrictTasks);
            });
            enqueueSnackbar(`All requests have been successfully ${restrictTasks ? "restricted" : "unrestricted"}. Request and attachment access has been updated.`, { variant: SnackbarVariantTypes.Success });
            return tasks;
        }
        else {
            setResponse(response);
            const errorMessages = generateBulkErrorMessages(response, tasks);
            Utils.enqueueMultiLineSnackbar(errorMessages, enqueueSnackbar, { variant: SnackbarVariantTypes.Error, autoHideDuration: null });
        }
    }

    const generateBulkErrorMessages = (response: RequestRestrictionResponse, tasks: ITask[]) => {
        let messages = ['Unable to update request access for the following requests:'];

        const taskMap = new Map(tasks.map(task => [task.taskId, task]));

        const getTaskNumbers = (taskIds: string[]) => taskIds.map(id => taskMap.get(id)?.taskNumber).filter(Boolean).join(', ');

        if (response.tasksWithInaccessibleDocuments?.length) {
            const taskNumbers = getTaskNumbers(response.tasksWithInaccessibleDocuments);
            messages.push(`These requests have attachments which are inaccessible to you: ${taskNumbers}`);
        }
        if (response.tasksWithAttachmentsInRestrictedFolders?.length) {
            const taskNumbers = getTaskNumbers(response.tasksWithAttachmentsInRestrictedFolders);
            messages.push(`These requests have attachments in restricted folders. These attachments must be moved to a different folder or unattached before these requests can be restricted: ${taskNumbers}`);
        }
        if (response.tasksWithDocumentsAttachedToOtherRequests?.length) {
            const taskNumbers = getTaskNumbers(response.tasksWithDocumentsAttachedToOtherRequests);
            messages.push(`These requests have attachments which are attached to other requests: ${taskNumbers}`);
        }

        return messages;
    }

    const handleSingleUpdate = (response: RequestRestrictionResponse, task: ITask, restrictTasks: boolean) => {
        if (response && response.success) {
            updateTask(task, restrictTasks);
            enqueueSnackbar(`The request has been successfully ${restrictTasks ? "restricted" : "unrestricted"}. Request and attachment access has been updated.`, { variant: SnackbarVariantTypes.Success });
            return task;
        }
        else {
            const errorMessages = generateErrorMessages(response);
            Utils.enqueueMultiLineSnackbar(errorMessages, enqueueSnackbar, { variant: SnackbarVariantTypes.Error, autoHideDuration: null });
        }
    }

    const generateErrorMessages = (response: RequestRestrictionResponse) => {
        let messages = ['Unable to update request access.'];

        if (response.tasksWithInaccessibleDocuments?.length) {
            messages.push('This request has attachments which are inaccessible to you.');
        }
        if (response.tasksWithAttachmentsInRestrictedFolders?.length) {
            messages.push('This request has attachments in restricted folders. These attachments must be moved to a different folder or unattached before this request can be restricted.');
        }
        if (response.tasksWithDocumentsAttachedToOtherRequests?.length) {
            messages.push('This request has attachments which are attached to other requests. To restrict access to a request, all of its attachments must only be attached to that request.');
        }

        return messages;
    }

    const { onTrigger: updateRequestRestriction, loading } = useAsyncFunctionIsLoading(async (tasks: ITask[], restrictTasks: boolean) => {
        const dto: RequestRestrictionDto = {
            taskIds: tasks.map(t => t.taskId!),
            restrictTasks
        }

        const result = await dataWriteService.UpdateRequestRestriction(dto);

        const commandResponse = result.response?.data as RequestRestrictionResponse;
        if (commandResponse) {
            return tasks.length === 1
                ? handleSingleUpdate(commandResponse, tasks[0], restrictTasks)
                : handleBulkUpdate(commandResponse, tasks, restrictTasks);
        }
        else {
            enqueueSnackbar(result.errorMessages, { variant: SnackbarVariantTypes.Error });
        }
    });

    return { updateRequestRestriction, loading, response }
}


