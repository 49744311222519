import { useSnackbar } from "notistack";
import { SnackbarVariantTypes } from "../helpers/enums/enums";
import IContentHierarchy from "../interfaces/IContentHierarchy";
import { hierarchyIsFile } from "../helpers/ContentHierarchyHelpers";


export const useCopyLinkToClipboard = () => {
    const { enqueueSnackbar } = useSnackbar();

    const hrefBase = `${process.env.REACT_APP_CLIENTPLATFORM_CLIENT_URL}`;

    const getHierarchyLink = (hierarchy?: IContentHierarchy | null) => {
        if (!hierarchy) {
            return;
        }

        let link;
        if (hierarchyIsFile(hierarchy)) {
            link = `${hrefBase}/document-management/${hierarchy.id}`;
        }
        else {
            link = `${hrefBase}/folder/${hierarchy.id}`;
        }

        copyLinkToClipboard(link);
    }

    const copyLinkToClipboard = async (link: string) => {
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(link);
            enqueueSnackbar("Link copied to clipboard.", { variant: SnackbarVariantTypes.Success });
        }
    }

    return { getHierarchyLink };
}