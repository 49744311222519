import React, { useEffect } from "react";
import { Drawer, IconButton, isWidthDown, isWidthUp, withWidth } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import { TMuiListItemButtonClickEvent } from "../../types/MaterialUIEventTypes";
import SidebarPrimaryNav from "./SidebarPrimaryNav";
import ISidebarProps from "./ISidebarProps";
import { drawerWidth } from "../layout/Layout";
import HelpfulHints from "./HelpfulHints";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.mossadams.warmGray200,
        position: "fixed",
        height: `calc(100vh - ${theme.spacing(38)}px)`,

    },
    drawerPaper: {
        backgroundColor: theme.palette.mossadams.warmGray200,
        boxShadow: theme.shadows[6],
        padding: 0,
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflow: "hidden"
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(28.5),
    },
    menuButton: {
        color: theme.palette.primary.main,
        width: theme.spacing(20),
        height: theme.spacing(20),
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButtonOpen: {
        marginLeft: theme.spacing(100),
    },
    menuButtonClosed: {
        marginLeft: theme.spacing(4),
    },
    scaleXMenuOpenIcon: {
        transform: "scaleX(-1)",
    },
    primaryNav: {
        marginBottom: "auto",
    },
}));

const Sidebar = (props: ISidebarProps): JSX.Element => {
    const classes = useStyles();

    const [showPrimaryNav, setShowPrimaryNav] = React.useState(true);
    const [sidebarOpen, setSidebarOpen] = React.useState(true);

    const handleToggleNav: TMuiListItemButtonClickEvent = (event): void => {
        event.preventDefault();
        // NOTE: Not currently using the event parameter that is the property passed by the event click, but will likely use it in the future.
        setShowPrimaryNav(!showPrimaryNav);
    };

    const handleSidebarToggle = (): void => {
        props.onSidebarOpenChanged(!sidebarOpen);
        setSidebarOpen(!sidebarOpen);
    };

    useEffect(() => {
        if (isWidthDown('md', props.width)) {
            props.onSidebarOpenChanged(false);
            setSidebarOpen(false);
        } else if (isWidthUp('xl', props.width)) {
            props.onSidebarOpenChanged(true);
            setSidebarOpen(true);
        }
    }, [props.width]);

    return (
        <Drawer
            variant="permanent"
            elevation={6}
            classes={{
                root: classes.root,
                paper: sidebarOpen
                    ? classes.drawerPaper
                    : `${classes.drawerPaper} ${classes.drawerPaperClose}`,
            }}
            open={sidebarOpen}
        >
            <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleSidebarToggle}
                className={`${classes.menuButton} ${(sidebarOpen ? classes.menuButtonOpen : classes.menuButtonClosed)}`}
            >
                {sidebarOpen
                    ? <MenuOpenIcon />
                    : <MenuOpenIcon className={classes.scaleXMenuOpenIcon} />
                }
            </IconButton>
            <SidebarPrimaryNav
                onClickToggleNav={handleToggleNav}
                showToolTip={!sidebarOpen}
                className={classes.primaryNav}
            />
            <HelpfulHints sidebarOpen={sidebarOpen} />
        </Drawer>
    );
};

export default withWidth()(Sidebar);
