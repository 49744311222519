import { Button, DialogActions, makeStyles, Paper, Popper, Typography } from '@material-ui/core';
import * as React from 'react';
import useIsLoadingAsync from '../../hooks/useIsLoadingAsync';
import SpinnerBackdrop from '../common/SpinnerBackdrop';

const useStyles = makeStyles((theme) => ({
    popper: {
        zIndex: 1200,
        "& .MuiPaper-root": {
            padding: '16px 12px 6px 12px',
        },
        "& .MuiDialogActions-root": {
            padding: '8px 6px 6px',
        },
    }
}))

export interface IConfirmationDialogPopperProps {
    anchorEl: HTMLButtonElement | null;
    popperText: string;
    onConfirmAsync: () => Promise<void>;
    onCancel: React.MouseEventHandler<HTMLButtonElement>;
    confirmDisabled: boolean;
}

const ConfirmationDialogPopper = (props: IConfirmationDialogPopperProps) => {
    const {anchorEl, popperText, onCancel, confirmDisabled} = props;
    const classes = useStyles();
    const {onTrigger: onClick, loading} = useIsLoadingAsync(props.onConfirmAsync);
    
    return <Popper className={classes.popper} id={Boolean(anchorEl) ? 'confirm-popper' : undefined} anchorEl={anchorEl} placement='left-end' open={Boolean(anchorEl)} >
        <Paper elevation={5}>
            <SpinnerBackdrop isActive={loading} />
            <Typography variant='h5'>
                {popperText}
            </Typography>
            <DialogActions>
                <Button onClick={onClick} size='small' color='primary' disabled={confirmDisabled} variant='outlined'>OK</Button>
                <Button onClick={onCancel} size='small' color='primary' variant='outlined'>Cancel</Button>
            </DialogActions>
        </Paper>
    </Popper>
}

export default ConfirmationDialogPopper