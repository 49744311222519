import { GroupCellRendererParams } from "@ag-grid-enterprise/all-modules";
import { LocalDateTimeString } from "../../helpers/DateHelpers";
import { taskIsNotPlaceholderNode } from "../../helpers/TaskHelpers";
import RestrictedIcon from "../common/RestrictedIcon/RestrictedIcon";
import { Tooltip } from "@material-ui/core";

export const dateRenderer = (params: GroupCellRendererParams) => {
    return (
        <span>
            {params.value && LocalDateTimeString(params.value as Date, false)}
        </span>
    );
}

export const attachmentsRenderer = (params: GroupCellRendererParams) => {
    if (!(params && params.data) || params.data.taskNumber === '') return null;
    return (
        <span>
            {params.data.hasAttachments ? "Yes" : "No"}
        </span>
    );
}

export const entityCellRenderer = (props: GroupCellRendererParams) => {
    const value = props.value;
    const isGroupNode = props?.node?.group;

    return (isGroupNode
        ? <strong>{value}</strong>
        : <>{taskIsNotPlaceholderNode(props?.node?.data) ? value : ''}</>)
}


export const restrictedRenderer = (params: GroupCellRendererParams) => {
    return params?.data?.isRestricted
        ? <Tooltip title='Restricted Request'>
            <span>
                <RestrictedIcon useGridStyle />
            </span>
        </Tooltip>
        : null
}
