/**
 * Reformat the error messages returned from the ClientPlatform Api.
 * 
 * @remarks
 * The errors arrive from the api as an object of an index signature type with keys (the source of the error)
 * of strings and values of an array of strings (the error message).
 * 
 * @param errorsFromApi The errors as an object of type of an index signature.
 */
export const parseApiErrors = (errorsFromApi: { [key: string]: string[] }): string[] => {

    const errorsParsed: string[] = [];

    Object.keys(errorsFromApi).forEach((key: string) => {

        errorsFromApi[key].forEach((value: string) => {
            
            const errorMessage = `${key}: ${value}`;

            errorsParsed.push(errorMessage);
        });
    });

    return errorsParsed;
};