import React from "react";
import { Link, useHistory } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import HomeIcon from "@material-ui/icons/Home";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import GroupIcon from '@material-ui/icons/Group';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import BarChartIcon from "@material-ui/icons/BarChartOutlined";
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import ListAlt from "@material-ui/icons/ListAlt";
import { TMuiListItemButtonClickEvent } from "../../types/MaterialUIEventTypes";
import Tooltip from "@material-ui/core/Tooltip";
import Dashboard from '../Dashboard/Dashboard';
import ICurrentUser from "../../interfaces/ICurrentUser";
import { connect } from "react-redux";
import { setCurrentUser } from "../../store/actions";

const useStyles = makeStyles((theme) => ({
    sidebarLink: {
        textDecoration: "none",
    },
    sidebarItem: {
        color: theme.palette.primary.main,
        fontWeight: 700,
        "&.Mui-selected": {
            // borderLeft: "5px solid",
            // borderLeftColor: theme.palette.primary.main,
            boxShadow: "inset 5px 0 0 0"
        },
    },
    sidebarItemIcon: {
        minWidth: 0,
    },
    sidebarItemText: {
        paddingLeft: theme.spacing(8),
    },
    signOffsCountChip: {
        fontSize: "0.8rem",
        height: "26px",
    },
}));

const SidebarPrimaryNav = (props: {
    onClickToggleNav: TMuiListItemButtonClickEvent;
    showToolTip: boolean;
    className?: string;
    currentUser?: ICurrentUser;
}): JSX.Element => {

    const dashboardTitle = "DASHBOARD";
    const engagementManagementTitle = "ENGAGEMENT REQUESTS";
    const documentManagementTitle = "DOCUMENTS";
    const documentRecycleBinTitle = "RECYCLE BIN";
    const internalReportingTitle = "REPORTING";
    const teamTitle = "MOSS ADAMS TEAM";

    const homePath = "/";
    const engagementManagementPath = "/engagement-management";
    const documentManagementPath = "/document-management";
    const documentRecycleBinPath = "/document-recycle-bin";
    const internalReportingPath = "/internal-reporting";
    const teamPath = "/team";

    const classes = useStyles();
    const history = useHistory();

    const [path, setPath] = React.useState(window.location.pathname);

    React.useEffect(() => {
        history.listen((location) => setPath(location.pathname));
        return () => { history.listen(() => { }) }  // Dispose the listener.
    }, []);

    return (
        <React.Fragment>
            <List className={props.className}>
                <Link to={homePath} className={classes.sidebarLink}>
                    <ListItem className={classes.sidebarItem} button selected={path === homePath}>
                        <ListItemIcon className={classes.sidebarItemIcon}>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary={dashboardTitle} className={classes.sidebarItemText} />
                    </ListItem>
                </Link>
                <Link to={engagementManagementPath} className={classes.sidebarLink}>
                    <Tooltip
                        title={props.showToolTip ? engagementManagementTitle : ""}
                        placement="right"
                        arrow
                    >
                        <ListItem className={classes.sidebarItem} button selected={path.startsWith(engagementManagementPath)}>
                            <ListItemIcon className={classes.sidebarItemIcon}>
                                <ListAlt />
                            </ListItemIcon>
                            <ListItemText primary={engagementManagementTitle} className={classes.sidebarItemText} />
                        </ListItem>
                    </Tooltip>
                </Link>
                <Link to={documentManagementPath} className={classes.sidebarLink}>
                    <Tooltip
                        title={props.showToolTip ? documentManagementTitle : ""}
                        placement="right"
                        arrow
                    >
                        <ListItem className={classes.sidebarItem} button selected={path.startsWith(documentManagementPath)}>
                            <ListItemIcon className={classes.sidebarItemIcon}>
                                <FileCopyOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={documentManagementTitle} className={classes.sidebarItemText} />
                        </ListItem>
                    </Tooltip>
                </Link>
                {props.currentUser?.isMossAdamsStaff &&
                    <Link to={documentRecycleBinPath} className={classes.sidebarLink}>
                        <Tooltip
                            title={props.showToolTip ? documentRecycleBinTitle : ""}
                            placement="right"
                            arrow
                        >
                            <ListItem className={classes.sidebarItem} button selected={path.startsWith(documentRecycleBinPath)}>
                                <ListItemIcon className={classes.sidebarItemIcon}>
                                    <RestoreFromTrashIcon />
                                </ListItemIcon>
                                <ListItemText primary={documentRecycleBinTitle} className={classes.sidebarItemText} />
                            </ListItem>
                        </Tooltip>
                    </Link>
                }
                {props.currentUser?.isMossAdamsStaff &&
                    <Link to={internalReportingPath} className={classes.sidebarLink}>
                        <Tooltip
                            title={props.showToolTip ? internalReportingTitle : ""}
                            placement="right"
                            arrow
                        >
                            <ListItem className={classes.sidebarItem} button selected={path === internalReportingPath}>
                                <ListItemIcon className={classes.sidebarItemIcon}>
                                    <BarChartIcon />
                                </ListItemIcon>
                                <ListItemText primary={internalReportingTitle} className={classes.sidebarItemText} />
                            </ListItem>
                        </Tooltip>
                    </Link>
                }

                {/*  <Link to={teamPath} className={classes.sidebarLink}>
                    <Tooltip
                        title={props.showToolTip ? teamTitle : ""}
                        placement="right"
                        arrow
                    >
                        <ListItem className={classes.sidebarItem} button selected={path === teamPath}>
                            <ListItemIcon className={classes.sidebarItemIcon}>
                                <GroupIcon />
                            </ListItemIcon>
                            <ListItemText primary={teamTitle} className={classes.sidebarItemText} />
                        </ListItem>
                    </Tooltip>
                </Link> */}
            </List>
        </React.Fragment>
    );
};

const mapStateToProps = (state: any) => {
    return ({
        currentUser: state.currentUser as ICurrentUser
    })
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setCurrentUser: (data: any) => dispatch(setCurrentUser(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarPrimaryNav);
