
/**
 * These are base keys used to generate keys for saved views. (see {@link useSavedViews})
 * Each base key can have maxViewsPerKey associated views.
 * 
 * Note that any changes made to existing keys will result in associated saved views becoming inaccessible to users. 
 */
export const SavedViewKeys = {
    engagementManagement: "EngagementManagement",
    taskListManagement: "TaskListManagement",
    documentManagement: "DocumentManagement",
    recycleBin: "DocumentRecycleBin",
}