import React from 'react';

type Params = unknown[];

interface ModifiedAsyncFunction<T, A extends Params> {
    onTrigger: (...args: A) => Promise<T | undefined>;
    loading: boolean;
}


const useAsyncFunctionIsLoading = <T, A extends Params>(asyncFunction: (...args: A) => Promise<T>, onLoadingStatusChanged?: (loading: boolean) => void): ModifiedAsyncFunction<T, A> => {
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (onLoadingStatusChanged) {
            onLoadingStatusChanged(loading);
        }
    }, [loading])

    const onTrigger = async (...args: A) => {
        if (loading) {
            return;
        }

        setLoading(true);
        try {
            let result = await asyncFunction(...args);
            return result;
        }
        catch (error) {
            console.error('An unhandled error occured.', error);
        }
        finally {
            setLoading(false);
        }
    }

    return { onTrigger, loading };
}

export default useAsyncFunctionIsLoading;