import React, { useState } from 'react'
import DataReadService from '../../services/DataReadService';
import { CircularProgress, makeStyles, TextField, } from "@material-ui/core";
import _ from 'lodash';
import { Autocomplete } from '@material-ui/lab';
import { useUpdateSelectedClientsAndEngagements } from './useUpdateSelectedClientsAndEngagements';



const useStyles = makeStyles((theme) => {
    const focusedColor = theme.palette.primary.main;
    const font = "Founders Grotesk Reg";
    return {
        autocomplete: {
            '& .MuiAutocomplete-endAdornment': {
                top: 'auto',
            },
            "& .MuiAutocomplete-popupIndicator": {
                marginRight: 0,
                padding: 0,
            },
            "& .MuiAutocomplete-clearIndicator": {
                marginRight: 0,
                padding: 0,
            },
            '&.Mui-focused ': {
                borderColor: focusedColor,
            },
            "&.Mui-focused .MuiAutocomplete-inputRoot": {
                borderColor: focusedColor,
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
                padding: '0px',
                fontSize: '0.95rem !important',
                fontFamily: `${font} !important`,
            },
        },
        listbox: {
            padding: 0,
            margin: 0,
        },
        option: {
            fontSize: '1rem',
            fontFamily: font,
            "&.MuiAutocomplete-option[aria-selected='true']": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                "&:hover": {
                    backgroundColor: theme.palette.mossadams.darkGreen500
                },
            },
        },
        noOptions: {
            fontSize: '1rem',
            fontFamily: font,
        },
        paper: {
            borderRadius: '0px',
            margin: 0,
        },
        inputLabel: {
            paddingLeft: '0.41rem',
            color: `${theme.palette.mossadams.gray300} !important`,
            '&.MuiInputLabel-formControl': {
                fontFamily: font,
                transform: 'translate(0, 6px) scale(1)',
            }
        },
        inactive: {
            "& .MuiAutocomplete-inputRoot": {
                color: theme.palette.mossadams.gray200
            },
        },
    }
});

interface Props {
    filters: Set<number>;
    triggerClientEngagementListUpdate: (clientId: string) => Promise<void>;
    disabled: boolean;
}

export interface ClientOption {
    id: string;
    num: string;
    name: string;
}

export default function ClientAutocomplete(props: Props) {
    const { updateClientLastAccessed } = useUpdateSelectedClientsAndEngagements();

    const getSearchParams = (searchString: string) => {
        const params = new URLSearchParams();
        params.append('queryString', searchString);
        params.append('accountTypeFilters', [...props.filters].join(','));
        return params;
    }

    const getClients = async (searchString: string) => {
        const dataReadService = new DataReadService();

        const res = await dataReadService.GetClients(getSearchParams(searchString));

        if (res.status) {
            return res.data;
        }
        else {
            console.error("Error searching clients.");
        }
    }

    const addClient = async (clientId: string) => {
        const res = await updateClientLastAccessed(clientId);
        if (res.status) {
            await props.triggerClientEngagementListUpdate(clientId);
        }
        else {
            console.error("Error updating client last accessed.");
        }
    }

    return (
        <>
            <AsyncAutocomplete
                fetchData={getClients}
                onChange={addClient}
                disabled={props.disabled}
            />
        </>
    )
}

interface AsyncAutocompleteProps<T> {
    fetchData: (searchString: string) => Promise<T[] | undefined>;
    onChange: any;
    disabled: boolean;
}

function AsyncAutocomplete<T extends unknown>(props: AsyncAutocompleteProps<T>) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [lastFetchedSearchedString, setLastFetchedSearchString] = useState<string | null>(null);

    const classes = useStyles();
    const debounceInterval = 1000;
    const minLengthForSearch = 3;
    const startSearchMessage = 'Search for clients by name or number.';
    const noClientsFoundMessage = 'No clients found';
    const loadingMessage = 'Searching...';

    let noOptionsMessage = (searchString.length < minLengthForSearch && !options.length) ? startSearchMessage : noClientsFoundMessage;
    noOptionsMessage = lastFetchedSearchedString === searchString ? noOptionsMessage : startSearchMessage;

    const fetchData = async () => {
        setLoading(true);
        const options = await props.fetchData(searchString);
        if (options) {
            setOptions(options);
        }
        setLoading(false);
    }

    const updateSearchStringDebounced = React.useMemo(() =>
        _.debounce((val: string) => {
            setSearchString(val);
        }, debounceInterval)
        , []
    );

    const onChangeSearch = (val: string) => {
        if (!loading) {
            if (searchString.length >= minLengthForSearch) {
                updateSearchStringDebounced(val);
                setLastFetchedSearchString(val);
            }
            else {
                setSearchString(val);
            }
        }
    }

    React.useEffect(() => {
        if (open && searchString.length >= minLengthForSearch) {
            fetchData();
        }
    }, [open, searchString]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
            setSearchString('');
        }
    }, [open]);

    const onSelectResult = (event: any, value: any, reason: string) => {
        setOpen(false);
        props.onChange(value?.id);
    }

    return (
        <Autocomplete
            disabled={props.disabled}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => `${option.name} (${option.num})`}
            noOptionsText={noOptionsMessage}
            options={options}
            loading={loading}
            loadingText={loadingMessage}
            clearOnEscape={true}
            filterOptions={option => option}
            size='small'
            onChange={onSelectResult}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    placeholder='Search'
                    onChange={(e) => onChangeSearch(e.target.value)}
                    disabled={loading}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
