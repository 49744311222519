import { AccountInfo } from '@azure/msal-browser';

/**
 * The uninitalized state of the Microsoft Authentication Library (MSAL) AccountInfo.
 */
export const emptyMsalAccountInfo: AccountInfo = {

    environment: '',
    homeAccountId: '',
    localAccountId: '',
    tenantId: '',
    username: ''
};

const InitialState = {
    clients: [],
    engagements: [],
    selectedClients: [],
    selectedEngagements: [],
    selectedClientEngagementIds: [],
    areSelectedClientEngagementsLoaded: false,
    currentUser: {
        userId: '',
        email: '',
        isMossAdamsStaff: false,
        name: '',
        accessToken: '',
        accessTokenExpiresOn: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
        account: { ...emptyMsalAccountInfo }
    },
    engagementManagementIsDirty: false,
    taskListEditorIsDirty: false,
    lookupLists: null,
    loadingLookupLists: false,
    clientEngagementSelectorDisabled: false,
}

export default InitialState;