import React from 'react';
import { useSnackbar } from "notistack";
import { SnackbarVariantTypes } from "../../helpers/enums/enums";
import IContentHierarchy from "../../interfaces/IContentHierarchy";
import ITaskDocument from "../../interfaces/ITaskDocument";
import DataWriteService from "../../services/DataWriteService";
import { Utils } from "../../utilities/utils";
import { CustomDropzone } from "../CustomDropzone/CustomDropzone";
import FileUploadDialog, { errorSeverity } from "../FileUploadDialog/FileUploadDialog";
import ITask from '../../interfaces/ITask';
import { DirectUploadProps } from '../FileUploadDialog/IFileUploadDialogProps';
import { makeStyles } from "@material-ui/core/styles";
import { FileUploadProviderWrapper } from '../../contexts/FileUploadContext/FileUploadProviderWrapper';


const useStyles = makeStyles((theme) => ({
    dropzoneDisabled: {
        height: "100%"
    },
    backdrop: {
        zIndex: 100,
        color: '#fff',
    },
}));

interface TaskDocumentsDropzoneProps {
    task: ITask;
    taskDocuments: ITaskDocument[];
    uploadFolderId: string;
    children: React.ReactNode;
    fileUploadDialogIsOpen?: boolean;
    disabled: boolean;
    onFilesUploaded: (uploadedFiles: IContentHierarchy[]) => Promise<void>;
}

export const TaskDocumentsDropzone = (props: TaskDocumentsDropzoneProps) => {
    const { taskDocuments, task, uploadFolderId, children, onFilesUploaded } = props;
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const [directUploadProps, setDirectUploadProps] = React.useState<DirectUploadProps | null>(null);

    const handleAttachmentGridDrop = async (files: File[], handleFinishedLoad: Function) => {
        const hasAttachment = taskDocuments.length > 0;
        const uploadingMultiple = files.length > 1;
        if (!task.allowMultipleAttachments && (hasAttachment || uploadingMultiple)) {
            var fileCountError = "There can only be one file for this task."
            enqueueSnackbar(fileCountError, { variant: errorSeverity });
            handleFinishedLoad();
            throw fileCountError;
        }

        const onAfterDragUploadCompleted = () => {
            setDirectUploadProps(null);
        };
        setDirectUploadProps({ files, onAfterDragUploadCompleted });

        handleFinishedLoad();
    }

    return (
        <div>
            <FileUploadProviderWrapper
                folderId={props.uploadFolderId}
                isRestrictedFileUpload={false}
                suppressNotifications={true}
            >
                <FileUploadDialog
                    directUploadProps={directUploadProps}
                    isOpen={false}
                    clientName={props.task.clientName!}
                    engagementName={props.task.engagementName!}
                    folderId={props.uploadFolderId}
                    isSingleFile={false}
                    onClose={() => { setDirectUploadProps(null) }}
                    onFilesUploaded={onFilesUploaded}
                />
            </FileUploadProviderWrapper>
            {props.disabled ? <div className={classes.dropzoneDisabled}>{children}</div> :
                <CustomDropzone
                    handleDrop={handleAttachmentGridDrop}
                    fileUploadDialogIsOpen={props.fileUploadDialogIsOpen}
                >
                    {children}
                </CustomDropzone>
            }
        </div>
    );
}
