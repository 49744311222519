import { Link } from "react-router-dom";
import { ICellRendererParams } from '@ag-grid-community/core';
import ITask from "../../interfaces/ITask";
import { TaskStatus } from "../../helpers/enums/TaskStatus";

const RequestTitleCellRenderer = (params: ICellRendererParams) => {
    const task: ITask = params.data;
    const isDeleted = task.taskStatusId == TaskStatus.Deleted.id;

    if (task !== null) {
        if (isDeleted == false) {
            return <Link to={`/engagement-management/${task.taskId}`} title={task.title}> {task.title} </Link>
        } else {
            return <span title={task.title}>{task.title}</span>
        }
    }
    return "";
}

export default RequestTitleCellRenderer;