import React from 'react'
import SavedViewModal, { SavedViewModalProps } from './SavedViewModal';
import TooltipIconButton from '../common/TooltipIconButton/TooltipIconButton';
import { TableChart } from '@material-ui/icons';

type Props = {
    modalProps: Omit<SavedViewModalProps, 'isOpen' | 'onClose'>;
    disabled?: boolean;
}


export default function LayoutManagerModalButton(props: Props) {
    const [isOpen, setIsOpen] = React.useState(false); 

    const disabled = props.disabled || !props.modalProps.gridApi || !props.modalProps.columnApi;

    return <>
        <TooltipIconButton tooltip="Layout Manager" onClick={() => setIsOpen(true)} disabled={disabled}>
            <TableChart />
        </TooltipIconButton>
        <SavedViewModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            {...props.modalProps}
        />
    </>
}
