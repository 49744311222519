import { Dialog } from '@material-ui/core';
import * as React from 'react';
import IDocument from '../../interfaces/IDocument';
import { IBulkDocumentActionHelper } from '../documentManagement/useDocumentsBulkActionHelper';
import RestrictedFileUserAssociationDialogContent, { UpdateDocumentAccessModel } from './RestrictedFileUserAssociationDialogContent';


export interface RestrictedFileAssociationDialogProps {
    open: boolean;
    onClose: () => void;
    document: IDocument | null;
    onUpdateAccess: (isPrivate: boolean, usersWithAccess: string[]) => void;
}

const RestrictedFileAssociationDialog = ({ open, onClose, document, onUpdateAccess }: RestrictedFileAssociationDialogProps) => {
    const documentIsSelected = document !== null;
    const render = documentIsSelected;

    if (!render || !open) return null;

    const doc: UpdateDocumentAccessModel = {
        documentId: document.documentGuid,
        createdByUserId: document.createdByUserGuid ,
        parentId: document.parentGuid,
        isPrivate: document.isPrivate,
    }

    return <>
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
        >
            <RestrictedFileUserAssociationDialogContent title='Manage File Access'
                onClose={onClose} 
                document={doc} 
                onUpdateAccess={onUpdateAccess}
            />
        </Dialog>
    </>
}

export default RestrictedFileAssociationDialog