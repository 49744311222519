import React, { lazy, Suspense } from "react";
import { Router, Route, Switch } from 'react-router';
import { createBrowserHistory } from 'history';
import EngagementManagement from "./components/engagementManagement/EngagementManagement";
import Layout from "./components/layout/Layout";
import "./custom.css";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAuthentication } from "./hooks/useAuthentication";
const Dashboard = lazy(() => import('./components/Dashboard/Dashboard'));
const DocumentManagement = lazy(() => import('./components/documentManagement/DocumentManagement'));
const DocumentRecycleBin = lazy(() => import('./components/DocumentRecycleBin/DocumentRecycleBin'));
const TaskListEditor = lazy(() => import('./components/TaskListEditor/TaskListEditor'));
const Team = lazy(() => import('./components/team/Team'));
const Reports = lazy(() => import('./components/Reports/Reports'));
const HelpCenter = lazy(() => import('./components/HelpCenter/HelpCenter'));
const AccountSettings = lazy(() => import('./components/AccountSettings/AccountSettings'));
const ErrorPage = lazy(() => import('./components/error/Error'));
const NotFoundPage = lazy(() => import('./components/notFound/NotFound'));

/**
 * A history object to use for navigation.
 * When history is passed to </Router>, the history object is available for use within components
 * This allows developers to navigate users within the React application programmatically, among other things
 */
const history = createBrowserHistory();

const App = (): JSX.Element => {
    const { currentUser } = useAuthentication();

    return (
        <>
            {currentUser.accessToken &&
                <Router history={history}>
                    <Layout>
                        <Suspense fallback={<CircularProgress color="inherit" size={20} />}>
                            <Switch>
                                <Route exact path="/" component={Dashboard} />
                                <Route path="/document-management" component={DocumentManagement} />
                                {
                                    currentUser.isMossAdamsStaff &&
                                    (<Route exact path="/document-recycle-bin" component={DocumentRecycleBin} />)
                                }
                                <Route exact path="/engagement-management" component={EngagementManagement} />
                                <Route exact path="/engagement-management/:id" component={EngagementManagement} />
                                {
                                    currentUser.isMossAdamsStaff &&
                                    (<Route exact path="/engagement-management/request-list/:id" component={TaskListEditor} />)
                                }
                                {
                                    currentUser.isMossAdamsStaff &&
                                    (<Route exact path="/internal-reporting" component={Reports} />)
                                }
                                <Route exact path="/team" component={Team} />
                                <Route exact path="/help-center" component={HelpCenter} />
                                <Route exact path="/account-settings" component={AccountSettings} />
                                <Route exact path="/error" component={ErrorPage} />
                                <Route path="*" component={NotFoundPage} />
                            </Switch>
                            <style>
                                {`div.uv-icon {
                                    display: block;
                                }`}
                            </style>
                        </Suspense>
                    </Layout>
                </Router>
            }
        </>
    );
};

export default App;