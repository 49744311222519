import React from 'react'
import { ConfirmDialog } from '../ConfirmDialog/ConfirmDialog'
import { Typography } from '@material-ui/core'

interface Props {
    isOpen: boolean
    onConfirm: () => void
    onClose: () => void
}

export default function RestrictedAttachmentsConfirmDialog({ isOpen, onConfirm, onClose }: Props) {
    return (
        <ConfirmDialog
            title={'Attach To Restricted Request?'}
            isOpen={isOpen}
            onConfirm={onConfirm}
            onClose={onClose}
        >
            <Typography>
                By attaching a file to a restricted request, its visibility may change. Only request assignees and Moss Adams users will have access. Would you like to continue?
            </Typography>
        </ConfirmDialog>
    )
}
