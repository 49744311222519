import React from "react";
import IApplicationsPopoverProps from "./IApplicationsPopoverProps";
import { Button, Grid, makeStyles, Paper, Popover, Typography } from "@material-ui/core";
import SecurityIcon from '@material-ui/icons/Security';
import AppsIcon from '@material-ui/icons/Apps';
import ICurrentUser from "../../interfaces/ICurrentUser";
import { connect } from 'react-redux';
import PageHeader from "../PageHeader/PageHeader";

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(15),
    },
    paper: {
        padding: theme.spacing(8),
        width: "384px",
        height: "368px",
        boxShadow: "none",
    },
    clientUserAdmin: {
        fontSize: "40px",
        paddingRight: theme.spacing(2),
    },
    popover: {
        width: "100%",
    },
    appButton: {
        maxWidth: "114px"
    },
    appButtonText: {
        fontSize: "0.7rem",
        fontFamily: "Founders Grotesk Med",
        textAlign: "center"
    },
    mossAdamsPortalMenuIcon: {
        width: "45px",
    }
}));

const ApplicationsPopover = (props: IApplicationsPopoverProps): JSX.Element => {
    const classes = useStyles();
    const [open, setOpen] = React.useState<boolean>(false);
    const clientUserAdminTitle = "Moss Adams Universal Identity";
    const mauiUrl = `${process.env.REACT_APP_CLIENTPLATFORM_MAUI_URL}`
    const mossAdamsPortalMenuTile = "Moss Adams Portal";
    const mossAdamsPortalMenuURL = `${process.env.REACT_APP_CLIENTPLATFORM_MOSS_ADAMS_PORTAL_URL}`
    const id = open ? 'simple-popover' : undefined;

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <AppsIcon fontSize="large" onClick={handleClick} className={props.iconStyles} />
            <Popover
                id={id}
                open={open}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 74, left: 0 }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className={classes.popover}
                PaperProps={{ square: true }}
            >
                <Paper className={classes.paper}>
                    <PageHeader className={classes.header}>Applications</PageHeader>
                    <Grid
                        container
                        direction="row"
                        alignItems="flex-start"
                    >
                        <Button className={classes.appButton} href={mauiUrl}>
                            <Grid
                                container
                                direction="column"
                                justifyContent="space-evenly"
                                alignItems="center"
                            >
                                <SecurityIcon className={classes.clientUserAdmin} />
                                <Typography className={classes.appButtonText}>{clientUserAdminTitle}</Typography>
                            </Grid>
                        </Button>
                        <Button className={classes.appButton} href={mossAdamsPortalMenuURL}>
                            <Grid
                                container
                                direction="column"
                                justifyContent="space-evenly"
                                alignItems="center"
                            >
                                <img
                                    src="/images/moss-adams-logo-green.svg"
                                    className={classes.mossAdamsPortalMenuIcon}
                                    alt="Moss Adams Portal Menu"
                                />
                                <Typography className={classes.appButtonText}>{mossAdamsPortalMenuTile}</Typography>
                            </Grid>
                        </Button>
                    </Grid>
                </Paper>
            </Popover>
        </>
    );
}

const mapStateToProps = (state: any) => {
    return ({
        currentUser: state.currentUser as ICurrentUser
    })
}

export default connect(mapStateToProps)(ApplicationsPopover);