import React from "react"
import { connect } from "react-redux";
import { setCurrentUser } from "../../store/actions";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogContent, FormControl, makeStyles, TextField, Tooltip, Typography } from "@material-ui/core";
import ITaskComment from "../../interfaces/ITaskComment";
import ITaskCommentsProps from "./ITaskCommentsProps";
import DataReadService from "../../services/DataReadService";
import DataWriteService from "../../services/DataWriteService";
import { isEmpty } from '../../helpers/StringHelpers';
import { useSnackbar } from 'notistack';
import { SnackbarVariantTypes } from "../../helpers/enums/enums";
import { Utils } from "../../utilities/utils";
import { LocalDateTimeString } from "../../helpers/DateHelpers";
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import { detailPaneAccordionStyle } from "../../helpers/portalTheme";
import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { GetContextMenuItemsParams, GridApi, MenuItemDef, RowNode, SuppressKeyboardEventParams, ValueGetterParams } from "@ag-grid-enterprise/all-modules";
import { EllipsisButtonActionRenderer } from "../AgGridRenderers/EllipsisButtonActionRenderer";
import { ConfirmDialog } from "../ConfirmDialog/ConfirmDialog";
import { DeleteCommentWarningText } from "../../helpers/Constants";
import MADialogTitle from "../MADialogTitle/MADialogTitle";
import { agGridThemeOverride } from "../../helpers/portalTheme";
import { ExpandMore } from "@material-ui/icons";
import _ from "lodash";
import { CommentCellRenderer } from "../AgGridRenderers/TaskCommentCellRenderer";
import useAsyncFunctionIsLoading from "../../hooks/useIsLoadingAsync";

const errorGettingTaskCommentsText = "There was an error getting the request comments";

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: "1.1rem",
        paddingRight: theme.spacing(2.5),
        minWidth: theme.spacing(54),
    },
    formControl: {
        width: "100%",
        marginBottom: theme.spacing(5),
    },
    button: {
        marginTop: theme.spacing(10),
    },
    commentsSection: {
        backgroundColor: theme.palette.mossadams.gray100,
        "& .MuiInputBase-root": {
            minWidth: "auto",
        },
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        "&& .ag-header": {
            display: "none"
        }
    },
    commentsSectionDialog: {
        height: "100%",
        "&& .ag-header": {
            display: "block"
        }
    },
    commentsHeading: {
        fontSize: "1.3rem",
        flex: 1
    },
    commentsListContainer: {
        overflow: "auto",
        "& > div:nth-child(2n+2)": {
            backgroundColor: "white"
        }
    },
    commentsGrid: {
        flex: 1,
        minHeight: "300px",
        width: "100%",
        ...agGridThemeOverride
    },
    commentLengthCounter: {
        textAlign: "left",
        flex: 1,
        marginLeft: theme.spacing(2)
    },
    commentInputContainer: {
        paddingTop: theme.spacing(3),
    },
    commentInputContainerDialog: {
        padding: theme.spacing(4),
    },
    commentInputText: {
        backgroundColor: theme.palette.background.paper,
        "&& .MuiOutlinedInput-multiline": {
            margin: theme.spacing(0),
        }
    },
    commentInputButtonDiv: {
        display: "flex",
        flexDirection: "row-reverse",
    },
    commentInputButton: {
        width: theme.spacing(90),
        marginTop: 0,
    },
    accordion: {
        ...detailPaneAccordionStyle,
        borderRadius: theme.spacing(1), //Overriding because the background color fights with the border color
    },
    accordionSummary: {
        backgroundColor: theme.palette.mossadams.darkGreen400,
        color: theme.palette.primary.contrastText,
        "&& .MuiIconButton-label": {
            color: theme.palette.primary.contrastText,
        }
    },
    editMessage: {
        flex: 1,
        color: theme.palette.primary.contrastText,
        textAlign: 'right'
    },
    commentDialog: {
        height: '90vh',
        "&& .MuiPaper-root": {
            height: "100%"
        }
    },
    errorText: {
        color: theme.palette.error.main,
    },
}));

export const commentMaxLength: number = 4000;

const TaskComments = (props: ITaskCommentsProps): JSX.Element => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [taskComments, setTaskComments] = React.useState<ITaskComment[]>([]);
    const [newComment, setNewComment] = React.useState("");
    const [selectedComment, setSelectedComment] = React.useState<ITaskComment>();
    const [commentsGridApi, setCommentsGridApi] = React.useState<GridApi>();
    const [deleteConfirmDialogIsOpen, setDeleteConfirmDialogIsOpen] = React.useState<boolean>(false);
    const [commentDialogIsOpen, setCommentDialogIsOpen] = React.useState<boolean>(false);
    const [editNode, setEditNode] = React.useState<RowNode | null>(null);
    const [submitting, setSubmitting] = React.useState(false);
    const [context, setContext] = React.useState({});

    const isEditing = editNode !== null;

    const components = {
        actionsRenderer: EllipsisButtonActionRenderer
    }

    React.useEffect(() => {
        setTaskComments([]);
        getTaskComments();
    }, [props.taskId]);

    React.useEffect(() => {
        const context = {
            editNode,
            submitting,
            commentDialogIsOpen,
            submitEdit: handleEditSubmission,
            endEdit: () => setEditNode(null)
        };

        setContext(context);
    }, [editNode, submitting, commentDialogIsOpen])

    React.useEffect(() => {
        commentsGridApi?.refreshCells({ force: true });
    }, [context])

    function handleCommentChange(e: any) {
        setNewComment(e.target.value);
    }

    function handleAddComment(e: any) {
        let taskComment: ITaskComment =
        {
            taskId: props.taskId || "",
            comment: newComment,
        };
        AddComment(taskComment);
    }

    const commentHasError = (comment: string) => {
        let commentHasError = false;
        if (isEmpty(comment)) {
            enqueueSnackbar("Comment cannot be blank.", { variant: SnackbarVariantTypes.Error });
            commentHasError = true;
        }
        if (comment.length > commentMaxLength) {
            enqueueSnackbar(`Comment must be ${commentMaxLength} characters or less.`, { variant: SnackbarVariantTypes.Error });
            commentHasError = true;
        }

        return commentHasError;
    }

    const { onTrigger: getTaskComments } = useAsyncFunctionIsLoading(async () => {
        if (props.taskId) {
            const dataReadService = new DataReadService();

            const response = await dataReadService.GetTaskComments(props.taskId)
            if (response.status) {
                const newComments = response.data.map((item: ITaskComment) => {
                    return {
                        ...item,
                        userHasAccess: item.userId == props.currentUser?.userId
                    }
                })
                setTaskComments(newComments)
            } else {
                const errors = [errorGettingTaskCommentsText, ...response.errorMessages];
                Utils.enqueueMultiLineSnackbar(errors, enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
            }
        }
    }, props.onLoadingStatusChanged)

    const AddComment = async (taskComment: ITaskComment) => {
        if (taskComment) {
            if (commentHasError(taskComment.comment) || submitting) return;

            setSubmitting(true);
            const dataWriteService = new DataWriteService();
            const result = await dataWriteService.CreateTaskComment(taskComment);
            if (result.status) {
                enqueueSnackbar("Comment saved successfully.", { variant: SnackbarVariantTypes.Success });
                getTaskComments();
                setNewComment("");
                props.onCommentCreated();
            } else {
                const errors = ["Failed to save comment.", ...result.errorMessages];
                Utils.enqueueMultiLineSnackbar(errors, enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
            }
            setSubmitting(false);
        }
    }

    const deleteTaskComment = async (taskComment: ITaskComment) => {
        if (taskComment && taskComment.taskCommentId) {
            const dataWriteService = new DataWriteService();

            const result = await dataWriteService.DeleteTaskComment(taskComment.taskCommentId);
            if (result.status) {
                var comment = taskComments.find(t => t.taskCommentId == taskComment.taskCommentId);
                if (comment) {
                    const filteredDocs = taskComments.filter((t: any) =>
                        t.taskCommentId !== taskComment.taskCommentId
                    );
                    setTaskComments(filteredDocs);
                    commentsGridApi && commentsGridApi.setRowData(filteredDocs);
                    commentsGridApi?.refreshCells();
                    enqueueSnackbar("Comment deleted successfully.", { variant: SnackbarVariantTypes.Success });
                }
            } else {
                const errors = ["Failed to delete task comment.", ...result.errorMessages];
                Utils.enqueueMultiLineSnackbar(errors, enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
            }
        }
    }

    const handleDeleteTaskComment = async (comment: ITaskComment) => {
        setDeleteConfirmDialogIsOpen(false);
        if (selectedComment) {
            await deleteTaskComment(selectedComment);
        }
    }

    const onAttachmentsGridReady = (params: any) => {
        setCommentsGridApi(params.api);
    }

    const handleEditSubmission = async (newComment: string) => {
        if (commentHasError(newComment) || submitting) return;

        setSubmitting(true);
        if (newComment && editNode?.data) {
            let taskComment = _.cloneDeep(taskComments.find(t => t.taskCommentId === editNode.data.taskCommentId));
            if (taskComment) {
                taskComment!.comment = newComment;

                const dataWriteService = new DataWriteService();
                const result = await dataWriteService.UpdateTaskComment(taskComment);
                if (result.status) {
                    enqueueSnackbar("Comment updated successfully.", { variant: SnackbarVariantTypes.Success });
                    if (result.response) {
                        let comment = taskComments.find(t => t.taskCommentId === editNode.data.taskCommentId);
                        if (comment) {
                            comment.comment = result.response.data.comment;
                            comment.updatedDateTime = new Date(Date.now());

                            if (commentsGridApi) {
                                commentsGridApi?.applyTransaction({ update: [...taskComments] });
                                setTaskComments([...taskComments]);
                                commentsGridApi.refreshCells({ force: true });
                            }
                        }
                        setEditNode(null);

                    }
                } else {
                    const errors = ["Failed to update comment.", ...result.errorMessages];
                    Utils.enqueueMultiLineSnackbar(errors, enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
                }
            }
            setSubmitting(false);
        }
    }

    const handleDeleteClick = () => {
        setDeleteConfirmDialogIsOpen(true);
    }

    const handleDeleteConfirmDialogClose = () => {
        setDeleteConfirmDialogIsOpen(false);
    };

    const handleDeleteConfirmDialogConfirm = async () => {
        setDeleteConfirmDialogIsOpen(false);
        if (selectedComment) {
            handleDeleteTaskComment(selectedComment);
        }
    };

    const getContextMenuItems = (params: GetContextMenuItemsParams) => {
        const node: RowNode | null = params.node;

        if (node === null || !params?.node?.data?.userHasAccess) {
            return [];
        }

        const result: (string | MenuItemDef)[] = props.disabled
            ? []
            : [
                {
                    name: "Edit",
                    action: () => {
                        setEditNode(null);
                        setEditNode(node);
                    },
                },
                {
                    name: "Delete",
                    action: () => {
                        setEditNode(null);
                        setSelectedComment(node.data);
                        handleDeleteClick();
                    }
                },
            ];

        const useCopyOptions = params.column?.getColId() === 'comment';
        if (useCopyOptions) {
            result.splice(1, 0, 'copy');
        }

        return result;
    }

    const handleFullScreenClick = () => {
        setCommentDialogIsOpen(true);
        setEditNode(null);
    }

    const handleCommentDialogClose = () => {
        setCommentDialogIsOpen(false);
        setEditNode(null);
    }

    const dateFormatter = (value: Date) => {
        return LocalDateTimeString(value, false);
    }

    const handleSuppressKeyboardEvent = (params: SuppressKeyboardEventParams) => {
        const key = params.event.key;
        const keysToIgnoreWithCtrl = ['c', 'v'];
        const keysToIgnore = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];
        if (params.event.ctrlKey && keysToIgnoreWithCtrl.includes(key)) {
            return true;
        }
        return keysToIgnore.includes(key);
    }

    const commentDetails = <>
        <div className={commentDialogIsOpen ? classes.commentsSection + ' ' + classes.commentsSectionDialog : classes.commentsSection}>
            <div className={"ag-theme-alpine " + classes.formControl + " " + classes.commentsGrid} >
                <AgGridReact
                    rowData={taskComments}
                    onGridReady={onAttachmentsGridReady}
                    getRowId={(row: any) => row.data.taskCommentId}
                    components={components}
                    readOnlyEdit={true}
                    editType=""
                    suppressClickEdit={true}
                    getContextMenuItems={getContextMenuItems}
                    onCellClicked={(params: any) => {
                        if (params.column.colDef.field === '...') {
                            params.api.contextMenuFactory.showMenu(params.node, params.column, params.value, params.event)
                        }
                    }}
                    stopEditingWhenCellsLoseFocus={true}
                    defaultColDef={{
                        editable: false,
                        sortable: true,
                        filter: true,
                        resizable: true,
                        floatingFilter: true,
                        suppressMenu: true,
                        menuTabs: ['generalMenuTab', 'filterMenuTab']
                    }}
                    context={context}
                >
                    {!commentDialogIsOpen &&
                        <AgGridColumn
                            field="comment"
                            colId="comment"
                            flex={1}
                            sortable={false}
                            filter={false}
                            floatingFilter={false}
                            wrapText={true}
                            headerHeight={0}
                            autoHeight={true}
                            cellRenderer={CommentCellRenderer}
                            suppressKeyboardEvent={handleSuppressKeyboardEvent}
                        />}
                    {commentDialogIsOpen &&
                        <AgGridColumn
                            headerName="Name"
                            field="username"
                            filter="agTextColumnFilter"
                        />
                    }
                    {commentDialogIsOpen &&
                        <AgGridColumn
                            headerName="Comment"
                            field="comment"
                            flex={1}
                            cellRenderer={CommentCellRenderer}
                            filter="agTextColumnFilter"
                            editable={false}
                            wrapText={true}
                            autoHeight={true}
                            suppressKeyboardEvent={handleSuppressKeyboardEvent}
                        />
                    }
                    {commentDialogIsOpen &&
                        <AgGridColumn
                            field="commentDateTime"
                            headerName="Created Date"
                            filter="agDateColumnFilter"
                            filterParams={{ defaultOption: 'inRange' }}
                            valueFormatter={(params: ValueGetterParams) => dateFormatter(params.data.commentDateTime)}
                            minWidth={180}
                            getQuickFilterText={(params: any) => {
                                return LocalDateTimeString(params.value, false);
                            }}
                        />
                    }
                    {commentDialogIsOpen &&
                        <AgGridColumn
                            field="updatedDateTime"
                            headerName="Edited Date"
                            filter="agDateColumnFilter"
                            filterParams={{ defaultOption: 'inRange' }}
                            valueFormatter={(params: ValueGetterParams) => dateFormatter(params.data.updatedDateTime)}
                            minWidth={180}
                            getQuickFilterText={(params: any) => {
                                return LocalDateTimeString(params.value, false);
                            }}
                        />
                    }

                    <AgGridColumn
                        headerName=""
                        field="..."
                        cellRenderer="actionsRenderer"
                        editable={false}
                        filter={false}
                        width={35}
                        hide={props.disabled}
                        suppressMenu={true}
                    />
                </AgGridReact>
            </div>
            <div className={commentDialogIsOpen ? classes.commentInputContainerDialog : classes.commentInputContainer}>
                <FormControl className={classes.formControl}>
                    <TextField
                        className={classes.commentInputText}
                        name="Comment"
                        multiline
                        variant="outlined"
                        value={newComment}
                        onChange={handleCommentChange}
                        placeholder={"Comment Here"}
                        disabled={props.disabled}
                    />
                </FormControl>
                <div className={classes.commentInputButtonDiv}>
                    <Button className={`${classes.button} ${classes.commentInputButton}`} variant="contained" color="primary"
                        onClick={handleAddComment}
                        disabled={props.disabled || isEditing || submitting}>
                        Add Comment
                    </Button>
                    <Typography className={classes.commentLengthCounter + ` ${newComment.length > commentMaxLength ? classes.errorText : ''}`}>
                        {newComment.length}/{commentMaxLength}
                    </Typography>
                </div>
            </div>
        </div>
    </>


    return (
        <>
            {commentDialogIsOpen ?
                <Dialog open={commentDialogIsOpen} aria-labelledby="comments-dialog" fullWidth={true} maxWidth={"lg"} className={classes.commentDialog}  >
                    <MADialogTitle id="upload-file-dialog-title" onClose={handleCommentDialogClose}>
                        Comments - {props.taskNumber} - {props.taskTitle}
                    </MADialogTitle>
                    <DialogContent>
                        {commentDetails}
                    </DialogContent>
                </Dialog>
                :
                <Accordion defaultExpanded className={classes.accordion} disabled={props.disabled}>
                    <AccordionSummary
                        className={classes.accordionSummary}
                        expandIcon={<ExpandMore />}
                        aria-controls="TaskComments-content"
                        id="TaskComments-header"
                    >
                        <Typography className={`${classes.label} ${classes.commentsHeading}`}>Comments:</Typography>
                        {isEditing && <Box mr={4} alignSelf={'center'} className={classes.editMessage}>Press enter to save</Box>}
                        <Tooltip title='Expand View'>
                            <Button onClick={handleFullScreenClick} variant="contained" color="primary">
                                <ZoomOutMapIcon />
                            </Button>
                        </Tooltip>
                    </AccordionSummary>
                    <AccordionDetails>
                        {commentDetails}
                    </AccordionDetails>
                </Accordion>
            }
            <ConfirmDialog
                isOpen={deleteConfirmDialogIsOpen}
                onClose={handleDeleteConfirmDialogClose}
                onConfirm={handleDeleteConfirmDialogConfirm}
                title="Delete Comment"
                children={<Typography>{DeleteCommentWarningText}</Typography>}
            />
        </>
    );

}

const mapStateToProps = (state: any) => {
    return ({
        currentUser: state.currentUser,
    })
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setCurrentUser: (data: any) => dispatch(setCurrentUser(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TaskComments);

