import React from "react";
import { MenuItem } from '@material-ui/core';
import ILookup from "../interfaces/ILookup";
import IUser from "../interfaces/IUser";

export const renderOptions = (list: ILookup[]) => {
    return list.map((item, i) => {
        return (
            <MenuItem
                key={item.id}
                value={item.description}>
                {item.description}
            </MenuItem>
        );
    });
}

export const renderOptionsIdValue = (list: ILookup[]) => {
    return list.map((item, i) => {
        return (
            <MenuItem
                key={item.id}
                value={item.id}>
                {item.description}
            </MenuItem>
        );
    });
}

export const renderUsers = (list: IUser[]) => {
    return list.filter(user => user.isActive).map((item) => {
        return (
            <MenuItem
                key={item.userId}
                value={item.displayName}>
                {item.displayName}
            </MenuItem>
        );
    });
}

export const getLookupKey = (list: ILookup[], name: string | null) => {
    const key = Boolean(name) ? list.find(i => i.description == name)?.id : null;
    return nullIfUndefined(key);
}

export const getLookupValue = (list: ILookup[], id: number) => {
    return nullIfUndefined(list.find(i => i.id == id)?.description);
}

export const getUserKey = (list: IUser[], name: string | null) => {
    const key = Boolean(name) ? list.find(i => i.displayName == name)!.userId : null;
    return key;
}

export const getUserName = (list: IUser[], userId: string) => {
    return list.find(i => i.userId == userId)!.displayName;
}

export const getUserNameAllowNull = (list: IUser[], userId: string) => {
    return nullIfUndefined(list.find(i => i.userId == userId)?.displayName);
}

const nullIfUndefined = (val: any) => {
    if(typeof(val) === undefined) return null;
    return val;
}