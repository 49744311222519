import React from 'react';
import { Typography, Theme, createStyles, withStyles, WithStyles } from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: "24px",
        },
        title: {
            textTransform: "none",
            marginTop: "-8px"
        },
        closeButton: {
            position: 'absolute',
            padding: 0,
            right: "25px",
            top: "25px",
            height: "16px",
            width: "16px",
            color: theme.palette.primary.main,
            "& svg": {
                fontWeight: 700,
                fontSize: "1.6rem"
            }
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}


const MADialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h5" className={classes.title}>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default MADialogTitle;