import { createTheme, Theme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import createSpacing from "@material-ui/core/styles/createSpacing";
import type { } from '@material-ui/lab/themeAugmentation'; // Enables mui/lab components to have css overrides.

// Material-UI Theme Overrides Leveraging Theme Palette Colors https://stackoverflow.com/a/57632144
const portalTheme: Theme = createTheme();

// Material UI's default spacing is 8 pixels, but we want to be able to
// better fine-tune spacing so changing the default spacing to 2 pixels.
portalTheme.spacing = createSpacing(2);

portalTheme.palette = {
    ...portalTheme.palette,
    common: {
        ...portalTheme.palette.common,
    },
    signOff: {
        gray: "#E8E8EB",
        orange: "#FA972D",
        green: "#67C60B",
    },
    mossadams: {
        warmGray100: "#FAF9F8",
        warmGray200: "#F0EEEA",
        warmGray300: "#E7E3DD",
        warmGray400: "#DDD8CF",
        warmGray500: "#D3CDC1",
        warmGray600: "#CFC7BA",
        teal100: "#F7FAF9",
        teal200: "#E7F0EE",
        teal300: "#D6E6E2",
        teal400: "#C6DBD7",
        teal500: "#C6DBD7",
        teal600: "#ADCCC6",
        brightGreen100: "#F6FEEB",
        brightGreen200: "#E3FCC2",
        brightGreen300: "#D1F99A",
        brightGreen400: "#BEF771",
        brightGreen500: "#ABF548",
        brightGreen600: "#A2F434",
        brightGreen700: "#5CB21A",
        darkGreen100: "#E7EBEB",
        darkGreen200: "#B7C3C3",
        darkGreen300: "#869C9B",
        darkGreen400: "#567473",
        darkGreen500: "#264C4B",
        darkGreen600: "#0E3837",
        gray100: "#F0F0F0",
        gray200: "#C0C0C0",
        gray300: "#979797",
        gray400: "#6D6D6D",
        gray500: "#454545",
        gray600: "#303030",
        gray700: "rgba(0, 0, 0, 0.87)",
        orange: "#EF730B",
        red100: "rgba(255,82,82, 0.25)",
        red200: "rgba(255,82,82, 0.5)",
        selectedRow: "#CEE0DD",
        everyOtherRowColor: "#F5F8F8",
        divider: "#707070",
        border: "#C4C4C4",
    },
    primary: {
        main: "#0E3837",
        light: "#264C4B",
        dark: "#000000",
        contrastText: "#FFFFFF",
    },
    secondary: {
        main: "#A2F434",
        light: "#BEF771",
        dark: "#A2F434",
        contrastText: "#FFFFFF",
    },
    background: {
        paper: "#FFFFFF",
        default: "#FFFFFF"
    }
};

export const detailPaneTextStyle = {
    fontFamily: "Founders Grotesk Reg",
    fontSize: "1.1rem",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em"
}

portalTheme.overrides = {
    ...portalTheme.overrides,
    MuiChip: {
        root: {
            backgroundColor: grey[700],
            fontWeight: "bold",
        },
        label: {
            color: portalTheme.palette.common.white,
            paddingLeft: portalTheme.spacing(5),
            paddingRight: portalTheme.spacing(5),
        },
    },
    MuiDialogActions: {
        root: {
            padding: "20px"
        }
    },
    MuiLink: {
        root: {
            cursor: "pointer",
        }
    },
    MuiSvgIcon: {
        root: {
            "&.MuiSelect-icon.Mui-disabled": {
                display: "none"
            },
        },
        colorPrimary: {
            color: portalTheme.palette.primary.main,
        },
        colorSecondary: {
            color: portalTheme.palette.secondary.main,
        }
    },
    MuiCheckbox: {
        colorPrimary: {
            color: portalTheme.palette.primary.main,
            "&.Mui-checked": {
                color: portalTheme.palette.primary.main
            }
        },
        colorSecondary: {
            color: portalTheme.palette.mossadams.orange,
            "&.Mui-checked": {
                color: portalTheme.palette.mossadams.orange
            }
        }
    },
    MuiInputBase: {
        root: {}
    },
    MuiFormControl: {
        root: {}
    },
    MuiFormLabel: {
        root: {
            color: portalTheme.palette.common.black,
            fontFamily: "Founders Grotesk Reg",
        }
    },
    MuiInputLabel: {
        root: {},
        shrink: {
            color: portalTheme.palette.primary.main,
            fontSize: ".9rem",
            textTransform: "uppercase",
            fontWeight: "bold"
        }
    },
    MuiPaper: {
        root: {}
    },
    MuiDialogContent: {
        root: {
            fontSize: "1.2rem",
            "& p": {
                fontSize: "1.2rem"
            },
        }
    },
    MuiFormHelperText: {
        root: {
            fontSize: "0.75rem !important"
        }
    },
    MuiRadio: {
        colorPrimary: {
            color: portalTheme.palette.primary.main,
        },
        colorSecondary: {
            color: portalTheme.palette.secondary.main
        }
    },
    MuiButton: {
        root: {
            cursor: "pointer",
            paddingLeft: portalTheme.spacing(8),
            paddingRight: portalTheme.spacing(8),
            paddingTop: portalTheme.spacing(3),
            paddingBottom: portalTheme.spacing(3),
        },
        label: {
            color: portalTheme.palette.primary.main,
            fontFamily: "Founders Grotesk Reg",
            letterSpacing: "2px",
            fontSize: "1.2em",
            fontWeight: 500,
            cursor: "pointer"
        },
        containedPrimary: {
            backgroundColor: portalTheme.palette.primary.main,
            "& .MuiButton-label": {
                color: portalTheme.palette.common.white,
            },
            "&:hover": {
                backgroundColor: portalTheme.palette.primary.main,
            }
        },
        outlined: {
            borderRadius: 0,
            borderWidth: "3px !important",

        },
        disabled: {
            "& span": {
                color: portalTheme.palette.grey[400],
                pointerEvents: "auto",
            }
        },
        outlinedPrimary: {
            borderWidth: "3px",
            borderColor: portalTheme.palette.secondary.main,
            "&:hover": {
                backgroundColor: portalTheme.palette.secondary.main,
                borderColor: portalTheme.palette.secondary.main
            },
            "&:hover:disabled": {
                color: portalTheme.palette.grey[400],
                borderColor: portalTheme.palette.grey[400],
            },
        },
    },
    MuiButtonBase: {
        root: {
            "&.MuiPickersDay-daySelected": {
                color: portalTheme.palette.common.white
            }
        }
    },
    MuiListItem: {
        selected: {
            "& .MuiListItemText-primary": {
            }
        }
    },
    MuiListItemIcon: {
        root: {
            color: portalTheme.palette.primary.main,
        }
    },
    MuiListItemText: {
        primary: {
            color: portalTheme.palette.mossadams.darkGreen400,
            fontSize: "0.86rem",
            letterSpacing: "0.069rem",
        }
    },
    MuiAccordionSummary: {
        root: {
            maxHeight: "2.625rem",
            "&.Mui-expanded": {
                minHeight: "2.625rem"
            }
        },
        content: {
            margin: "0rem",
            "&.Mui-expanded": {
                margin: "0rem"
            }
        }

    },
    MuiAccordionDetails: {
        root: {
            padding: "0rem"
        }
    },
    MuiAutocomplete: {
        root: {
            ...detailPaneTextStyle
        },
        option: {
            ...detailPaneTextStyle
        }
    },
    MuiSelect: {
        root: {
            ...detailPaneTextStyle,
            fontSize: '1rem',
            padding: '0.2rem',
        }
    },
    MuiMenuItem: {
        root: {
            ...detailPaneTextStyle,
            fontSize: '1rem',
            "&$selected": {
                backgroundColor: portalTheme.palette.primary.main,
                color: 'white',
                "&:hover": {
                    backgroundColor: portalTheme.palette.mossadams.darkGreen500
                }
            },
            '&:hover': {
                backgroundColor: portalTheme.palette.mossadams.warmGray200
            }
        }
    },
    MuiTooltip: {
        tooltip: {
            fontSize: "0.8rem",
            backgroundColor: portalTheme.palette.primary.main,
        }
    },
    MuiTypography: {
        h1: {
            fontFamily: "Founders Grotesk Cond Med",
            color: portalTheme.palette.primary.main,
            textTransform: "uppercase",
            letterSpacing: "2px"
        },
        h2: {
            fontFamily: "Founders Grotesk Cond Med",
            color: portalTheme.palette.primary.main,
            textTransform: "uppercase",
            letterSpacing: "2px"
        },
        h3: {
            fontFamily: "Founders Grotesk Cond Med",
            color: portalTheme.palette.primary.main,
            textTransform: "uppercase",
            letterSpacing: "2px",
            fontSize: "2rem"
        },
        h4: {
            fontFamily: "Founders Grotesk Cond Med",
            color: portalTheme.palette.primary.main,
            //textTransform: "uppercase",
            //letterSpacing: "2px"
        },
        h5: {
            fontFamily: "Founders Grotesk Cond Med",
            color: portalTheme.palette.primary.main,
            //textTransform: "uppercase",
            //letterSpacing: "2px"
        },
        h6: {
            fontFamily: "Founders Grotesk Cond Med",
            //textTransform: "uppercase",
            //letterSpacing: "2px",
            color: grey[800],
        },
        subtitle1: {
            fontFamily: "Founders Grotesk Reg",
            color: portalTheme.palette.primary.dark,
            opacity: "90%",
        },
        subtitle2: {
            fontFamily: "Founders Grotesk Reg",
            color: portalTheme.palette.primary.main,
        },
        body1: {
            fontFamily: "Founders Grotesk Reg",
        },
        body2: {
            fontFamily: "Founders Grotesk Reg",
        }
    },
};

export const border = {
    border: `solid ${portalTheme.spacing(0.5)}px ${portalTheme.palette.mossadams.border}`,
    borderRadius: portalTheme.spacing(2)
}

export const detailPanePadding = {
    padding: portalTheme.spacing(4),
}

export const detailPaneAccordionStyle = {
    boxShadow: "none",
    ...border,
}

export const detailPaneAccordionHeaderStyle = {
    fontSize: "1.3rem",
    // fontWeight: portalTheme.typography.fontWeightRegular //todo
}

export const marginAroundDescription = {
    "& .MuiOutlinedInput-multiline": {
        marginLeft: portalTheme.spacing(2.5),
        marginRight: portalTheme.spacing(2.5),
    }
}

export const detailsPaneLastUpdatedStyle = {
    "& div, & span": {
        ...detailPaneTextStyle,
        fontSize: "0.9rem",
        paddingRight: portalTheme.spacing(2.5),
        minWidth: portalTheme.spacing(65)
    }
}

export const boxShadow = { boxShadow: "0px 1px 5px rgba(0, 0, 0, .3)" }

export const fullWindowHeight = { height: `calc(100vh - ${portalTheme.spacing(104)}px)` }

export const actionButtonStyle = {
    flex: .25,
    width: "100%",
    paddingTop: portalTheme.spacing(4),
    paddingBottom: portalTheme.spacing(4),
    "& .MuiButton-startIcon": {
        color: portalTheme.palette.primary.main,
    },
    "& .MuiButton-root": {
        width: "100%",
    },
    "& .MuiButton-label": {
        textTransform: "none",
        color: portalTheme.palette.common.black,
        letterSpacing: 0
    },
    "& .MuiButton-iconSizeMedium > *:first-child": {
        fontSize: "2rem"
    },
    "&.Mui-disabled .MuiButton-startIcon": {
        color: portalTheme.palette.mossadams.gray300
    }
}

export const actionButtonVariant = {
    ...actionButtonStyle,
    "& .MuiButton-startIcon": {
        color: portalTheme.palette.primary.contrastText,
    },
    "&.Mui-disabled .MuiButton-startIcon": {
        color: portalTheme.palette.mossadams.gray300
    }
}

export const agGridThemeOverride = {
    "& .ag-row-hover": {
        backgroundColor: portalTheme.palette.mossadams.gray100,
    },
    "& .ag-row-group .ag-group-value": {
        width: "100%",
    },
    "&& .ag-icon": {
        color: portalTheme.palette.mossadams.darkGreen400
    },
    "&& .ag-header-cell-text, && .ag-header-cell-label": {
        fontFamily: "Founders Grotesk Med",
        color: portalTheme.palette.mossadams.darkGreen400,
        letterSpacing: "0.72px",
        fontSize: ".75rem",
        textTransform: "uppercase",
        overflow: "visible",
        whiteSpace: "normal",
        textAlign: "center",
        fontWeight: "normal"
    },
    "& .ag-theme-alpine": {
        "--ag-alpine-active-color": portalTheme.palette.primary.main, //changes checkbox and other theme colors
        //according to ag-grid docs, alpine-active-color should have changed focus colors, but it did not
        "--ag-input-focus-border-color": portalTheme.palette.primary.main,
        //"--ag-input-focus-box-shadow": "0 0 2px 0.1rem rgba(14, 56, 55, 0.4)",
        //the commented out setting was not working, so below does the same thing, preserved for future reference
        "& input[class^='ag-']:focus": {
            boxShadow: "0 0 2px 0.1rem rgba(14, 56, 55, 0.4)",
        },
        "& .ag-row-selected": {
            backgroundColor: portalTheme.palette.mossadams.selectedRow,
            fontWeight: 'bold',
        },
        "& .ag-root-wrapper": {
            border: "none"
        },
        "& .ag-header": {
            backgroundColor: portalTheme.palette.mossadams.darkGreen100
        },
        "& .MuiInputBase-input": {
            fontFamily: "Founders Grotesk Reg",
            fontSize: ".88rem"
        },
        "& .ag-root .ag-row .ag-cell-inline-editing, .ag-root .ag-row .ag-cell-inline-editing .ag-react-container input:focus, .ag-root .ag-row .ag-cell-inline-editing .ag-react-container input:active": {
            border: "none !important",
            outline: "none !important"
        },
        "&.ag-popup div.ag-list.ag-select-list": {
            minWidth: "160px !important"
        },
        "& .ag-layout-auto-height .ag-center-cols-clipper, .ag-layout-auto-height .ag-center-cols-container, .ag-layout-print .ag-center-cols-clipper, .ag-layout-print .ag-center-cols-container": {
            minHeight: "0px"
        },
        fontFamily: "Founders Grotesk Reg",
    },
    // The following themes are used by getRowClass to color rows bases on state
    complete: {
        "&&.ag-row": {
            color: portalTheme.palette.mossadams.gray300
        }
    },
    deleted: {
        "&&.ag-row": {
            color: portalTheme.palette.mossadams.gray400,
            fontStyle: "italic"
        }
    },
    highPriority: {
        "&&.ag-row": {
            backgroundColor: portalTheme.palette.mossadams.red100,
            "& .ag-row-selected": {
                backgroundColor: portalTheme.palette.mossadams.red200,
            },
        },
        "&&.ag-row.ag-row-selected": {
            backgroundColor: portalTheme.palette.mossadams.red200,
        },
    },

    validationError: {
        "&&.ag-cell": {
            borderColor: 'red'
        }
    },
    draft: {
        color: "red"
    }
}

export default portalTheme;
