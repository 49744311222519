import { makeStyles } from '@material-ui/core';
import React from 'react';
import { boxShadow } from '../../helpers/portalTheme';


const useStyles = makeStyles((theme) => ({

    popup: {
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.dark,
        padding: theme.spacing(4),
        fontSize: '0.88rem',
        fontFamily: "Founders Grotesk Reg",
        width: 'max-content',
        maxWidth: '33vw',
        maxHeight: '60vh',
        whiteSpace: 'break-spaces',
        border: '1px solid black',
        filter: `drop-shadow(${boxShadow.boxShadow})`,
    },

}));

const replaceMultiLineBreaks = (text: string) => {
    return text.replace(/(\r\n|\r|\n){2,}/g, '$1\n'); // replace multiple line breaks with a single line break.
}

export const RequestDescriptionTooltip = (params: any) => {
    const classes = useStyles();

    let text = params.value.substring(0, 501);
    text = replaceMultiLineBreaks(text);
    const truncatedText = '...\n\n Please click the request to view the full description.';

    return <pre className={classes.popup}>
        {text}{text.length === params.value.length ? '' : <strong>{truncatedText}</strong>}
    </pre>
}

export const RequestNotesTooltip = (params: any) => {
    const classes = useStyles();
    let text = replaceMultiLineBreaks(params.value);
    return <pre className={classes.popup}>
        {text}
    </pre>
    
}