import { Tooltip, makeStyles } from '@material-ui/core'
import { Warning } from '@material-ui/icons'
import React from 'react'
import { DateTime } from 'luxon';
import { LocalDateTimeString } from '../../helpers/DateHelpers';
import { useSelector } from 'react-redux';
import ICurrentUser from '../../interfaces/ICurrentUser';

interface Props {
    date: Date
}

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.mossadams.orange,
        height: '22px',
        width: 'auto',
        verticalAlign: 'middle',
    },
}));

export default function ExpirationWarningIcon({date}: Props) {
    const classes = useStyles();
    const currentUser = useSelector((state: any) => state.currentUser as ICurrentUser);

    if (!currentUser.isMossAdamsStaff) {
        return null;
    }

    const tooltip = `Expires on ${LocalDateTimeString(date, false)}`;

    const isDocumentExpiringSoon = () => {
        return DateTime.fromJSDate(date).startOf('day') <= DateTime.now().plus({days: 7}).endOf('day');
    }

    return (isDocumentExpiringSoon() ?
        <Tooltip title={tooltip}>
            <Warning className={classes.icon} />
        </Tooltip>
        : null
    )
}

