import React, { Dispatch, SetStateAction } from 'react';
import { AppBar, Tabs, Tab, Button, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    tabBarContainer: {
        display: 'flex',
        minWidth: '0',
    },
    tabBar: {
        backgroundColor: theme.palette.mossadams.darkGreen400,
        width: "100%",
        boxShadow: 'none',
        flexShrink: 1,
        '& .MuiTabs-flexContainer': {
            width: 0,
        },
    },
    tab: {
        '&.MuiTab-root': {
            maxWidth: '500px',
            minWidth: '0',
            maxHeight: '110px',
        },

        '& .MuiTab-wrapper': {
            whiteSpace: 'nowrap',
            minWidth: '0',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: 'inline-block',
        },

        '&.Mui-selected': {
            backgroundColor: theme.palette.mossadams.darkGreen500,
            fontWeight: 'bold',
        },
    },
    engagementToggleButton: {
        backgroundColor: theme.palette.mossadams.darkGreen400,
        borderRadius: 0,
        color: theme.palette.primary.contrastText,
        padding: 0,
        boxShadow: 'none',
        '&.MuiButton-root': {
            '&:hover': {
                backgroundColor: theme.palette.mossadams.darkGreen400,
            }
        }
    },
}))

interface IClientTabBarProps {
    show: boolean;
    expand: boolean;
    clientNames: string[];
    selectedTabIndex: number;
    setSelectedTabIndex: Dispatch<SetStateAction<number>>;
    onClickExpand: () => void;
}


export const ClientTabBar = (props: IClientTabBarProps): JSX.Element | null => {
    const { show, clientNames, selectedTabIndex, expand, setSelectedTabIndex, onClickExpand } = props;
    const classes = useStyles();

    return show ?
        <div className={classes.tabBarContainer}>
            <AppBar className={classes.tabBar} position='static'>
                <Tabs value={selectedTabIndex}
                    onChange={(e, val) => setSelectedTabIndex(val)}
                    variant='scrollable'
                    scrollButtons='auto'
                >
                    {
                        clientNames.map((name, index) => {
                            return <Tooltip title={name} key={name} enterDelay={500}>
                                <Tab id={`grid-tab-${index}`} className={classes.tab} label={name} value={index} />
                            </Tooltip>
                        })
                    }
                </Tabs>
            </AppBar>
            {!clientNames.length ? null :
                <Button size='small' className={classes.engagementToggleButton} onClick={onClickExpand} variant='contained'>
                    <Typography className={classes.engagementToggleButton}>
                        {`${expand ? 'Expand' : 'Collapse'} All Engagements`}
                    </Typography>
                </Button>
            }
        </div> : null
}