import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { FormControlLabel, TextField, Chip } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import IMultiUserSelectorProps from "./IMultiUserSelectorProps";
import IUser from "../../interfaces/IUser";
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        paddingRight: theme.spacing(2.5),
        marginLeft: theme.spacing(6),
        position: "relative",
        "&& .MuiTypography-body1": {
            fontSize: "1.1rem",
            minWidth: theme.spacing(65),
        },
        "&& .MuiAutocomplete-root": {
            flex: 1,
            marginRight: theme.spacing(6)
        },
        "&& .MuiInputBase-fullWidth": {
            maxWidth: '300px'
        },
        '& .MuiAutocomplete-endAdornment': {
            top: 'auto',
        },
    },
    input: {
        fontSize: "0.9rem",
        "& input": {
            fontSize: "0.9rem",
        },
        "& .MuiSelect-select.MuiSelect-select": {
            paddingTop: theme.spacing(5.5),
        },
        "& .MuiAutocomplete-inputRoot[class*=\"MuiInput-root\"] .MuiAutocomplete-input:first-child": {
            paddingTop: theme.spacing(5)
        },
        "& .MuiAutocomplete-popupIndicator": {
            marginRight: 0,
            padding: theme.spacing(0.5),
            marginLeft: theme.spacing(0.5),
        },
        "& .MuiIconButton-root": {
            padding: theme.spacing(0.5),
        },
    },
    chip: {
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(1),
        height: '1.6rem',
        fontWeight: 'normal',
        "& .MuiChip-deleteIcon": {
            opacity: 0.4,
            color: theme.palette.primary.contrastText,
            '&:hover': {
                opacity: 0.8,
            },
        },
        "&.clientUser": {
            backgroundColor: theme.palette.mossadams.darkGreen400,
        },

    },
    option: {
        padding: 0,
        fontSize: theme.typography.fontSize,
    },
}))

const MultiUserSelector = (props: IMultiUserSelectorProps): JSX.Element => {
    const classes = useStyles();
    const [selectedUsers, setSelectedUsers] = useState<IUser[]>(props.selectedUsers ? props.selectedUsers : []);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const selectAllUserOption: IUser = { userId: '0', displayName: 'Select All', isMossAdamsStaff: false, isActive: false };

    React.useEffect(() => {
        setSelectedUsers(props.selectedUsers);
    }, [props.selectedUsers]);

    const handleOnChange = (event: React.ChangeEvent<{}>, value: IUser[], reason: any) => {
        if (props.selectAll && value.includes(selectAllUserOption)) {
            props.onSelectedUsersChange(event, props.userList, reason);
        }
        else {
            props.onSelectedUsersChange(event, value, reason);
        }
    }

    const isOptionDisabled = (option: IUser) => {
        if (_.isEqual(option, selectAllUserOption)) {
            return _.isEqual(selectedUsers.map(u => u.userId).sort(), props.userList.map(u => u.userId).sort());
        }
        else {
            return selectedUsers.some(user => user?.userId === option?.userId);
        }
    }

    return (
        <>
            <FormControlLabel
                className={classes.root}
                disabled={props.disabled}
                control={
                    <Autocomplete
                        multiple
                        disableCloseOnSelect
                        disableClearable={isDropdownOpen}
                        onOpen={() => setIsDropdownOpen(true)}
                        onClose={() => setIsDropdownOpen(false)}
                        id={props.name}
                        disabled={props.disabled}
                        options={props.selectAll ? [selectAllUserOption, ...props.userList] : props.userList}
                        disabledItemsFocusable={false}
                        getOptionDisabled={isOptionDisabled}
                        getOptionLabel={user => user!.displayName!}
                        clearOnBlur={false}
                        onBlur={props.onBlur}
                        onChange={props.disabled ? () => { } : handleOnChange}
                        value={selectedUsers}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    key={`${option.userId}`}
                                    label={option?.displayName}
                                    {...getTagProps({ index })}
                                    className={`${classes.chip}${option.isMossAdamsStaff ? '' : ' clientUser'}`}
                                />
                            ))
                        }
                        renderOption={(option, { selected }) => {
                            const isSelectAllOption = option.userId === '0';
                            const optionStyle = isSelectAllOption
                                ? { fontWeight: 'bold' }
                                : {};

                            return (
                                <div style={optionStyle}>
                                    {option.displayName}
                                </div>
                            );
                        }}
                        renderInput={params => (
                            <TextField
                                className={classes.input}
                                {...params}
                                name="selectedUsers"
                            />
                        )}
                    />
                }
                label={props.label}
                labelPlacement={"start"}
            />
        </>
    )
}

export default MultiUserSelector;