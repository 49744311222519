import React from 'react'
import IPlatformApiPostResult from '../../interfaces/IPlatformApiPostResult';

export interface FileUploadContext {
    files: File[];
    handleFileChange: (files: File[]) => void;
    handleRemoveFile: (file: File) => void;
    uploadFiles: () => Promise<void>;
    uploadRestrictedFiles: (permittedUsers: string[]) => Promise<void>;
    uploadNewVersion: () => Promise<void>;
    uploadWithRenameOnCollision: () => Promise<void>;
    fileNameCollisions: File[];
    setUploadSuccessCallback: (callback: (result: IPlatformApiPostResult) => void) => void;
    setUploadFailureCallback: (callback: (result: IPlatformApiPostResult) => void) => void;
    uploadingBackdrop: JSX.Element;
}

export const FileUploadContext = React.createContext<FileUploadContext | null>(null);

export const useFileUploadContext = () => {
    const context = React.useContext(FileUploadContext);

    if (context === null) {
        throw new Error('useFileUploadContext must be used with a FileUploadContext.Provider');
    }

    return context;
}