import { ColumnApi, ColumnState, GridApi } from '@ag-grid-enterprise/all-modules'
import React from 'react'
import { Utils } from '../utilities/utils';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';


/**
 * A hook which allows preset AG Grid layouts to be managed and applied to the Grid associated with passed paramaters.
 * 
 * Each unique grid which implements this feature should have a distinct storageKey (unless sharing views between grids is possible and desired). 
 * Storage keys should be saved within {@link SavedViewKeys}
 * 
 * Typically will be used with {@link SavedViewModal} component.
 * 
 * @param gridApi
 * @param columnApi 
 * @param storageKey 
 * @returns {ISavedViewControls}
 */
export const useSavedView = (gridApi: GridApi | undefined, columnApi: ColumnApi | undefined, storageKey: string, onLoadView?: () => void): ISavedViewControls => {
    const [savedViews, setSavedViews] = React.useState<ISavedView[]>([]);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const viewBaseKey = `${storageKey}-view`
    const getLastUsedKey = () => `${storageKey}-last-used`;
    const getViewStorageKey = (id: string) => `${viewBaseKey}-${id}`;

    const maxViewsPerKey = 5;

    React.useEffect(() => {
        getSavedViewOptions();
    }, [viewBaseKey])


    React.useEffect(() => {
        if (gridApi && columnApi) {
            closeSnackbar();
            loadLastUsedView();
        }
    }, [gridApi, columnApi, viewBaseKey])

    const viewNameIsValid = (id: string) => {
        return (id.length && !savedViews.some(view => view.id === id));
    }

    const maxViewsReached = () => {
        return savedViews.length === maxViewsPerKey;
    }

    const saveView = (id: string) => {
        if (maxViewsReached()) {
            console.error(`Unable to save more than ${maxViewsPerKey} views for this key`);
            return;
        }

        if (gridApi && columnApi) {
            const view: ISavedView = {
                id: id,
                columnState: columnApi.getColumnState(),
                filterModel: gridApi.getFilterModel(),
                order: columnApi.getAllDisplayedColumns().map(col => col.getColId()),
            }

            saveViewToStorage(view);
        }
    }

    const saveViewToStorage = (view: ISavedView, newView = true) => {
        const key = getViewStorageKey(view.id);
        if (viewNameIsValid(view.id)){
            Utils.saveItemToLocalStorage(key, JSON.stringify(view));
        }

        if (localStorage.getItem(key) !== null && newView) {
            setSavedViews((prevValues) => [
                ...prevValues,
                view
            ]);
            saveLastUsedView(view);
            enqueueSnackbar("New grid layout saved", { variant: 'success' });
        }
        else if (newView) {
            enqueueSnackbar("Unable to add grid layout", { variant: 'error' });
        }
    }

    const loadView = (id: string, toastMessage?: string) => {
        const key = getViewStorageKey(id);

        const dataString = localStorage.getItem(key);
        if (dataString && gridApi && columnApi?.getAllColumns()) {
            const view: ISavedView = JSON.parse(dataString);
            columnApi.applyColumnState({ state: [...view.columnState] });
            gridApi.setFilterModel(view.filterModel);
            view.order.forEach((colId, index) => columnApi.moveColumn(colId, index));
            gridApi.refreshCells();
            onLoadView && onLoadView();

            saveLastUsedView(view);
            enqueueSnackbar(toastMessage || `Grid layout "${id}" was applied`, { variant: 'info' });
        }
    };

    const deleteView = (id: string, showDeleteToast = true) => {
        localStorage.removeItem(getViewStorageKey(id));
        setSavedViews(prevValues => prevValues.filter(view => view.id !== id));
        showDeleteToast && enqueueSnackbar("Grid layout deleted", { variant: 'success' });
    }

    const renameView = (id: string, newId: string) => {
        const key = getViewStorageKey(id);

        const dataString = localStorage.getItem(key);
        if (dataString) {
            const prevViews = cloneDeep(savedViews);

            const view: ISavedView = JSON.parse(dataString);
            const viewIndex = savedViews.findIndex(view => view.id === id);
            view.id = newId;
            deleteView(id, false);
            saveViewToStorage(view, false);

            prevViews[viewIndex] = view; 
            setSavedViews(prevViews);
        }
    }

    const saveLastUsedView = (view: ISavedView) => {
        Utils.saveItemToLocalStorage(getLastUsedKey(), view.id);
    }

    const loadLastUsedView = () => {
        const key = getLastUsedKey();
        const id = localStorage.getItem(key);

        if (id) {
            loadView(id);
        }
    }

    const getSavedViewOptions = () => {
        if (!savedViews.length) {
            const newSavedViews: ISavedView[] = [];

            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                if (key?.startsWith(viewBaseKey)) {
                    const view = JSON.parse(localStorage.getItem(key)!);
                    newSavedViews.push(view);
                }
            }

            setSavedViews(newSavedViews);
            return newSavedViews;
        }

        return savedViews
    }

    React.useEffect(() => {
        const handleViewChanges = (e: StorageEvent) => {
            if (e.key && e.key.startsWith(viewBaseKey)) {
                if (e.newValue) {
                    try {
                        const newView: ISavedView = JSON.parse(e.newValue);
                        setSavedViews((prevValues) => [
                            ...prevValues,
                            newView
                        ])
                    }
                    catch (error) {
                        console.error("Unable to parse saved layout.", error);
                    }
                }
                else if (e.oldValue) {
                    try {
                        const removedView: ISavedView = JSON.parse(e.oldValue);
                        setSavedViews(prevValues => prevValues.filter(view => view.id !== removedView.id))
                    }
                    catch (error) {
                        console.error("Unable to parse removed layout.", error);
                    }
                }
            }
        };

        window.addEventListener('storage', handleViewChanges);

        return () => {
            window.removeEventListener('storage', handleViewChanges);
        }
    }, [viewBaseKey])

    return { 
        saveView, 
        loadView, 
        deleteView, 
        renameView, 
        maxViewsReached, 
        viewBaseKey, 
        savedViews 
    };
}

export interface ISavedView {
    id: string;
    filterModel: {
        [key: string]: any;
    };
    columnState: ColumnState[];
    order: string[];
}

export interface ISavedViewControls {
    saveView: Function;
    loadView: Function;
    deleteView: Function;
    renameView: Function;
    maxViewsReached: Function;
    viewBaseKey: string;
    savedViews: ISavedView[];
}