import React from 'react'
import { Grid, Paper, makeStyles } from '@material-ui/core'

interface Props {
    children: React.ReactNode;
    renderPane: boolean;
}

const useStyles = makeStyles((theme) => ({
    detailsPane: {
        height: '100%',
        flex: '1 1 auto',
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
        maxHeight: `calc(100vh - ${theme.spacing(53)}px)`,
        minWidth: '25%',
        "&&.MuiGrid-item": {
            padding: 0
        },
        [theme.breakpoints.up(1650)]: {
            maxWidth: "450px",
        },
        [theme.breakpoints.down(1650)]: {
            width: "400px",
            maxWidth: "400px",
        },
    },
}));

export default function DetailsPane(props: Props) {
    const classes = useStyles();

    if (!props.renderPane) {
        return null;
    }

    return (
        <Grid className={`${classes.detailsPane}`} container item>
            {props.children}
        </Grid>
    )
}
