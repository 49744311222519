import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    logoFlexContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "7px 0",
    },
    mossAdamsLogo: {
        width: "172px",
        height: "50px",
    },
    mossAdamsLogoMinimized: {
        height: "50px",
        width: "50px",
    },
    hide: {
        display: "none",
    },
});

const MALogo = (): JSX.Element => {
    const cssClasses = useStyles();

    let logoCssClasses = cssClasses.logoFlexContainer;

    return (
        <React.Fragment>
            <Box className={logoCssClasses}>
                <img
                    src="/images/moss-adams-header-logo-white.svg"
                    className={cssClasses.mossAdamsLogo}
                    alt="Moss Adams Logo"
                />
            </Box>
        </React.Fragment>
    );
};

export default MALogo;
