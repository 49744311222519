import { InitialGroupOrderComparatorParams, RowNode } from "@ag-grid-enterprise/all-modules";
import IContentHierarchy from "../interfaces/IContentHierarchy";
import { AssociatedTask } from "../interfaces/IContentHierarchy";
import { isNumber } from "lodash";


export const entityColumnComparator = (valueA: any, valueB: any, nodeA: RowNode, nodeB: RowNode, isInverted: boolean) => {
    if (nodeA?.field === 'taskEntity' && nodeB?.field === 'taskEntity' &&  nodeA?.group && nodeB?.group) {
        valueA = nodeA.key || '';
        valueB = nodeB.key || '';
        return valueA.localeCompare(valueB);
    }
}

export const initialEngagementRequestsGroupOrderComparatorem = (params: InitialGroupOrderComparatorParams) => {
    const {nodeA, nodeB} = params;
    const sortableGroups = ['taskEntity', 'engagementName'];
    const fieldA = nodeA?.field || '';
    const fieldB = nodeB?.field || '';
    if (fieldA === fieldB &&  
        nodeA?.group && nodeB?.group && 
        sortableGroups.includes(fieldA) && sortableGroups.includes(fieldB)) 
    {
        const valueA = params.nodeA.key || '';
        const valueB = params.nodeB.key || '';
        
        return valueA.localeCompare(valueB);
    }

    return 0;
}

export const associatedTasksComparator = (valueA: any, valueB: any, nodeA: RowNode, nodeB: RowNode, isInverted: boolean) => {
    const intA = parseInt(valueA) || false;
    const intB = parseInt(valueB) || false;
    const hasUnfiledValue = !(intA && intB);

    // "Unfiled" field should sort below a filed document by default
    if (hasUnfiledValue) {
        return intA === intB ? 0 
        : isNumber(intB) ? 1 : -1;
    }

    return intA === intB ? 0
     : intA > intB ? 1 : -1;
}