import React from 'react';
import { Box, CircularProgress, createStyles, withStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
        width: "100%",
        display: "flex",
        "&& .MuiCircularProgress-root": {
            margin: "0 auto"
        }
    }
}));


const LoadingIndicator = () => {
    const styles = useStyles();
    return (
        <Box className={styles.root}>
            <CircularProgress />
        </Box>
    );
}

export default LoadingIndicator;