import { ICellRendererParams } from "@ag-grid-enterprise/all-modules";
import { Grid, TextareaAutosize, Box, Typography, makeStyles, CircularProgress } from "@material-ui/core";
import React from "react";
import { LocalDateTimeString } from "../../helpers/DateHelpers";
import { commentMaxLength } from "../TaskComments/TaskComments";


const useStyles = makeStyles((theme) => ({
    commentUsername: {
        color: theme.palette.primary.main,
        fontSize: "1rem",
    },
    commentDateTime: {
        color: theme.palette.mossadams.gray400,
        textAlign: "right",
    },
    commentText: {
        fontFamily: "Founders Grotesk Reg",
        fontSize: "1rem",
        lineHeight: "1.1rem",
        wordBreak: "break-word",
        paddingBottom: theme.spacing(3),
        overflowWrap: "break-word",
        userSelect: "text",
    },
    modalCommentCell: {
        marginTop: theme.spacing(5.5),
    },
    errorText: {
        color: theme.palette.error.main,
    },
    editCommentTextArea: {
        fontFamily: "Founders Grotesk Reg",
        fontSize: "1rem",
        lineHeight: "1.1rem",
        wordBreak: "break-word",
        overflowWrap: "break-word",
        padding: theme.spacing(3),
        marginTop: theme.spacing(5.5),
        width: "100%",
        resize: "none",
    },
}));

export const CommentCellRenderer = (params: ICellRendererParams) => {
    const { editNode, submitting, commentDialogIsOpen, submitEdit, endEdit } = params.context;
    const classes = useStyles();
    const taskCommentId = params.node.data.taskCommentId;
    const comment = params.data.comment;
    const updatedDate = params.data.updatedDateTime;
    const editCell = params.node === editNode;

    const [editedComment, setEditedComment] = React.useState<string | undefined>('');
    const textAreaRef = React.useRef<HTMLTextAreaElement | null>(null);
    const containerRef = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        if (editCell) {
            setEditedComment(editNode?.data?.comment);
        }
        else {
            setEditedComment('');
        }
    },[editNode, editCell])


    React.useEffect(() => {
        if (editNode && editedComment === editNode.data.comment) {
            const textArea = textAreaRef.current;
            const container = containerRef.current;
    
            if (textArea && container) {
                textArea.focus();
                textArea.selectionStart = textArea.value.length;
                textArea.selectionEnd = textArea.value.length;
                textArea.scrollTop = textArea.scrollHeight;

                textAreaRef.current = null;
            }
        }
    },[editedComment, editNode])

    const handleEditCommentChange = (e: any) => {
        setEditedComment(e.target.value);
    }

    const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            editedComment !== undefined && submitEdit(editedComment);
        }
        if (event.key === 'Escape') {
            endEdit();
        }
    }

    const cellContent = <>
        <div key={taskCommentId}>
            {!commentDialogIsOpen &&
                <Grid container direction="row" justifyContent="space-between">
                    <Grid className={classes.commentUsername} item xs={4}>
                        {params.data.username}
                    </Grid>
                    <Grid className={classes.commentDateTime} item xs={7}>
                        <div>
                            {LocalDateTimeString(updatedDate || params.data.commentDateTime)}
                            {updatedDate
                                && <span title={LocalDateTimeString(updatedDate)} key={LocalDateTimeString(updatedDate)}> (Edited)</span>
                            }
                        </div>
                    </Grid>
                </Grid>
            }
            <div className={`${classes.commentText} ${commentDialogIsOpen ? classes.modalCommentCell : ''}`} >
                {comment.split("\n").map((i: string, key: number) => { return <div key={key}>{i}</div> })}
            </div>
        </div>
    </>

    const editCellContent = editedComment !== undefined && <>
        {
            submitting
                ? <CircularProgress size={14} />
                : <Grid container direction='column'>
                    <Grid item style={{ flexGrow: 1 }}>
                        <TextareaAutosize ref={textAreaRef}
                            onKeyDown={handleEnterKeyPress}
                            id='edit-comment-area'
                            className={classes.editCommentTextArea}
                            wrap={"soft"}
                            defaultValue={editedComment}
                            onChange={handleEditCommentChange}
                            maxRows={10}
                            readOnly={submitting}
                        />
                    </Grid>
                    <Grid item>
                        <Box mb={4}>
                            <Typography variant='subtitle1'>
                                Press enter to save or escape to cancel.
                            </Typography>
                            <Typography variant='subtitle2' className={`${editedComment.length > commentMaxLength ? classes.errorText : ''}`}>
                                {editedComment.length}/{commentMaxLength}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
        }
    </>

    return (
        <div key={taskCommentId} ref={containerRef}>
            {editCell
                ? editCellContent
                : cellContent
            }
        </div>);
};