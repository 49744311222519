import React from 'react'
import { useSnackbar } from 'notistack';
import DataWriteService from '../services/DataWriteService';
import IContentHierarchy from '../interfaces/IContentHierarchy';
import useAsyncFunctionIsLoading from './useIsLoadingAsync';
import { SnackbarVariantTypes } from '../helpers/enums/enums';
import { Utils } from '../utilities/utils';

export interface MoveFoldersDto {
    folders: string[];
    newParent: string;
}

export const useMoveFolders = () => {
    const { enqueueSnackbar } = useSnackbar();

    const dataWriteService = new DataWriteService();

    const getDtoFromHierarchies = (hierarchies: IContentHierarchy[], newParent: IContentHierarchy) => {
        return {
            folders: hierarchies.map(h => h.id),
            newParent: newParent.id,
        }
    }

    const { onTrigger: moveFolders, loading } = useAsyncFunctionIsLoading(async (hierarchies: IContentHierarchy[], newParent: IContentHierarchy) => {
        const response = await dataWriteService.MoveFolders(getDtoFromHierarchies(hierarchies, newParent));
        if (response.status) {
            enqueueSnackbar("Folder moved successfully.", { variant: SnackbarVariantTypes.Success });
        }
        else {
            Utils.enqueueMultiLineSnackbar(response.errorMessages, enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
        }
        return response;
    });

    return { moveFolders, loading };
}