import { GroupCellRendererParams } from "@ag-grid-enterprise/all-modules";
import ITask from "../interfaces/ITask";
import { LocalDateTimeString } from "./DateHelpers"
import { isTaskOverdue } from "./TaskHelpers";

const requestedDateRenderer = (params: GroupCellRendererParams, overdueDateClassName: string) => {
    const taskData: ITask = params.data;
    let jsDate = new Date(params.value);
    let className = "";
    
    if (isTaskOverdue(taskData) && overdueDateClassName) {
        className = overdueDateClassName;
    }

    return (
        <span className={className}>
            {params.value && LocalDateTimeString(jsDate, false)}
        </span>
    );
}

export default requestedDateRenderer;
