import React from 'react'
import { Typography,  TableContainer, Table, TableHead, Paper, TableBody, TableCell, TableRow } from '@material-ui/core';
import { ISavedViewTableRowProps, SavedViewTableRow } from './SavedViewTableRow';

interface ISavedViewTableProps {
    addNewView: boolean;
    rowProps: Omit<ISavedViewTableRowProps, 'view'>;
}

export const SavedViewTable = (props: ISavedViewTableProps) => {
    const rows = <>
        {
            props.rowProps.viewControls.savedViews.map((view, i) =>
                <SavedViewTableRow
                    key={view.id}
                    view={view}
                    {...props.rowProps}
                />)
        }
        {!props.rowProps.viewControls.maxViewsReached() && props.addNewView &&
            <SavedViewTableRow
                {...props.rowProps}
            />
        }
    </>

    return <TableContainer component={Paper}>
        <Table aria-label="view table">
            <TableHead >
                {props.rowProps.viewControls.savedViews.length !== 0 &&
                    <TableRow>
                        <TableCell ><Typography><strong>Name</strong></Typography></TableCell>
                        <TableCell align='center'><Typography><strong>Use Layout</strong></Typography></TableCell>
                        <TableCell align='center'><Typography><strong>Rename</strong></Typography></TableCell>
                        <TableCell align='center'><Typography><strong>Delete</strong></Typography></TableCell>
                    </TableRow>
                }
            </TableHead>
            <TableBody>
                {rows}
            </TableBody>
        </Table>
    </TableContainer>
}