import React from 'react'
import IConfirmDialogProps from '../../ConfirmDialog/IConfirmDialogProps'
import { ConfirmDialog } from '../../ConfirmDialog/ConfirmDialog'
import { Typography } from '@material-ui/core';
import { useCountdown } from '../../../hooks/useCountdown';


interface Props extends IConfirmDialogProps {
    useCountdown?: boolean;
    secondConfirmationText?: string;
}

const secondConfirmationText = 'This action is irreversible. Are you sure that you want to continue?';

export default function DoubleConfirmDialog(props: Props) {
    const [confirmed, setConfirmed] = React.useState(false);
    const { countdown, isCounting: awaitingTimer } = useCountdown(3, confirmed);

    const onConfirm = () => {
        if (confirmed) {
            props.onConfirm();
        }
        else {
            setConfirmed(true);
        }
    }

    let confirmButtonText = confirmed ? 'I\'m sure' : 'Yes';
    confirmButtonText = (props.useCountdown && awaitingTimer) ? `${countdown}` : confirmButtonText;

    return <ConfirmDialog
        {...props}
        onConfirm={onConfirm}
        onClose={props.onClose}
        confirmButtonText={confirmButtonText}
        confirmDisabled={awaitingTimer}
    >
        {props.children}
        {confirmed && <Typography>{props.secondConfirmationText || secondConfirmationText}</Typography>}
    </ConfirmDialog>
}
