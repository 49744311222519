import { TaskStatus, TaskStatusIds } from "../helpers/enums/TaskStatus";
import { SocStatusIds } from "../helpers/enums/SocStatus";
import ITask, { IBulkUpdateTask } from "../interfaces/ITask";
import { TaskTypeIds } from "../helpers/enums/TaskType";
import { TaskPriorityIds } from "../helpers/enums/TaskPriority";
import { TaskAreasIds } from "../helpers/enums/TaskArea";
import { TaskPhaseIds } from "../helpers/enums/TaskPhase";
import { LineOfBusinessId } from "../helpers/enums/LineOfBusinessId";
import { DateTime } from "luxon";
import ILookup from "../interfaces/ILookup";
import { nullTaskEntityGroupName } from "../helpers/TaskHelpers";

export default class TaskValidator {

    _taskTypeList: number[] = [];

    constructor(taskTypeList: ILookup[]) {
        this._taskTypeList = taskTypeList.map(t => t.id);
    }

    public PrependedValidationMessage = "Your selected request(s) may have one or more of the following issues: ";
    public PhaseValidationMessage = "Phase is required.";
    public AreaValidationMessage = "Area is required.";
    public TitleValidationMessage = "Title is required and must be between 1 and 200 characters.";
    public DescriptionValidationMessage = "Description is required and must be between 3 and 3000 characters.";
    public NotesValidationMessage = "Notes cannot be more than 1000 characters.";
    public TypeValidationMessage = "Request Type is required and must be a valid type.";
    public StatusValidationMessage = "Status is required.";
    public SocStatusValidationMessage = "SOC Status is required.";
    public PriorityValidationMessage = "Priority is required and must be either 'Normal' or 'High'.";
    public RequestedDateValidationMessage = "Due Date is invalid";
    public AssignedToUserValidationMessage = "Assignee user is required";
    public MossAdamsContactUserValidationMessage = "Contact is required";
    public RowsValidationMessage = "Invalid cells are highlighted in red, please correct and try again.";
    public NoDataValidationMessage = "No data could be parsed. The file appears to be empty.";
    public EntityValidationMessage = "The maximum length of the Entity field is 200 characters.";

    public SocStatusIsValid = (value: number | null, status?: number | null, isPublished?: boolean, isSocEngagementType?: boolean | null) => {
        const isDraftStatus = status == TaskStatus.Draft.id;
        if ((isPublished || !isDraftStatus) && isSocEngagementType) {
            return value != null && value != undefined && value != 0 && SocStatusIds.includes(value);
        } else if (isDraftStatus || !isSocEngagementType) {
            return (value && SocStatusIds.includes(value)) || [0, null, undefined].includes(value);
        } else {
            return value && SocStatusIds.includes(value);
        }
    }

    public PhaseIsValid = (value: number | null) => {
        return (value && TaskPhaseIds.includes(value)) || [0, null, undefined].includes(value);
    }

    public AreaIsValid = (value: number | null) => {
        return (value && TaskAreasIds.includes(value)) || [0, null, undefined].includes(value);
    }

    public TitleIsValid = (value: string) => {
        return value?.length >= 1 && value?.length <= 200;
    }

    public DescriptionIsValid = (value: string | null | undefined) => {
        return value === null || value === undefined || value?.length <= 3000;
    }

    public NotesIsValid = (value: string | null | undefined) => {
        return value === null || value === undefined || value?.length <= 1000;
    }

    public TypeIsValid = (value: number | null) => {
        return (value && this._taskTypeList.includes(value)) || [0, null, undefined].includes(value);
    }

    public StatusIsValid = (value: number | null) => {
        return value && TaskStatusIds.includes(value);
    }

    public PriorityIsValid = (value: number | null) => {
        return (value && TaskPriorityIds.includes(value)) || [0, null, undefined].includes(value);
    }

    public MossAdamsContactUserIsValid = (value: string | null, status?: number | null, isPublished?: boolean) => {
        if (isPublished || status != TaskStatus.Draft.id) {
            return (value != null && value != undefined && value?.length > 0);
        }
        else if (status == TaskStatus.Draft.id) {
            return (value && value?.length > 0) || ["", null, undefined].includes(value);
        } else {
            return (value && value?.length > 0);
        }
    }

    public RequestedDateIsValid = (value: Date | null, status?: number | null, isPublished?: boolean) => {
        const isDraftStatus = status === TaskStatus.Draft.id;
        let isValid = true;
        if (value !== null) {
            const earliestValidDate = DateTime.fromISO('1900-01-01');
            const dt = DateTime.fromJSDate(value);
            isValid = dt.isValid && dt.startOf('day') >= earliestValidDate.startOf('day');
        }

        return (isDraftStatus || value !== null) && isValid;
    }

    public EntityIsValid = (value: string | null | undefined, allowNullTaskEntityGroupValue = false) => {
        if (value?.toLowerCase() === nullTaskEntityGroupName.toLowerCase()) return allowNullTaskEntityGroupValue;
        return value === null || value === undefined || value.length <= 200;
    }

    public ExcelRequestedDateIsValid = (value: Date | null) => {
        let isValid = true;
        if (value !== null) {
            const dt = DateTime.fromJSDate(value);
            isValid = dt.isValid;
        }

        return isValid;
    }

    public ExcelFileTaskIsValid = (task: ITask | null) => {

        if (task) {
            return this.PhaseIsValid(task.phaseId) &&
                this.AreaIsValid(task.areaId) &&
                this.SocStatusIsValid(task.socStatusId, task.taskStatusId, task.isPublished, task.isSocEngagementType) &&
                this.TitleIsValid(task.title) &&
                this.DescriptionIsValid(task.description) &&
                this.NotesIsValid(task.notes) &&
                this.TypeIsValid(task.taskTypeId) &&
                this.StatusIsValid(task.taskStatusId) &&
                this.PriorityIsValid(task.taskPriorityId) &&
                this.MossAdamsContactUserIsValid(task.mossAdamsContactUserId, task.taskStatusId, task.isPublished) &&
                this.ExcelRequestedDateIsValid(task.requestedDate) &&
                this.EntityIsValid(task.taskEntity);
        } else {
            return false;
        }
    }

    public TaskIsValid = (task: ITask | null) => {
        if (task) {
            //NOTE: This block is useful for debugger validation errors.
            /*          const v1 = this.PhaseIsValid(task.phaseId, task.taskStatusId, task.isPublished)
                     const v2 = this.AreaIsValid(task.areaId, task.taskStatusId, task.isPublished)
                     const v3 = this.TitleIsValid(task.title)
                     const v4 = this.DescriptionIsValid(task.description, task.taskStatusId, task.isPublished)                     
                     const v5 = this.TypeIsValid(task.taskTypeId, task.taskStatusId, task.isPublished)
                     const v6 = this.StatusIsValid(task.taskStatusId)
                     const v7 = this.PriorityIsValid(task.taskPriorityId, task.taskStatusId, task.isPublished)
                     const v8 = this.AssignedToUserIsValid(task.assignedToUserId, task.taskStatusId, task.isPublished)
                     const v9 = this.MossAdamsContactUserIsValid(task.assignedToUserId, task.taskStatusId, task.isPublished)
                     const v10 = this.RequestedDateIsValid(task.requestedDate, task.taskStatusId, task.isPublished) 
                     const v11 = this.SocStatusIsValid(task.socStatusId, task.taskStatusId, task.isPublished, task.isSocEngagementType)
                     const v12 = this.NotesIsValid(task.notes, task.taskStatusId, task.isPublished) */


            return this.PhaseIsValid(task.phaseId) &&
                this.AreaIsValid(task.areaId) &&
                this.SocStatusIsValid(task.socStatusId, task.taskStatusId, task.isPublished, task.isSocEngagementType) &&
                this.TitleIsValid(task.title) &&
                this.DescriptionIsValid(task.description) &&
                this.NotesIsValid(task.notes) &&
                this.TypeIsValid(task.taskTypeId) &&
                this.StatusIsValid(task.taskStatusId) &&
                this.PriorityIsValid(task.taskPriorityId) &&
                this.MossAdamsContactUserIsValid(task.mossAdamsContactUserId, task.taskStatusId, task.isPublished) &&
                this.RequestedDateIsValid(task.requestedDate, task.taskStatusId, task.isPublished) &&
                this.EntityIsValid(task.taskEntity);
        } else {
            return false;
        }
    }

    public AllTasksAreValid = (tasks: ITask[] | null) => {
        if (tasks?.length) {
            for (const task of tasks) {
                if (!this.TaskIsValid(task)) {
                    return false;
                }
            }
            return true;
        } else {
            return false;
        }
    }

    public GetAllErrorMessages = (task: ITask | IBulkUpdateTask) => {
        const errorMessages: string[] = [];

        if (!this.PhaseIsValid(task.phaseId)) {
            errorMessages.push(this.PhaseValidationMessage)
        }
        if (!this.AreaIsValid(task.areaId)) {
            errorMessages.push(this.AreaValidationMessage)
        }
        if (!this.SocStatusIsValid(task.socStatusId, task.taskStatusId, task.isPublished, task.isSocEngagementType)) {
            errorMessages.push(this.SocStatusValidationMessage)
        }
        if (!this.TitleIsValid(task.title)) {
            errorMessages.push(this.TitleValidationMessage)
        }
        if (!this.DescriptionIsValid(task.description)) {
            errorMessages.push(this.DescriptionValidationMessage)
        }
        if (!this.NotesIsValid(task.notes)) {
            errorMessages.push(this.NotesValidationMessage)
        }
        if (!this.TypeIsValid(task.taskTypeId)) {
            errorMessages.push(this.TypeValidationMessage)
        }
        if (!this.StatusIsValid(task.taskStatusId)) {
            errorMessages.push(this.StatusValidationMessage)
        }
        if (!this.PriorityIsValid(task.taskPriorityId)) {
            errorMessages.push(this.PriorityValidationMessage)
        }
        if (!this.MossAdamsContactUserIsValid(task.mossAdamsContactUserId, task.taskStatusId)) {
            errorMessages.push(this.MossAdamsContactUserValidationMessage)
        }
        if (!this.RequestedDateIsValid(task.requestedDate, task.taskStatusId)) {
            errorMessages.push(this.RequestedDateValidationMessage)
        }
        if (!this.EntityIsValid(task.taskEntity)) {
            errorMessages.push(this.EntityValidationMessage)
        }

        if (errorMessages.length) {
            errorMessages.unshift(this.PrependedValidationMessage);
        }

        return errorMessages;
    }

    public GetAllExcelErrorMessages = (task: ITask) => {
        const errorMessages: string[] = [];

        if (!this.PhaseIsValid(task.phaseId)) {
            errorMessages.push(this.PhaseValidationMessage)
        }
        if (!this.AreaIsValid(task.areaId)) {
            errorMessages.push(this.AreaValidationMessage)
        }
        if (!this.SocStatusIsValid(task.socStatusId, task.taskStatusId, task.isPublished, task.isSocEngagementType)) {
            errorMessages.push(this.SocStatusValidationMessage)
        }
        if (!this.TitleIsValid(task.title)) {
            errorMessages.push(this.TitleValidationMessage)
        }
        if (!this.DescriptionIsValid(task.description)) {
            errorMessages.push(this.DescriptionValidationMessage)
        }
        if (!this.NotesIsValid(task.notes)) {
            errorMessages.push(this.NotesValidationMessage)
        }
        if (!this.TypeIsValid(task.taskTypeId)) {
            errorMessages.push(this.TypeValidationMessage)
        }
        if (!this.StatusIsValid(task.taskStatusId)) {
            errorMessages.push(this.StatusValidationMessage)
        }
        if (!this.PriorityIsValid(task.taskPriorityId)) {
            errorMessages.push(this.PriorityValidationMessage)
        }
        if (!this.MossAdamsContactUserIsValid(task.mossAdamsContactUserId, task.taskStatusId)) {
            errorMessages.push(this.MossAdamsContactUserValidationMessage)
        }
        if (!this.ExcelRequestedDateIsValid(task.requestedDate)) {
            errorMessages.push(this.RequestedDateValidationMessage)
        }
        if (!this.EntityIsValid(task.taskEntity)) {
            errorMessages.push(this.EntityValidationMessage)
        }

        if (errorMessages.length) {
            errorMessages.unshift(this.PrependedValidationMessage);
        }

        return errorMessages;
    }

}