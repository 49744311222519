import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import RestrictedUserAutocomplete, { RestrictedUserOption } from '../RestrictedFileAssociationDialog/RestrictedUserAutocomplete';
import { ArrowDropDown } from '@material-ui/icons';
import { hierarchyIsRestrictedFolder } from '../../helpers/ContentHierarchyHelpers';
import IContentHierarchy from '../../interfaces/IContentHierarchy';
import ICurrentUser from '../../interfaces/ICurrentUser';
import { useSelector } from 'react-redux';
import { PermittedUserListProps } from '../../hooks/usePermittedUsers';

interface Props {
    children?: JSX.Element;
    permittedUsers: PermittedUserListProps;
    folder: IContentHierarchy;
}

const useStyles = makeStyles((theme) => {
    return {
        removeRestrictionsButton: {
            justifySelf: 'flex-start',
            alignSelf: 'center',
        },
        buttonGrid: {
            justifyContent: 'space-between',
        },
        uploadRestrictedFilesMessage: {
            paddingY: theme.spacing(2),
            fontSize: "1rem !important",
        },
        icon: {
            verticalAlign: 'middle',
        },
    }
})

export function ManageFolderAccessModalContent(props: Props) {
    const { folder, permittedUsers } = props;
    const classes = useStyles();
    const currentUser = useSelector((state: any) => state.currentUser as ICurrentUser);

    const isUpdate = hierarchyIsRestrictedFolder(folder);

    const createdByUser = {
        userId: isUpdate ? folder.createdByUserId! : currentUser.userId,
        label: '(Folder Owner)'
    }

    const shouldUserBeSelected = (option: RestrictedUserOption) => {
        if (isUpdate) {
            return option.hasAccess;
        }

        return option.isMossAdamsStaff || option.userId === createdByUser.userId;
    }

    return (
        <>
            {!isUpdate &&
                <Typography className={classes.uploadRestrictedFilesMessage}>
                    This folder will be accessible to all Moss Adams users unless you de-select them.
                </Typography>
            }
            <Typography className={classes.uploadRestrictedFilesMessage}>
                Click on the <ArrowDropDown className={classes.icon}/> expand icon to view the members of this portal. Selected users will be able to see and access this folder and all files within.
            </Typography>
            <RestrictedUserAutocomplete
                permittedUsers={permittedUsers}
                folderId={folder.id}
                createdByUser={createdByUser}
                shouldUserBeSelected={shouldUserBeSelected}
            />
        </>
    )
}