import React from 'react';
import saveAs from 'file-saver';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { SnackbarVariantTypes } from '../../helpers/enums/enums';
import DataReadService from '../../services/DataReadService';
import { Utils } from '../../utilities/utils';
import ConfirmationDialogPopper, { IConfirmationDialogPopperProps } from '../ConfirmationDialogPopper/ConfirmationDialogPopper';
import ITask from '../../interfaces/ITask';

interface DownloadProps {
    tasks: ITask[];
    onCompleteRequest: Function;
}
 
export type DownloadTaskDocumentPopperProps = DownloadProps & Omit<IConfirmationDialogPopperProps, "onConfirmAsync">;

const DownloadTaskDocumentsPopper: React.FunctionComponent<DownloadTaskDocumentPopperProps> = (props) => {
    const { enqueueSnackbar } = useSnackbar();

    const downloadTaskDocuments = async () => {
        const taskIds = props.tasks.map(task => task.taskId as string);
        const dataReadService = new DataReadService();
        return await dataReadService.DownloadDocumentsByTaskIdList(taskIds).then((response) => {
            if (response.status) {
                //@ts-ignore createObjectURL expects Blob or MediaSource object.
                //The api returns a BlobPart(superset that contains Blob) but IPlatformApiGetResult casts it to a BlobPart[]
                let url = window.URL.createObjectURL(response.data as Blob);
                saveAs(url, `MA_Portal_Documents_${DateTime.now().toFormat('yyyy_MM_dd')}.zip`);
            }
            else {
                if (response.timeoutOccured) {
                    response.errorMessages = ["The download failed due to time out. Please select fewer files and try again."];
                }
                Utils.enqueueMultiLineSnackbar([...response.errorMessages], enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
            }
            props.onCompleteRequest();
        })
    }

    return <ConfirmationDialogPopper 
        {...props}
        onConfirmAsync={downloadTaskDocuments}
    />
}
 
export default DownloadTaskDocumentsPopper;