import React from 'react'
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';

interface ConditionalVisiblityProps {
    isVisible: boolean;
    useLoadingIndicator?: boolean;
    errorHandling?: {
        hasError: boolean;
        message: string;
    },
    children: React.ReactNode;
    classes?: string;
}

/**
 * A wrapper component for use when an element of the page should be mounted, but only conditionally displayed.
 * @param props 
 * @returns 
 */
export default function ConditionalVisiblity(props: ConditionalVisiblityProps) {
    const style = props.isVisible ? {} : { display: 'none'};
    const loadingIndicator = (!props.isVisible && props.useLoadingIndicator) ? <span className={props.classes}><LoadingIndicator /></span> : null;

    if (props.errorHandling && props.errorHandling.hasError) {
        return <span>{props.errorHandling.message}</span>
    }

    return (
        <>
            {loadingIndicator}
            <span className={props.classes} style={style}>
                {props.children}
            </span>
        </>
    )
}
