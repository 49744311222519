import { Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react'

const useStyles = makeStyles((theme) => ({
    fileAccessTitle: {
        backgroundColor: theme.palette.mossadams.darkGreen400,
        color: theme.palette.primary.contrastText,
        "&& .MuiIconButton-label": {
            color: theme.palette.primary.contrastText,
        },
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'vertical',
        flexGrow: 1,
        padding: theme.spacing(3),
        '& .MuiAccordionSummary-content': {
            justifyContent: 'space-between',
        }
    },
    titleText: {
        fontSize: '1.3rem'
    },
}));

interface DetailSectionHeaderProps {
    title: string;
    children?: React.ReactNode;
}

export function DetailSectionHeader(props: DetailSectionHeaderProps) {
    const classes = useStyles();

    return <Grid item className={classes.fileAccessTitle}>
        <Typography className={classes.titleText}>{props.title}</Typography>
        {props.children}
    </Grid>
}