import { Chip, Typography, makeStyles } from '@material-ui/core';
import React from 'react'
import { CloudUpload, Description } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    dropzoneClass: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: "150px",
        maxHeight: "350px",
        overflowY: "auto",
        border: 'none',
        backgroundImage: "url(" + "\"data:image/svg+xml,%3csvg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%' height='100%' fill='none' rx='4' ry='4' stroke='%23B7C2C2FF' stroke-width='4' stroke-dasharray='10 20' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e" + "\");",
        borderRadius: "4px",
        marginBottom: "15px",
        "& .MuiChip-root": {
            height: "auto",
        },
        "& .MuiChip-label": {
            whiteSpace: "normal",
            overflow: "auto",
            overflowWrap: "anywhere"
        },
        cursor: 'pointer',
        padding: theme.spacing(3),
    },
    droppingClass: {
        backgroundColor: theme.palette.mossadams.warmGray200,
    },
    dropzoneParagraphClass: {
        marginTop: '30px',
        font: `${theme.typography.body1}`
    },
    chipClass: {
        backgroundColor: theme.palette.primary.light,
        '& .MuiChip-deleteIcon': {
            color: theme.palette.primary.contrastText,
            '&:hover': {
                color: theme.palette.mossadams.warmGray400,
            }
        },
    },
    previewChipLabelClass: {
        color: 'black'
    },
    chipIconClass: {
        color: theme.palette.primary.contrastText,
    },
    iconClass: {
        color: 'black',
        fontSize: '3rem'
    },

}));


export interface IDropzoneProps {
    onAdd: (files: File[]) => void;
    onRemove: (file: File) => void;
    files: File[];
}

export default function FileDropzone(props: IDropzoneProps) {
    const classes = useStyles();
    const [inputKey, setInputKey] = React.useState(new Date());
    const [dropping, setDropping] = React.useState(false);

    const inputRef = React.useRef<HTMLInputElement>(null);

    const onDragLeave = (event: React.DragEvent) => {
        event.preventDefault();
        event.stopPropagation();

        const targetElement = document.getElementById('file-dropzone');
        const relatedTarget = event.relatedTarget as Node;

        if (relatedTarget && targetElement?.contains(relatedTarget)) {
            return;
        }

        setDropping(false);
    }

    const onClick = () => {
        inputRef.current?.click();
    }

    const onChangeFiles = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const files = Array.from(event.target.files);
            props.onAdd(files);
            setInputKey(new Date());
        }
    }, [props.files])

    const onDrop = React.useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        if (event.dataTransfer) {
            const files = Array.from(event.dataTransfer.files);
            props.onAdd(files);
            setInputKey(new Date());
            setDropping(false);
        }
    }, [props.files])

    const onDragOver = React.useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDropping(true);
    }, [props.files])

    const dropzoneText = `Drag and drop or click here`;
    return (
        <>
            <input key={inputKey.toString()} ref={inputRef} type="file" multiple hidden onChange={onChangeFiles} />
            <div
                id='file-dropzone'
                className={`${classes.dropzoneClass} ${dropping ? classes.droppingClass : ''}`}
                onClick={onClick}
                onDrop={onDrop}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
            >
                {props.files.length
                    ? props.files.map(
                        file => <File key={file.name} file={file} onRemove={() => props.onRemove(file)} />
                    )
                    :
                    <>
                        <Typography id='dnd-text' onDragOver={onDragOver} className={classes.dropzoneParagraphClass} variant='body1'>
                            {dropzoneText}
                        </Typography>
                        <CloudUpload id='upload-icon' className={classes.iconClass} />
                    </>
                }
            </div>
        </>
    )
}

const File = (props: { file: File, onRemove: () => void }) => {
    const classes = useStyles();

    return <div>
        <Chip
            className={classes.chipClass}
            icon={<Description className={classes.chipIconClass} />}
            label={props.file.name}
            onDelete={props.onRemove}
        />
    </div>
}
