import React from 'react';
import { hierarchyIsFile, hierarchyIsRestrictedFolder } from '../../helpers/ContentHierarchyHelpers';
import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import IContentHierarchy from '../../interfaces/IContentHierarchy';
import RestrictedIcon from '../common/RestrictedIcon/RestrictedIcon';

export const PrivateHierarchyCellRenderer = (params: ICellRendererParams) => {
    const data: IContentHierarchy = params?.data;

    if (data && ((hierarchyIsFile(data) && params.data.isPrivate) || hierarchyIsRestrictedFolder(data))) {
        return <RestrictedIcon useGridStyle />;
    }

    return "";
};
