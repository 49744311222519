import { Link } from "react-router-dom";
import { ICellRendererParams } from '@ag-grid-community/core';
import ITask from "../../interfaces/ITask";
import { TaskStatus } from "../../helpers/enums/TaskStatus";

const RequestNumberCellRenderer = (params: ICellRendererParams) => {
    const task: ITask = params.data;
    const isDeleted = task.taskStatusId == TaskStatus.Deleted.id;

    if (task !== null) {
        if (isDeleted == false) {
            return <Link to={`/engagement-management/${params.data.taskId}`}> {params.data.taskNumber} </Link>
        } else {
            return <span>{params.data.taskNumber} [Deleted]</span>
        }
    }
    return "";
}

export default RequestNumberCellRenderer;