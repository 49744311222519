import { IDoesFilterPassParams } from "@ag-grid-community/core";
import { Box, Button, FormControlLabel, Radio, RadioGroup, Typography } from "@material-ui/core";
import { DateTime } from "luxon";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

interface ExpireDateFilterProps {
    filterChangedCallback: () => void;
}

export const ExpireDateFilter = forwardRef((props: ExpireDateFilterProps, ref) => {
    const [selectedRange, setSelectedRange] = useState<string | null>(null);

    useImperativeHandle(ref, () => {
        return {
            doesFilterPass(params: IDoesFilterPassParams) {
                const expireDate = DateTime.fromJSDate(params.data.expirationDate);

                const today = DateTime.now().startOf('day');
                if (selectedRange === "7days") {
                    const sevenDaysFromNow = today.plus({days: 7}).endOf('day');
                    return expireDate >= today && expireDate <= sevenDaysFromNow;
                }

                if (selectedRange === "30days") {
                    const thirtyDaysFromNow = today.plus({days: 30}).endOf('day');
                    return expireDate >= today && expireDate <= thirtyDaysFromNow;
                }

                return true;
            },

            isFilterActive() {
                return selectedRange !== null;
            },

            getModel() {
                return {value: selectedRange}
            },

            setModel(model: {value: string | null} | null) {
                setSelectedRange(model?.value || null);
            },

            getModelAsString() {
                if (selectedRange === "7days") {
                    return '< 7 days';
                }
                if (selectedRange === "30days") {
                    return '< 30 days';
                }
                return '';
            },
        }
    });

    useEffect(() => {
        props.filterChangedCallback()
    }, [selectedRange]);

    const onChangeSelectedRange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedRange(event.target.value);
    }

    return (
        <Box p='10px'>
            <Typography>Show files expiring in:</Typography>
            <RadioGroup aria-label="year" name="year" value={selectedRange} onChange={onChangeSelectedRange}>
                <FormControlLabel value="7days" control={<Radio size='small' color="primary" />} label="7 Days" />
                <FormControlLabel value="30days" control={<Radio size='small' color="primary" />} label="30 Days" />
            </RadioGroup>
            <Button size="small" onClick={() => setSelectedRange(null)}>Clear</Button>
        </Box>
    )
});