import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import axios from 'axios';
import Button from "@material-ui/core/Button";
import { ThemeProvider } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Close";
import { SnackbarProvider, ProviderContext } from "notistack";
import portalTheme from "./helpers/portalTheme";
import App from "./App";
import { Provider } from "react-redux";
import configureStore from "./store/ConfigureStore";
import setupAxios from './services/SetupAxios';
import { setAgGridLicense } from './helpers/agGridHelpers';

setAgGridLicense();

// Create browser history to use in the Redux store
const baseUrl = document
    .getElementsByTagName("base")[0]
    .getAttribute("href") as string;
const history = createBrowserHistory({ basename: baseUrl });

/**
 * Store is a reference to the REDUX store
 * Persistor is an object that can be used to persist data from the redux store locally
 */
//const { store, persistor } = configureStore();
const store = configureStore();

// Add close button to all snackbars
const notistackRef = React.createRef<SnackbarProvider>();

/**
 * Close a notistack alert bar.
 *
 * @param key The key that identifies the specific alert bar to be closed.
 */
const onClickDismiss = (key: React.ReactText) => (): void => {
    notistackRef.current?.closeSnackbar(key);
};

setupAxios(axios, store, history);

ReactDOM.render(
    <Router history={history}>
        <ThemeProvider theme={portalTheme}>
            <SnackbarProvider
                maxSnack={4}
                ref={notistackRef}
                anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom",
                }}
                autoHideDuration={5000}
                transitionDuration={{ exit: 500 }}
                action={(key: React.ReactText): JSX.Element => {
                    return (
                        <Button
                            onClick={onClickDismiss(key)}
                            style={{ color: "#fff" }}
                        >
                            <CancelIcon />
                        </Button>
                    );
                }}
            >
                <Provider store={store}>
                    <App />
                </Provider>
            </SnackbarProvider>
        </ThemeProvider>
    </Router>,
    document.getElementById("root")
);