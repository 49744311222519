import PlatformApiService from './PlatformApiService';
import IPlatformApiGetResult from '../interfaces/IPlatformApiGetResult';
import ICurrentUser from "../interfaces/ICurrentUser";
import IClient from "../interfaces/IClient";
import IClientEngagement from "../interfaces/IClientEngagement";
import IUser from "../interfaces/IUser";
import IEngagement from "../interfaces/IEngagement";
import IDocument from "../interfaces/IDocument";
import IContentHierarchy from "../interfaces/IContentHierarchy";
import IContentHierarchyGetDto from "../interfaces/IContentHierarchyGetDto";
import IStaffClientsEngagements from "../interfaces/IStaffClientsEngagements";
import IStaffClientsEngagementsGetDto from "../interfaces/IStaffClientsEngagementsGetDto";
import ITaskListRequestDto from "../interfaces/ITaskListRequestDto";
import ITaskListVM from "../interfaces/ITaskListVM";
import IEngagementManagementListVM from "../interfaces/IEngagementManagementListVM";
import IDocumentByFileNameRequestModel from '../interfaces/IDocumentByFileNameRequestModel';
import ITaskSummary from "../interfaces/ITaskSummary";
import IDueDatesForMonthRequestDto from '../interfaces/IDueDatesForMonthRequestDto'
import ITaskDocument from "../interfaces/ITaskDocument";
import ITaskComment from "../interfaces/ITaskComment";
import ITaskCommentForExportVM from "../interfaces/ITaskCommentForExportVM";
import IUserUsageReportDto from "../interfaces/IUserUsageReportDto";
import IUserAccessReportDto from '../interfaces/IUserAccessReportDto';
import IUserNotificationSettings from '../interfaces/IUserNotificationSettings';
import IDueDate from '../interfaces/IDueDate';
import ISummaryTask from '../interfaces/ISummaryTask';
import IMySummary from '../interfaces/IMySummary';
import ILookup from "../interfaces/ILookup";
import ISocDrlTestingStatusVM from "../interfaces/ISocDrlTestingStatusVM";
import IRecycleBinDocumentsRequestDto from "../interfaces/IRecycleBinDocumentsRequestDto";
import IRecycleBinDocument from '../interfaces/IRecycleBinDocument';
import { IDownloadDocumentsDto } from '../interfaces/IDownloadDocumentsDto';
import { IRestrictedUser } from '../interfaces/IRestrictedUser';
import IRestrictedUserListDto from '../interfaces/IRestrictedUserListDto';
import { ClientOption } from '../components/ClientEngagementSelector/ClientAutocomplete';
import { IListOfTasksDto } from '../interfaces/IListOfTasksDto';
import ITask from '../interfaces/ITask';
import IMyTaskRequestDto from "../interfaces/IMyTaskRequestDto";
import IDocumentHistoryItem from '../interfaces/IDocumentHistoryItem';
import IDocumentHistoryDto from '../interfaces/IDocumentHistoryDto';

/** 
 * A set of methods for making GET calls to the Platform API to request data.
 */
export default class DataReadService extends PlatformApiService {

    /**
     * Get the user's Clients by making a call to the Platform API endpoint.
     * 
     * @returns A [[DataResult]] with the Clients as the data payload.
     */
    public async GetCurrentUser(): Promise<IPlatformApiGetResult<ICurrentUser>> {

        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_CURRENTUSER || '';

        return await this.PlatformApiGet<ICurrentUser>(platformApiEndpointPath);
    }

    /**
     * Get the user's Clients by making a call to the Platform API endpoint.
     * 
     * @returns A [[DataResult]] with the Clients as the data payload.
     */
    public async GetClients(params: URLSearchParams): Promise<IPlatformApiGetResult<ClientOption>> {

        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_CLIENTS || '';

        return await this.PlatformApiGet<ClientOption>(platformApiEndpointPath, undefined, params);
    }

    /**
     * Get the user's Clients by making a call to the Platform API endpoint.
     * 
     * @returns A [[DataResult]] with the Clients as the data payload.
     */
    public async GetClientEngagements(): Promise<IPlatformApiGetResult<IClientEngagement>> {

        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_CLIENTENGAGEMENTS || '';

        return await this.PlatformApiGet<IClientEngagement>(platformApiEndpointPath);
    }


    /**
     * Get the the client and engagement Ids for the provided documentId by making a call to the Platform API endpoint.
     * 
     * @returns A [[DataResult]] with the Clients as the data payload.
     */
    public async GetClientEngagementsByFolderOrDocumentId(documentOrFolderId: string): Promise<IPlatformApiGetResult<string>> {

        const platformApiEndpointPath: string = `${process.env.REACT_APP_CLIENTPLATFORM_API_CLIENTENGAGEMENTSBYDOCUMENTID}/${documentOrFolderId}`;

        return await this.PlatformApiGet<string>(platformApiEndpointPath);
    }

    /**
     * Get the user's Client\Engagement for a given taskId by making a call to the Platform API endpoint.
     * 
     * @returns A [[DataResult]] with the Client and Engagement Ids as the data payload.
     */
    public async GetClientEngagementsByTaskId(taskId: string): Promise<IPlatformApiGetResult<string>> {

        const platformApiEndpointPath: string = `${process.env.REACT_APP_CLIENTPLATFORM_API_CLIENTENGAGEMENTSBYTASKID}/${taskId}`;

        return await this.PlatformApiGet<string>(platformApiEndpointPath);
    }

    /**
     * Get the user's Engagements by client ID by making a call to the Platform API endpoint.
     * 
     * @returns A [[DataResult]] with the Engagements as the data payload.
     */
    public async GetEngagements(clientId: string): Promise<IPlatformApiGetResult<IEngagement>> {

        const platformApiEndpointPath: string = `${process.env.REACT_APP_CLIENTPLATFORM_API_ENGAGEMENTS}/${clientId}`;

        return await this.PlatformApiGet<IEngagement>(platformApiEndpointPath);
    }

    /**
      * Get the task types for the given engagement Id.
      * 
      * @returns A [[DataResult]] with the TaskTypes as an ILookup list.
      */
    public async GetTaskTypes(engagementId: string): Promise<IPlatformApiGetResult<ILookup>> {

        const platformApiEndpointPath: string = `${process.env.REACT_APP_CLIENTPLATFORM_API_TASKTYPES_GET}/${engagementId}`;

        return await this.PlatformApiGet<ILookup>(platformApiEndpointPath);
    }

    /**
    * Get task summary by making a call to the Platform API endpoint.
    * 
    * @returns A [[DataResult]] with the TaskListSummary as the data payload.
    */
    public async GetTaskSummary(): Promise<IPlatformApiGetResult<ITaskSummary>> {

        const platformApiEndpointPath: string = `${process.env.REACT_APP_CLIENTPLATFORM_API_TASKSUMMARY_GET}`;

        return await this.PlatformApiGet<ITaskSummary>(platformApiEndpointPath);
    }

    /**
    * Get amount of due tasks on a specific date by making a call to the Platform API endpoint.
    * 
    * @returns A [[DataResult]] with the DueTasks as the data payload.
    */
    public async GetDueDatesForMonth(model: IDueDatesForMonthRequestDto): Promise<IPlatformApiGetResult<IDueDate>> {

        const platformApiEndpointPath: string = `${process.env.REACT_APP_CLIENTPLATFORM_API_DUEDATESFORMONTH_GET}`;

        return await this.PlatformApiPostRequest<IDueDate, IDueDatesForMonthRequestDto>(platformApiEndpointPath, model);
    }

    /**
    * Get my summary for logged in user by making a call to the Platform API endpoint.
    * 
    * @returns A [[DataResult]] with the MySummary as the data payload.
    */
    public async GetMySummary(engagementIds: string[]): Promise<IPlatformApiGetResult<IMySummary>> {

        const platformApiEndpointPath: string = `${process.env.REACT_APP_CLIENTPLATFORM_API_MYSUMMARY_GET}` || '';

        return await this.PlatformApiPostRequest<IMySummary, string[]>(platformApiEndpointPath, engagementIds);
    }

    /**
    * Get my tasks for logged in user by making a call to the Platform API endpoint.
    * @param filter: new, past due, due this week, due next week
    * @returns 
    */
    public async GetMyTasks(dto: IMyTaskRequestDto): Promise<IPlatformApiGetResult<ISummaryTask>> {

        const platformApiEndpointPath: string = `${process.env.REACT_APP_CLIENTPLATFORM_API_MYTASKS_GET}` || '';

        return await this.PlatformApiPostRequest<ISummaryTask, IMyTaskRequestDto>(platformApiEndpointPath, dto);
    }


    /**
    * Get a tasklist with tasks by engagement ID by making a call to the Platform API endpoint.
    * 
    * @returns A [[DataResult]] with the TaskList as the data payload.
    */
    public async GetTaskList(taskListId: string): Promise<IPlatformApiGetResult<ITaskListVM>> {

        const platformApiEndpointPath: string = `${process.env.REACT_APP_CLIENTPLATFORM_API_TASKLIST_GET}/${taskListId}`;

        return await this.PlatformApiGet<ITaskListVM>(platformApiEndpointPath);
    }

    /**
     * Get a List of TaskVM
     * 
     * @param model: 
     */

    public async GetListOfTasks(model: IListOfTasksDto): Promise<IPlatformApiGetResult<ITask>> {
        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_LISTOFTASKS_GET || "";

        return await this.PlatformApiPostRequest<ITask, IListOfTasksDto>(platformApiEndpointPath, model);
    }

    /**
     * Get a a task's attached TaskDocuments.
     * 
     * @returns A [[DataResult]] with the TaskDocuments as the data payload.
     */
    public async GetTaskDocuments(taskId: string): Promise<IPlatformApiGetResult<ITaskDocument>> {

        const platformApiEndpointPath: string = `${process.env.REACT_APP_CLIENTPLATFORM_API_TASKDOCUMENTS_GET}/${taskId}`;

        return await this.PlatformApiGet<ITaskDocument>(platformApiEndpointPath);
    }

    /**
     * Get a a task's attached TaskComments.
     * 
     * @returns A [[DataResult]] with the TaskComments as the data payload.
     */
    public async GetTaskComments(taskId: string): Promise<IPlatformApiGetResult<ITaskComment>> {

        const platformApiEndpointPath: string = `${process.env.REACT_APP_CLIENTPLATFORM_API_TASKCOMMENTS_GET}/${taskId}`;

        return await this.PlatformApiGet<ITaskComment>(platformApiEndpointPath);
    }

    /**
     * Get a ISocDrlTestingStatusVM for the SocDrlTestingStatus dashboard widget.
     * 
     * @returns A [[DataResult]] with the TaskList as the data payload.
     */
    public async GetTaskCommentsForExport(engagementIds: string[]): Promise<IPlatformApiGetResult<ITaskCommentForExportVM>> {

        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_TASKCOMMENTSFOREXPORT_GET || '';

        return await this.PlatformApiPostRequest<ITaskCommentForExportVM, string[]>(platformApiEndpointPath, engagementIds);
    }

    /**
     * Get a tasklist with tasks by engagement ID by making a call to the Platform API endpoint.
     * 
     * @returns A [[DataResult]] with the TaskList as the data payload.
     */
    public async GetEngagementManagementList(taskListRequestDto: ITaskListRequestDto): Promise<IPlatformApiGetResult<IEngagementManagementListVM>> {

        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_ENGAGEMENTMANAGEMENT_GET || '';

        return await this.PlatformApiPostRequest<IEngagementManagementListVM, ITaskListRequestDto>(platformApiEndpointPath, taskListRequestDto);
    }

    /**
     * Get a ISocDrlTestingStatusVM for the SocDrlTestingStatus dashboard widget.
     * 
     * @returns A [[DataResult]] with the TaskList as the data payload.
     */
    public async GetSocDrlTestingStatus(engagementIds: string[]): Promise<IPlatformApiGetResult<ISocDrlTestingStatusVM>> {

        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_SOCDRLTESTINGSTATUS_GET || '';

        return await this.PlatformApiPostRequest<ISocDrlTestingStatusVM, string[]>(platformApiEndpointPath, engagementIds);
    }

    /**
     * Gets list of documents for the Recycle Bin component.
     * 
     * @returns A [[DataResult]] with the RecycleBinDocumentList as the data payload.
     */
    public async GetRecycleBinDocuments(dto: IRecycleBinDocumentsRequestDto): Promise<IPlatformApiGetResult<IRecycleBinDocument>> {

        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_RECYCLEBINDOCUMENTS_GET || '';

        return await this.PlatformApiPostRequest<IRecycleBinDocument, IRecycleBinDocumentsRequestDto>(platformApiEndpointPath, dto);
    }

    /**
     * Get the content hierarchy list by client.
     * 
     * @returns A [[DataResult]] with the ContentHierarchy list as the data payload.
     */
    public async GetContentHierarchy(contentHierarchyGetDto: IContentHierarchyGetDto): Promise<IPlatformApiGetResult<IContentHierarchy>> {

        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_CONTENTHIERARCHY || '';

        return await this.PlatformApiPostRequest<IContentHierarchy, IContentHierarchyGetDto>(platformApiEndpointPath, contentHierarchyGetDto);
    }

    /**
     * Get a document(file) by making a call to the Platform API endpoint.
     * 
     * @returns A [[DataResult]] with the File as the data payload.
     */
    public async DownloadDocument(fileId: string, version?: string): Promise<IPlatformApiGetResult<BlobPart>> {

        const platformApiEndpointPath: string = version ? `${process.env.REACT_APP_CLIENTPLATFORM_API_DOCUMENT_DOWNLOAD}/${fileId}/${version}` : `${process.env.REACT_APP_CLIENTPLATFORM_API_DOCUMENT_DOWNLOAD}/${fileId}`;

        return await this.PlatformApiGet<BlobPart>(platformApiEndpointPath, "application/octet-stream");
    }

    /**
     * Get a Excel file of app usage by userId by making a call to the Platform API endpoint.
     * 
     * @returns A [[DataResult]] with the File as the data payload.
     */
    public async GetUserUsageReport(dto: IUserUsageReportDto): Promise<IPlatformApiGetResult<BlobPart>> {

        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_REPORTS_GETUSERUSAGE || ''

        return await this.PlatformApiPostRequest<BlobPart, IUserUsageReportDto>(platformApiEndpointPath, dto, "application/octet-stream");
    }

    /**
     * Get an Excel file of client/engagment access by userId by making a call to the Platform API endpoint.
     * 
     * @returns A [[DataResult]] with the File as the data payload.
     */
    public async GetUserAccessReport(dto: IUserAccessReportDto): Promise<IPlatformApiGetResult<BlobPart>> {

        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_REPORTS_GETUSERACCESS || ''

        return await this.PlatformApiPostRequest<BlobPart, IUserAccessReportDto>(platformApiEndpointPath, dto, "application/octet-stream");
    }

    /**
     * Get an Excel file of all tasks by userId by making a call to the Platform API endpoint.
     * 
     * @returns A [[DataResult]] with the File as the data payload.
     */
    public async GetTaskReport(): Promise<IPlatformApiGetResult<BlobPart>> {

        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_REPORTS_GETTASKS || ''

        return await this.PlatformApiPostRequest<BlobPart, {}>(platformApiEndpointPath, {}, "application/octet-stream");
    }

    /**
     * Get a a list of users by searchText by making a call to the Platform API endpoint.
     * 
     * @returns A list of users.
     */
    public async GetUsers(searchText: string): Promise<IPlatformApiGetResult<IUser>> {

        const platformApiEndpointPath: string = `${process.env.REACT_APP_CLIENTPLATFORM_API_USERS_GET}/${searchText}`;

        return await this.PlatformApiGet<IUser>(platformApiEndpointPath);
    }

    /**
     * Get a folder's size by making a call to the Platform API endpoint.
     * 
     * @returns A number representing the total bytes of all the files in the folder.
     */
    public async GetFolderSize(folderId: string): Promise<IPlatformApiGetResult<number>> {

        const platformApiEndpointPath: string = `${process.env.REACT_APP_CLIENTPLATFORM_API_DOCUMENT_GETFOLDERSIZE}/${folderId}`;

        return await this.PlatformApiGet<number>(platformApiEndpointPath);
    }

    /**
     * Get a document(file)'s details and history by making a call to the Platform API endpoint.
     * 
     * @returns A [[DataResult]] with the document's details as the data payload.
     */
    public async GetDocument(fileId: string): Promise<IPlatformApiGetResult<IDocument>> {

        const platformApiEndpointPath: string = `${process.env.REACT_APP_CLIENTPLATFORM_API_DOCUMENT_GET}/${fileId}`;

        return await this.PlatformApiGet<IDocument>(platformApiEndpointPath);
    }

    /* Get a tasklist with tasks by engagement ID by making a call to the Platform API endpoint.
    * 
    * @returns A [[DataResult]] with the TaskList as the data payload.
    */
    public async GetDocumentByFileName(documentByFileNameRequestModel: IDocumentByFileNameRequestModel): Promise<IPlatformApiGetResult<IContentHierarchy>> {

        // TODO: IPlatformApiGetResult defines data as an array, but the back end returns a single object.

        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_DOCUMENT_GETBYFILENAME || '';

        return await this.PlatformApiPostRequest<IContentHierarchy, IDocumentByFileNameRequestModel>(platformApiEndpointPath, documentByFileNameRequestModel);
    }

    /**
     * Get a folder and it's nexted content as a zip file.
     * 
     * @returns A zip file as the data payload.
     */
    public async DownloadFolder(folderId: string): Promise<IPlatformApiGetResult<BlobPart>> {

        const platformApiEndpointPath: string = `${process.env.REACT_APP_CLIENTPLATFORM_API_DOCUMENT_DOWNLOADFOLDER}/${folderId}`;

        return await this.PlatformApiGet<BlobPart>(platformApiEndpointPath, "application/octet-stream");
    }

    /**
     * Get a list of documents as a zip file.
     * 
     * @returns A zip file as the data payload.
     */
    public async DownloadDocuments(docs: IDownloadDocumentsDto[]): Promise<IPlatformApiGetResult<BlobPart>> {

        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_DOCUMENT_DOWNLOADDOCUMENTS || '';

        return await this.PlatformApiPostRequest<BlobPart, IDownloadDocumentsDto[]>(platformApiEndpointPath, docs, "application/octet-stream");
    }

    /**
     * Get a list of documents as a zip file.
     * 
     * @returns A zip file as the data payload.
     */
    public async DownloadDocumentsByTaskIdList(taskIds: string[]): Promise<IPlatformApiGetResult<BlobPart>> {

        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_DOCUMENT_DOWNLOADTASKDOCUMENTS || '';

        return await this.PlatformApiPostRequest<BlobPart, string[]>(platformApiEndpointPath, taskIds, "application/octet-stream");
    }

    /**
     * Get the team members (staff) associated with a list of clients and engagement ids.
     * 
     * @returns A [[DataResult]] with the Staff list as the payload.
     */
    public async GetStaffByClientsAndEngagements(staffClientsEngagementsGetDto: IStaffClientsEngagementsGetDto): Promise<IPlatformApiGetResult<IStaffClientsEngagements>> {

        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_TEAMMEMBERS || '';

        return await this.PlatformApiPostRequest<IStaffClientsEngagements, IStaffClientsEngagementsGetDto>(platformApiEndpointPath, staffClientsEngagementsGetDto);

    }

    /**
     * Get notification settings for user
     * 
     * @returns A [[DataResult]] with the notification settings as the payload.
     */
    public async GetNotificationSettings(): Promise<IPlatformApiGetResult<IUserNotificationSettings>> {

        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_USERNOTIFICATIONSETTINGS_GET || '';

        return await this.PlatformApiGet<IUserNotificationSettings>(platformApiEndpointPath);

    }

    /**
     * Get users with access to client/engagement and whether or not already assigned to document
     * 
     * @returns A [[DataResult]] with the Restricted User List as the payload.
     */
    public async GetRestrictedUserList(restrictedUserListDto: IRestrictedUserListDto): Promise<IPlatformApiGetResult<IRestrictedUser>> {

        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_RESTRICTED_USER_LIST || '';

        return await this.PlatformApiPostRequest<IRestrictedUser, IRestrictedUserListDto>(platformApiEndpointPath, restrictedUserListDto);

    }

    
    /**
     * Get the Document History data for a given Document Guid.
     * 
     * @returns A [[DataResult]] with the latest Document History entry for each FileVersion as the payload.
     */
    public async GetVersionHistory(historyDto: IDocumentHistoryDto): Promise<IPlatformApiGetResult<IDocumentHistoryItem>> {

        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_DOCUMENT_HISTORY_GET || '';

        return await this.PlatformApiPostRequest<IDocumentHistoryItem, IDocumentHistoryDto>(platformApiEndpointPath, historyDto);

    }

    /**
     * Get the static Area list
     */
    public async GetAreaList(): Promise<IPlatformApiGetResult<ILookup>> {
        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_LOOKUPS_AREAS || '';
        return await this.PlatformApiGet<ILookup>(platformApiEndpointPath);
    }

    /**
     * Get the static Phase list
     */
    public async GetPhaseList(): Promise<IPlatformApiGetResult<ILookup>> {
        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_LOOKUPS_PHASES || '';
        return await this.PlatformApiGet<ILookup>(platformApiEndpointPath);

    }

    /**
     * Get the static Priority list 
     */
    public async GetTaskPriorityList(): Promise<IPlatformApiGetResult<ILookup>> {
        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_LOOKUPS_TASK_PRIORITY || '';
        return await this.PlatformApiGet<ILookup>(platformApiEndpointPath);

    }

     /**
     * Get the static Task Status list
     */
    public async GetTaskStatusList(): Promise<IPlatformApiGetResult<ILookup>> {
        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_LOOKUPS_TASK_STATUS || '';
        return await this.PlatformApiGet<ILookup>(platformApiEndpointPath);

    }

    /**
     * Get the static Task Type list
     */
    public async GetTaskTypeList(): Promise<IPlatformApiGetResult<ILookup>> {
        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_LOOKUPS_TASK_TYPE || '';
        return await this.PlatformApiGet<ILookup>(platformApiEndpointPath);

    }

     /**
     * Get the static Soc Status list
     */
    public async GetSocStatusList(): Promise<IPlatformApiGetResult<ILookup>> {
        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_LOOKUPS_SOC_STATUS || '';
        return await this.PlatformApiGet<ILookup>(platformApiEndpointPath);

    }

    /**
     * Get the static Soc Status list
     */
    public async GetUserTermsAndConditionsAcceptanceStatus(): Promise<IPlatformApiGetResult<boolean>> {
        const platformApiEndpointPath: string = process.env.REACT_APP_CLIENTPLATFORM_API_GET_CURRENT_USER_ACCEPTANCE_STATUS || '';
        return await this.PlatformApiGet<boolean>(platformApiEndpointPath);
    }

}
