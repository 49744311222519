import { Checkbox,  makeStyles, TextField, Chip, CircularProgress, Box } from '@material-ui/core'
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason } from '@material-ui/lab/Autocomplete'
import _ from 'lodash'
import React from 'react'
import { IRestrictedUser } from '../../interfaces/IRestrictedUser'
import IRestrictedUserListDto from '../../interfaces/IRestrictedUserListDto'
import DataReadService from '../../services/DataReadService'
import { PermittedUserListProps } from '../../hooks/usePermittedUsers'

const useStyles = makeStyles((theme) => {
    const font = theme.typography.fontFamily;

    return {
        input: {
            fontSize: "0.9rem",
            "& input": {
                fontSize: "0.9rem",
            },
            "& .MuiSelect-select.MuiSelect-select": {
                paddingTop: theme.spacing(5.5),
            },
            "& .MuiAutocomplete-inputRoot[class*=\"MuiInput-root\"] .MuiAutocomplete-input:first-child": {
                paddingTop: theme.spacing(5)
            },
            "& .MuiAutocomplete-popupIndicator": {
                marginRight: 0,
                padding: 0,
                "& .MuiSvgIcon-root": {
                    fontSize: "2.1rem",
                    color: "black",
                }
            },
            "& .MuiIconButton-root": {
                padding: 0,
            },
        },
        checkbox: {
            "& .MuiCheckbox-root": {

            },
            "& .Mui-checked": {
                backgroundColor: theme.palette.primary.main,

            },
            "&.MuiIconButton-root": {
                padding: theme.spacing(1),
            },
        },
        autocomplete: {
            flex: "1 1 100%",
        },
        option: {
            padding: 0,
            fontFamily: font,
            fontSize: theme.typography.fontSize,
        },
        list: {
            "& .MuiListSubheader-root": {
                lineHeight: theme.spacing(1),
            },
        },
        chip: {
            backgroundColor: theme.palette.primary.main,
            margin: theme.spacing(1),
            height: '1.6rem',
            fontWeight: 'normal',
            "& .MuiChip-deleteIcon": {
                opacity: 0.4,
                color: theme.palette.primary.contrastText
            },
            "&.clientUser": {
                backgroundColor: theme.palette.mossadams.darkGreen400,
            }
        },
        spinner: {
            textAlign: 'center',
            margin: '1rem 0'
        },
    }
})


interface Props {
    permittedUsers: PermittedUserListProps;
    documentId?: string; 
    folderId: string;
    createdByUser?: {
        userId: string;
        label: string;
    }
    shouldUserBeSelected: (options: RestrictedUserOption) => boolean;
}

export type RestrictedUserOption = IRestrictedUser & { firstLetter: string, groupName: string }


function RestrictedUserAutocomplete({permittedUsers, documentId, folderId, createdByUser, shouldUserBeSelected}: Props) {

    const classes = useStyles();

    const { 
        selectedUserOptions: values, 
        setSelectedUserOptions: setValues, 
        userOptions: options, 
        setUserOptions: setOptions  
    } = permittedUsers;

    React.useEffect(() => {
        getRestrictedUserList();
    }, [documentId, folderId])

    const getRestrictedUserList = async () => {
        const dataReadService = new DataReadService();

        const dto: IRestrictedUserListDto = {
            documentId: documentId,
            folderId: folderId
        }

        const list = await dataReadService.GetRestrictedUserList(dto);
        if (list.status) {
            const maGroupName = "Moss Adams Users";
            const clientGroupName = "Client Users"
            const optionsArray = list.data.map(restrictedUser => {
                return {
                    ...restrictedUser,
                    firstLetter: restrictedUser.displayName?.charAt(0),
                    groupName: restrictedUser.isMossAdamsStaff ? maGroupName : clientGroupName
                }
            })
            const usersWithAccess: string[] = [];

            optionsArray.forEach(option => shouldUserBeSelected(option) && usersWithAccess.push(option.userId));

            const defaultValues = _.clone(optionsArray).filter(option => usersWithAccess.includes(option.userId));
            setValues(defaultValues);
            setOptions(optionsArray);
        }
    }

    const onChange = (e: React.ChangeEvent<{}>, selected: RestrictedUserOption[], reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<RestrictedUserOption | undefined>) => {
        e.stopPropagation();

        let newValues = [...selected];
        // Handle removal explicitly - as it is inconsistent within MUI [between removing via clicking the chips and removing via clicking the checkbox]...
        if (reason === 'remove-option') {
            newValues = [...values];
            _.remove(newValues, option => details?.option?.userId === option.userId);
        }
        else if (reason === 'clear') {
            const createdByOption = options.find(optionIsCreatedByUser);
            newValues = createdByOption ? [createdByOption] : []
        }

        setValues(newValues);
    }

    const optionIsCreatedByUser = (option: RestrictedUserOption) => {
        return option.userId === createdByUser?.userId;
    }

    const createdByUserIdentifier = createdByUser ? ` ${createdByUser.label}` : '';
    return (
        !(options.length) ? <div className={classes.spinner}><CircularProgress size={"1.5rem"} color='inherit' /></div> :
            <Box paddingTop='10px'>
                <Autocomplete
                    className={classes.autocomplete}
                    classes={{
                        option: classes.option,
                        listbox: classes.list,
                    }}
                    id="permitted-user-selector"
                    multiple
                    options={options.sort((a, b) => -b.groupName.localeCompare(a.groupName) || -b.firstLetter.localeCompare(a.firstLetter))}
                    onChange={onChange}
                    onOpen={e => e.stopPropagation()}
                    onClose={e => e.stopPropagation()}
                    getOptionSelected={option => values.includes(option)}
                    value={values}
                    disableCloseOnSelect
                    groupBy={(option) => option.groupName}
                    getOptionLabel={(option) => option.displayName}
                    fullWidth={true}
                    renderInput={(params) => <TextField className={classes.input} {...params} label="Permitted Users" />}
                    renderOption={(option, { selected }) => (
                        <>
                            <Checkbox className={classes.checkbox}
                                color='primary'
                                checked={selected}
                            />
                            {option.displayName}{optionIsCreatedByUser(option) ? createdByUserIdentifier : ''}
                        </>
                    )}
                    getOptionDisabled={optionIsCreatedByUser}
                    renderTags={(values, getTagsProps) =>
                        <span>
                            {
                                values.map((option, index) => {
                                    const isUploadedByUser = optionIsCreatedByUser(option);
                                    const uploadedByUserProps = !isUploadedByUser ? {} : {
                                        disabled: true,
                                        onDelete: undefined,
                                        label: option.displayName + createdByUserIdentifier
                                    }
                                    return <span key={index}>
                                        <Chip

                                            label={option.displayName}
                                            {...getTagsProps({ index })}
                                            className={`${classes.chip}${option.isMossAdamsStaff ? '' : ' clientUser'}`}
                                            {...uploadedByUserProps}
                                        />
                                    </span>
                                }

                                )
                            }
                        </span>
                    }
                />
            </Box>
    )
}

export default RestrictedUserAutocomplete