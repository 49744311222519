import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import DataReadService from '../../services/DataReadService';
import { useSnackbar } from 'notistack';
import { errorSeverity } from '../FileSelectDialog/FileSelectDialog';
import { errorRequestNotFoundText } from '../documentManagement/DocumentManagement';
import { Utils } from '../../utilities/utils';
import IClientEngagement from '../../interfaces/IClientEngagement';
import { useDispatch, useSelector } from 'react-redux';
import { setAreSelectedClientEngagementsLoaded, setClientsAndEngagements } from '../../store/actions';
import { useUpdateSelectedClientsAndEngagements } from './useUpdateSelectedClientsAndEngagements';


/**
 * A hook for fetching clients and engagements on the first render of the application. 
 * Also determines which route is being accessed and selects the appropriate data after fetching.
 */
const useFetchClientsAndEngagements = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [fetchingClientsAndEngagements, setFetchingClientsAndEngagements] = useState(true);

    const engagementManagementMatch = useRouteMatch({
        path: "/engagement-management/:id",
        exact: true,
    });
    const documentManagementMatch = useRouteMatch("/document-management/:id");

    const clients = useSelector((state: any) => state.clients);
    const engagements = useSelector((state: any) => state.engagements);
    const areSelectedClientEngagementsLoaded = useSelector((state: any) => state.areSelectedClientEngagementsLoaded);

    const { updateSelectedClientAndEngagementIds } = useUpdateSelectedClientsAndEngagements();
    const dataReadService = new DataReadService();

    const dispatch = useDispatch();

    useEffect(() => {
        if (!areSelectedClientEngagementsLoaded) {
            fetchClientsAndEngagements();
        }
    }, []);

    const fetchClientsAndEngagements = async () => {
        const selectedClientsAndEngagements = await getClientsAndEngagementsToSelect();
        await updateSelectedClientAndEngagementIds(selectedClientsAndEngagements);

        await fetchData();

        dispatch(setAreSelectedClientEngagementsLoaded(true));
    };

    const fetchData = async () => {
        setFetchingClientsAndEngagements(true);

        const dataReadService = new DataReadService();
        const response = await dataReadService.GetClientEngagements();

        let clientAndEngagementList: IClientEngagement[] = [];
        if (response.status) {
            clientAndEngagementList = response?.data;
            updateAppState(clientAndEngagementList);
        }
        else {
            enqueueSnackbar("There was a problem loading clients and engagements. Please refresh this page and try again.",
                { variant: errorSeverity, preventDuplicate: true });
        }

        setFetchingClientsAndEngagements(false);
        return clientAndEngagementList;
    }

    const getClientsAndEngagementsToSelect = async () => {
        let selectedClientsAndEngagements: string[] = [];

        if (engagementManagementMatch) {
            selectedClientsAndEngagements = await getSelectedClientsAndEngagementsByTaskId() || [];
        }
        else if (documentManagementMatch) {
            selectedClientsAndEngagements = await getSelectedClientsAndEngagementsByFolderOrDocumentId() || [];
        }
        else {
            selectedClientsAndEngagements = loadSelectedClientEngagementIdsSessionStorage() || [];
        }

        return selectedClientsAndEngagements;
    }

    const loadSelectedClientEngagementIdsSessionStorage = () => {
        var ids = Utils.getSessionStorage('selectedClientEngagementIds');
        return ids?.toString().split('|');
    }

    const updateAppState = (clientAndEngagementList: IClientEngagement[]) => {
        dispatch(setClientsAndEngagements({
            clients: clientAndEngagementList.filter(ce => ce.isClient),
            engagements: clientAndEngagementList.filter(ce => !ce.isClient)
        }));
    }

    const updateClientAndEngagementList = async (clientIdToSelect?: string) => {
        const clientAndEngagementList = await fetchData();
        return clientAndEngagementList;
    }

    const getSelectedClientsAndEngagementsByFolderOrDocumentId = async () => {
        const hierarchyId: string | null = Utils.getUrlGUID();
        if (!hierarchyId) return;

        const response = await dataReadService.GetClientEngagementsByFolderOrDocumentId(hierarchyId)
        const errorRequestNotFoundText = "The requested item was not found.";

        if (response.status) {
            return response.data;
        }
        else {
            enqueueSnackbar(errorRequestNotFoundText, { variant: errorSeverity, preventDuplicate: true });
        }
    }

    const getSelectedClientsAndEngagementsByTaskId = async () => {
        const taskId: string | null = Utils.getUrlGUID();
        if (!taskId) return;

        const dataReadService = new DataReadService();
        const response = await dataReadService.GetClientEngagementsByTaskId(taskId)
        if (response.data.length && response.status) {
            return response.data;
        }
        else {
            enqueueSnackbar(errorRequestNotFoundText, { variant: errorSeverity, preventDuplicate: true });
        }
    }

    return {
        updateClientAndEngagementList,
        loadingClientsAndEngagements: fetchingClientsAndEngagements,
        clientEngagements: [...clients, ...engagements],
    };
};

export default useFetchClientsAndEngagements;