import React from 'react'
import { Tooltip, makeStyles, Switch, FormLabel } from '@material-ui/core'
import { LockOpen } from '@material-ui/icons'
import ICurrentUser from '../../interfaces/ICurrentUser';
import { useSelector } from 'react-redux';
import RestrictedIcon from '../common/RestrictedIcon/RestrictedIcon';
import ITask from '../../interfaces/ITask';
import { useUpdateRequestRestriction } from '../../hooks/useUpdateRequestRestriction';
import SpinnerBackdrop from '../common/SpinnerBackdrop';
import RequestRestrictionConfirmDialog from '../RequestRestrictionConfirmDialog/RequestRestrictionConfirmDialog';

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(2)
    },
    switch: {
        "& .MuiSwitch-thumb": {
            marginTop: theme.spacing(-1.5)
        },
        "& .MuiTypography-root": {
            marginTop: theme.spacing(2),
        },
        "& .MuiFormLabel-root": {
            margin: 0,
            paddingLeft: '2px',
        },
        "& .MuiFormControlLabel-labelPlacementStart": {
            margin: 0,
        }
    },
}));

interface RestrictRequestToggleProps {
    task: ITask;
    onUpdateTaskRestriction: (task: ITask) => void;
}

export default function RestrictRequestToggle(props: RestrictRequestToggleProps) {
    const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
    const { updateRequestRestriction, loading } = useUpdateRequestRestriction();

    const isRestricted = props.task.isRestricted;

    const updateTaskRestriction = async () => {
        setConfirmDialogOpen(false);

        const updatedTask = await updateRequestRestriction([props.task], !props.task.isRestricted) as ITask;

        if (updatedTask) {
            props.onUpdateTaskRestriction(updatedTask);
        }
    }

    const onClickRestrictionToggle = () => {
        setConfirmDialogOpen(true);
    }

    return (<>
        <SpinnerBackdrop isActive={loading} />
        <RequestRestrictionConfirmDialog
            isOpen={confirmDialogOpen}
            onConfirm={updateTaskRestriction}
            onClose={() => setConfirmDialogOpen(false)}
            restricting={!isRestricted}
        />

        <Toggle
            isRestricted={isRestricted}
            onClick={onClickRestrictionToggle}
        />
    </>
    )
}

function Toggle({ isRestricted, onClick }: { isRestricted: boolean; onClick: () => void; }) {
    const classes = useStyles();
    const currentUser = useSelector((state: any) => state.currentUser as ICurrentUser);

    const icon = isRestricted ? <RestrictedIcon /> : <LockOpen />;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        event.stopPropagation();
        onClick();
    };

    return currentUser.isMossAdamsStaff ? (
        <Tooltip title={"If Restriction is enabled, visibility of the request and associated data is limited to the Assignees and Moss Adams users."}>
            <span className={classes.buttonContainer}>
                <FormLabel id="update-request-restriction-toggle"
                    className={classes.switch}
                >
                    Restricted:
                    <Switch color="primary"
                        onChange={handleChange}
                        checked={isRestricted}
                    />
                </FormLabel>
                {icon}
            </span>
        </Tooltip>
    ) : null;
}