import React, { useState } from 'react';
import { useSelector } from 'react-redux';

export const useSelectedClientsAndEngagements = () => {
    const selectedClients: string[] = useSelector((state: any) => state.selectedClients);
    const selectedEngagements: string[] = useSelector((state: any) => state.selectedEngagements);
    const selectedClientEngagementIds: string[] = useSelector((state: any) => state.selectedClientEngagementIds);
    const areSelectedClientEngagementsLoaded: string[] = useSelector((state: any) => state.areSelectedClientEngagementsLoaded);


    return {
        selectedClients,
        selectedEngagements,
        selectedClientAndEngagementIds: selectedClientEngagementIds,
        areSelectedClientEngagementsLoaded,
    };
};
