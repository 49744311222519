import { makeStyles, Paper } from '@material-ui/core';
import React from 'react';
import PageHeader from '../PageHeader/PageHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { AllModules, FirstDataRenderedEvent, GridApi, ValueFormatterParams, ValueGetterParams } from "@ag-grid-enterprise/all-modules";
import { DropZoneRenderer, IDropZoneRendererProps } from "../AgGridRenderers/DropZoneRenderer";
import { fullWindowHeight } from "../../helpers/portalTheme";
import ITask from "../../interfaces/ITask";
import { LocalDateTimeString } from "../../helpers/DateHelpers";
import ITaskListRequestDto from "../../interfaces/ITaskListRequestDto";
import IContentHierarchy from "../../interfaces/IContentHierarchy";
import DataReadService from "../../services/DataReadService";
import IPlatformApiGetResult from "../../interfaces/IPlatformApiGetResult";
import IEngagementManagementListVM from "../../interfaces/IEngagementManagementListVM";
import { SnackbarVariantTypes } from "../../helpers/enums/enums";
import { useSnackbar } from 'notistack';
import ITaskDocument from "../../interfaces/ITaskDocument";
import DataWriteService from "../../services/DataWriteService";
import { Utils } from "../../utilities/utils";
import { HierarchyType } from '../../helpers/enums/HierarchyType';
import { TaskStatus } from '../../helpers/enums/TaskStatus';
import { assignedToUserNameValueFormatter } from '../engagementManagement/RequestCellValueFormatters';
import { assignedToUserNameValueGetter } from '../AgGridValueGetters/TaskAssigneeValueGetter';
import IUserEngagement from '../../interfaces/IUserEngagement';
import IUser from '../../interfaces/IUser';
import RestrictedAttachmentsConfirmDialog from '../RestrictedAttachmentConfirmDialog/RestrictedAttachmentsConfirmDialog';
import RestrictedIcon from '../common/RestrictedIcon/RestrictedIcon';
import { restrictedRenderer } from '../engagementManagement/RequestCellRenderers';

const useStyles = makeStyles((theme) => ({
    taskGrid: {
        height: "100%",
        marginTop: theme.spacing(3),
    },
    paperTableRoot: {
        "& .MuiPaper-root": {
            height: "-webkit-fill-available",
        },
    },
    fullWindowHeight
}));

interface ITaskListDnDProps {
    engagementId?: string;
    documentGridApi?: GridApi;
    engagementName?: string;
}



const TaskListDnD = (props: ITaskListDnDProps) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [taskListGridApi, setTaskListGridApi] = React.useState<GridApi>();

    const [restrictedAttachmentsConfirmDialogIsOpen, setRestrictedAttachmentsConfirmDialogIsOpen] = React.useState(false);
    const [tasks, setTasks] = React.useState<ITask[] | null>(null);
    const [clientUserList, setClientUserList] = React.useState<IUser[] | null>(null);
    const [userEngagementList, setUserEngagementList] = React.useState<IUserEngagement[]>([]);
    const [filesToAttach, setFilesToAttach] = React.useState<IContentHierarchy | null>(null);
    const [taskToAttachTo, setTaskToAttachTo] = React.useState<string | null>(null);

    const noRowsOverlay = () => {
        return (<span>No requests for the selected engagement</span>);
    }

    const components = {
        dropZoneRenderer: DropZoneRenderer,
        noRowsOverlay: noRowsOverlay,
        restrictedRenderer,
    }

    React.useEffect(() => {
        if (props.engagementId != null) {
            setTasks(null);
            setClientUserList(null);
            setUserEngagementList([]);
            getTaskList();
        }
    }, [props.engagementId]);

    const getTaskList = (): void => {
        taskListGridApi?.showLoadingOverlay();

        const dataReadService = new DataReadService();

        if (props.engagementId) {
            const requestDto: ITaskListRequestDto = {
                clientIds: [],
                engagementIds: [props.engagementId]
            };

            dataReadService.GetEngagementManagementList(requestDto).then((response: IPlatformApiGetResult<IEngagementManagementListVM>) => {
                if (response.status) {
                    const vm: IEngagementManagementListVM = response.data[0];
                    setTasks(vm.tasks.filter(task => task.taskId != null));
                    setClientUserList(vm.clientUserList);
                    setUserEngagementList(vm.userEngagementList);
                }
                else {
                    enqueueSnackbar("There was an error retrieving the requests for this folder.", { variant: SnackbarVariantTypes.Error });
                }
            });
        }
        taskListGridApi?.showLoadingOverlay();
    }

    const onTasksGridReady = (params: any) => {
        setTaskListGridApi(params.api);
    }

    const onDropDocument = (contentHierarchy: IContentHierarchy, taskId: string) => {
        const rowNode = taskListGridApi?.getRowNode(taskId);
        if (rowNode?.data.isRestricted) {
            setFilesToAttach(contentHierarchy);
            setTaskToAttachTo(taskId);
            setRestrictedAttachmentsConfirmDialogIsOpen(true);
        }
        else {
            createTaskDocument(contentHierarchy, taskId);
        }
    }

    const createTaskDocument = async (contentHierarchy: IContentHierarchy, taskId: string) => {
        if (contentHierarchy?.hierarchyTypeDescription == HierarchyType.File.string && taskId) {

            taskListGridApi?.showLoadingOverlay();

            const taskDocumentToAdd: ITaskDocument = {
                documentId: contentHierarchy.id,
                taskId: taskId,
                folderId: contentHierarchy.parentId,
                fileName: contentHierarchy.name,
                validFrom: new Date(),
                fileExtension: contentHierarchy.fileExtension,
                displayName: contentHierarchy.displayName,
                onMultipleTasks: false,
                isPrivate: contentHierarchy.isPrivate,
                expirationDate: new Date(),
            }
            const taskDocList = [taskDocumentToAdd];

            const dataWriteService = new DataWriteService();
            const result = await dataWriteService.CreateTaskDocument(taskDocList);

            if (result.status) {
                const attachedDocument = result.response?.data.find((taskDoc: ITaskDocument) => taskDoc.documentId === contentHierarchy.id);
                const rowNode = props.documentGridApi?.getRowNode(contentHierarchy.id);
                if (rowNode?.data) {
                    const rowNodeData: IContentHierarchy = rowNode?.data;
                    const task = tasks!.find(task => task.taskId === taskId);
                    rowNodeData.isPrivate = attachedDocument.isPrivate;
                    if (task?.taskNumber && task?.taskId) {
                        rowNodeData.associatedTasks = [{ taskNumber: Number(task.taskNumber!), taskId: task.taskId!, isDeleted: task.taskStatusId == TaskStatus.Deleted.id }];
                    }
                    rowNode.setData(rowNodeData);
                }

                enqueueSnackbar("Document attached", { variant: SnackbarVariantTypes.Success });
            } else {

                const errors = ["Failed to add document.", ...result.errorMessages];
                Utils.enqueueMultiLineSnackbar(errors, enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
            }

            taskListGridApi?.hideOverlay();

        }
    }

    const canBeDropped = (data: ITask) => {
        return data.taskStatusDescription != "Completed";
    }

    const dropZoneRendererParams: IDropZoneRendererProps = {
        handleDrop: onDropDocument,
        validateData: canBeDropped,
    }

    function dateFormatter(params: any) {
        if (params.value) {
            return LocalDateTimeString(params.value, false);
        }
    }

    function onFirstDataRendered(event: FirstDataRenderedEvent) {
        const statusFilter: any = event.api.getFilterInstance("taskStatusDescription");
        if (statusFilter) {
            const defaultValues = statusFilter.getValues().filter((v: string) => {
                if (v !== 'Completed') { // Deselect these
                    return true;
                } else {
                    return false;
                }
            });
            statusFilter.setModel({ values: defaultValues });
            event.api.onFilterChanged();
        }
    }


    const onConfirmRestrictedAttachments = async () => {
        if (filesToAttach && taskToAttachTo) {
            onCloseRestrictedAttachmentsDialog();
            await createTaskDocument(filesToAttach, taskToAttachTo);
        }
    }

    const onCloseRestrictedAttachmentsDialog = () => {
        setRestrictedAttachmentsConfirmDialogIsOpen(false);
        setFilesToAttach(null);
    }

    return (
        <>
            <RestrictedAttachmentsConfirmDialog
                isOpen={restrictedAttachmentsConfirmDialogIsOpen}
                onConfirm={onConfirmRestrictedAttachments}
                onClose={onCloseRestrictedAttachmentsDialog}
            />
            <PageHeader>
                {props.engagementName && `${props.engagementName} - `}Requests
            </PageHeader>
            <Paper className={classes.fullWindowHeight + " " + classes.paperTableRoot}>
                <div className={"ag-theme-alpine " + classes.taskGrid}>
                    <AgGridReact
                        rowData={tasks}
                        editType=""
                        rowSelection="single"
                        components={components}
                        onGridReady={onTasksGridReady}
                        loadingOverlayComponent={CircularProgress}
                        suppressCellFocus={true}
                        suppressMenuHide={true}
                        getRowId={(row: any) => row.data.taskId}
                        modules={AllModules}
                        noRowsOverlayComponent={'noRowsOverlay'}
                        defaultColDef={{
                            editable: false,
                            sortable: true,
                            filter: true,
                            resizable: true,
                            floatingFilter: false,
                        }}
                        onFirstDataRendered={onFirstDataRendered}
                    >
                        <AgGridColumn
                            field="isRestricted"
                            headerName="Restricted"
                            minWidth={60}
                            maxWidth={60}
                            cellRenderer="restrictedRenderer"
                            headerComponent={() => <RestrictedIcon />}
                        />
                        <AgGridColumn field="taskNumber" headerName="#" initialSort="asc" minWidth={70} maxWidth={80} suppressMenu={true}></AgGridColumn>
                        <AgGridColumn field="title" headerName="Request Title" minWidth={150} filter={true} cellRenderer="dropZoneRenderer" cellRendererParams={dropZoneRendererParams}></AgGridColumn>
                        <AgGridColumn field="description" headerName="Description" filter="agTextColumnFilter" minWidth={300} maxWidth={400}></AgGridColumn>
                        <AgGridColumn
                            field="taskAssignees"
                            headerName="Assignee(s)"
                            minWidth={120}
                            filter="agTextColumnFilter"
                            getQuickFilterText={(params: any) => {
                                return assignedToUserNameValueFormatter(params, clientUserList, userEngagementList);
                            }}
                            valueGetter={(params: ValueGetterParams) => {
                                return assignedToUserNameValueGetter(params, clientUserList);
                            }}
                            valueFormatter={(params: ValueFormatterParams) => assignedToUserNameValueFormatter(params, clientUserList, userEngagementList)}
                        />
                        <AgGridColumn field="taskTypeDescription" headerName="Request Type" minWidth={140} maxWidth={140} filter={true}></AgGridColumn>
                        <AgGridColumn field="taskStatusDescription" headerName="Status" minWidth={180} filter={true}></AgGridColumn>
                        <AgGridColumn field="requestedDate" headerName="Due Date" minWidth={110} maxWidth={140} valueFormatter={dateFormatter} textAlign="right"
                            getQuickFilterText={(params: any) => {
                                return LocalDateTimeString(params.value, false);
                            }}
                        ></AgGridColumn>
                    </AgGridReact>
                </div>
            </Paper>
        </>)
}



export default TaskListDnD;