import { GroupCellRendererParams } from "@ag-grid-enterprise/all-modules";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { LocalDateTimeString } from "../../helpers/DateHelpers";
import { taskIsPlaceholderNode } from "../../helpers/TaskHelpers";
import ITask from "../../interfaces/ITask";
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    engagementHeader: {
        overflow: "auto",
        textOverflow: "ellipsis",
        lineHeight: "inherit",
        fontFamily: "Founders Grotesk Med",
        fontSize: "1.25rem",
        color: theme.palette.mossadams.darkGreen600,
        letterSpacing: "0rem"
    },
    engagementName: {
        maxWidth: '42%',
    },
    editTaskListIcon: {
        marginBottom: '4px',
    },
}));

const EngagementGroupCellRenderer = (props: GroupCellRendererParams, isUserStaff: boolean = false) => {
    const allLeafChildren = props?.node?.allLeafChildren;
    const classes = useStyles();
    let history = useHistory();
    const maContact = allLeafChildren?.[0]?.data?.maContact ? 'MA Contact: ' + allLeafChildren[0].data.maContact : "";

    let taskListId = allLeafChildren?.[0]?.data?.taskListId;
    if (!taskListId) { // Deals with the case where tasklistId is undefined due to Entity groups.
        const nodes: ITask[] = [];
        props.api.forEachNode(node => node?.data && nodes.push(node.data));
        taskListId = nodes?.[0]?.taskListId;
    }

    let requestCount = (props?.node?.allChildrenCount || 0);

    // Need to check for the "No requests available" node and set count to zero if found.
    if (requestCount === 1 && taskIsPlaceholderNode(allLeafChildren?.[0]?.data)) {
        requestCount = 0;
    }

    return (
        <>
            <Grid container justifyContent="flex-start" >
                <Grid item className={classes.engagementName}>
                    <Typography className={classes.engagementHeader} variant="subtitle1">
                        {props.value} ({requestCount})
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography className={classes.engagementHeader} variant="body1">
                        {maContact}
                    </Typography>
                </Grid>
                <Grid item>
                    {isUserStaff && <>
                        <Button variant="text" size="small" className={classes.editTaskListIcon}
                            onClick={() => { history.push('/engagement-management/request-list/' + taskListId) }}>
                            <EditIcon />
                        </Button>
                    </>}
                </Grid>
            </Grid>
        </>
    )
}

export default EngagementGroupCellRenderer;