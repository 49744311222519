import IClientEngagement from "../../interfaces/IClientEngagement";
import { ILookupLists } from "../../interfaces/ILookupLists";

export const setClientsAndEngagements = (appState: { clients: IClientEngagement[], engagements: IClientEngagement[] }) => {
    return {
        type: 'SET_CLIENTS_AND_ENGAGEMENTS',
        payload: appState
    };
};

export const setSelectedClients = (appState: string[]) => {
    return {
        type: 'SET_SELECTED_CLIENTS',
        payload: appState
    };
};

export const setSelectedEngagements = (appState: string[]) => {
    return {
        type: 'SET_SELECTED_ENGAGEMENTS',
        payload: appState
    };
};

export const setSelectedClientEngagementIds = (appState: string[]) => {
    return {
        type: 'SET_SELECTED_CLIENT_ENGAGEMENT_IDS',
        payload: appState
    };
};

export const setAreSelectedClientEngagementsLoaded = (appState: boolean) => {
    return {
        type: 'SET_ARE_SELECTED_CLIENT_ENGAGEMENTS_LOADED',
        payload: appState
    };
};

export const setCurrentUser = (appState: any) => {
    return {
        type: 'SET_CURRENT_USER',
        payload: appState
    };
};

export const setEngagementManagementIsDirty = (appState: any) => {
    return {
        type: 'SET_ENGAGEMENT_MANAGEMENT_IS_DIRTY',
        payload: appState
    };
};

export const setTaskListEditorIsDirty = (appState: any) => {
    return {
        type: 'SET_TASK_LIST_EDITOR_IS_DIRTY',
        payload: appState
    };
};

export const setClientEngagementSelectorDisabled = (appState: boolean) => {
    return {
        type: 'SET_CLIENT_ENGAGEMENT_SELECTOR_DISABLED',
        payload: appState
    };
};

export const setLookupLists = (appState: ILookupLists) => {
    return {
        type: 'SET_LOOKUP_LISTS',
        payload: appState
    };
};

export const setLoadingLookupLists = (appState: boolean) => {
    return {
        type: 'SET_LOADING_LOOKUP_LISTS',
        payload: appState
    };
};