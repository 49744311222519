import { Dialog } from '@material-ui/core';
import * as React from 'react';
import IDocument from '../../interfaces/IDocument';
import { IBulkDocumentActionHelper } from '../documentManagement/useDocumentsBulkActionHelper';
import RestrictedFileUserAssociationDialogContent, { UpdateDocumentAccessModel } from './RestrictedFileUserAssociationDialogContent';


interface BulkRestrictedFileAssociationDialogProps {
    open: boolean;
    onClose: () => void;
    bulkDocumentHelper: IBulkDocumentActionHelper;
    onUpdateAccess: (isPrivate: boolean, usersWithAccess: string[]) => void;
}

const BulkRestrictedFileAssociationDialog = ({ open, onClose, onUpdateAccess, bulkDocumentHelper }: BulkRestrictedFileAssociationDialogProps) => {
    const firstSelection = bulkDocumentHelper?.selected?.[0];

    const doc: UpdateDocumentAccessModel = {
        documentId: firstSelection?.id as string,
        createdByUserId: firstSelection?.createdByUserId as string,
        parentId: firstSelection?.parentId as string,
        isPrivate: bulkDocumentHelper.selected?.some(s => s.isPrivate) as boolean,
    }

    return <>
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
        >
            <RestrictedFileUserAssociationDialogContent title='Manage File Access'
            onClose={onClose} 
            document={doc} 
            bulkDocumentHelper={bulkDocumentHelper.isRestrictionMode ? bulkDocumentHelper : undefined} 
            onUpdateAccess={onUpdateAccess}
            />
        </Dialog>
    </>
}

export default BulkRestrictedFileAssociationDialog