import React from 'react';
import { Link } from 'react-router-dom';
import IContentHierarchy, { AssociatedTask } from '../../interfaces/IContentHierarchy';
import { ICellRendererParams } from '@ag-grid-community/core';
import { HierarchyType } from '../../helpers/enums/HierarchyType';

const AssociatedRequestsCellRenderer = (params: ICellRendererParams) => {
    const data: IContentHierarchy = params.data;
    const isNotFile = data?.hierarchyTypeDescription !== HierarchyType.File.string;
    if (isNotFile) return '';

    const associatedTasks: AssociatedTask[] = params.data?.associatedTasks;
    if (associatedTasks !== null && associatedTasks.length > 0) {
        const links = associatedTasks.map((associatedTask: AssociatedTask, index: number) =>
            <span key={index}>
                {associatedTask?.isDeleted ? <span>{associatedTask?.taskNumber.toString()}[Deleted]</span> :
                    <Link to={`/engagement-management/${associatedTask?.taskId}`}>{associatedTask?.taskNumber.toString()}</Link>}
                {associatedTasks?.[index + 1] ? ', ' : ''}
            </span>
        )
        return links;
    }
    return "Unfiled";
}

export default AssociatedRequestsCellRenderer;