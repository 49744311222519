import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Typography, TypographyProps } from "@material-ui/core";
import IPageHeaderProps from "./IPageHeaderProps"

const useStyles = makeStyles((theme) => ({
    pageTitle: {
        color: theme.palette.primary.main,
        letterSpacing: "0rem",
        paddingBottom: '1.5rem',
        textTransform: "none",
        fontWeight: "bold",
        fontFamily: "Berlingske Serif Bold",
        fontSize: "2.25rem",
        lineHeight: "2.688rem",
    },
    noPadding: {
        paddingBottom: theme.spacing(0),
    }
}));

const PageHeader = <C extends React.ElementType>(props: TypographyProps<C, { component?: C }> & IPageHeaderProps) => {
    const classes = useStyles();
    const { withoutPadding, ...rest } = props;
    return (
        <Typography {...rest} className={classes.pageTitle + (withoutPadding ? ` ${classes.noPadding} ` : ' ') + props.className} variant="h1">{props.children}</Typography>
    )
}

export default PageHeader;