import React from "react";
import { Button, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import IFileSelectDialogProps from "./IFileSelectDialogProps"
import MADialogTitle from "../MADialogTitle/MADialogTitle"
import DocumentTree from "./DocumentTree";
import IContentHierarchy from "../../interfaces/IContentHierarchy";
import IDocumentCopyModel from '../../interfaces/IDocumentCopyModel';
import DataWriteService from "../../services/DataWriteService";
import IPlatformApiPostResult from '../../interfaces/IPlatformApiPostResult';
import { VariantType } from 'notistack';
import { useSnackbar } from 'notistack';
import { makeStyles } from "@material-ui/core/styles";
import { validateFileName } from "../../Validators/FileValidators";
import { SnackbarVariantTypes } from "../../helpers/enums/enums";
import { Utils } from "../../utilities/utils";

export const errorAttachingFileText = 'There was an error attaching the file.';
export const errorAttachingDuplicateFileText = "A file with this name already exists in this folder. Please choose a different name for the file.";
export const errorSeverity: VariantType = 'error';

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        height: "70vh",
        width: "70vw",
        minWidth: "900px !important",
        maxWidth: "none"
    },
    dialogActionsClass: {
        flexDirection: "column",
    },
    fileNameTextField: {
        [theme.breakpoints.up("xl")]: {
            zoom: "160%"
        }
    }
}));

export default function FileSelectDialog(props: IFileSelectDialogProps) {

    const { enqueueSnackbar } = useSnackbar();
    const [selectedFiles, setSelectedFiles] = React.useState<IContentHierarchy[]>([]);
    const [fileNameValidationMessage, setFileNameValidationMessage] = React.useState<string>("");
    const [fileName, setFileName] = React.useState<string>("");
    const [isFileNameValid, setIsFileNameValid] = React.useState(true);
    const classes = useStyles();

    React.useEffect(() => {
        const validation = validateFileName(fileName);
        setIsFileNameValid(validation.isValid);
        setFileNameValidationMessage(validation.error);
    }, [fileName]);

    const handleClose = () => {
        setSelectedFiles([]);
        setIsFileNameValid(true);
        props.onClose();
    }

    const handleFileSelect = (files: IContentHierarchy[]) => {
        setSelectedFiles(files);
        if (files.length == 1) {
            setFileName(files[0].name);
        }
    }

    const handleClick = async () => {
        if (props.chooseFromCurrentEngagement) {
            props.onSelect(selectedFiles);
            handleClose();
            return;
        }

        // Call the copy document api and get new content hierarchy record
        const model: IDocumentCopyModel = {
            folderId: props.folderId!,
            fileName: fileName.length ? fileName : selectedFiles[0]!.name,
            sourceFileId: selectedFiles[0]!.id
        }
        const service = new DataWriteService();
        let result: IPlatformApiPostResult;
        result = await service.CopyDocument(model);
        if (result.status) {
            props.onSelect(result.response?.data);
            handleClose();
        } else {
            let errors = [errorAttachingFileText];
            result.errorMessages.forEach((errorMessage) => {
                if (errorMessage.includes("name already exists")) {
                    setIsFileNameValid(false);
                    setFileNameValidationMessage(errorAttachingDuplicateFileText);
                    errors.push(errorAttachingDuplicateFileText);
                } else {
                    errors.push(errorMessage);
                }
            });
            Utils.enqueueMultiLineSnackbar(errors, enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
        }
    }

    const handleDialogClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    }

    return (
        <div className={props.className}>
            <Dialog
                open={props.isOpen}
                onClose={handleClose}
                aria-labelledby="select-file-dialog"
                maxWidth={"md"}
                fullWidth={true}
                onClick={handleDialogClick}
                classes={{ paper: classes.dialogPaper }}
            >
                <MADialogTitle id="select-file-dialog-title" onClose={handleClose}>
                    {props.chooseFromCurrentEngagement ? "Attach" : "Copy"} existing file(s) to Request# {props.taskNumber} - {props.taskTitle}
                </MADialogTitle>
                <DialogContent>
                    <DocumentTree
                        clientId={props.clientId}
                        engagementId={props.engagementId}
                        selectedFiles={selectedFiles}
                        handleFileSelect={handleFileSelect}
                        chooseFromCurrentEngagement={props.chooseFromCurrentEngagement}
                        allowMultiSelect={props.allowMultiple}
                    />
                </DialogContent>
                <DialogActions className={classes.dialogActionsClass}>
                    <Button onClick={handleClick} color="primary" fullWidth={true} variant="outlined" disabled={selectedFiles.length == 0 || isFileNameValid === false} >
                        {props.chooseFromCurrentEngagement ? "Attach to Request" : "Copy"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
