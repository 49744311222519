import saveAs from "file-saver";
import { useSnackbar } from "notistack";
import { SnackbarVariantTypes } from "../helpers/enums/enums";
import DataReadService from "../services/DataReadService";
import { Utils } from "../utilities/utils";
import useAsyncFunctionIsLoading from "./useIsLoadingAsync";

const errorDownloadFileText = 'There was an error downloading the file.';

export const useDownloadFile = () => {
    const { enqueueSnackbar } = useSnackbar();

    const downloadFile = async (documentId: string, fileName: string, fileVersion?: string | undefined) => {
        const dataReadService = new DataReadService();

        return dataReadService.DownloadDocument(documentId, fileVersion).then((response) => {
            if (response.status) {
                //@ts-ignore createObjectURL expects Blob or MediaSource object.
                //The api returns a BlobPart(superset that contains Blob) but IPlatformApiGetResult casts it to a BlobPart[]
                let url = window.URL.createObjectURL(response.data);
                saveAs(url, fileName);
                enqueueSnackbar("Successfully downloaded document.", { variant: SnackbarVariantTypes.Success })
            }
            else {
                const errors = [errorDownloadFileText, ...response.errorMessages];
                Utils.enqueueMultiLineSnackbar(errors, enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
            }
        })
    }

    const { onTrigger: handleDownload, loading: downloadingFile } = useAsyncFunctionIsLoading(downloadFile);

    return { handleDownload, downloadingFile };
}