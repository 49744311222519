
import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({

    backdrop: {
        zIndex: 100000,
        color: '#fff',
    },

}))

interface ISpinnerBackdropProps {
    isActive: boolean,
}

const SpinnerBackdrop = (props: ISpinnerBackdropProps) => {
    const classes = useStyles();

    return (
        <Backdrop onClick={e => e.stopPropagation()} className={classes.backdrop} open={props.isActive}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default SpinnerBackdrop