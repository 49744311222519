import React from 'react';
import RestrictedIcon from '../common/RestrictedIcon/RestrictedIcon';

export const PrivateFileCellRenderer = (params: any) => {

    if (!params?.data) {
        return "";
    }

    return params?.data?.isPrivate && <RestrictedIcon useGridStyle />
}