import { makeStyles } from '@material-ui/core';
import Icon from '@material-ui/icons/Lock';
import React from 'react'

const useStyles = makeStyles((theme) => ({
    lockIcon: {
        color: '#900000',
    },
    gridStyle: {
        height: "40px"
    }
}));

interface Props {
    useGridStyle?: boolean
}

export default function RestrictedIcon(props: Props) {
    const classes = useStyles();

    const classNames = `${classes.lockIcon} ${props.useGridStyle ? classes.gridStyle : ''}`
    return (
        <Icon className={classNames} />
    )
}