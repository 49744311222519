import { VariantType } from 'notistack';

//Task related enums have moved to the following files
// ./TaskArea.ts
// ./TaskPhase.ts
// ./TaskPriority.ts
// ./TaskStatus.ts
// ./TaskType.ts

export class SnackbarVariantTypes {
    static Default: VariantType = 'default';
    static Error: VariantType = 'error';
    static Success: VariantType = 'success';
    static Warning: VariantType = 'warning';
    static Info: VariantType = 'info';
}