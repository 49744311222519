import React from 'react';
import ITaskAssignee from '../../interfaces/ITaskAssignee';
import { ICellRendererParams } from '@ag-grid-community/core';

const TaskAssigneeCellRenderer = (params: ICellRendererParams) => {
    const data: ITaskAssignee[] = params.data;

    const taskAssignees: ITaskAssignee[] = params.data?.taskAssignees;
    if (taskAssignees !== null && taskAssignees.length > 0) {
        var assignees = taskAssignees.map(function (taskAssignee: ITaskAssignee) {
            return taskAssignee.displayName
        }).join(', ');
        return <span>{assignees}</span>
    }
    return "";
}

export default TaskAssigneeCellRenderer;