import React from 'react';
import { Backdrop, Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 100000,
        color: theme.palette.common.white,
    },
    uploadProgress: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "50%"
    },
    uploadProgressText: {
        color: theme.palette.common.white,
        fontSize: "1.2rem"
    }
}));

export interface IUploadingBackdropProps {
    progress: number;
    isUploading: boolean;
}


const ProgressSpinnerBackdrop = (props: IUploadingBackdropProps) => {
    const [showProcessing, setShowProcessing] = React.useState(false);

    const classes = useStyles();

    if (props.progress === 99) {
        setTimeout(() => {
            setShowProcessing(true);
        }, 5000);
    }
    else if (showProcessing) {
        setShowProcessing(false);
    }

    return (
        <Backdrop className={classes.backdrop} open={props.isUploading}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
            >
                <Box
                    position="relative"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <CircularProgress color="inherit" variant="determinate" value={props.progress} size={100} className={classes.uploadProgress} />
                    <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography variant="caption" component="div" color="textSecondary" className={classes.uploadProgressText}>
                            {`${props.progress}%`}
                        </Typography>
                    </Box>
                </Box>
                {showProcessing &&
                    <>
                        <Typography variant="caption" component="div" color="textSecondary" className={classes.uploadProgressText}>
                            Finalizing upload, please wait.
                        </Typography>
                        <Typography variant="caption" component="div" color="textSecondary" className={classes.uploadProgressText}>
                            For large uploads this may take a few minutes.
                        </Typography>
                    </>
                }
            </Box>
        </Backdrop>
    )
}

export default ProgressSpinnerBackdrop;