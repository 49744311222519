import { makeStyles } from '@material-ui/core';
import React from 'react'

const useStyles = makeStyles((theme) => ({
    iconContainer: {
        verticalAlign: 'middle',
        fontSize: '.1rem',
        "& .MuiSvgIcon-root": {
            fontSize: '1.1rem'
        },
    },
}));

interface Props {
    children: React.ReactNode;
}

function HelpfulHintIcon({children}: Props) {
  const classes = useStyles();
  return (
    <span className={classes.iconContainer}>
        {children}
    </span>
  )
}

export default HelpfulHintIcon