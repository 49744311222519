import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { parseApiErrors } from '../helpers/DataFormatters';
import IPlatformApiGetResult from '../interfaces/IPlatformApiGetResult';
import IPlatformApiError400 from '../interfaces/IPlatformApiError400';
import IPlatformApiError404 from '../interfaces/IPlatformApiError404';
import IPlatformApiError500 from '../interfaces/IPlatformApiError500';
import IPlatformApiPostResult from '../interfaces/IPlatformApiPostResult';
import TRowData from '../types/TRowData';

const apiTimeOutLimit = parseInt(process.env.REACT_APP_CLIENTPLATFORM_API_TIMEOUT as string);
const fileDownloadTimoutLimit = parseInt(process.env.REACT_APP_CLIENTPLATFORM_DOWNLOAD_TIMEOUT as string);

/**
 * Make GET and POST calls to the Platform API.
 * 
 * @remarks
 * This is intended to be used like an abstract class would be in C#.  That is it should not be used directly, but the other data service classes will extend this class.
 */
export default class PlatformApiService {


    /**
     * Make a GET request to the Platform API to request data.
     * 
     * @param platformApiGetEndpointPath The unique path portion of the url to the Platform API endpoint.
     * 
     * @returns The [[IDataResult]] with a data result of the type T.
     */
    protected async PlatformApiGet<T extends TRowData>(platformApiGetEndpointPath: string, contentType?: string, searchParams?: URLSearchParams): Promise<IPlatformApiGetResult<T>> {

        let result: IPlatformApiGetResult<T> = { data: [], status: true, timeoutOccured: false, errorMessages: [] };

        const platformApiGetEndpoint = `${process.env.REACT_APP_CLIENTPLATFORM_API_URL}${platformApiGetEndpointPath}`;

        const config: AxiosRequestConfig = { timeout: apiTimeOutLimit, validateStatus: function (status: any) { return status < 500 } }
        if (contentType == "application/octet-stream") {
            config.responseType = "blob";
            config.timeout = fileDownloadTimoutLimit;
        }

        if (searchParams) {
            config.params = searchParams;
        }

        await axios.get<T[]>(platformApiGetEndpoint, config)

            .then(response => {

                let platformApiError400: IPlatformApiError400;
                let platformApiError404: IPlatformApiError404;
                let platformApiError500: IPlatformApiError500;
                let errorMessagesParsed: string[];

                switch (response.status) {

                    case 200:

                        result = { ...result, data: response.data };

                        break;

                    case 400:

                        platformApiError400 = response.data as unknown as IPlatformApiError400;

                        errorMessagesParsed = parseApiErrors(platformApiError400.errors);

                        result = { ...result, status: false, errorMessages: errorMessagesParsed };

                        break;

                    case 404:

                        platformApiError404 = response.data as unknown as IPlatformApiError404;

                        result = { ...result, status: false, errorMessages: [platformApiError404.detail] };

                        break;

                    default:

                        result = { ...result, status: false, errorMessages: ['Sorry, there was an error.  If you continue to see this error please, contact your champion.'] };

                        break;
                }

            })
            .catch(error => {

                let errorMessage: string;

                if (error.response) {

                    errorMessage = `PlatformApiGet returned error "${error.response.status} ${error.response.statusText} when making a GET request to ${platformApiGetEndpointPath}".`;

                } else {

                    errorMessage = 'Error:Network Error';
                }

                if (!error.response && error.message.toString().includes('timeout') && error.message.toString().includes('exceeded')) {

                    result = { ...result, timeoutOccured: true };
                }

                /* TODO: Add appInsights without causing tests to fail.
                const exceptionToTrack = generateAppInsightsError({
                    message: errorMessage, 
                    name: 'PlatformApiGet error', 
                    id: 'platformApiServicePlatformApiGetFailure', 
                    severityLevel: SeverityLevel.Critical
                });

                appInsights.trackException(exceptionToTrack);
                */

                result = { ...result, status: false };
            });

        return result;
    }



    /**
     * Make a POST request to the Platform API to request data. Use this when you need to post complex data for the query that won't work on the URL.
     * 
     * @param platformApiPostEndpointPath The unique path portion of the url to the Platform API endpoint.
     * 
     * @returns The [[IDataResult]] with a data result of the type T.
     */
    protected async PlatformApiPostRequest<T extends TRowData, U>(platformApiPostEndpointPath: string, data: U, contentType?: string): Promise<IPlatformApiGetResult<T>> {

        let result: IPlatformApiGetResult<T> = { data: [], status: true, timeoutOccured: false, errorMessages: [] };

        const platformApiPostEndpoint = `${process.env.REACT_APP_CLIENTPLATFORM_API_URL}${platformApiPostEndpointPath}`;

        const config: AxiosRequestConfig = { timeout: apiTimeOutLimit, validateStatus: function (status) { return status === 200 || status === 204 || status === 400 || status === 404 } };

        if (contentType == "application/octet-stream") {
            config.responseType = "blob";
            config.timeout = fileDownloadTimoutLimit;
        }

        await axios.post<T[]>(platformApiPostEndpoint, data, config)
            .then(response => {
                if (!response?.data) throw response;
                let platformApiError400: IPlatformApiError400;
                let platformApiError404: IPlatformApiError404;
                let platformApiError500: IPlatformApiError500;
                let errorMessagesParsed: string[];

                switch (response.status) {

                    case 200:
                    case 204:

                        result = { ...result, data: response.data };

                        break;
                    
                    case 400:
                        
                        platformApiError400 = response.data as unknown as IPlatformApiError400;

                        errorMessagesParsed = parseApiErrors(platformApiError400.errors);

                        result = { ...result, status: false, errorMessages: errorMessagesParsed };

                        break;

                    case 404:

                        platformApiError404 = response.data as unknown as IPlatformApiError404;

                        result = { ...result, status: false, errorMessages: [platformApiError404.detail] };

                        break;


                    default:

                        result = { ...result, status: false, errorMessages: ['Sorry, there was an error.  If you continue to see this error please, contact your champion.'] };

                        break;
                }

            })
            .catch(async error => {

                let errorMessages: string[] = [];

                const is500Error = error?.request?.status === 500;
                if (error.request?.responseType === 'blob' && is500Error) {
                    let errorObjString = await error.response.data.text();
                    let platformApiError500: IPlatformApiError500 = JSON.parse(errorObjString);
                    errorMessages.push(`${platformApiError500.title} ${platformApiError500.detail ?? ''}`);
                }
                else if (is500Error) {
                    errorMessages.push(error.response?.data?.detail);
                }

                if (!error.response && error?.message?.toString().includes('timeout') && error.message.toString().includes('exceeded')) {

                    result = { ...result, timeoutOccured: true };
                }

                /* TODO: Add appInsights without causing tests to fail.
                const exceptionToTrack = generateAppInsightsError({
                    message: errorMessage, 
                    name: 'PlatformApiGet error', 
                    id: 'platformApiServicePlatformApiGetFailure', 
                    severityLevel: SeverityLevel.Critical
                });

                appInsights.trackException(exceptionToTrack);
                */

                result = { ...result, status: false, errorMessages };
            });

        return result;
    }




    /**
     * Make a POST request to the Platform API to create or update data.
     * 
     * @param platformApiPostEndpointPath The unique path portion of the url to the Platform API endpoint.
     * @param data The data payload of type U to be sent as the payload for the POST call.
     * 
     * @returns TODO: Need to standardize the result response.
     */
    protected async PlatformApiPost<T, U>(platformApiPostEndpointPath: string, data: U, axiosConfigurations?: AxiosRequestConfig): Promise<IPlatformApiPostResult> {

        let result: IPlatformApiPostResult = { status: true, response: null, errorMessages: [], timeoutOccured: false };

        const platformApiPostEndpoint = `${process.env.REACT_APP_CLIENTPLATFORM_API_URL}${platformApiPostEndpointPath}`;

        // If a custom timeout response was provided in the axiosConfig parameters use that timeout, else use the standard api timeout limit
        const requestTimeout = axiosConfigurations?.timeout ? axiosConfigurations.timeout : apiTimeOutLimit;

        let axiosConfig: AxiosRequestConfig = { timeout: requestTimeout, validateStatus: function (status) { return status === 200 || status === 204 || status === 400 || status === 404 || status === 500 } };

        // Add a timeout to the axios configurations
        if (axiosConfigurations !== undefined) {

            axiosConfig = { ...axiosConfigurations, timeout: axiosConfig.timeout, validateStatus: axiosConfig.validateStatus };
        }

        await axios.post<T>(platformApiPostEndpoint, data, axiosConfig)
            .then((response: AxiosResponse) => {

                let platformApiError400: IPlatformApiError400;
                let platformApiError404: IPlatformApiError404;
                let platformApiError500: IPlatformApiError500;
                let errorMessagesParsed: string[];

                switch (response.status) {

                    case 200:
                    case 204:

                        result = { ...result, response: response };

                        break;

                    case 400:

                        platformApiError400 = response.data as unknown as IPlatformApiError400;

                        errorMessagesParsed = parseApiErrors(platformApiError400.errors);

                        result = { ...result, status: false, errorMessages: errorMessagesParsed, response };

                        break;

                    case 404:

                        platformApiError404 = response.data as unknown as IPlatformApiError404;

                        result = { ...result, status: false, errorMessages: [`${platformApiError404.title} `, ` ${platformApiError404.detail ?? ''}`] };

                        break;

                    case 500:

                        platformApiError500 = response.data as unknown as IPlatformApiError500;

                        result = { ...result, status: false, errorMessages: [`${platformApiError500.title} ${platformApiError500.detail ?? ''}`] };

                        break;

                    default:

                        result = { ...result, status: false, errorMessages: ['Sorry, there was an error.  If you continue to see this error please, contact your champion.'] };

                        break;
                }
            })
            .catch(error => {

                let errorMessage: string;

                if (!error.response) {

                    errorMessage = 'Error:Network Error';

                } else {

                    errorMessage = `PlatformApiPost returned error "${error.response.status} ${error.response.statusText}" when making a POST request to ${platformApiPostEndpointPath}".`;
                }

                /* TODO: Add appInsights without causing tests to fail.
                const exceptionToTrack = generateAppInsightsError({
                    message: errorMessage, 
                    name: 'PlatformApiPost error', 
                    id: 'platformApiServicePlatformApiPostFailure', 
                    severityLevel: SeverityLevel.Critical
                });

                appInsights.trackException(exceptionToTrack);
                */

                // Similar to the errorMessages in each case of the switch statement, return the useErrorMessage as an array type
                const userErrorMessage = error.message ? error.message : 'There was a server error. Please try again or report a bug.';

                result = { ...result, status: false, errorMessages: [userErrorMessage] };
            });

        return result;
    }

    /**
     * Make a DELETE request to the Platform API to remove a record from the repository.
     * 
     * @param platformApiPostEndpointPath The unique path portion of the url to the Platform API endpoint.
     * @param data The data payload of type U to be sent as the payload for the POST call.
     * 
     * @returns TODO: Need to standardize the result response.
     */
    protected async PlatformApiDelete<T>(platformApiGetEndpointPath: string, data: T): Promise<IPlatformApiPostResult> {

        let result: IPlatformApiPostResult = { status: true, errorMessages: [], response: null, timeoutOccured: false };

        const platformApiGetEndpoint = `${process.env.REACT_APP_CLIENTPLATFORM_API_URL}${platformApiGetEndpointPath}`;

        const deleteConfig: AxiosRequestConfig = { data: data, timeout: apiTimeOutLimit };

        await axios.delete(platformApiGetEndpoint, deleteConfig)
            .then(response => {

                let platformApiError400: IPlatformApiError400;
                let platformApiError404: IPlatformApiError404;
                let platformApiError500: IPlatformApiError500;
                let errorMessagesParsed: string[];

                //Happens when the delete command throws an error. 
                //It needs special handling because an error's payload differs in nesting between errors and successes.  
                //@ts-ignore
                if (response?.isAxiosError === true) {
                    //@ts-ignore
                    response = response.response;
                } 

                switch (response.status) {

                    case 200:
                    case 204:

                        result = { ...result, response: response };

                        break;

                    case 400:

                        platformApiError400 = response.data as unknown as IPlatformApiError400;

                        errorMessagesParsed = parseApiErrors(platformApiError400.errors);

                        result = { ...result, status: false, errorMessages: errorMessagesParsed, response };

                        break;

                    case 404:

                        platformApiError404 = response.data as unknown as IPlatformApiError404;

                        result = { ...result, status: false, errorMessages: [`${platformApiError404.title} `, ` ${platformApiError404.detail ?? ''}`] };

                        break;

                    case 500:

                        platformApiError500 = response.data as unknown as IPlatformApiError500;

                        result = { ...result, status: false, errorMessages: [`${platformApiError500.title} ${platformApiError500.detail ?? ''}`] };

                        break;

                    default:

                        result = { ...result, status: false, errorMessages: ['Sorry, there was an error.  If you continue to see this error please, contact your champion.'] };

                        break;
                }
            })
            .catch(error => {
                let errorMessage: string;

                if (!error.response) {

                    errorMessage = 'Error:Network Error';

                } else if (error.response.data.detail) {

                    errorMessage = error.response.data.detail;

                } else {

                    errorMessage = `PlatformApiDelete returned error "${error.response.status} ${error.response.statusText} when making a DELETE request to ${platformApiGetEndpointPath}".`;
                }

                result = { ...result, status: false, errorMessages: [errorMessage] };
            });

        return result;
    }


    /**
     * Make a PUT request to the Platform API to remove a record from the repository.
     * 
     * @param platformApiPostEndpointPath The unique path portion of the url to the Platform API endpoint.
     * @param data The data payload of type U to be sent as the payload for the POST call.
     * 
     * @returns TODO: Need to standardize the result response.
     */
    protected async PlatformApiPut<T>(platformApiGetEndpointPath: string, data: T): Promise<IPlatformApiPostResult> {

        let result: IPlatformApiPostResult = { status: true, errorMessages: [], response: null, timeoutOccured: false };

        const platformApiGetEndpoint = `${process.env.REACT_APP_CLIENTPLATFORM_API_URL}${platformApiGetEndpointPath}`;

        const putConfig: AxiosRequestConfig = { data: data, timeout: apiTimeOutLimit };

        await axios.put(platformApiGetEndpoint, data, putConfig)
            .then(response => {

                result = { ...result, response };
            })
            .catch(error => {

                let errorMessage: string;

                if (!error.response) {

                    errorMessage = 'Error:Network Error';

                } else {

                    errorMessage = `PlatformApiPut returned error "${error.response.status} ${error.response.statusText} when making a PUT request to ${platformApiGetEndpointPath}".`;
                }

                result = { ...result, status: false };
            });

        return result;
    }

    /**
     * Make a PATCH request to the Platform API to create or update data.
     * 
     * @param platformApiPostEndpointPath The unique path portion of the url to the Platform API endpoint.
     * @param data The data payload of type U to be sent as the payload for the PATCH call.
     * 
     * @returns The Platform response object with either success or errors.
     */
    protected async PlatformApiPatch<T, U>(platformApiPostEndpointPath: string, data: U, axiosConfigurations?: AxiosRequestConfig): Promise<IPlatformApiPostResult> {

        let result: IPlatformApiPostResult = { status: true, response: null, errorMessages: [], timeoutOccured: false };

        const platformApiPostEndpoint = `${process.env.REACT_APP_CLIENTPLATFORM_API_URL}${platformApiPostEndpointPath}`;

        const requestTimeout = apiTimeOutLimit;

        let axiosConfig: AxiosRequestConfig = { timeout: requestTimeout, validateStatus: function (status) { return status === 200 || status === 204 || status === 400 || status === 404 || status === 500 } };

        // Add a timeout to the axios configurations
        if (axiosConfigurations !== undefined) {

            axiosConfig = { ...axiosConfigurations, timeout: axiosConfig.timeout, validateStatus: axiosConfig.validateStatus };
        }

        await axios.patch<T>(platformApiPostEndpoint, data, axiosConfig)
            .then((response: AxiosResponse) => {

                let platformApiError400: IPlatformApiError400;
                let platformApiError404: IPlatformApiError404;
                let platformApiError500: IPlatformApiError500;
                let errorMessagesParsed: string[];

                switch (response.status) {

                    case 200:

                        result = { ...result, response: response };

                        break;

                    case 204:

                        result = { ...result, response: response };

                        break;

                    case 400:

                        platformApiError400 = response.data as unknown as IPlatformApiError400;

                        errorMessagesParsed = parseApiErrors(platformApiError400.errors);

                        result = { ...result, status: false, errorMessages: errorMessagesParsed, response };

                        break;

                    case 404:

                        platformApiError404 = response.data as unknown as IPlatformApiError404;

                        result = { ...result, status: false, errorMessages: [`${platformApiError404.title} ${platformApiError404.detail ?? ''}`] };

                        break;

                    case 500:

                        platformApiError500 = response.data as unknown as IPlatformApiError500;

                        result = { ...result, status: false, errorMessages: [`${platformApiError500.title} ${platformApiError500.detail ?? ''}`] };

                        break;

                    default:

                        result = { ...result, status: false, errorMessages: ['Sorry, there was an error.  If you continue to see this error please, contact your champion.'] };

                        break;
                }
            })
            .catch((error: AxiosError) => {

                let errorMessage: string;

                if (!error.response) {

                    errorMessage = 'Error:Network Error';

                } else {

                    errorMessage = `PlatformApiPost returned error "${error.response.status} ${error.response.statusText}" when making a POST request to ${platformApiPostEndpointPath}".`;
                }

                /* TODO: Add appInsights without causing tests to fail.
                const exceptionToTrack = generateAppInsightsError({
                    message: errorMessage, 
                    name: 'PlatformApiPost error', 
                    id: 'platformApiServicePlatformApiPostFailure', 
                    severityLevel: SeverityLevel.Critical
                });

                appInsights.trackException(exceptionToTrack);
                */

                const userErrorMessage = error.message ? error.message : 'There was a server error. Please try again or report a bug.';

                result = { ...result, status: false, errorMessages: [userErrorMessage] };
            });

        return result;
    }
}