import React from 'react'
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { SnackbarVariantTypes } from '../../helpers/enums/enums';
import { LocalDateTimeString } from '../../helpers/DateHelpers';
import SplitButton from '../common/SplitButton/SplitButton';
import IContentHierarchy from '../../interfaces/IContentHierarchy';
import ExpirationWarningIcon from '../ExpirationWarningIcon/ExpirationWarningIcon';
import { isExpirationDateExtensible } from '../../helpers/DocumentHelpers';
import { updateExpireDates } from '../../services/DocumentService';
import { useSelector } from 'react-redux';
import ICurrentUser from '../../interfaces/ICurrentUser';
import _ from 'lodash';

interface Props {
    document: IContentHierarchy;
    onUpdateExpireDate: Function
}

const useStyles = makeStyles((theme) => {
    return {
        expirationDate: {
            alignItems: 'center',
            justifyContent: 'space-between'
        }
    }
});

function DocumentExpirationDate(props: Props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const currentUser = useSelector((state: any) => state.currentUser as ICurrentUser);

    const [updatedExpireDate, setUpdatedExpireDate] = React.useState(null);
    const [submitting, setSubmitting] = React.useState(false);

    if (!currentUser.isMossAdamsStaff) {
        return null;
    }

    const extendExpireDate = async (dayIncrement: number) => {
        if (props.document) {
            setSubmitting(true);
            const document = _.cloneDeep(props.document);
            document.expirationDate = updatedExpireDate ? updatedExpireDate : document.expirationDate;

            const result = await updateExpireDates([document], dayIncrement);
            if (result.status) {
                props.onUpdateExpireDate(result.response?.data);
                setUpdatedExpireDate(result.response?.data?.[0]?.currentExpirationDate);
                enqueueSnackbar("Document expiration date extended.", { variant: SnackbarVariantTypes.Success });
            }
            else {
                enqueueSnackbar(result.errorMessages, { variant: SnackbarVariantTypes.Error });
            }
            setSubmitting(false);
        }
    }

    const options = [
        { display: "30 days", value: 30 },
    ];

    const renderDate = updatedExpireDate ?? props.document.expirationDate;
    return (
        <Grid item container className={classes.expirationDate}>
            <span>
                <Typography>
                    Expires: {LocalDateTimeString(renderDate, false)} <ExpirationWarningIcon date={renderDate} />
                </Typography>
            </span>
            {isExpirationDateExtensible(renderDate) ?
                <SplitButton
                    isLoading={submitting}
                    defaultText="Extend"
                    defaultOptionIndex={0}
                    options={options} onConfirm={extendExpireDate} />
                : null
            }
        </Grid>
    )
}

export default DocumentExpirationDate