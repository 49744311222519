import React from "react";

export const useCountdown = (initialTime: number, active: boolean) => {
    const [countdown, setCountdown] = React.useState(initialTime);
    const [isCounting, setIsCounting] = React.useState(false);

    React.useEffect(() => {
        if (active && countdown !== 0) {
            setIsCounting(true);

            const timerId = setTimeout(() => {
                setCountdown(prev => prev - 1);
            }, 1000)

            return () => clearTimeout(timerId);
        }
        if (countdown === 0 || !active) {
            setIsCounting(false);
        }
    }, [countdown, initialTime, active])

    React.useEffect(() => {
        setCountdown(initialTime);
    }, [active])

    return { countdown, isCounting };
}
