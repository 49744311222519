import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Tooltip, makeStyles } from '@material-ui/core';
import { Box, Button, Checkbox, Grid, TextField, Typography, FormControl, FormControlLabel, Paper, Divider, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ITask from "../../interfaces/ITask";
import ITaskDetailsEditorProps from "./ITaskDetailsEditorProps";
import { ConfirmDialog } from "../ConfirmDialog/ConfirmDialog";
import { TaskStatus } from "../../helpers/enums/TaskStatus";
import TaskValidator from "../../Validators/TaskValidator";
import { getLookupKey, getUserNameAllowNull, } from "../../utilities/lookupUtils";
import AttachmentsGrid from "../AttachmentsGrid/AttachmentsGrid";
import DataWriteService from "../../services/DataWriteService";
import { useSnackbar } from 'notistack';
import { SnackbarVariantTypes } from "../../helpers/enums/enums";
import _ from "lodash";
import ITaskDocument from '../../interfaces/ITaskDocument';
import { Utils } from '../../utilities/utils';
import { LocalDateTimeString } from '../../helpers/DateHelpers';
import { actionButtonVariant, detailPaneAccordionHeaderStyle, detailPaneAccordionStyle, detailPanePadding, detailPaneTextStyle, detailsPaneLastUpdatedStyle, marginAroundDescription } from "../../helpers/portalTheme";
import SaveIcon from '@material-ui/icons/Save';
import DiscardIcon from '@material-ui/icons/Undo';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AutocompleteField from '../common/AutocompleteField/AutocompleteField';
import DetailDataField from '../common/DetailDataField/DetailDataField';
import DetailHeader from '../common/DetailHeader/DetailHeader';
import { LineOfBusinessId } from '../../helpers/enums/LineOfBusinessId';
import { getTrimmedTaskEntityForTask, isTaskOverdue } from '../../helpers/TaskHelpers';
import TaskComments from "../TaskComments/TaskComments";
import DataReadService from '../../services/DataReadService';
import MultiUserSelector from "../MultiUserSelector/MultiUserSelector";
import SpinnerBackdrop from '../common/SpinnerBackdrop';
import useAsyncFunctionIsLoading from '../../hooks/useIsLoadingAsync';
import { Delete } from '@material-ui/icons';
import DeleteTasksConfirmDialog from '../DeleteTasksConfirmDialog/DeleteTasksConfirmDialog';
import LastModified from '../RequestDetails/LastModified';
import RestrictRequestToggle from '../RequestDetails/RestrictRequestToggle';
import ConditionalVisiblity from '../common/ConditionalVisibility/ConditionalVisiblity';
import RestrictedIcon from '../common/RestrictedIcon/RestrictedIcon';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        paddingTop: theme.spacing(0),
        maxHeight: `calc(100vh - ${theme.spacing(60)}px)`,
        display: "flex",
        flexDirection: "column",
    },
    luxInterior: {
        ...detailPanePadding,
        ...marginAroundDescription,
        "& input,& textarea": {
            ...detailPaneTextStyle
        },
        display: "flex",
        flexDirection: "column",
        flex: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    header: {
        backgroundColor: theme.palette.mossadams.darkGreen400,
        color: theme.palette.primary.contrastText,
        "&& .MuiIconButton-label": {
            color: theme.palette.primary.contrastText,
        }
    },
    column: {
        display: "flex",
        flexDirection: "column"
    },
    formControl: {
        width: "100%",
        marginBottom: theme.spacing(5),
    },
    formControlLabelStart: {
        margin: "0px 0px 0px 2px",
        display: "flex",
        "&.overdue": {
            "& .MuiInputBase-root input": {
                color: "red"
            },
            "& time ": {
                color: "red"
            },
        }
    },
    formControlLabelEnd: {
        marginLeft: "0"
    },
    label: {
        minWidth: theme.spacing(65),
        margin: "0px 12px 0px 0px",
        justifyContent: "flex-end",
        fontSize: "1.1rem",
    },
    disabledUserInput: {
        "& .MuiSelect-select.MuiSelect-select": {
            color: theme.palette.mossadams.gray200
        },
    },
    row: {
        display: "flex",
        flexDirection: "row"
    },
    draft: {
        "&& .MuiInputBase-input ": {
            backgroundColor: theme.palette.background.paper,
            color: "red"
        }
    },
    completed: {
        color: theme.palette.mossadams.gray200,
        "&& input, .MuiInputBase-input": {
            color: theme.palette.mossadams.gray200,
        }
    },
    limitAccess: {
        marginRight: "15px",
        padding: "15px",
        backgroundColor: theme.palette.background.paper,
        "& .MuiFormControlLabel-label": {
            width: "100%"
        },
        "& .MuiCheckbox-root": {
            paddingLeft: 0,
            paddingTop: 0,
            paddingBottom: 0
        }
    },
    nonEditableField: {
        fontSize: "1rem"
    },
    checkboxGroup: {
        flex: 1,
        width: "100%"
    },
    attachments: {},
    descriptionTextField: {
        marginTop: theme.spacing(4),
        "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
            transform: "translate(20px, 13px) scale(1)",
        },
        "& .MuiOutlinedInput-multiline": {
            padding: '10px 10px',
        },
        "&&& textarea": {
            overflowY: "auto !important",
            maxHeight: "360px",
            fontSize: "0.9rem",
            fontFamily: "inherit",
            lineHeight: "inherit"
        },
        "& legend": {
            width: "84px"
        }
    },
    notesTextField: {
        marginTop: theme.spacing(4),
        "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
            transform: "translate(20px, 13px) scale(1)",
        },
        "& .MuiOutlinedInput-multiline": {
            padding: '10px 10px',
        },
        "&&& textarea": {
            overflowY: "auto !important",
            maxHeight: "360px",
            fontSize: "0.9rem",
            fontFamily: "inherit",
            lineHeight: "inherit"
        },
        "& legend": {
            width: "84px"
        }
    },
    buttonRow: {
        display: "flex",
        marginRight: theme.spacing(-5),
        marginLeft: theme.spacing(-5),
    },
    actionButton: {
        ...actionButtonVariant
    },
    accordion: {
        ...detailPaneAccordionStyle
    },
    accordionHeader: {
        ...detailPaneAccordionHeaderStyle
    },
    lastUpdated: {
        ...detailsPaneLastUpdatedStyle
    },
    dateInput: {
        "& .MuiInputBase-input": {
            padding: '4px 0 6px 0'
        }
    }
}))

export const IsDirtyDisabledMessage = "You must Save or Discard Changes to the request to update comments and attachments.";

export const TaskDetailsEditor = (props: ITaskDetailsEditorProps): JSX.Element => {
    const {
        onTaskDocumentUpdated: handleTaskDocumentsUpdated
    } = props;

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [cancelConfirmDialogIsOpen, setCancelConfirmDialogIsOpen] = React.useState<boolean>(false);
    const [updatedTask, setUpdatedTask] = React.useState<ITask>(props.task);
    const [taskDocuments, setTaskDocuments] = React.useState<ITaskDocument[]>([]);
    const [originalTask, setOriginalTask] = React.useState<ITask>(props.task);
    const [commentsLoading, setCommentsLoading] = React.useState(false);
    const [attachmentsLoading, setAttachmentsLoading] = React.useState(false);
    const [isDirty, setIsDirty] = React.useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

    let taskValidator = new TaskValidator(props.taskTypeList ? props.taskTypeList : []);

    React.useEffect(() => {
        setOriginalTask(props.task);
        setUpdatedTask(props.task);
        if (props.task.isNew) {
            setIsDirty(true);
        }
    }, [props.task]);

    React.useEffect(() => {
        props.onDataChanged(isDirty);
    }, [isDirty]);

    React.useEffect(() => {
        if (updatedTask.isNew) {
            setIsDirty(true);
        } else {
            setIsDirty(taskIsDirty());
        }

    }, [updatedTask]);

    const taskIsDirty = () => {
        return !_.isEqual(updatedTask, originalTask);
    }

    const handleRequestDateChange = (date: any) => {
        const task = { ...updatedTask, requestedDate: date };
        setUpdatedTask({
            ...task,
            isOverdue: isTaskOverdue(task)
        });
    };

    const handleCheckboxChange = (e: any) => {
        if (e === null) {
            return;
        }
        switch (e.target.name) {
            default:
                setUpdatedTask({
                    ...updatedTask,
                    [e.target.name]: e.target.checked,
                });
                break;
        }
    }

    const handleFieldChange = (e: any) => {
        if (e === null) {
            return;
        }
        switch (e.target.name) {
            case "taskStatusDescription":
                const statusId = getLookupKey(props.taskStatusList!, e.target.value);
                setUpdatedTask({
                    ...updatedTask,
                    taskStatusId: statusId,
                    taskStatusDescription: e.target.value,
                });
                break;
            case "socStatusDescription":
                const socStatusId = getLookupKey(props.socStatusList!, e.target.value);
                setUpdatedTask({
                    ...updatedTask,
                    socStatusId: socStatusId,
                    socStatusDescription: e.target.value,
                });
                break;
            case "taskTypeDescription":
                const typeId = getLookupKey(props.taskTypeList!, e.target.value);
                setUpdatedTask({
                    ...updatedTask,
                    taskTypeId: typeId,
                    taskTypeDescription: e.target.value,
                });
                break;
            case "taskPriorityDescription":
                const priorityId = getLookupKey(props.taskPriorityList!, e.target.value);
                setUpdatedTask({
                    ...updatedTask,
                    taskPriorityId: priorityId,
                    taskPriorityDescription: e.target.value,
                });
                break;
            case "areaDescription":
                const areaId = getLookupKey(props.areaList!, e.target.value);
                setUpdatedTask({
                    ...updatedTask,
                    areaId: areaId,
                    areaDescription: e.target.value,
                });
                break;
            case "phaseDescription":
                const phaseId = getLookupKey(props.phaseList!, e.target.value);
                setUpdatedTask({
                    ...updatedTask,
                    phaseId: phaseId,
                    phaseDescription: e.target.value,
                });
                break;
            case "mossAdamsContactUserId":
                const mossAdamsContactUserName = getUserNameAllowNull(props.mossAdamsUserList!, e.target.value);
                setUpdatedTask({
                    ...updatedTask,
                    mossAdamsContactUserId: e.target.value,
                    mossAdamsContactUserName: mossAdamsContactUserName
                });
                break;
            case "taskEntity":
                setUpdatedTask({
                    ...updatedTask,
                    taskEntity: e.target.value === '' ? null : e.target.value.trimStart(),
                });
                break;

            case "notes":
                setUpdatedTask({
                    ...updatedTask,
                    notes: e.target.value === '' ? null : e.target.value.trimStart(),
                });
                break;
            case "title":
            case "description":
            default:
                setUpdatedTask({
                    ...updatedTask,
                    [e.target.name]: e.target.value,
                });
                break;
        }
    }

    const handleCancelClick = () => {
        setCancelConfirmDialogIsOpen(true);
    }
    const handleCancelConfirmDialogClose = () => { //discard => no
        setCancelConfirmDialogIsOpen(false);
    };
    const handleCancelConfirmDialogConfirm = () => { //discard => yes
        setCancelConfirmDialogIsOpen(false);

        if (updatedTask.isNew) {
            props.onCancelNewTask();
        }
        setUpdatedTask(originalTask);
    };

    const handleSaveClick = async () => {
        saveTask();
    }

    const getUpdatedTaskStatus = (task: ITask) => {
        if ((task.taskStatusId == TaskStatus.New.id || task.taskStatusId == TaskStatus.MossAdamsResponded.id) && props.currentUser?.isMossAdamsStaff == false) {
            task.taskStatusId = TaskStatus.SubmittedByClient.id;
            task.taskStatusDescription = TaskStatus.SubmittedByClient.string;
        }
        if ((task.taskStatusId == TaskStatus.New.id || task.taskStatusId == TaskStatus.SubmittedByClient.id) && props.currentUser?.isMossAdamsStaff == true) {
            task.taskStatusId = TaskStatus.MossAdamsResponded.id;
            task.taskStatusDescription = TaskStatus.MossAdamsResponded.string;
        }
        return task;
    }

    const handleCommentCreated = () => {
        var updatedData: ITask = {
            ...originalTask,
        }
        updatedData = getUpdatedTaskStatus(updatedData);

        if (originalTask.taskStatusId !== updatedData.taskStatusId) {
            updatedData.updatedDateTime = new Date();

            setOriginalTask(updatedData);
            setUpdatedTask(updatedData);
            props.onTaskUpdated(updatedData, updatedData.isNew);
        }
    }

    const handleAssigneesChange = (e: any, newValue: any) => {
        setUpdatedTask({
            ...updatedTask,
            taskAssignees: newValue,
        });
    }

    const { onTrigger: saveTask, loading: submitting } = useAsyncFunctionIsLoading(async () => {
        if (isDirty) {
            if (taskValidator.TaskIsValid(updatedTask) === false) {
                enqueueSnackbar("There are invalid values, please correct before saving.", { variant: SnackbarVariantTypes.Error });
                return;
            }

            if (taskDocuments.length > 1 && updatedTask.allowMultipleAttachments === false && originalTask.allowMultipleAttachments === true) {
                enqueueSnackbar("You cannot unselect Allow Multiple Attachments while there are multiple attachments.", { variant: SnackbarVariantTypes.Error });
                return;
            }

            const request: ITask = { ...updatedTask, taskEntity: getTrimmedTaskEntityForTask(updatedTask) };
            const dataWriteService = new DataWriteService();
            const dataReadService = new DataReadService();
            const result = await dataWriteService.UpdateTask(request);
            if (result.status) {
                const fetchedTask = await dataReadService.GetListOfTasks({ TaskListId: request.taskListId, Tasks: [request] });

                if (result.response && fetchedTask.data?.[0]) {
                    const task: ITask = fetchedTask.data?.[0];
                    task.engagementLineOfBusinessId = updatedTask.engagementLineOfBusinessId;
                    task.hasAttachments = taskDocuments.length > 0;
                    setUpdatedTask(task);
                    props.onTaskUpdated(task, updatedTask.isNew);
                    setIsDirty(false);
                    enqueueSnackbar("Request saved successfully.", { variant: SnackbarVariantTypes.Success });
                }
                else {
                    enqueueSnackbar("Failed to retrieve updated request.", { variant: SnackbarVariantTypes.Error });
                }

            } else {
                const errors = ["Failed to save task.", ...result.errorMessages];
                Utils.enqueueMultiLineSnackbar(errors, enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
            }
        }
    });

    const onUpdateTaskRestriction = (task: ITask): void => {
        props.onTaskUpdated(task, false, false);
        setOriginalTask(task);
        const updated = isDirty ? { ...updatedTask, updatedDateTime: task.updatedDateTime, isRestricted: task.isRestricted } : { ...task };
        setUpdatedTask(updated);
    }

    return (
        <>
            {updatedTask ?
                <Paper className={classes.root}>
                    <ConfirmDialog
                        isOpen={cancelConfirmDialogIsOpen}
                        onClose={handleCancelConfirmDialogClose}
                        onConfirm={handleCancelConfirmDialogConfirm}
                        title="Discard Changes"
                        children={<Typography>Are you sure you would like to discard changes?</Typography>}
                    />
                    <DeleteTasksConfirmDialog
                        isOpen={deleteDialogOpen}
                        onDelete={props.onDelete}
                        onClose={() => setDeleteDialogOpen(false)}
                        tasks={[props.task]}
                    />
                    <SpinnerBackdrop isActive={submitting || commentsLoading || attachmentsLoading} />
                    <DetailHeader onClose={props.onClose}>
                        <Box className={classes.buttonRow}>
                            <Button className={classes.actionButton} color="primary" disabled={!isDirty} onClick={handleSaveClick} title="Save" startIcon={<SaveIcon />}></Button>
                            <Button className={classes.actionButton} color="primary" disabled={!isDirty} onClick={handleCancelClick} title="Discard Changes" startIcon={<DiscardIcon />}></Button>
                            <Button className={classes.actionButton} color="primary"
                                onClick={() => setDeleteDialogOpen(true)} title="Delete Request"
                                startIcon={<Delete />}
                            />
                        </Box>
                    </DetailHeader>

                    <Divider />
                    <Box className={classes.luxInterior}>
                        <Grid container className={updatedTask.taskStatusDescription == TaskStatus.Completed.string ? classes.completed : undefined}>
                            <Grid item
                                xs={12}
                            >
                                <RestrictRequestToggle task={originalTask} onUpdateTaskRestriction={onUpdateTaskRestriction} />
                            </Grid>
                            <Grid item
                                xs={12}
                            >
                                <LastModified task={updatedTask} />
                            </Grid>

                            <Accordion defaultExpanded className={classes.accordion}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="TaskDetailsEditor-RequestDetails-content"
                                    id="TaskDetailsEditor-RequestDetails-header"
                                    className={classes.header}
                                >
                                    <Typography className={classes.accordionHeader}>Request Details</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid
                                            container
                                            item
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="stretch"
                                            lg={12}
                                        >
                                            <Grid
                                                container
                                                item
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="stretch"
                                                xs={12}
                                            >
                                                <Grid
                                                    container
                                                    item
                                                    direction="column"
                                                    alignItems="stretch"
                                                    xs={12}
                                                >
                                                    <Grid item>
                                                        <Grid container alignItems="flex-start" justifyContent="space-between">
                                                            <DetailDataField
                                                                labelClasses={(props.isEditable ? "" : " Mui-disabled")}
                                                                label={"Request #"}
                                                            >
                                                                <Typography color="textPrimary" className={classes.nonEditableField}>{`${updatedTask.taskNumber}`}</Typography>
                                                            </DetailDataField>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <DetailDataField
                                                            label={"Request Title"}
                                                        >
                                                            <TextField
                                                                value={updatedTask.title}
                                                                name="title"
                                                                disabled={!props.isEditable}
                                                                onChange={handleFieldChange}
                                                                helperText={taskValidator.TitleIsValid(updatedTask.title) ? '' : taskValidator.TitleValidationMessage}
                                                                error={!taskValidator.TitleIsValid(updatedTask.title)}
                                                            />
                                                        </DetailDataField>
                                                    </Grid>
                                                    <Grid item>
                                                        <DetailDataField
                                                            label={"Entity"}
                                                            key={updatedTask.taskId}
                                                        >
                                                            <TextField
                                                                value={updatedTask.taskEntity}
                                                                name="taskEntity"
                                                                disabled={!props.isEditable}
                                                                onChange={handleFieldChange}
                                                                error={!taskValidator.EntityIsValid(updatedTask.taskEntity)}
                                                                placeholder={"(Optional)"}
                                                            />
                                                        </DetailDataField>
                                                    </Grid>
                                                    <Grid item>
                                                        <FormControl className={classes.formControl}>
                                                            <TextField
                                                                className={classes.descriptionTextField}
                                                                label="Description"
                                                                name="description"
                                                                multiline
                                                                variant="outlined"
                                                                value={updatedTask.description}
                                                                onChange={handleFieldChange}
                                                                disabled={!props.currentUser?.isMossAdamsStaff}
                                                                error={!taskValidator.DescriptionIsValid(updatedTask.description)}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item>
                                                        <FormControl className={classes.formControl}>
                                                            <TextField
                                                                className={classes.descriptionTextField}
                                                                label="Notes"
                                                                name="notes"
                                                                multiline
                                                                variant="outlined"
                                                                value={updatedTask.notes || ''}
                                                                onChange={handleFieldChange}
                                                                disabled={!props.currentUser?.isMossAdamsStaff}
                                                                error={!taskValidator.NotesIsValid(updatedTask.notes)}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item>
                                                        <DetailDataField label={"Due Date"}
                                                            labelClasses={(props.isEditable ? "" : " Mui-disabled")}
                                                            controlClasses={(updatedTask.isOverdue ? " overdue" : "")}
                                                        >
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                <KeyboardDatePicker
                                                                    className={classes.dateInput}
                                                                    disableToolbar
                                                                    variant="inline"
                                                                    value={updatedTask.requestedDate}
                                                                    name="requestedDate"
                                                                    disabled={!props.isEditable}
                                                                    autoOk={true}
                                                                    onChange={handleRequestDateChange}
                                                                    format="MM/dd/yyyy"
                                                                    error={!taskValidator.RequestedDateIsValid(updatedTask.requestedDate, updatedTask.taskStatusId)}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </DetailDataField>
                                                    </Grid>
                                                    <Grid item>
                                                        <MultiUserSelector
                                                            selectAll
                                                            label={<Tooltip title={originalTask.isRestricted
                                                                ? 'Client visibility of this restricted request and associated data is limited to these users.'
                                                                : ''}
                                                            >
                                                                <label style={{ display: 'flex', alignItems: 'center' }}>
                                                                    Assignees: <ConditionalVisiblity isVisible={originalTask.isRestricted}>
                                                                        <span>
                                                                            <RestrictedIcon />
                                                                        </span>
                                                                    </ConditionalVisiblity>
                                                                </label>
                                                            </Tooltip>}
                                                            name="Assignees"
                                                            disabled={false}
                                                            selectedUsers={updatedTask.taskAssignees}
                                                            onSelectedUsersChange={handleAssigneesChange}
                                                            userList={props.clientUserList ? props.clientUserList.filter(user => user.isActive) : []}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <AutocompleteField
                                                            name="taskTypeDescription"
                                                            value={updatedTask.taskTypeDescription}
                                                            onChange={handleFieldChange}
                                                            optionData={props.taskTypeList!}
                                                            controlProps={{
                                                                readOnly: !props.isEditable,
                                                                label: "Request Type",
                                                                error: !taskValidator.TypeIsValid(updatedTask.taskTypeId),
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <AutocompleteField
                                                            name="taskStatusDescription"
                                                            className={(updatedTask.taskStatusDescription == TaskStatus.Draft.string ? classes.draft : "")}
                                                            value={updatedTask.taskStatusDescription}
                                                            onChange={handleFieldChange}
                                                            optionData={props.taskStatusList!}
                                                            controlProps={{
                                                                readOnly: !props.isEditable,
                                                                label: "Status",
                                                                error: !taskValidator.StatusIsValid(updatedTask.taskStatusId),
                                                            }}
                                                            disabledOptionValues={[TaskStatus.Deleted.string]}
                                                        />
                                                    </Grid>
                                                    {updatedTask.isSocEngagementType &&
                                                        <>
                                                            <Grid item>
                                                                <AutocompleteField
                                                                    name="socStatusDescription"
                                                                    value={updatedTask.socStatusDescription}
                                                                    onChange={handleFieldChange}
                                                                    optionData={props.socStatusList!}
                                                                    controlProps={{
                                                                        readOnly: !props.isEditable,
                                                                        label: "SOC Status",
                                                                        error: !taskValidator.SocStatusIsValid(updatedTask.socStatusId, updatedTask.taskStatusId, updatedTask.isPublished, updatedTask.isSocEngagementType),
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </>
                                                    }

                                                    {updatedTask.engagementLineOfBusinessId == LineOfBusinessId.BA &&
                                                        <>
                                                            <Grid item>
                                                                <AutocompleteField
                                                                    name="areaDescription"
                                                                    value={updatedTask.areaDescription}
                                                                    onChange={handleFieldChange}
                                                                    optionData={props.areaList!}
                                                                    controlProps={{
                                                                        readOnly: !props.isEditable,
                                                                        label: "Area",
                                                                        error: !taskValidator.AreaIsValid(updatedTask.areaId),
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <AutocompleteField
                                                                    name="phaseDescription"
                                                                    value={updatedTask.phaseDescription}
                                                                    onChange={handleFieldChange}
                                                                    optionData={props.phaseList!}
                                                                    controlProps={{
                                                                        readOnly: !props.isEditable,
                                                                        label: "Phase",
                                                                        error: !taskValidator.PhaseIsValid(updatedTask.phaseId),
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </>
                                                    }
                                                    <Grid item>
                                                        <AutocompleteField
                                                            name="taskPriorityDescription"
                                                            value={updatedTask.taskPriorityDescription}
                                                            onChange={handleFieldChange}
                                                            optionData={props.taskPriorityList!}
                                                            controlProps={{
                                                                readOnly: !props.isEditable,
                                                                label: "Priority",
                                                                error: !taskValidator.PriorityIsValid(updatedTask.taskPriorityId),
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <AutocompleteField
                                                            name="mossAdamsContactUserId"
                                                            value={updatedTask.mossAdamsContactUserId}
                                                            isInactiveValue={!props.mossAdamsUserList!.find(user => user.userId === updatedTask.mossAdamsContactUserId)?.isActive}
                                                            inactiveInputValue={updatedTask.mossAdamsContactUserName}
                                                            onChange={handleFieldChange}
                                                            optionData={props.mossAdamsUserList && props.mossAdamsUserList.filter(user => user.isActive)}
                                                            optionAccessorsProp={{ idKey: 'userId', displayKey: 'displayName', valueKey: 'userId' }}
                                                            controlProps={{
                                                                readOnly: !props.currentUser.isMossAdamsStaff || !props.isEditable,
                                                                label: "MA Contact",
                                                                error: !taskValidator.MossAdamsContactUserIsValid(updatedTask.mossAdamsContactUserId, updatedTask.taskStatusId),
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item className={classes.checkboxGroup}>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            className={classes.formControlLabelEnd}
                                                            classes={{
                                                                label: classes.label
                                                            }}
                                                            control={
                                                                <Checkbox disabled={!props.isEditable} color="primary" checked={updatedTask.allowMultipleAttachments} name="allowMultipleAttachments" onChange={handleCheckboxChange} />
                                                            }
                                                            label={"Allow Multiple Attachments"}
                                                            labelPlacement={"end"}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Tooltip title={isDirty ? IsDirtyDisabledMessage : ''} placement='top'>
                                <Grid
                                    item
                                    className={classes.attachments}
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    xs={12}
                                >
                                    <AttachmentsGrid
                                        onLoadingStatusChanged={(loading: boolean) => setAttachmentsLoading(loading)}
                                        allowMultipleAttachments={updatedTask.allowMultipleAttachments}
                                        task={updatedTask}
                                        setTaskDocuments={setTaskDocuments}
                                        onTaskDocumentUpdated={handleTaskDocumentsUpdated}
                                        taskDocuments={taskDocuments}
                                        clientName={props.clientName}
                                        engagementName={props.engagementName}
                                        disabled={isDirty || originalTask.taskStatusId === TaskStatus.Completed.id || updatedTask.isNew}
                                        uploadFolderId={props.uploadFolderId}
                                        taskIsNew={updatedTask.isNew}
                                    />
                                </Grid>
                            </Tooltip>
                        </Grid>

                        <Tooltip title={isDirty ? IsDirtyDisabledMessage : ''}>
                            <Box>
                                <TaskComments
                                    onLoadingStatusChanged={(loading: boolean) => setCommentsLoading(loading)}
                                    onCommentCreated={handleCommentCreated}
                                    taskId={updatedTask.taskId}
                                    taskNumber={updatedTask.taskNumber}
                                    taskTitle={updatedTask.title}
                                    disabled={isDirty || originalTask.taskStatusId === TaskStatus.Completed.id || updatedTask.isNew}
                                />
                            </Box>
                        </Tooltip>
                    </Box>
                </Paper>
                :
                <></>}
        </>
    )
}