import React from "react"
import { Grid, IconButton, makeStyles, } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import IDetailHeaderProps from "./IDetailHeaderProps";

const useStyles = makeStyles((theme) => ({
    detailHeader: {
        fontSize: "1.5rem",
        whiteSpace: "nowrap",
        backgroundColor: theme.palette.mossadams.darkGreen400,
        color: theme.palette.primary.contrastText,
        paddingRight: theme.spacing(0),
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(2.5),
    },
    detailHeaderTitle: {
        "& .MuiInputBase-root": {
            fontSize: "1.25rem",
            color: theme.palette.primary.contrastText,
        },
        "& .MuiInput-underline:after, & .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInput-underline:before": {
            borderColor: theme.palette.primary.contrastText,
        },
        "& .MuiInputBase-input": {
            fontFamily: "Founders Grotesk Med",
        },
        color: theme.palette.primary.contrastText,
        width: "100%",
        padding: theme.spacing(2),
    },
    detailHeaderClose: {
        "& .MuiSvgIcon-root": {
            color: theme.palette.primary.contrastText,
        },
        float: "right",
        padding: theme.spacing(2),
    },
}));

const DetailHeader = ({ onClose, children }: IDetailHeaderProps): JSX.Element => {
    const classes = useStyles();
    return (
      <Grid container alignItems="flex-start" className={classes.detailHeader}>
        <Grid item xs={11}>
          {children}
        </Grid>
        <Grid item xs={1}>
          <IconButton
            className={classes.detailHeaderClose}
            aria-label="close"
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
}

export default DetailHeader;