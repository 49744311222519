import React from 'react'
import { ISavedView, ISavedViewControls } from '../../hooks/useSavedView';
import { Button, Typography, Grid, TextField, Tooltip, makeStyles, TableCell, TableRow } from '@material-ui/core';
import { Cancel, CheckCircle, Delete, Edit, Save, SystemUpdateAlt } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    grid: {
        "&:nth-child(odd)": {
            backgroundColor: theme.palette.mossadams.everyOtherRowColor,
        },
    },
    buttonActionText: {
        fontSize: "0.75rem",
    },
}))

export interface ISavedViewTableRowProps {
     view?: ISavedView, 
     viewControls: ISavedViewControls, 
     onLoadView: Function, 
     onAddNewView?: Function, 
     onCancelAddView?: Function 
}

export const SavedViewTableRow = ({ view, viewControls, onLoadView, onAddNewView, onCancelAddView }: ISavedViewTableRowProps) => {
    const [editActive, setEditActive] = React.useState(false);
    const [deleteActive, setDeleteActive] = React.useState(false);

    const [newName, setNewName] = React.useState("");
    const [newNameIsDirty, setNewNameIsDirty] = React.useState(false);

    const classes = useStyles();

    const onChangeName = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setNewName(e.target.value);
        setNewNameIsDirty(true);
    }

    const onClickEdit = (id: string) => {
        setNewName(id);
        setNewNameIsDirty(false);
        setEditActive(prevValue => !prevValue);
    }

    const onClickSaveRename = (id: string) => {
        viewControls.renameView(id, newName);
        setEditActive(prevValue => !prevValue);
    }

    const onClickLoad = (id: string) => {
        viewControls.loadView(id);
        onLoadView();
    }

    const onSaveNewView = () => {
        if (!newNameIsInvalid) {
            viewControls.saveView(newName);
            onAddNewView && onAddNewView();
        }
    }

    const onClickCancelAddView = () => {
        onCancelAddView && onCancelAddView();
    }

    const getNewViewErrorMessage = () => {
        if (!newName.length) {
            return "Name must contain at least one character";
        }
        else {
            return "Name must be unique";
        }
    }

    const newNameIsInvalid = newNameIsDirty && (!newName.length || viewControls.savedViews.some(view => view.id === newName));

    const addRow = !view && <TableRow className={classes.grid}>
        <TableCell>
            <Grid container alignItems='center'>
                <Grid item xs={8}>
                    <TextField value={newName} error={newNameIsInvalid} id="new-layout-name" fullWidth onChange={onChangeName}
                        helperText={newNameIsInvalid ? getNewViewErrorMessage() : "Please choose a unique name for the current layout"}
                    />
                </Grid>

                <Grid container item xs={4} justifyContent='flex-end'>
                    <SaveButton
                        onClick={onSaveNewView} disabled={!newNameIsDirty || newNameIsInvalid}
                    />
                    <CancelButton onClick={onClickCancelAddView} />
                </Grid>
            </Grid>
        </TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
    </TableRow>

    const viewRow = view && <TableRow className={classes.grid}>
        <TableCell style={{ width: '60%' }}>
            {editActive
                ? <Grid container alignItems='center'>
                    <Grid item xs={8}>
                        <TextField value={newName} error={newNameIsInvalid} id="layout-name" fullWidth onChange={onChangeName}
                            helperText={newNameIsInvalid ? getNewViewErrorMessage() : ""} />
                    </Grid>
                    <Grid container item xs={4} justifyContent='flex-end'>
                        <SaveButton
                            onClick={() => onClickSaveRename(view.id)} disabled={newNameIsInvalid}
                        />
                        <CancelButton onClick={() => setEditActive(false)} />
                    </Grid>
                </Grid>
                : <Grid container alignItems='center'>
                    <Grid item xs={8}>
                        <Typography>
                            {view.id}
                        </Typography>
                        {deleteActive &&
                            <Typography variant='caption'>
                                Are you sure you want to delete this saved layout?
                            </Typography>
                        }
                    </Grid>
                    <Grid container item xs={4} justifyContent='flex-end'>
                        {deleteActive && <>
                            <IconTextButtonItem
                                icon={<CheckCircle />}
                                text="Confirm"
                                onClick={() => viewControls.deleteView(view.id)}
                            />
                            <CancelButton onClick={() => setDeleteActive(false)} />
                        </>
                        }
                    </Grid>
                </Grid>
            }
        </TableCell>
        {!(deleteActive || editActive) ?
            <>
                <TableCell align='center'>
                    <Tooltip title={"Use Layout"}>
                        <Button onClick={() => onClickLoad(view.id)}>
                            <SystemUpdateAlt />
                        </Button>
                    </Tooltip>
                </TableCell>
                <TableCell align='center'>
                    <Tooltip title={"Rename"}>
                        <Button onClick={() => onClickEdit(view.id)}>
                            <Edit />
                        </Button>
                    </Tooltip>
                </TableCell>
                <TableCell align='center'>
                    <Tooltip title={"Delete"}>
                        <Button onClick={() => setDeleteActive(true)}>
                            <Delete />
                        </Button>
                    </Tooltip>
                </TableCell>
            </>
            : <>
                <TableCell />
                <TableCell />
                <TableCell />
            </>

        }
    </TableRow>

    return <>
        {addRow}
        {viewRow}
    </>
}

const IconTextButtonItem = ({ onClick, text, icon, disabled }: { onClick: () => void, text: string, icon: React.ReactNode, disabled?: boolean }) => {
    const classes = useStyles();

    return <>
        <Grid item>
            <Tooltip title={text}>
                <span>
                    <Button onClick={onClick} disabled={Boolean(disabled)}>
                        <Grid container direction='column' alignItems="center">
                            <Grid item>
                                {icon}
                            </Grid>
                            <Grid item>
                                <Typography variant="caption" className={classes.buttonActionText}>{text}</Typography>
                            </Grid>
                        </Grid>
                    </Button>
                </span>
            </Tooltip>
        </Grid>
    </>
}

const CancelButton = ({ onClick }: { onClick: () => void }) => {
    return <>
        <IconTextButtonItem
            onClick={onClick}
            icon={<Cancel />}
            text={"Cancel"}
        />
    </>
}

const SaveButton = ({ onClick, disabled }: { onClick: () => void, disabled?: boolean }) => {
    return <>
        <IconTextButtonItem
            onClick={onClick}
            icon={<Save />}
            text={"Save"}
            disabled={disabled}
        />
    </>
}