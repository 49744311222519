import { Typography } from '@material-ui/core';
import _ from 'lodash';
import React from 'react'
import { ConfirmDialog } from '../ConfirmDialog/ConfirmDialog';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    removeAll: boolean;
}

function BulkUpdateAccessDialog({ isOpen, onConfirm, onClose, removeAll }: Props) {
    const getTitle = (removeAll = false) => {
        return `Bulk ${removeAll ? 'Remove' : 'Update'} File Restrictions?`;
    }

    const getMessage = (removeAll = false) => {
        return `You are about to ${removeAll ? 'remove access restrictions from' : 'update access for'} all selected files. Are you sure that you want to proceed?`;
    }

    return (
        <>
            <ConfirmDialog
                title={getTitle()}
                isOpen={isOpen && !removeAll}
                onConfirm={onConfirm}
                onClose={onClose}
            >
                <Typography>
                    {getMessage()}
                </Typography>
            </ConfirmDialog>

            <ConfirmDialog
                title={getTitle(true)}
                isOpen={isOpen && removeAll}
                onConfirm={onConfirm}
                onClose={onClose}
            >
                <Typography>
                    {getMessage(true)}
                </Typography>
            </ConfirmDialog>
        </>
    )
}

export default BulkUpdateAccessDialog