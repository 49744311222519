import ITask from "../interfaces/ITask";
import { TaskStatus } from "./enums/TaskStatus";


export const engagementManagementPlaceholderNodeTitle = "No Requests Available";
export const nullTaskEntityGroupName = "No Entity";

export const taskStatusIsDraft = (task: ITask) => {
    return task.taskStatusDescription === TaskStatus.Draft.string;
}

export const taskStatusIsDeleted = (task: ITask) => {
    return task.taskStatusDescription === TaskStatus.Deleted.string;
}

export const taskIsPlaceholderNode = (task: ITask) => {
    return task?.title === engagementManagementPlaceholderNodeTitle;
}

export const taskIsNotPlaceholderNode = (task: ITask) => {
    return !taskIsPlaceholderNode(task);
}

export const someTaskHasEntityValue = (tasks: ITask[] = []) => {
    return tasks.some(task => task.taskEntity !== null);
}

export const someTaskHasEntityValueAnd = (tasks: ITask[], conjunctivePredicate: (task: ITask) => boolean) => {
    return tasks.some(task => (task.taskEntity !== null) && conjunctivePredicate(task));
}

export const getTrimmedTaskEntityForTask = (task: ITask) => {
    if (task.taskEntity === null) return null;
    return task.taskEntity?.trim();
}

export const isTaskOverdue = (task: ITask) => {
    if (!task?.requestedDate) return false;
    let jsDate = new Date(task.requestedDate);
    const overdue = jsDate.setHours(0, 0, 0, 0) < (new Date().setHours(0, 0, 0, 0));

    const completed = task.taskStatusId === TaskStatus.Completed.id;
    return !(completed) && overdue;
}