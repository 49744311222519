import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { Store } from "redux";
import {
  AccountInfo,
  AuthError,
  AuthenticationResult,
  Configuration,
  PublicClientApplication,
  RedirectRequest,
  EndSessionRequest,
  SilentRequest,
} from "@azure/msal-browser";
import { msalConfig, loginRequest } from "./authConfig";
import { emptyMsalAccountInfo } from "../store/InitialState";
import { setCurrentUser } from "../store/actions/index";
import ICurrentUser from "../interfaces/ICurrentUser";

/**
 * Encapsulates the authentication methods into one class.
 */
export default class AuthenticationService {
  private msalBrowser: PublicClientApplication;
  private apiScope = `${process.env.REACT_APP_CLIENTPLATFORM_API_SCOPE}`;

  private silentRequestForClientPlatformApi: SilentRequest = {
    scopes: [this.apiScope], // For the token that grants access to the Client Platform API.
    account: { ...emptyMsalAccountInfo },
    forceRefresh: false,
  };

  constructor() {
    this.msalBrowser = new PublicClientApplication(msalConfig);
  }

  /**
   * First thing this checks for is whether we are in the middle of a redirect from login.microsoftonline.com.  If so, and it gets
   * a result then it uses that information to also call the Micorosft Graph to get the user's profile info. (email, name, profile image).
   * If we are not in the redirect state, then the else case is to initiate the call to login.microsoftonline.com.
   *
   * @remarks
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#redirect-apis
   *
   * The behavior of this function assumes that this is being called when the application loads (or re-loads) and there is no auth token in redux.
   */
  loginViaRedirect = async (): Promise<ICurrentUser> => {
    let currentUser = {
      userId: "",
      isMossAdamsStaff: false,
      name: "",
      accessToken: "",
      accessTokenExpiresOn: null,
    } as ICurrentUser;

    await this.msalBrowser
      .handleRedirectPromise()
      .then(async (result: AuthenticationResult | null) => {
        if (result !== null) {
          const silentClientPlatformApiRequest = {
            ...this.silentRequestForClientPlatformApi,
            account: result.account!,
          };

          await this.msalBrowser
            .acquireTokenSilent(silentClientPlatformApiRequest)
            .then(async (userResult: AuthenticationResult) => {
              currentUser = await this.getCurrentUser(userResult.accessToken);
              currentUser.accessToken = userResult.accessToken;
              currentUser.accessTokenExpiresOn = userResult.expiresOn;
              currentUser.account = userResult.account!;
            })
            .catch((error: AuthError) => {
              console.log(error);
            });
        } else {
          try {
            await this.msalBrowser.loginRedirect(loginRequest);
          } catch (error) {
            console.log(error);
          }
        }
      })
      .catch((error: AuthError) => {
        console.log(error);
      });

    return currentUser;
  };

  logout = (userId?: string, redirectUri?: string) => {
    let account: AccountInfo | null = null;
    if (userId) {
      account = this.msalBrowser.getAccountByLocalId(userId);
    }

    if (account) {
      const logoutRequest: EndSessionRequest = {
        account: account,
        postLogoutRedirectUri: redirectUri ?? window.location.href,
      };
      this.msalBrowser.logoutRedirect(logoutRequest);
    } else {
      this.msalBrowser.logoutRedirect();
    }
  };

  /**
   * Get the user's profile image.
   *
   * @param accessToken The access token (a big string of letters, numbers, and dashes) from Microsoft Authentication Library (MSAL) for Microsoft's Graph API endpoints.
   *
   * @returns The user's profile image as a base64 encoded string or an empty string if they do not have a profile image.
   */
  private getCurrentUser = async (
    accessToken: string
  ): Promise<ICurrentUser> => {
    let currentUser = {} as ICurrentUser;

    const axiosRequestConfig: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${accessToken}` },
      timeout: parseInt(
        process.env.REACT_APP_CLIENTPLATFORM_API_TIMEOUT as string
      ),
    };
    const endpoint = `${process.env.REACT_APP_CLIENTPLATFORM_API_URL}${process.env.REACT_APP_CLIENTPLATFORM_API_CURRENTUSER}`;

    await axios
      .get(endpoint, axiosRequestConfig)
      .then(async (response: AxiosResponse<ICurrentUser>) => {
        currentUser = response.data;
      })
      .catch((error: AxiosError) => {
        console.log(error);
      });

    return currentUser;
  };

  /**
   * This call is for when the user needs to update and expired authentication token for accessing the MATRIX API.
   *
   * @param store A reference to the Redux store.
   *
   * @returns Either the updated token or an empty string unable to get a new token.
   */
  getClientPlatformApiTokenSilent = async (store: Store): Promise<string> => {
    const { currentUser } = store.getState();
    const silentRequest = {
      ...this.silentRequestForClientPlatformApi,
      account: { ...currentUser.account },
    };
    let token = "";

    await this.msalBrowser
      .acquireTokenSilent(silentRequest)
      .then(async (graphResult: AuthenticationResult): Promise<void> => {
        const userUpdated: ICurrentUser = {
          ...currentUser,
          accessToken: graphResult.accessToken,
          accessTokenExpiresOn: graphResult.expiresOn,
        };

        store.dispatch(setCurrentUser({ currentUser: userUpdated }));
        token = graphResult.accessToken;
      })
      .catch((error: AuthError) => {
        console.log(error);
      });

    return token;
  };
}
