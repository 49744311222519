// Ids are fragile due to this relationship being defined in ClientPlatform\ClientPlatform.Db\Scripts\Seed\0_HierarchyType_Seed.sql

export const HierarchyType = {
    Client: {
        id: 1,
        string: "Client"
    },
    Engagement: {
        id: 2,
        string: "Engagement"
    },
    SystemFolder: {
        id: 3,
        string: "System Folder"
    },
    CustomFolder: {
        id: 4,
        string: "Custom Folder"
    },
    SecureFolder: {
        id: 5,
        string: "Secure Task Folder"
    },
    File: {
        id: 6,
        string: "File"
    },
    RestrictedFolder: {
        id: 7,
        string: "Restricted Folder"
    },
}