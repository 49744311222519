import React from 'react'
import { useFileUpload } from './hooks/useFileUpload';
import { useSnackbar } from 'notistack';
import { SnackbarVariantTypes } from '../../helpers/enums/enums';
import IDocumentsUploadModel from '../../interfaces/IDocumentsUploadModel';
import IPlatformApiPostResult from '../../interfaces/IPlatformApiPostResult';
import { Utils } from '../../utilities/utils';
import { FileUploadContext } from './FileUploadContext';
import { useGetFileCollisions } from './hooks/useGetFileCollisions';
import ProgressSpinnerBackdrop from '../../components/common/ProgressSpinnerBackdrop/ProgressSpinnerBackdrop';
import SpinnerBackdrop from '../../components/common/SpinnerBackdrop';
import ICurrentUser from '../../interfaces/ICurrentUser';
import { useSelector } from 'react-redux';

export interface FileUploadContextProviderProps {
    children: React.ReactNode;
    folderId: string | undefined;
    newVersionDocumentId?: string;
    isRestrictedFileUpload: boolean;
    suppressNotifications?: boolean;
}

export const FileUploadContextProvider = ({ children, folderId = '', newVersionDocumentId, isRestrictedFileUpload, suppressNotifications }: FileUploadContextProviderProps) => {

    const [permittedUserIds, setPermittedUserIds] = React.useState<string[]>([]);
    const [fileNameCollisions, setFileNameCollisions] = React.useState<File[]>([]);
    const [onUploadSuccess, setOnUploadSuccess] = React.useState<(result: IPlatformApiPostResult) => void>();
    const [onUploadFailure, setOnUploadFailure] = React.useState<(result: IPlatformApiPostResult) => void>();
    const { enqueueSnackbar } = useSnackbar();
    const currentUser = useSelector((state: any) => state.currentUser as ICurrentUser);

    const {
        files,
        updateSelectedFiles,
        handleUpload,
        uploadProgress,
        isUploading,
        uploadResult,
    } = useFileUpload();

    const { getDuplicateFiles, loadingNameCollisions } = useGetFileCollisions(files, folderId);

    const onInitiateUpload = async () => {
        const nameCollisions = newVersionDocumentId === undefined
            ? await getDuplicateFiles()
            : [];
        if (nameCollisions === undefined) {
            return;
        }
        setFileNameCollisions(nameCollisions);
        return nameCollisions;
    }

    const onAfterUploadAttempted = () => {
        if (uploadResult) {
            if (uploadResult.status) {
                onUploadSuccess && onUploadSuccess(uploadResult);
                setPermittedUserIds([]);
            }
            else {
                onUploadFailure && onUploadFailure(uploadResult);
            }

            setFileNameCollisions([]);
        }
    }
    React.useEffect(onAfterUploadAttempted, [uploadResult])

    React.useEffect(() => {
        setFileNameCollisions([]);
        setPermittedUserIds([]);
    }, [isRestrictedFileUpload, folderId])

    const getUploadModel = (overwriteExisting: boolean = true, permittedUsers: string[] = []) => {
        const model: IDocumentsUploadModel = {
            folderId: folderId,
            newVersionDocumentId: newVersionDocumentId,
            documentFiles: [],
            overwriteExisting: overwriteExisting,
            isPrivate: Boolean(isRestrictedFileUpload) || !currentUser.isMossAdamsStaff,
            suppressNotifications: Boolean(suppressNotifications),
            permittedUsers: permittedUsers || permittedUserIds,
        }
        return model;
    }

    const handleRemoveFile = (fileToRemove: File) => {
        const remainingFiles = files.filter((file) => file.name !== fileToRemove.name);
        updateSelectedFiles(remainingFiles);
    }

    const uploadFiles = async () => {
        const nameCollisions = await onInitiateUpload();

        if (nameCollisions && !nameCollisions?.length) {
            if (currentUser.isMossAdamsStaff) {
                await handleUpload(getUploadModel(true))
            }
            else {
                await handleUpload(getUploadModel(true, [currentUser.userId]));
            }
        }
    }

    const uploadRestrictedFiles = async (permittedUsers: string[]) => {
        const nameCollisions = await onInitiateUpload();

        if (nameCollisions && !nameCollisions?.length) {
            await handleUpload(getUploadModel(true, permittedUsers));
        }
        else {
            const message = restrictedFilenameCollisionMessage + nameCollisions?.map(nameCollision => `\n${nameCollision.name}`);
            Utils.enqueueMultiLineSnackbar([message], enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
        }
    }

    const uploadNewVersion = async () => {
        await handleUpload(getUploadModel())
    }

    const uploadWithRenameOnCollision = async () => {
        await handleUpload(getUploadModel(false))
    }

    const setUploadSuccessCallback = (callback: (result: IPlatformApiPostResult) => void) => {
        setOnUploadSuccess(() => callback);
    }

    const setUploadFailureCallback = (callback: (result: IPlatformApiPostResult) => void) => {
        setOnUploadFailure(() => callback);
    }

    const uploadingBackdrop = isUploading ? <ProgressSpinnerBackdrop key={`uploadingBackdrop`} progress={uploadProgress} isUploading={isUploading} />
        : <SpinnerBackdrop isActive={loadingNameCollisions} />

    const value: FileUploadContext = {
        files,
        fileNameCollisions,
        handleFileChange: updateSelectedFiles,
        handleRemoveFile,
        uploadFiles,
        uploadRestrictedFiles,
        uploadNewVersion,
        uploadWithRenameOnCollision,
        setUploadSuccessCallback,
        setUploadFailureCallback,
        uploadingBackdrop,
    }

    return <FileUploadContext.Provider value={value}>
        {children}
    </FileUploadContext.Provider>
}

const restrictedFilenameCollisionMessage = "Restricted file names must be unique within a folder. You must change the following filenames to upload them in this folder:";
